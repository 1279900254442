import {memo} from 'react';
import {twMerge} from 'tailwind-merge';
import {SuggestedEmailSection as SuggestedEmailSectionType} from './types';
import Mail01SolidIcon from '@/components/icons/Mail01SolidIcon';
import {Trans} from '@lingui/macro';
import {Button} from '@nextui-org/react';
import Copy03SolidIcon from '@/components/icons/Copy03SolidIcon';
import Markdown from 'react-markdown';

const SuggestedEmailSection = memo(
  ({
    suggested_email,
    className,
  }: {className?: string} & SuggestedEmailSectionType) => {
    return (
      <div
        className={twMerge('flex flex-col gap-6 rounded-3xl p-6', className)}>
        <div className="flex items-center gap-2 border-b-[thin] border-default-200 pb-6">
          <Mail01SolidIcon className="h-5 w-5 shrink-0 fill-default-400" />
          <h5 className="text-sm font-medium text-default-400">
            <Trans>Suggested email</Trans>
          </h5>
        </div>
        <Markdown className="prose prose-sm w-full break-words text-sm text-foreground">
          {suggested_email}
        </Markdown>
        <Button
          aria-label="Copy text"
          className="h-9 rounded-lg text-sm font-semibold"
          radius="none"
          variant="flat"
          color="primary"
          onPress={() => {
            navigator.clipboard.writeText(suggested_email);
          }}
          startContent={
            <Copy03SolidIcon className="h-4 w-4 shrink-0 fill-primary" />
          }>
          <Trans>Copy Text</Trans>
        </Button>
      </div>
    );
  },
);

SuggestedEmailSection.displayName = 'SuggestedEmailSection';

export default SuggestedEmailSection;
