import {SVGProps} from 'react';

export default function PortalChatActiveIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3019 8.44806C23.3689 11.515 25.3163 14.961 26.0663 17.9609C26.8294 21.0134 26.2847 23.1717 25.1032 24.3532C23.9217 25.5347 21.7634 26.0794 18.7109 25.3163C18.0017 25.139 17.2675 24.8947 16.5194 24.5846L14.7515 26.3524V26.3537C19.5015 28.5845 24.1312 28.625 26.7531 26.0031C30.7306 22.0256 28.581 13.4273 21.9519 6.79815C15.3227 0.169025 6.72439 -1.98056 2.74691 1.99691C0.125053 4.61877 0.16549 9.24847 2.39634 13.9985H2.40157L4.16656 12.2335C3.85587 11.4843 3.61125 10.7492 3.43372 10.0391C2.67061 6.98665 3.21533 4.82833 4.39683 3.64683C5.57833 2.46533 7.73665 1.92061 10.7891 2.68372C13.789 3.4337 17.235 5.38112 20.3019 8.44806ZM14.1662 14.5828H5.99951V12.2495H15.3328C15.9772 12.2495 16.4995 12.7718 16.4995 13.4162V22.7495H14.1662V14.5828ZM10.0845 18.6665H2.39356V16.3332H11.2512C11.8955 16.3332 12.4179 16.8555 12.4179 17.4999V26.3549H10.0845V18.6665Z"
        fill="currentColor"
      />
    </svg>
  );
}
