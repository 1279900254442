import {CREATE_MESSAGE} from '@/common/mutations';
import {useChatContext} from '@/hooks';
import {useMutation} from '@apollo/client';
import {memo, PropsWithChildren, useCallback, useMemo, useState} from 'react';
import {useCreateSessionWithMessage} from '../Chat/hooks';
import {UserChatMessageType} from '@/__generated__/graphql';
import {ChatInputContext} from '@/contexts/ChatInputContext';
import {Outlet} from 'react-router-dom';

const ChatInputProvider = memo(({children}: PropsWithChildren) => {
  const [text, setText] = useState('');
  const {activeSession, activeAgent, addOrUpdateMessage} = useChatContext();
  const resetText = useCallback(() => setText(''), [setText]);
  const [createMessage, {loading: createMessageIsLoading}] = useMutation(
    CREATE_MESSAGE,
    {
      onCompleted: ({message}) => {
        if (message) {
          addOrUpdateMessage(message, true);
        }

        resetText();
      },
    },
  );
  const [createSessionWithMessage, {loading: createSessionIsLoading}] =
    useCreateSessionWithMessage(resetText);

  const page = 'main';
  const extraContent = useMemo(
    () =>
      JSON.stringify({
        page: page,
      }),
    [page],
  );

  const onPressSend = useCallback(async () => {
    const content = text.trim();

    if (!content) {
      return;
    }

    if (activeSession) {
      createMessage({
        variables: {
          input: {
            type: UserChatMessageType.Conversation,
            content,
            sessionId: activeSession.id,
            extraContent,
          },
        },
      });
    } else if (activeAgent) {
      createSessionWithMessage({
        variables: {
          input: {
            type: UserChatMessageType.Conversation,
            content,
            agentTemplate: activeAgent.templateName,
            extraContent,
          },
        },
      });
    }
  }, [
    text,
    activeSession,
    activeAgent,
    extraContent,
    createMessage,
    createSessionWithMessage,
  ]);

  const onPressSuggestion = useCallback(
    async (content: string) => {
      if (!activeAgent) {
        return;
      }

      createSessionWithMessage({
        variables: {
          input: {
            type: UserChatMessageType.Conversation,
            content,
            agentTemplate: activeAgent?.templateName,
            extraContent,
          },
        },
      });
    },
    [createSessionWithMessage, activeAgent, extraContent],
  );

  const onEnterSend = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();

        await onPressSend();
      }
    },
    [onPressSend],
  );

  const value = useMemo(
    () => ({
      text,
      setText,
      createMessageIsLoading,
      createSessionIsLoading,
      onPressSend,
      onEnterSend,
      onPressSuggestion,
    }),
    [
      text,
      createMessageIsLoading,
      createSessionIsLoading,
      onPressSend,
      onEnterSend,
      onPressSuggestion,
    ],
  );

  return (
    <ChatInputContext.Provider value={value}>
      {children ? children : <Outlet />}
    </ChatInputContext.Provider>
  );
});

ChatInputProvider.displayName = 'ChatInputProvider';

export default ChatInputProvider;
