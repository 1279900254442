import {RefObject, useLayoutEffect} from 'react';

export const useResizeObserver = (
  elementRef: RefObject<HTMLElement> | null,
  onResize: (entry: ResizeObserverEntry) => void,
  {pollInterval = 15} = {},
) => {
  useLayoutEffect(() => {
    let observer: ResizeObserver;

    const observe = () => {
      if (elementRef?.current && !observer) {
        observer = new ResizeObserver(entries => {
          for (const entry of entries) {
            onResize(entry);
          }
        });
        observer.observe(elementRef.current);
      }
    };

    const interval = setInterval(() => {
      observe();
    }, pollInterval);

    return () => {
      if (observer && elementRef?.current) {
        observer.unobserve(elementRef.current);
      }

      clearInterval(interval);
    };
  }, [elementRef, onResize, pollInterval]);
};
