import CheckCircleIcon from '@/components/icons/CheckCircleIcon';
import CheckCircleSolidIcon from '@/components/icons/CheckCircleSolidIcon';
import {msg, plural, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {ComponentProps, useCallback} from 'react';
import {twMerge} from 'tailwind-merge';
import {string} from 'yup';

const MIN_LENGTH = 8;
const MIN_NUMBERS = 1;
const MIN_LETTERS = 1;

const matchLength = (password: string) => password.length >= MIN_LENGTH;
const matchNumbers = (password: string) =>
  (password.match(/\d/g) || []).length >= MIN_NUMBERS;
const matchLetters = (password: string) =>
  (password.match(/[a-zA-Z]/g) || []).length >= MIN_LETTERS;
const matchCorrectCharacters = (password: string) =>
  /^[A-Za-z0-9!@#$%^&*()_+\-=[\]{};:"'<>,.?\/~|\\]+$/g.test(password); // eslint-disable-line no-useless-escape

export const usePasswordRequirements = () => {
  const {_} = useLingui();
  const getPasswordRequirementsValidator = useCallback(() => {
    return string()
      .required(_(msg`Password is required`))
      .test(
        'is-valid-characters',
        _(
          msg`Password contains invalid characters. Only latin letters, digits and special characters are allowed.`,
        ),
        matchCorrectCharacters,
      )
      .test(
        'is-valid-password',
        _(msg`Password does not meet the requirements`),
        password =>
          matchLength(password) &&
          matchNumbers(password) &&
          matchLetters(password),
      );
  }, [_]);
  const renderPasswordRequirements = useCallback(
    ({
      password,
      className = '',
    }: {
      password: string;
      className?: ComponentProps<'div'>['className'];
    }) => {
      const matchesLength = matchLength(password);
      const matchesNumbers = matchNumbers(password);
      const matchesLetters = matchLetters(password);
      const requirements = [
        {
          text: plural(MIN_LENGTH, {
            one: 'At least one character',
            other: 'At least # characters',
          }),
          matches: matchesLength,
        },
        {
          text: plural(MIN_NUMBERS, {
            one: 'At least one digit',
            other: 'At least # digits',
          }),
          matches: matchesNumbers,
        },
        {
          text: plural(MIN_LETTERS, {
            one: 'At least one letter',
            other: 'At least # letters',
          }),
          matches: matchesLetters,
        },
      ];

      return (
        <div
          className={twMerge(
            `flex flex-col gap-5 rounded-[1rem] bg-foreground/[0.03] p-5`,
            className,
          )}>
          <div className="text-sm text-foreground">
            <Trans>Your password must include:</Trans>
          </div>
          <div className="flex w-full flex-col gap-3">
            {requirements.map((requirement, index) => (
              <div
                key={index}
                className="flex items-center gap-2 text-sm text-foreground/50">
                {requirement.matches ? (
                  <CheckCircleSolidIcon className="h-5 w-5 text-[#28CC2E]" />
                ) : (
                  <CheckCircleIcon className="h-5 w-5 text-foreground/40" />
                )}
                {requirement.text}
              </div>
            ))}
          </div>
        </div>
      );
    },
    [],
  );

  return {getPasswordRequirementsValidator, renderPasswordRequirements};
};

export default usePasswordRequirements;
