import {memo, useMemo} from 'react';
import RCTMarkdown, {Options} from 'react-markdown';
import Handlebars from 'handlebars';
import {useAuthContext} from '@/hooks';
import highlightMark from './highlightMarkPlugin';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

const plugins = [remarkGfm, remarkMath, highlightMark];

const escapeDollars = (content: string) => {
  // transform $, \$, \\\\$, etc. to \$ if followed by a digit
  return content.replace(/\\*\$(?!\D)/g, '\\$');
};

const preprocessLaTeX = (content: string) => {
  // Replace block-level LaTeX delimiters \[ \] with $$ $$
  const blockProcessedContent = content.replace(
    /\\\[(.*?)\\\]/gs,
    (_, equation) => `$$${equation}$$`,
  );
  // Replace inline LaTeX delimiters \( \) with $ $
  const inlineProcessedContent = blockProcessedContent.replace(
    /\\\((.*?)\\\)/gs,
    (_, equation) => `$${equation}$`,
  );

  return inlineProcessedContent;
};

interface Props extends Options {
  enableTemplating?: boolean;
  extraContext?: Record<string, unknown>;
}

interface HBContext {
  user?: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  };
  business?: {
    name: string;
  };
}

const Markdown = memo(
  ({
    children,
    className = '',
    enableTemplating = false,
    extraContext,
    ...props
  }: Props) => {
    const {user, business} = useAuthContext();
    const context: HBContext & Props['extraContext'] = useMemo(
      () => ({
        user: {
          first_name: user?.firstName || '',
          last_name: user?.lastName || '',
          email: user?.email || '',
          phone: user?.phone || '',
        },
        business: {
          name: business?.name || '',
        },
        ...(extraContext || {}),
      }),
      [user, business, extraContext],
    );

    const template = useMemo(() => {
      try {
        return children && enableTemplating
          ? Handlebars.compile(children)
          : null;
      } catch (e) {
        console.error(
          `Markdown preprocessing error when compiling the template: ${e}`,
        );
        return null;
      }
    }, [children, enableTemplating]);

    const preprocessed = useMemo(() => {
      if (!enableTemplating) {
        return children;
      }

      if (!template) {
        return '';
      }

      try {
        return template(context);
      } catch (e) {
        console.error(
          `Markdown preprocessing error when rendering the template: ${e}`,
        );
        return '';
      }
    }, [context, children, template, enableTemplating]);

    return (
      <RCTMarkdown
        className={`${className}`}
        skipHtml={false}
        {...props}
        remarkPlugins={[...plugins, ...(props.remarkPlugins ?? [])]}
        rehypePlugins={[rehypeKatex, ...(props.rehypePlugins ?? [])]}>
        {preprocessed ? preprocessLaTeX(escapeDollars(preprocessed)) : ''}
      </RCTMarkdown>
    );
  },
);

Markdown.displayName = 'Markdown';

export default Markdown;
