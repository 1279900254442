import {createContext, Dispatch, SetStateAction} from 'react';

export interface ChatInputContextValue {
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  createMessageIsLoading?: boolean;
  createSessionIsLoading?: boolean;
  onPressSend: () => Promise<void>;
  onEnterSend: (e: React.KeyboardEvent<HTMLInputElement>) => Promise<void>;
  onPressSuggestion: (content: string) => Promise<void>;
}

export const ChatInputContext = createContext<
  ChatInputContextValue | undefined
>(undefined);
