import {useQuery} from '@apollo/client';
import {memo, useEffect} from 'react';
import {Action} from './Action';
import {GET_ACTIONS} from '@/common/queries';

export const ActionList = memo(
  ({
    className,
    classNames,
    ids,
  }: {
    className?: string;
    ids: string[];
    classNames?: {action?: string};
  }) => {
    const {data, fetchMore} = useQuery(GET_ACTIONS, {
      variables: {
        filter: {id: {$in: ids}},
      },
    });
    const pageInfo = data?.connection?.pageInfo;
    const actions = data?.connection?.edges.map(edge => edge.node);

    useEffect(() => {
      if (pageInfo?.hasNextPage) {
        fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
        });
      }
    }, [pageInfo?.hasNextPage, pageInfo?.endCursor, fetchMore]);

    return (
      <div className={className}>
        {actions?.map(action => (
          <Action
            key={action.id}
            action={action}
            className={classNames?.action}
          />
        ))}
      </div>
    );
  },
);

ActionList.displayName = 'ActionList';
