import {memo} from 'react';
import {ScoreSection as ScoreSectionType, ChartIcon} from './types';
import CheckCircleSolidIcon from '@/components/icons/CheckCircleSolidIcon';
import {Trans} from '@lingui/macro';
import {twMerge} from 'tailwind-merge';

const iconByType: Record<
  ChartIcon,
  (color: ScoreSectionType['score']['icon_color']) => JSX.Element
> = {
  checkmark: color => (
    <CheckCircleSolidIcon style={{color}} className="h-8 w-8" />
  ),
};

const ScoreSection = memo(
  ({
    score: {icon_name, icon_color, score, max_score},
    className,
  }: {className?: string} & ScoreSectionType) => {
    return (
      <div className={twMerge('flex items-end gap-2 rounded-2xl', className)}>
        <div className="flex items-center gap-2">
          {icon_name ? iconByType[icon_name]?.(icon_color) : null}
          <div className="text-4xl font-semibold text-foreground">{score}</div>
        </div>
        {max_score !== undefined && (
          <div className="pb-1 text-sm font-medium text-foreground/50">
            <Trans>out of {max_score}</Trans>
          </div>
        )}
      </div>
    );
  },
);

ScoreSection.displayName = 'ScoreSection';

export default ScoreSection;
