import {cloneElement, FC} from 'react';
import {useLocation, useNavigate, useOutlet} from 'react-router-dom';
import {Modal, ModalContent, ModalProps} from './Modal';

const ModalOutlet: FC<
  {basePath?: string; subPath: string | string[]; replace?: boolean} & Omit<
    ModalProps,
    'children'
  >
> = ({basePath = '', subPath, replace, ...props}) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const outlet = useOutlet();

  if (!Array.isArray(subPath)) {
    subPath = [subPath];
  }

  const isOpen = Boolean(
    outlet && subPath.some(i => pathname.startsWith(basePath + '/' + i)),
  );

  return (
    <Modal
      isOpen={isOpen}
      isDismissable={false}
      onOpenChange={isOpen => {
        if (!isOpen) {
          navigate(basePath, {replace});
        }
      }}
      {...props}>
      <ModalContent>
        {outlet && cloneElement(outlet, {key: pathname})}
      </ModalContent>
    </Modal>
  );
};

export default ModalOutlet;
