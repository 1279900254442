import {memo, useCallback, useEffect, useMemo} from 'react';
import {Trans} from '@lingui/macro';
import {useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import {API_LIMIT} from '@/config';
import WidgetSection from '../components/WidgetSection';
import TopicHeader from './TopicHeader';
import {gql} from '@/__generated__';
import {Widget} from '../components/sections/types';
import InsightPreview from '../components/InsightPreview';
import dayjs from 'dayjs';
import UpdatedAt from '../components/sections/UpdatedAt';
import {useInView} from 'react-intersection-observer';
import PortalSpinner from '@/components/PortalSpinner';
import {useToolContext} from '@/hooks';
import {GET_INSIGHT_CONNECTION} from '@/common/queries';
import {InsightLayoutType} from '@/__generated__/graphql';
import WidgetContainer from '../components/WidgetContainer';
import DownloadFileButton from '@/components/buttons/DownloadFileButton';

const GET_TOPIC_BY_ID_DOCUMENT = gql(`
  query GetTopicById($id: ID!) {
    topic: getTopic(id: $id) {
      id
      title
      widgets
      explain
      hasDrilldown
      styleConfig
      createdAt
      updatedAt
    }
  }
`);

const TopicDrilldown = memo(() => {
  const navigate = useNavigate();
  const {topicId} = useParams<{topicId: string}>();
  const {appName} = useToolContext();
  const {ref: lastItemRef, inView: isLastItemInView} = useInView();
  const insightsResult = useQuery(GET_INSIGHT_CONNECTION, {
    variables: {
      first: API_LIMIT,
      filter: {topics: {$any: {id: topicId}}},
      order: {impact: 'DESC'},
    },
  });
  const insightsPageInfo = insightsResult.data?.connection.pageInfo;
  const topicResult = useQuery(GET_TOPIC_BY_ID_DOCUMENT, {
    variables: {id: topicId!},
  });
  const topic = topicResult.data?.topic;
  const widgets = useMemo(() => {
    return (
      (topic?.widgets as Widget[])?.filter(
        i =>
          i.sections.filter(s => s.contexts?.includes('DrillDown')).length > 0,
      ) ?? []
    );
  }, [topic?.widgets]);
  const [widgetsBefore, widgetsAfter] = useMemo(() => {
    const before = [];
    const after = [];

    for (const widget of widgets) {
      if (widget.position === 'after') {
        after.push(widget);
      } else {
        before.push(widget);
      }
    }

    return [before, after];
  }, [widgets]);
  const insights = useMemo(() => {
    return insightsResult.data?.connection.edges.map(e => e.node) ?? [];
  }, [insightsResult.data]);

  const renderWidgets = useCallback(
    (widgets: Widget[]) => {
      return widgets.map((widget, index) => {
        const sections = widget.sections.filter(s =>
          s.contexts?.includes('DrillDown'),
        );
        const maxUpdatedAt = Math.max(
          ...sections
            .filter(s => (s.json as any).updated_at)
            .map(s => dayjs((s.json as any).updated_at).valueOf()),
        );
        const updatedAt =
          maxUpdatedAt > 0 ? dayjs(maxUpdatedAt).toDate() : null;

        return (
          <WidgetContainer
            key={index}
            widget={widget}
            appName={appName}
            isDrillDown
            updatedAtNode={<UpdatedAt updatedAt={updatedAt} />}>
            {sections.map((section, index) => (
              <WidgetSection
                key={index}
                section={section}
                anchor={widget.anchor}
                title={widget.title}
                isDrillDown
                appName={appName}
              />
            ))}
            {Boolean(widget.notes) && (
              <div className="text-center text-sm text-foreground">
                {widget.notes}
              </div>
            )}
            {widget.download_file ? (
              <DownloadFileButton
                filename={widget.download_file.id}
                className="mt-6">
                {widget.download_file.button_text}
              </DownloadFileButton>
            ) : null}
          </WidgetContainer>
        );
      });
    },
    [appName],
  );

  useEffect(() => {
    if (topic && !topic.hasDrilldown) {
      navigate('..');
    }
  }, [topic, navigate]);

  useEffect(() => {
    if (isLastItemInView && insightsPageInfo?.hasNextPage) {
      insightsResult.fetchMore({
        variables: {after: insightsPageInfo.endCursor},
      });
    }
  }, [
    isLastItemInView,
    insightsPageInfo?.hasNextPage,
    insightsPageInfo?.endCursor,
    insightsResult,
  ]);

  return (
    <div
      style={{WebkitOverflowScrolling: 'touch'}}
      className="relative flex h-full w-full max-w-2xl grow flex-col items-center overflow-auto">
      <TopicHeader
        className="sticky top-0 z-20 bg-background/75 backdrop-blur-xl"
        title={topic?.title}
      />
      <div className="flex w-full flex-1 flex-col bg-default-100 pb-6">
        {renderWidgets(widgetsBefore)}
        <div className="flex flex-col">
          {insights.length &&
          insights.some(
            insight => insight.layoutType !== InsightLayoutType.Detailed,
          ) ? (
            <div className="flex-1 p-6 text-lg font-semibold text-foreground">
              <Trans>Insights</Trans>
            </div>
          ) : null}
          {insights.length ? (
            <div className="flex flex-col gap-6 px-6">
              {insights.map(insight => (
                <InsightPreview
                  key={insight.id}
                  insight={insight}
                  appName={appName}
                  isDrillDown
                  href={`insight/${insight.id}`}
                />
              ))}
            </div>
          ) : null}
          {insightsPageInfo?.hasNextPage && (
            <PortalSpinner
              ref={lastItemRef}
              size="md"
              className="w-full"
              classNames={{wrapper: 'm-2'}}
            />
          )}
        </div>
        {renderWidgets(widgetsAfter)}
      </div>
    </div>
  );
});

TopicDrilldown.displayName = 'TopicDrilldown';

export default TopicDrilldown;
