import {GetAgentByTemplateQuery} from '@/__generated__/graphql';
import {ToolSetupConfig} from '@/common/types';
import {ApolloError} from '@apollo/client';
import {createContext} from 'react';

export interface ToolContextValue {
  appName: string;
  isLoading?: boolean;
  agent?: GetAgentByTemplateQuery['agent'];
  isUnlocked?: boolean;
  rerunTool: (forceRun?: boolean) => void;
  unlockTool: () => void;
  error?: ApolloError;
  unlockSteps: ToolSetupConfig['steps'];
  runTool: () => void;
}

const initialState: ToolContextValue = {
  appName: '',
  rerunTool: () => null,
  unlockTool: () => null,
  unlockSteps: [],
  runTool: () => null,
};

export const ToolContext = createContext<ToolContextValue>(initialState);
