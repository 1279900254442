import {gql} from '@/__generated__';
import {INTEGRATION_CONNECTION_PARAM} from '@/config';
import {encodeIntegrationConnectionParam} from '@/utils';
import {useMutation} from '@apollo/client';
import PortalSpinner from '@/components/PortalSpinner';
import {FC, useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

const CREATE_AMAZON_ADS_INTEGRATION = gql(`
  mutation CreateAmazonAdsIntegration($input: AmazonAdsIntegrationCreateInput!) {
    createAmazonAdsIntegration(input: $input) {
      href
    }
  }
`);

const AmazonAdsAuth: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [mutate, {loading}] = useMutation(CREATE_AMAZON_ADS_INTEGRATION, {
    onCompleted: data => {
      const integrationParam = encodeIntegrationConnectionParam({
        type: 'amazon_ads',
        success: true,
      });

      const url = new URL(data.createAmazonAdsIntegration.href);

      url.searchParams.append(INTEGRATION_CONNECTION_PARAM, integrationParam);

      window.location.href = url.toString();
    },
    onError: () => {
      navigate('/');
    },
  });

  useEffect(() => {
    mutate({
      variables: {
        input: {
          state: searchParams.get('state') ?? '',
          code: searchParams.get('code'),
          error: searchParams.get('error'),
        },
      },
    });
  }, [mutate, searchParams]);

  if (loading) {
    return (
      <div className="relative flex h-[100dvh] max-h-[100dvh] min-h-[100dvh] w-[100dvw] min-w-[100dvw] max-w-[100dvw] flex-col bg-default-100">
        <PortalSpinner
          classNames={{
            base: 'backdrop-blur-sm fixed inset-0 z-40',
          }}
          size="lg"
        />
      </div>
    );
  }

  return null;
};

export default AmazonAdsAuth;
