import {GET_CHAT_SESSIONS} from '@/common/queries';
import {ChatSessionWithMessages} from '@/common/types/chat';
import {ChatContextValue} from '@/contexts';
import {useAppContext} from '@/hooks';
import {useQuery} from '@apollo/client';
import {useEffect, useMemo} from 'react';

export const useGetSessions = (
  unauthenticatedSessionIds: string[],
  setSessions: ChatContextValue['setSessions'],
  {skip}: {skip?: boolean} = {},
) => {
  const {isAuthenticated} = useAppContext();
  const sessionsData = useQuery(GET_CHAT_SESSIONS, {
    variables: {
      filter: {
        ...(isAuthenticated ? {} : {id: {$in: unauthenticatedSessionIds}}),
        agent: {
          $has: {
            isNew: true,
          },
        },
      },
      first: 1000,
      order: {updatedAt: 'desc'},
      messagesOrder: {createdAt: 'desc'},
      messagesFirst: 10,
    },
    skip,
  });

  useEffect(() => {
    const pageInfo = sessionsData.data?.connection.pageInfo;

    if (pageInfo?.hasNextPage) {
      sessionsData.fetchMore({variables: {after: pageInfo.endCursor}});
    }
  }, [sessionsData.data?.connection.pageInfo.hasNextPage]);

  const sessions: ChatSessionWithMessages[] | undefined = useMemo(() => {
    return sessionsData.data?.connection.edges.map(({node}) => ({
      ...node,
      messages: node.messages.edges.map(({node}) => node),
      messagesPageInfo: node.messages.pageInfo,
    }));
  }, [sessionsData.data?.connection.edges]);

  useEffect(() => {
    setSessions(sessions ?? []);
  }, [sessions]);

  return null;
};
