import {SVGProps} from 'react';

export default function ScalesIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5001 4.5C19.5001 3.67157 18.8285 3 18.0001 3C17.1717 3 16.5001 3.67157 16.5001 4.5V10.7295L8.78098 12.0161C8.68772 12.0298 8.5966 12.0522 8.50868 12.0827C8.31754 12.1487 8.14605 12.2514 8.00102 12.3811C7.88208 12.4873 7.77918 12.6132 7.6977 12.7558L2.29037 22.2185C2.09357 22.5625 1.91988 22.866 1.79395 23.1971C1.68313 23.4885 1.60289 23.7907 1.55453 24.0987C1.49958 24.4487 1.49979 24.7984 1.50003 25.1946L1.50006 25.5C1.50006 25.5044 1.50008 25.5088 1.50012 25.5132C1.50723 29.6493 4.86237 33 9.00011 33C13.1422 33 16.5001 29.6422 16.5001 25.5L16.5001 25.1653C16.5001 25.1511 16.5001 25.1366 16.5001 25.1219C16.5002 24.7553 16.4972 24.4271 16.4456 24.0987C16.3972 23.7907 16.317 23.4885 16.2062 23.1971C16.0802 22.866 15.9066 22.5625 15.7098 22.2185L11.371 14.6258L24.13 12.4993L20.2904 19.2185C20.0936 19.5625 19.9199 19.866 19.7939 20.1971C19.6831 20.4885 19.6029 20.7907 19.5545 21.0987C19.4996 21.4487 19.4998 21.7984 19.5 22.1946L19.5001 22.5C19.5001 22.5044 19.5001 22.5088 19.5001 22.5132C19.5073 26.6493 22.8624 30 27.0001 30C31.1422 30 34.5001 26.6422 34.5001 22.5L34.5001 22.1653C34.5001 22.1468 34.5001 22.128 34.5001 22.1088C34.5001 21.7475 34.4966 21.4232 34.4456 21.0987C34.3972 20.7907 34.317 20.4885 34.2062 20.1971C34.0802 19.866 33.9066 19.5625 33.7098 19.2185L28.3169 9.78116C28.2626 9.68157 28.1974 9.58938 28.1234 9.50586C27.843 9.18903 27.4376 9.00146 27.0051 9.00001L27.0001 9C26.9071 9 26.8153 9.00861 26.7258 9.02526L19.5001 10.2295V4.5ZM30.4153 19.5L27.0001 13.5233L23.5848 19.5H30.4153ZM12.4153 22.5L9.00006 16.5233L5.58483 22.5H12.4153Z"
        fill="black"
      />
    </svg>
  );
}
