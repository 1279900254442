import {useContext} from 'react';
import {
  AppProviderContext,
  AuthContext,
  ThemeProviderContext,
  SearchParamsContext,
  ChatContext,
  ErrorParamHandlerContext,
} from '@/contexts';
import {ToolContext} from '@/contexts/ToolContext';
import {ChatInputContext} from '@/contexts/ChatInputContext';

export const useAppContext = () => {
  const context = useContext(AppProviderContext);

  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  return context;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }

  return context;
};

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined)
    throw new Error('useTheme must be used within a ThemeProvider');

  return context;
};

export const useSearchParamsContext = () => {
  const context = useContext(SearchParamsContext);

  if (!context) {
    throw new Error(
      'useSearchParamsContext must be used within a SearchParamsProvider',
    );
  }

  return context;
};

export const useToolContext = () => {
  const context = useContext(ToolContext);

  if (!context) {
    throw new Error('useToolContext must be used within a ToolProvider');
  }

  return context;
};

export const useChatContext = () => {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }

  return context;
};

export const useChatInputContext = () => {
  const context = useContext(ChatInputContext);

  if (!context) {
    throw new Error(
      'useChatInputContext must be used within a ChatInputProvider',
    );
  }

  return context;
};

export const useErrorParamHandlerContext = () => {
  const context = useContext(ErrorParamHandlerContext);

  if (!context) {
    throw new Error(
      'useErrorParamHandlerContext must be used within a SearchParamsProvider',
    );
  }

  return context;
};
