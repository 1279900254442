import React, {memo, useState, useEffect, useRef, useMemo} from 'react';
import {BlurhashCanvas} from 'react-blurhash';

export type BlurhashImageProps = {
  url: string;
  blurHash?: string | null;
  alt?: string;
  punch?: number;
  width?: number | null;
  height?: number | null;
  referenceWidth?: number;
  referenceHeight?: number;
  scaleByWidth?: boolean;
  scaleByHeight?: boolean;
  className?: string;
  style?: React.CSSProperties;
  classNames?: {
    blur?: string;
    image?: string;
  };
  imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
};

const BlurhashImage = memo(
  ({
    url,
    blurHash,
    alt,
    punch = 1,
    width,
    height,
    referenceWidth,
    referenceHeight,
    scaleByWidth = false,
    scaleByHeight = false,
    className,
    style,
    classNames,
    imageProps,
  }: BlurhashImageProps) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setImageLoaded(false);
    }, [url]);

    useEffect(() => {
      const img = new Image();
      img.onload = () => setImageLoaded(true);
      img.src = url;
    }, [url]);

    const blurhashDimensions = useMemo(() => {
      const aspectRatio = width && height ? width / height : 1;

      let calculatedWidth = width ?? 0;
      let calculatedHeight = height ?? 0;

      // If container size is 0, use window dimensions
      const containerWidth =
        referenceWidth || Math.round(window.innerWidth / 20);
      const containerHeight =
        referenceHeight || Math.round(window.innerHeight / 20);

      if (scaleByWidth) {
        calculatedWidth = containerWidth;
        calculatedHeight = calculatedWidth / aspectRatio;
      } else if (scaleByHeight) {
        calculatedHeight = containerHeight;
        calculatedWidth = calculatedHeight * aspectRatio;
      } else {
        // If not scaling, fit within container/window while preserving aspect ratio
        const containerAspectRatio = containerWidth / containerHeight;

        if (aspectRatio > containerAspectRatio) {
          calculatedWidth = containerWidth;
          calculatedHeight = calculatedWidth / aspectRatio;
        } else {
          calculatedHeight = containerHeight;
          calculatedWidth = calculatedHeight * aspectRatio;
        }
      }

      // Ensure dimensions are at least 1 pixel and not larger than container/window
      return {
        width: Math.max(
          1,
          Math.min(Math.round(calculatedWidth), containerWidth),
        ),
        height: Math.max(
          1,
          Math.min(Math.round(calculatedHeight), containerHeight),
        ),
      };
    }, [
      width,
      height,
      referenceWidth,
      referenceHeight,
      scaleByWidth,
      scaleByHeight,
    ]);

    return (
      <div
        ref={containerRef}
        className={className}
        style={{position: 'relative', overflow: 'hidden', ...style}}>
        {blurHash && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <BlurhashCanvas
              hash={blurHash}
              width={blurhashDimensions.width}
              height={blurhashDimensions.height}
              punch={punch}
              className={classNames?.blur}
              style={{
                transition: 'opacity 0.3s ease-in-out',
                opacity: imageLoaded ? 0 : 1,
                width: '100%',
                height: '100%',
              }}
            />
          </div>
        )}
        <img
          src={url}
          alt={alt}
          className={classNames?.image}
          {...imageProps}
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: imageLoaded ? 1 : 0,
            ...(imageProps?.style || {}),
          }}
        />
      </div>
    );
  },
);

BlurhashImage.displayName = 'BlurhashImage';

export default BlurhashImage;
