import {FC} from 'react';
import {
  Modal as NUIModal,
  ModalProps as NUIModalProps,
  ModalHeader as NUIModalHeader,
  ModalHeaderProps,
  ModalBody as NUIModalBody,
  ModalBodyProps,
  ModalContent as NUIModalContent,
  ModalContentProps,
  ModalFooter as NUIModalFooter,
  ModalFooterProps,
  Button,
  ButtonProps,
} from '@nextui-org/react';
import {memo} from 'react';
import {twMerge} from 'tailwind-merge';
import CloseIcon from './icons/CloseIcon';

export interface ModalProps extends NUIModalProps {
  presentation?: 'card' | 'fullScreen';
}

const CloseButton: FC<Omit<ButtonProps, 'children'>> = ({
  className,
  ...props
}) => (
  <Button
    aria-label="Close modal"
    isIconOnly
    radius="full"
    className={twMerge(
      'h-8 min-h-0 w-8 min-w-0 shrink-0 bg-foreground/[0.08] transition-background data-[hover=true]:bg-foreground/[0.12] [&>svg]:stroke-foreground/40 [&>svg]:data-[hover=true]:stroke-foreground/60',
      className,
      'p-0',
    )}
    {...props}>
    <CloseIcon className="h-5 w-5 transition-[stroke]" />
  </Button>
);

export default CloseButton;

const Modal = memo(
  ({className, presentation = 'card', classNames, ...rest}: ModalProps) => {
    return (
      <NUIModal
        closeButton={<CloseButton />}
        className={twMerge(
          `rounded-none rounded-b-none md:rounded-b-[2rem]`,
          presentation === 'card'
            ? 'rounded-t-[1.25rem] md:rounded-t-[2rem]'
            : 'rounded-t-none md:rounded-t-[2rem]',
          className,
        )}
        classNames={{
          ...classNames,
          base: twMerge(
            presentation === 'card'
              ? 'max-h-[calc(100dvh-var(--navbar-height))]'
              : 'h-full max-h-full',
            classNames?.base,
          ),
          body: 'rounded-none',
          wrapper: twMerge(
            'z-[1100] sm:items-end md:items-center',
            classNames?.wrapper,
          ),
          closeButton: twMerge(
            'absolute top-5 right-5 md:right-10',
            classNames?.closeButton,
          ),
        }}
        {...rest}
      />
    );
  },
);

Modal.displayName = 'Modal';

const ModalContent = memo(({className, ...rest}: ModalContentProps) => {
  return (
    <NUIModalContent
      className={twMerge(
        '!m-0 max-h-[calc(100dvh-4rem)] w-auto min-w-full max-w-full md:h-auto md:min-w-[28rem] md:max-w-[min(var(--max-content-width),calc(100dvw-4rem))]',
        className,
      )}
      {...rest}
    />
  );
});

ModalContent.displayName = 'ModalContent';

const ModalHeader = memo(({className, ...rest}: ModalHeaderProps) => {
  return (
    <NUIModalHeader
      className={twMerge(
        'flex items-center justify-between gap-4 px-5 pb-5 pt-[3.25rem] md:px-10',
        className,
      )}
      {...rest}
    />
  );
});

ModalHeader.displayName = 'ModalHeader';

const ModalBody = memo(({className, ...rest}: ModalBodyProps) => {
  return (
    <NUIModalBody
      className={twMerge('flex flex-col overflow-auto p-5 md:p-10', className)}
      {...rest}
    />
  );
});

ModalBody.displayName = 'ModalBody';

const ModalFooter = memo(({className, ...rest}: ModalFooterProps) => {
  return (
    <NUIModalFooter
      className={twMerge('flex p-4 md:p-8 md:pt-4', className)}
      {...rest}
    />
  );
});

ModalFooter.displayName = 'ModalFooter';

export {Modal, ModalHeader, ModalBody, ModalContent, ModalFooter};
