import {useAppContext, useAuthContext} from '@/hooks';
import {Trans} from '@lingui/macro';
import {Avatar, Button} from '@nextui-org/react';
import {Link as RouterLink} from 'react-router-dom';
import {memo} from 'react';
import {getUserName} from '@/utils';
import LoginIcon from '@/components/icons/LoginIcon';
import {twMerge} from 'tailwind-merge';

interface Props {
  title: string;
  className?: string;
}

const HeaderWithProfile = memo(({title, className}: Props) => {
  const {isAuthenticated} = useAppContext();
  const {user} = useAuthContext();
  const userName = getUserName(user);
  const userAvatar = user?.avatar ?? undefined;

  return (
    <div
      className={twMerge(
        'flex w-full max-w-full items-center justify-between bg-background px-5 py-3',
        className,
      )}>
      <div className="text-3xl font-bold text-foreground">{title}</div>
      {isAuthenticated ? (
        <Avatar
          as={RouterLink}
          to={'/profile/account'}
          src={userAvatar}
          alt={userName}
          className="h-8 max-h-none min-h-0 w-8 min-w-0 shrink-0 p-0"
        />
      ) : (
        <Button
          aria-label="Log In"
          as={RouterLink}
          to={'auth/login'}
          disableAnimation
          disableRipple
          color="primary"
          className="max-h-none min-h-0 min-w-0 gap-1 rounded-full px-4 py-2 text-medium font-bold text-background"
          startContent={
            <LoginIcon strokeWidth={3} className="flex h-4 w-4 shrink-0" />
          }>
          <Trans>Log In</Trans>
        </Button>
      )}
    </div>
  );
});

HeaderWithProfile.displayName = 'HeaderWithProfile';

export default HeaderWithProfile;
