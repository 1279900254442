import * as amplitude from '@amplitude/analytics-browser';
import {
  InstrumentTransport,
  InstrumentEventMetadata,
  InstrumentEventName,
} from '../InstrumentTracker';

export class AmplitudeTransport extends InstrumentTransport {
  async track(
    eventName: string,
    eventProperties?: Record<string, any>,
    eventMetadata: InstrumentEventMetadata = {},
  ): Promise<void> {
    const metadata = {...eventMetadata};

    // Add user_id to metadata for logout events
    // bacause user_id gets removed before the event is sent
    // even after awaiting (probably due to batching)
    if (eventName === InstrumentEventName.LOGOUT) {
      const user_id = amplitude.getUserId();

      if (user_id) {
        metadata.user_id = user_id;
      }
    }

    amplitude.track(eventName, eventProperties, metadata);
  }

  async getMetadata(): Promise<InstrumentEventMetadata> {
    return {
      user_id: amplitude.getUserId(),
      session_id: amplitude.getSessionId(),
      device_id: amplitude.getDeviceId(),
    };
  }

  async flushQueue(): Promise<void> {
    amplitude.flush();
  }

  setUserId(userId?: string): void {
    amplitude.setUserId(userId);
  }
}
