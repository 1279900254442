import {Topic} from '@/__generated__/graphql';
import {memo, useCallback, useMemo} from 'react';
import {StyleConfigItem, Widget} from './sections/types';
import {Card, CardBody, CardFooter, CardHeader} from '@nextui-org/react';
import {useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import WidgetSection from './WidgetSection';
import InsightPreview from './InsightPreview';
import {Explain} from '@/common/types';
import ExplainButton from '@/components/ExplainButton';
import {GET_INSIGHT_CONNECTION} from '@/common/queries';
import WidgetContainer from './WidgetContainer';
import {CardMoreButton} from '@/components/buttons/CardMoreButton';
import DownloadFileButton from '@/components/buttons/DownloadFileButton';

const PREVIEW_INSIGHTS_LIMIT = 5;

interface TopicPreviewProps {
  templateName: string;
  topic: Omit<Partial<Topic>, 'widgets' | 'explain'> & {
    explain?: Explain;
    widgets?: Widget[];
  };
}

const TopicPreview = memo(({templateName, topic}: TopicPreviewProps) => {
  const navigate = useNavigate();
  const insights = useQuery(GET_INSIGHT_CONNECTION, {
    variables: {
      first: PREVIEW_INSIGHTS_LIMIT,
      filter: {topics: {$any: {id: topic.id}}},
      order: {impact: 'DESC'},
    },
    skip: !topic.id,
  });
  const widgets = useMemo(() => {
    return (
      topic.widgets?.filter(
        i => i.sections.filter(s => s.contexts?.includes('Preview')).length > 0,
      ) ?? []
    );
  }, [topic.widgets]);
  const [widgetsBefore, widgetsAfter] = useMemo(() => {
    const before = [];
    const after = [];

    for (const widget of widgets) {
      if (widget.position === 'after') {
        after.push(widget);
      } else {
        before.push(widget);
      }
    }

    return [before, after];
  }, [widgets]);
  const styleConfig = (topic.styleConfig?.Preview as StyleConfigItem) ?? {};
  const downloadButtons = useMemo(() => {
    return widgets
      .map(widget => widget.download_file)
      .filter(button => button && button.contexts?.includes('Preview'));
  }, [widgets]);

  const renderWidgets = useCallback(
    (widgets: Widget[]) => {
      return widgets.map((widget, index) => (
        <WidgetContainer key={index} widget={widget} appName={templateName}>
          {widget.sections
            .filter(s => s.contexts?.includes('Preview'))
            .map((section, index) => (
              <WidgetSection
                key={index}
                section={section}
                anchor={widget.anchor}
                title={widget.title}
                isDrillDown={false}
                appName={templateName}
              />
            ))}
        </WidgetContainer>
      ));
    },
    [templateName],
  );

  return (
    <Card
      aria-label="Open topic"
      as={'div'}
      fullWidth
      {...(topic.hasDrilldown && {
        disableAnimation: true,
        isHoverable: true,
        isPressable: true,
        onPress: () => {
          navigate(`topic/${topic.id}`);
        },
      })}
      style={Object.assign(
        {
          boxShadow:
            '0px 0px 20px -3px rgba(0, 0, 0, 0.15), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
        },
        styleConfig?.style?.container ?? {},
      )}
      shadow="none"
      className={`rounded-3xl pb-6 data-[hover=true]:bg-background`}>
      {topic.title ? (
        <CardHeader
          style={styleConfig?.style?.title}
          className="justify-between gap-2 p-6">
          <h3 className="text-start text-xl font-semibold text-foreground">
            {topic.title}
          </h3>
          {topic.explain ? (
            <ExplainButton
              colors={styleConfig?.explain_button_colors}
              templateName={templateName}
              className="bg-background"
              {...topic.explain}
            />
          ) : null}
        </CardHeader>
      ) : null}
      <CardBody
        style={styleConfig?.style?.content}
        className="gap-6 overflow-hidden p-0">
        {renderWidgets(widgetsBefore)}
        {insights.data?.connection.edges.length ? (
          <div className="flex flex-col gap-6 px-6">
            {insights.data?.connection.edges.map(e => (
              <InsightPreview
                key={e.node.id}
                insight={e.node}
                appName={templateName}
                isDrillDown={false}
              />
            ))}
          </div>
        ) : null}
        {renderWidgets(widgetsAfter)}
      </CardBody>
      {topic.hasDrilldown || downloadButtons.length ? (
        <CardFooter className="flex-col gap-2 p-0 px-6 pt-6">
          {downloadButtons.map((button, index) => (
            <DownloadFileButton
              key={index}
              filename={button!.id}
              className="w-full">
              {button?.button_text}
            </DownloadFileButton>
          ))}
          <CardMoreButton />
        </CardFooter>
      ) : null}
    </Card>
  );
});

TopicPreview.displayName = 'TopicPreview';

export default TopicPreview;
