import {useAppContext} from '@/hooks';
import PortalSpinner from '@/components/PortalSpinner';
import {memo, useEffect, useRef} from 'react';
import {Navigate, useNavigate, useResolvedPath} from 'react-router-dom';
import {
  decodeAuthParam,
  deleteParamsAfterLogin,
  getParamsAfterLogin,
} from '@/utils';
import {
  reportAppleSignIn,
  reportAppleSignUp,
  reportGoogleSignIn,
  reportGoogleSignUp,
} from '@/common/analytics';
import {instrumentTracker} from '@/common/instrumentTracker';

const AuthRedirect = memo(() => {
  const navigate = useNavigate();
  const {isAuthenticated, login} = useAppContext();
  const paramsAfterAuthRef = useRef('');
  const {pathname} = useResolvedPath('../..');

  useEffect(() => {
    const url = new URL(location.href);
    const urlParams = new URLSearchParams(url.search);
    const extraParams = getParamsAfterLogin();
    const errorParam = urlParams.get('error');

    if (errorParam) {
      console.error(errorParam);
      urlParams.delete('error');
    }

    const authParam = urlParams.get('auth');
    const authInfo = authParam ? decodeAuthParam(authParam) : null;

    if (authInfo?.id) {
      instrumentTracker.setUserId(authInfo?.id);
    }

    if (extraParams) {
      for (const [key, value] of extraParams.entries()) {
        urlParams.set(key, value);
      }
    }

    urlParams.delete('auth');
    deleteParamsAfterLogin();

    if (!authInfo) {
      navigate(isAuthenticated ? '../..' : '../login');

      return;
    }

    paramsAfterAuthRef.current = urlParams.toString();

    if (authInfo.signup) {
      switch (authInfo.provider) {
        case 'google':
          reportGoogleSignUp();
          break;
        case 'apple':
          reportAppleSignUp();
          break;
      }
    } else {
      switch (authInfo.provider) {
        case 'google':
          reportGoogleSignIn();
          break;
        case 'apple':
          reportAppleSignIn();
          break;
      }
    }

    login(authInfo);
  }, []);

  if (isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname,
          search: paramsAfterAuthRef.current,
        }}
      />
    );
  }

  return (
    <PortalSpinner
      classNames={{
        base: 'backdrop-blur-sm fixed inset-0 z-40',
      }}
      size="lg"
    />
  );
});

AuthRedirect.displayName = 'AuthRedirect';

export default AuthRedirect;
