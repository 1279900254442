import {createContext, Dispatch, SetStateAction} from 'react';
import {
  ChatSessionWithMessages,
  ChatMessage,
  ChatSession,
  Agent,
} from '@/common/types/chat';

export type ChatContextValue = {
  activeAgent: Agent | null;
  activeSessionId: string | undefined;
  sessions: ChatSessionWithMessages[];
  activeSession?: ChatSessionWithMessages;
  unreadCountBySession: Record<string, number>;
  totalUnreadCount: number;
  isInlineChatOpen: boolean;
  unauthenticatedSessionIds: string[];
  setSessions: (sessions: ChatSessionWithMessages[]) => void;
  addSessionMessages: (sessionId: string, messages: ChatMessage[]) => void;
  setActiveSession: (sessionId?: string) => void;
  updateSessionData: (sessionId: string, data: Partial<ChatSession>) => void;
  addOrUpdateMessage: (message: ChatMessage, finished: boolean) => void;
  addOrUpdateSession: (session: ChatSessionWithMessages) => void;
  setIsInlineChatOpen: (isOpen: boolean) => void;
  setUnauthenticatedSessionIds: Dispatch<SetStateAction<string[]>>;
  fromLocation: Location | null;
  setFromLocation: Dispatch<SetStateAction<Location | null>>;
};

export const ChatContext = createContext<ChatContextValue | undefined>(
  undefined,
);
