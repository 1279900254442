import {ToolUnlockStepId} from '@/common/types';
import {Trans} from '@lingui/macro';
import {Button} from '@nextui-org/react';
import {memo, ReactNode} from 'react';
import {Link as RouterLink} from 'react-router-dom';

const InfoSectionHeader = memo(
  ({title, stepId}: {title: ReactNode; stepId: ToolUnlockStepId}) => {
    return (
      <div className="flex items-center gap-3">
        <h5 className="flex-1 text-base font-semibold">{title}</h5>
        <Button
          aria-label="Edit"
          as={RouterLink}
          to={stepId}
          variant="solid"
          radius="full"
          className="h-8 min-w-0 bg-[#F2F2F5] text-base font-semibold text-primary"
          disableRipple>
          <Trans>Edit</Trans>
        </Button>
      </div>
    );
  },
);

InfoSectionHeader.displayName = 'InfoSectionHeader';

export default InfoSectionHeader;
