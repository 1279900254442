import {memo} from 'react';
import {
  AnimatePresence,
  AnimatePresenceProps,
  HTMLMotionProps,
  motion,
} from 'framer-motion';

interface Props extends HTMLMotionProps<'span'> {
  text: string | null | undefined;
  mode?: AnimatePresenceProps['mode'];
}

const AnimatedText = memo(({text, mode = 'wait', ...restProps}: Props) => (
  <AnimatePresence mode={mode} initial={false}>
    <motion.span
      initial={{opacity: 0, translateX: 16}}
      animate={{opacity: 1, translateX: 0}}
      exit={{opacity: 0, translateX: -10}}
      transition={{
        duration: 0.35,
        ease: 'easeInOut',
      }}
      key={text}
      {...restProps}>
      {text}
    </motion.span>
  </AnimatePresence>
));

AnimatedText.displayName = 'AnimatedText';

export default AnimatedText;
