import {SVGProps} from 'react';

export default function ExplainIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}>
      <path
        d="M13.2777 1.36756C13.1539 1.04573 12.8447 0.833374 12.4999 0.833374C12.1551 0.833374 11.8459 1.04573 11.7221 1.36756L10.7399 3.92124C10.4896 4.57211 10.4109 4.75967 10.3033 4.911C10.1954 5.06284 10.0627 5.1955 9.91087 5.30347C9.75955 5.41107 9.57199 5.48973 8.92112 5.74007L6.36744 6.72225C6.04561 6.84603 5.83325 7.15523 5.83325 7.50004C5.83325 7.84485 6.04561 8.15405 6.36744 8.27783L8.92112 9.26001C9.57199 9.51035 9.75955 9.58902 9.91087 9.69662C10.0627 9.80458 10.1954 9.93725 10.3033 10.0891C10.4109 10.2404 10.4896 10.428 10.7399 11.0788L11.7221 13.6325C11.8459 13.9544 12.1551 14.1667 12.4999 14.1667C12.8447 14.1667 13.1539 13.9544 13.2777 13.6325L14.2599 11.0788C14.5102 10.428 14.5889 10.2404 14.6965 10.0891C14.8045 9.93725 14.9371 9.80458 15.089 9.69662C15.2403 9.58902 15.4278 9.51035 16.0787 9.26001L18.6324 8.27783C18.9542 8.15405 19.1666 7.84485 19.1666 7.50004C19.1666 7.15523 18.9542 6.84603 18.6324 6.72225L16.0787 5.74007C15.4278 5.48973 15.2403 5.41107 15.089 5.30347C14.9371 5.1955 14.8045 5.06284 14.6965 4.911C14.5889 4.75967 14.5102 4.57211 14.2599 3.92124L13.2777 1.36756Z"
        fill={props.color ?? 'url(#paint0_linear_2656_730)'}
      />
      <path
        d="M13.2777 1.36756C13.1539 1.04573 12.8447 0.833374 12.4999 0.833374C12.1551 0.833374 11.8459 1.04573 11.7221 1.36756L10.7399 3.92124C10.4896 4.57211 10.4109 4.75967 10.3033 4.911C10.1954 5.06284 10.0627 5.1955 9.91087 5.30347C9.75955 5.41107 9.57199 5.48973 8.92112 5.74007L6.36744 6.72225C6.04561 6.84603 5.83325 7.15523 5.83325 7.50004C5.83325 7.84485 6.04561 8.15405 6.36744 8.27783L8.92112 9.26001C9.57199 9.51035 9.75955 9.58902 9.91087 9.69662C10.0627 9.80458 10.1954 9.93725 10.3033 10.0891C10.4109 10.2404 10.4896 10.428 10.7399 11.0788L11.7221 13.6325C11.8459 13.9544 12.1551 14.1667 12.4999 14.1667C12.8447 14.1667 13.1539 13.9544 13.2777 13.6325L14.2599 11.0788C14.5102 10.428 14.5889 10.2404 14.6965 10.0891C14.8045 9.93725 14.9371 9.80458 15.089 9.69662C15.2403 9.58902 15.4278 9.51035 16.0787 9.26001L18.6324 8.27783C18.9542 8.15405 19.1666 7.84485 19.1666 7.50004C19.1666 7.15523 18.9542 6.84603 18.6324 6.72225L16.0787 5.74007C15.4278 5.48973 15.2403 5.41107 15.089 5.30347C14.9371 5.1955 14.8045 5.06284 14.6965 4.911C14.5889 4.75967 14.5102 4.57211 14.2599 3.92124L13.2777 1.36756Z"
        fill={props.color ?? 'url(#paint1_linear_2656_730)'}
      />
      <path
        d="M6.16194 10.4607C6.02078 10.1784 5.73223 10 5.41658 10C5.10094 10 4.81239 10.1784 4.67123 10.4607L4.01751 11.7681C3.78211 12.2389 3.71038 12.3744 3.62322 12.4875C3.53579 12.6009 3.43412 12.7026 3.3207 12.79C3.20763 12.8772 3.07215 12.9489 2.60134 13.1843L1.29391 13.838C1.01159 13.9792 0.833252 14.2677 0.833252 14.5834C0.833252 14.899 1.01159 15.1876 1.29391 15.3287L2.60134 15.9824C3.07215 16.2178 3.20763 16.2896 3.3207 16.3767C3.43412 16.4642 3.53579 16.5658 3.62322 16.6793C3.71038 16.7923 3.78211 16.9278 4.01751 17.3986L4.67123 18.7061C4.81239 18.9884 5.10094 19.1667 5.41659 19.1667C5.73223 19.1667 6.02078 18.9884 6.16194 18.7061L6.81566 17.3986C7.05106 16.9278 7.12279 16.7923 7.20996 16.6793C7.29738 16.5658 7.39905 16.4642 7.51247 16.3767C7.62554 16.2896 7.76103 16.2178 8.23183 15.9824L9.53926 15.3287C9.82158 15.1876 9.99992 14.899 9.99992 14.5834C9.99992 14.2677 9.82158 13.9792 9.53926 13.838L8.23183 13.1843C7.76103 12.9489 7.62554 12.8772 7.51247 12.79C7.39905 12.7026 7.29738 12.6009 7.20995 12.4875C7.12279 12.3744 7.05106 12.2389 6.81566 11.7681L6.16194 10.4607Z"
        fill={props.color ?? 'url(#paint2_linear_2656_730)'}
      />
      <path
        d="M6.16194 10.4607C6.02078 10.1784 5.73223 10 5.41658 10C5.10094 10 4.81239 10.1784 4.67123 10.4607L4.01751 11.7681C3.78211 12.2389 3.71038 12.3744 3.62322 12.4875C3.53579 12.6009 3.43412 12.7026 3.3207 12.79C3.20763 12.8772 3.07215 12.9489 2.60134 13.1843L1.29391 13.838C1.01159 13.9792 0.833252 14.2677 0.833252 14.5834C0.833252 14.899 1.01159 15.1876 1.29391 15.3287L2.60134 15.9824C3.07215 16.2178 3.20763 16.2896 3.3207 16.3767C3.43412 16.4642 3.53579 16.5658 3.62322 16.6793C3.71038 16.7923 3.78211 16.9278 4.01751 17.3986L4.67123 18.7061C4.81239 18.9884 5.10094 19.1667 5.41659 19.1667C5.73223 19.1667 6.02078 18.9884 6.16194 18.7061L6.81566 17.3986C7.05106 16.9278 7.12279 16.7923 7.20996 16.6793C7.29738 16.5658 7.39905 16.4642 7.51247 16.3767C7.62554 16.2896 7.76103 16.2178 8.23183 15.9824L9.53926 15.3287C9.82158 15.1876 9.99992 14.899 9.99992 14.5834C9.99992 14.2677 9.82158 13.9792 9.53926 13.838L8.23183 13.1843C7.76103 12.9489 7.62554 12.8772 7.51247 12.79C7.39905 12.7026 7.29738 12.6009 7.20995 12.4875C7.12279 12.3744 7.05106 12.2389 6.81566 11.7681L6.16194 10.4607Z"
        fill={props.color ?? 'url(#paint3_linear_2656_730)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_2656_730"
          x1="9.99992"
          y1="0.833374"
          x2="9.99992"
          y2="19.1667"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2656_730"
          x1="0.833252"
          y1="10"
          x2="19.1666"
          y2="10"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#943FFF" />
          <stop offset="0.5" stopColor="#6C3EF0" />
          <stop offset="1" stopColor="#474EFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2656_730"
          x1="9.99992"
          y1="0.833374"
          x2="9.99992"
          y2="19.1667"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2656_730"
          x1="0.833252"
          y1="10"
          x2="19.1666"
          y2="10"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#943FFF" />
          <stop offset="0.5" stopColor="#6C3EF0" />
          <stop offset="1" stopColor="#474EFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
