import {useLingui} from '@lingui/react';
import {memo} from 'react';

interface Props {
  value?: string;
}

const PercentageInfoItem = memo(({value}: Props) => {
  const {i18n} = useLingui();
  const valueNumber = Number(value ?? '');

  if (!value || !Number.isFinite(valueNumber)) {
    return null;
  }

  return (
    <div className="flex items-center gap-1 text-sm text-default-600">
      <span className="flex-1">
        {i18n.number(valueNumber / 100, {
          style: 'percent',
          maximumFractionDigits: 1,
        })}
      </span>
    </div>
  );
});

PercentageInfoItem.displayName = 'PercentageInfoItem';

export default PercentageInfoItem;
