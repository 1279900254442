import {memo} from 'react';
import Markdown from 'react-markdown';
import {MarkdownSection as MarkdownSectionType} from './types';
import {twMerge} from 'tailwind-merge';

const MarkdownSection = memo(
  ({markdown, className}: {className?: string} & MarkdownSectionType) => {
    return (
      <Markdown
        className={twMerge(
          'prose prose-sm overflow-x-auto text-sm font-normal text-default-600 [&>pre>code]:font-sans [&>pre]:whitespace-pre-wrap',
          className,
        )}>
        {markdown}
      </Markdown>
    );
  },
);

MarkdownSection.displayName = 'MarkdownSection';

export default MarkdownSection;
