import {memo} from 'react';
import FastMarquee from 'react-fast-marquee';
import {useAuthContext, useChatInputContext} from '@/hooks';
import {Trans} from '@lingui/macro';
import {Button} from '@nextui-org/react';
import ChatInput from './ChatInput';
import PortalChatHeader from '@/routes/PortalChatHeader';

const suggestions = [
  [
    '📖 Find my weakest points now',
    '🎖️ How can I improve my customer retention?',
    '📈 What am i doing well',
    '🔍 What marketing strategies are working best?',
    '⚙️ Help me to manage idleness',
  ],
  [
    '💰 Help me with black friday',
    '📈 What can i do to increase my sales',
    '⚙️ How do I optimize my advertising budget across platforms?',
    '🎖️ How do I improve my online presence?',
    '📖 How can I prepare for seasonal sales variations?',
    '🛍️ Which products should I promote more aggressively?',
  ],
  [
    '🛍️ Find my worst performing campaigns',
    '🛍️ Should I spend more on marketing',
    '⚙️ How do I balance stock levels with sales demand?',
    '🔍 What products are underperforming?',
    '📈 How can I increase customer satisfaction?',
    "🎖️ How can I attract my competitor's customers",
  ],
];

const ChatPreview = memo(() => {
  const {onPressSuggestion} = useChatInputContext();
  const {user} = useAuthContext();

  return (
    <div className="relative flex w-full max-w-[min(100dvw,48rem)] grow flex-col justify-between">
      <PortalChatHeader />
      <div className="flex w-full max-w-full flex-col">
        <div className="flex w-full max-w-[48rem] flex-col items-center justify-center gap-12 text-center">
          <div className="px-5 text-2xl font-semibold text-foreground">
            {user?.firstName ? (
              <Trans>Welcome, {user.firstName}</Trans>
            ) : (
              <Trans>Welcome to Portal AI</Trans>
            )}
          </div>
          <div className="flex w-full flex-col gap-2">
            {suggestions.map((suggestion, index) => (
              <FastMarquee
                key={index}
                direction={index % 2 ? 'left' : 'right'}
                className="flex w-full gap-2"
                speed={32}
                pauseOnHover>
                {suggestion.map((caption, index) => (
                  <div className="flex gap-2" key={index}>
                    {index > 0 ? <div /> : null}
                    <Button
                      aria-label="Suggestion"
                      disableAnimation
                      disableRipple
                      className="max-h-auto min-h-0 min-w-0 rounded-full bg-[#F2F2F5] px-5 py-2"
                      onPress={() => onPressSuggestion(caption)}>
                      {caption}
                    </Button>
                  </div>
                ))}
              </FastMarquee>
            ))}
          </div>
        </div>
      </div>
      <ChatInput />
    </div>
  );
});

ChatPreview.displayName = 'ChatPreview';

export default ChatPreview;
