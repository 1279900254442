import Globe01Icon from '@/components/icons/Globe01Icon';
import {memo} from 'react';

const UrlInfoItem = memo(({url}: {url?: string}) => {
  if (!url) {
    return null;
  }

  try {
    new URL(url);
  } catch {
    url = `https://${url}`;
  }

  return (
    <div className="flex items-center gap-1 text-sm text-default-600">
      <Globe01Icon className="h-4 w-4 shrink-0" />
      <span className="flex-1">{url}</span>
    </div>
  );
});

UrlInfoItem.displayName = 'UrlInfoItem';

export default UrlInfoItem;
