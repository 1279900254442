import {Trans} from '@lingui/macro';
import {Link} from '@nextui-org/react';
import {FC} from 'react';

export const LegalFooter: FC = () => (
  <div className={`text-center text-xs leading-4 text-default-400`}>
    <Trans>
      <p>
        By clicking Continue, you agree to the{' '}
        <Link
          aria-label="Terms of Service"
          className="text-xs text-default-400 underline"
          href="/legal/terms">
          Terms of Service
        </Link>
      </p>
      <p>
        . Learn more about our data practices in our{' '}
        <Link
          aria-label="Privacy Policy"
          className="text-xs text-default-400 underline"
          href="/legal/privacy">
          Privacy Policy
        </Link>
        .
      </p>
    </Trans>
  </div>
);
