import {memo} from 'react';
import {BarChart as BarChartType, BarChartData, ChartFromat} from '../../types';
import {twMerge} from 'tailwind-merge';
import {useLingui} from '@lingui/react';

interface Props {
  data: BarChartData;
  format?: ChartFromat;
  className?: string;
  charts: BarChartType[];
}

const BarChart = memo(({data, format, charts, className}: Props) => {
  const {i18n} = useLingui();
  const maxValue = Math.max(
    ...(charts.map(i => i.data.max_value ?? i.data.value) ?? [0]),
  );
  const maxValueBasis = Math.round(
    ((data.max_value ?? data.value) / maxValue) * 100,
  );

  return (
    <div className={twMerge('flex flex-col items-center gap-3', className)}>
      <div className="flex w-full flex-1 flex-col justify-end">
        <div
          style={{flexBasis: `${maxValueBasis}%`}}
          className={`bottom-0 z-10 flex w-full flex-col justify-end rounded-xl bg-[repeating-linear-gradient(-45deg,#BBA4FD,#BBA4FD_6px,#5100D6_6px,#5100D6_8px)] text-sm font-semibold text-background`}>
          <div
            style={{
              flexBasis: `${Math.round((data.value / (data.max_value ?? data.value)) * 100)}%`,
            }}
            className={`bottom-0 z-10 flex w-full items-center justify-center rounded-xl bg-[#5100D6] px-2 py-1 text-sm font-semibold text-background`}>
            {i18n.number(data.value, {maximumFractionDigits: 0, ...format})}
          </div>
        </div>
      </div>
      <div className="text-center text-[0.625rem] font-medium sm:text-sm">
        {data.label}
      </div>
    </div>
  );
});

BarChart.displayName = 'BarChart';

export default BarChart;
