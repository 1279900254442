import {memo} from 'react';
import {NoticeSection as NoticeSectionType} from './types';
import {twMerge} from 'tailwind-merge';

const NoticeSection = memo(
  ({notice, className}: {className?: string} & NoticeSectionType) => {
    return (
      <div
        className={twMerge('flex flex-col gap-2 rounded-2xl p-4', className)}>
        {notice.icon_url && (
          <img src={notice.icon_url} className="h-8 w-auto" alt="Icon" />
        )}
        <div className="text-center text-sm font-normal text-default-600">
          {notice.description}
        </div>
      </div>
    );
  },
);

NoticeSection.displayName = 'NoticeSection';

export default NoticeSection;
