import {Trans} from '@lingui/macro';
import {memo} from 'react';
import {useGetBusinessUrl} from '../hooks';
import UrlInfoItem from './UrlInfoItem';
import InfoSectionHeader from './InfoSectionHeader';

const BusinessUrlInfoSection = memo(() => {
  const {businessUrl} = useGetBusinessUrl();

  return (
    <div className="flex w-full flex-col gap-2">
      <InfoSectionHeader
        stepId="website_url"
        title={<Trans>My Business Website</Trans>}
      />
      <UrlInfoItem url={businessUrl} />
    </div>
  );
});

BusinessUrlInfoSection.displayName = 'BusinessUrlInfoSection';

export default BusinessUrlInfoSection;
