import {
  ChatSessionWithMessages,
  ChatMessage,
  ChatSession,
} from '@/common/types/chat';

export type ChatSessionsState = {
  sessions: ChatSessionWithMessages[];
};

export enum ChatActionType {
  SET_SESSIONS = 'SET_SESSIONS',
  ADD_OR_UPDATE_MESSAGE = 'ADD_OR_UPDATE_MESSAGE',
  ADD_OR_UPDATE_SESSION = 'ADD_OR_UPDATE_SESSION',
  ADD_SESSION_MESSAGES = 'ADD_SESSION_MESSAGES',
  UPDATE_SESSION_DATA = 'UPDATE_SESSION_DATA',
}

export type SetSessionsAction = {
  type: ChatActionType.SET_SESSIONS;
  payload: ChatSessionWithMessages[];
};

export type AddOrUpdateMessageAction = {
  type: ChatActionType.ADD_OR_UPDATE_MESSAGE;
  payload: {
    message: ChatMessage;
    finished: boolean;
  };
};

export type AddOrUpdateSessionAction = {
  type: ChatActionType.ADD_OR_UPDATE_SESSION;
  payload: {
    session: ChatSessionWithMessages;
  };
};

export type AddSessionMessagesAction = {
  type: ChatActionType.ADD_SESSION_MESSAGES;
  payload: {
    sessionId: string;
    messages: ChatMessage[];
  };
};

export type UpdateSessionData = {
  type: ChatActionType.UPDATE_SESSION_DATA;
  payload: {
    sessionId: string;
    data: Partial<ChatSession>;
  };
};

export type ChatActions =
  | SetSessionsAction
  | AddOrUpdateMessageAction
  | AddOrUpdateSessionAction
  | AddSessionMessagesAction
  | UpdateSessionData;
