import BackButton from '@/components/buttons/BackButton';
import InfoCircleIcon from '@/components/icons/InfoCircleIcon';
import Settings01Icon from '@/components/icons/Settings01Icon';
import {useToolContext} from '@/hooks';
import {Button} from '@nextui-org/react';
import {memo} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';

const ToolOverviewHeader = memo(({className}: {className?: string}) => {
  const {appName, agent} = useToolContext();
  const location = useLocation();
  const backTo = location.state?.from || '../..';
  const {logo, name} = agent!;

  return (
    <div className={twMerge(`flex w-full flex-col`, className)}>
      <BackButton className="mr-auto h-12" to={backTo} />
      <div className="px-5 pb-3 pt-1">
        <div className="flex items-center gap-2">
          {logo?.url ? (
            <img
              className="flex aspect-square w-7 shrink-0 self-center rounded-lg"
              src={logo?.url}
            />
          ) : null}
          <h1 className="text-xl font-semibold">{name}</h1>
          <Button
            aria-label="Tool info"
            as={RouterLink}
            to={`/catalog/${appName}/unlocked/info`}
            state={{from: location}}
            radius="full"
            className="h-5 w-5 min-w-0 shrink-0 p-0 data-[hover=true]:bg-transparent"
            variant="light"
            isIconOnly>
            <InfoCircleIcon className="h-full w-full text-default-400" />
          </Button>
          <Button
            aria-label="Tool settings"
            as={RouterLink}
            to={`/catalog/${appName}/unlocked/settings`}
            radius="full"
            className="ml-auto h-7 w-7 min-w-0 shrink-0 p-0 data-[hover=true]:bg-transparent"
            variant="light"
            isIconOnly>
            <Settings01Icon className="h-full w-full text-default-500" />
          </Button>
        </div>
      </div>
    </div>
  );
});

ToolOverviewHeader.displayName = 'ToolOverviewHeader';

export default ToolOverviewHeader;
