import {memo, useState, PropsWithChildren, useMemo, useCallback} from 'react';
import {LS_AUTH_TOKEN_KEY, LS_REFRESH_TOKEN_KEY} from '@/config';
import {AppProviderContext} from '@/contexts';
import {Toast, TokenResponse} from '@/common/types';
import {ApolloClient} from '@apollo/client';
import {instrumentTracker} from '@/common/instrumentTracker';
import {reportLogout} from '@/common/analytics';

const initialToken = localStorage.getItem(LS_AUTH_TOKEN_KEY) ?? undefined;

export const AppProvider = memo(({children, ...props}: PropsWithChildren) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(initialToken));

  const login = useCallback(
    ({
      access_token,
      refresh_token,
    }: Pick<TokenResponse, 'access_token' | 'refresh_token'>) => {
      localStorage.setItem(LS_AUTH_TOKEN_KEY, access_token);

      if (refresh_token) {
        localStorage.setItem(LS_REFRESH_TOKEN_KEY, refresh_token);
      }

      setIsAuthenticated(true);
    },
    [],
  );

  const logout = useCallback((apolloClient: ApolloClient<any> | undefined) => {
    localStorage.removeItem(LS_AUTH_TOKEN_KEY);
    localStorage.removeItem(LS_REFRESH_TOKEN_KEY);
    setIsAuthenticated(false);
    reportLogout();
    instrumentTracker.setUserId(undefined);
    apolloClient?.clearStore();
  }, []);

  const appState = useMemo(
    () => ({
      toasts,
      setToasts,
      login,
      logout,
      isAuthenticated,
    }),
    [toasts, login, logout, isAuthenticated],
  );

  return (
    <AppProviderContext.Provider {...props} value={appState}>
      {children}
    </AppProviderContext.Provider>
  );
});

AppProvider.displayName = 'AppProvider';
