import {ComponentProps, memo, useCallback, useRef} from 'react';
import {Button, Textarea} from '@nextui-org/react';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';
import {AnimatePresence, motion} from 'framer-motion';
import {useChatContext, useChatInputContext} from '@/hooks';
import Attachment01Icon from '@/components/icons/Attachment01Icon';
import SendIcon from '@/components/icons/SendIcon';
import {useFileUpload} from '@/components/Chat/hooks';
import ChatCounter from './ChatCounter';
import {twMerge} from 'tailwind-merge';

interface Props {
  className?: ComponentProps<'div'>['className'];
}

const ChatInput = memo(({className = ''}: Props) => {
  const {activeSession, totalUnreadCount} = useChatContext();
  const {
    createMessageIsLoading,
    createSessionIsLoading,
    text,
    setText,
    onPressSend,
    onEnterSend,
  } = useChatInputContext();
  const isLoading = createMessageIsLoading || createSessionIsLoading;
  const {_} = useLingui();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChange = useFileUpload();
  const isLocked = activeSession?.locked;
  const enabled = !isLocked;
  const sendTextEnabled = !isLoading && Boolean(text) && enabled;
  const sendFileEnabled = !isLoading && enabled;
  const wrapperFocusClass = 'group-data-[focus=true]:bg-background';
  const wrapperHoverClass = 'data-[hover=true]:bg-background';
  const wrapperGroupFocusClass = 'group-data-[focus=true]:bg-background';
  const placeholderClass = 'text-default-700 placeholder:text-default-400';
  const textHasValueClass = 'group-data-[has-value=true]:text-foreground';
  const endIconClass =
    'min-h-8 max-h-8 min-w-8 max-w-8 p-1 items-center justify-center opacity-100 rounded-lg !bg-transparent';
  const fileStrokeClass = sendFileEnabled
    ? '[&:hover_path]:stroke-default-400 [&>path]:stroke-default-600'
    : '[&>path]:stroke-default-400';
  const sendStrokeClass = sendTextEnabled
    ? '[&>path]:fill-default-600 [&:hover_path]:fill-default-400'
    : '[&>path]:stroke-default-600 [&:hover_path]:stroke-default-400';

  const focusInput = useCallback(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 500);
  }, []);

  const onPressSendAction = useCallback(() => {
    onPressSend();

    focusInput();
  }, [onPressSend, focusInput]);

  const onEnterSendAction = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      onEnterSend(e);

      focusInput();
    },
    [onEnterSend, focusInput],
  );

  return (
    <div
      className={twMerge(
        `relative flex w-full flex-row items-center gap-4 px-5 py-2`,
        className,
      )}>
      <div
        className={`w-full ${enabled ? 'md:max-w-[var(--chat-input-max-width)]' : 'md:min-w-[15rem]'} transition-[min-width] duration-300 ease-out`}>
        <Textarea
          ref={inputRef}
          isReadOnly={!enabled}
          classNames={{
            inputWrapper: `relative w-full !shadow-none !opacity-100 bg-default-500 border-1 border-default-200 bg-background p-1 min-h-10 rounded-3xl ${wrapperFocusClass} ${wrapperHoverClass} ${wrapperGroupFocusClass}`,
            innerWrapper:
              'w-full min-h-10 p-0 h-auto items-center transition-width ',
            input: `flex grow w-auto px-3 data-[has-start-content=true]:ps-3 font-medium data-[has-end-content=true]:pe-3 py-1 ${enabled ? '' : 'cursor-pointer'} ${placeholderClass} ${textHasValueClass}`,
          }}
          placeholder={_(isLocked ? msg`Chat session is Closed` : msg`Message`)}
          value={enabled ? text : ''}
          minRows={1}
          maxRows={5}
          endContent={
            <AnimatePresence>
              {enabled ? (
                <motion.div className="flex h-full flex-row items-center self-end overflow-hidden p-1">
                  <Button
                    aria-label="Attach file"
                    isIconOnly
                    disableRipple
                    radius="sm"
                    variant="light"
                    disabled={!sendFileEnabled}
                    className={endIconClass}
                    onPress={() => inputFileRef.current?.click()}>
                    <Attachment01Icon
                      className={`h-6 w-6 ${fileStrokeClass}`}
                    />
                  </Button>
                  <Button
                    aria-label="Send message"
                    disableRipple
                    isIconOnly
                    className={`${endIconClass} mr-2`}
                    isDisabled={!sendTextEnabled}
                    onPress={onPressSendAction}>
                    <SendIcon
                      className={sendStrokeClass}
                      enabled={sendTextEnabled}
                    />
                  </Button>
                  <input
                    ref={inputFileRef}
                    type="file"
                    className="hidden"
                    accept="application/pdf"
                    disabled={!sendFileEnabled}
                    onChange={event => {
                      onFileChange(event).finally(() => {
                        if (inputFileRef.current) {
                          inputFileRef.current.value = '';
                        }
                      });
                    }}
                  />
                  <ChatCounter count={totalUnreadCount} />
                </motion.div>
              ) : null}
            </AnimatePresence>
          }
          isDisabled={isLoading}
          onValueChange={setText}
          onKeyDown={onEnterSendAction}
        />
      </div>
    </div>
  );
});

ChatInput.displayName = 'ChatInput';

export default ChatInput;
