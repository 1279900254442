import {useRef, memo, useState} from 'react';
import debounce from 'lodash/debounce';
import {WordCloudSection as WordCloudSectionType} from '../types';
import {twMerge} from 'tailwind-merge';
import {useResizeObserver} from '@/hooks';
import cloudSVG from './cloud.svg';
import Words from './Words';

type WordCloudProps = {
  className?: string;
  isDrillDown?: boolean;
} & WordCloudSectionType;

const WordCloudSection = memo(
  ({wordcloud, title, className}: WordCloudProps) => {
    const wordsContainer = useRef<HTMLDivElement>(null);
    const [wordsDimensions, setWordsDimensions] = useState<
      {width: number; height: number} | undefined
    >();

    const onWordsContainerResizeRef = useRef(
      debounce(
        (entry: ResizeObserverEntry) => {
          const width = entry.contentRect.width;
          const height = Math.max(0, entry.contentRect.height - 40); // add padding on top

          if (width && height) {
            setWordsDimensions({width, height});
          }
        },
        80,
        {
          leading: true,
          trailing: true,
        },
      ),
    );

    useResizeObserver(wordsContainer, onWordsContainerResizeRef.current);

    return (
      <div
        className={twMerge(
          'relative flex w-full flex-col items-center gap-6 overflow-hidden',
          className,
        )}>
        <div className="flex w-full px-6 text-start text-xs text-default-500">
          {title ?? ''}
        </div>
        <div ref={wordsContainer} className="relative w-full">
          <img src={cloudSVG} alt="Word Cloud" />
          {wordsDimensions ? (
            <Words
              {...wordsDimensions}
              wordcloud={wordcloud}
              className="absolute bottom-[1.5rem] left-0 right-0"
            />
          ) : null}
        </div>
        <div
          className="absolute bottom-0 left-0 right-0 h-[5.5rem]"
          style={{
            background:
              'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
          }}
        />
      </div>
    );
  },
);

WordCloudSection.displayName = 'WordCloudSection';

export default WordCloudSection;
