import useLineClamp from '@/hooks/useLineClamp';
import {Trans} from '@lingui/macro';
import {Button} from '@nextui-org/react';
import {ComponentProps, memo, useRef, useState} from 'react';

interface Props {
  description: string;
  className?: ComponentProps<'div'>['className'];
}

const AppDescription = memo(({description, className}: Props) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [visible, setVisible] = useState(false);
  const clamps = useLineClamp(textRef, {
    lines: 3,
    key: description,
  });

  return (
    <div className={className ?? ''}>
      <p
        ref={textRef}
        className={`whitespace-pre-wrap text-sm text-foreground ${visible ? '' : 'line-clamp-3'}`}>
        {description}
      </p>
      <div className="relative">
        {clamps && !visible ? (
          <Button
            aria-label={visible ? 'Show less' : 'Show more'}
            disableAnimation
            disableRipple
            className="absolute bottom-0 right-0 h-auto min-h-0 w-auto min-w-0 bg-background pl-4 pr-0 text-primary !opacity-100"
            onPress={() => setVisible(!visible)}>
            {visible ? <Trans>less</Trans> : <Trans>more</Trans>}
          </Button>
        ) : null}
      </div>
    </div>
  );
});

AppDescription.displayName = 'AppDescription';

export default AppDescription;
