import {SVGProps} from 'react';

export default function GridIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57938 2.33338C7.08198 2.33356 8.5875 2.33356 10.0871 2.33338C10.3816 2.33334 10.6683 2.33331 10.9112 2.35315C11.1779 2.37494 11.4926 2.42635 11.8092 2.5877C12.2483 2.8114 12.6052 3.16836 12.8289 3.6074C12.9903 3.92406 13.0417 4.23875 13.0635 4.50545C13.0833 4.7483 13.0833 5.03507 13.0833 5.32952V9.83724C13.0833 10.1317 13.0833 10.4185 13.0635 10.6613C13.0417 10.928 12.9903 11.2427 12.8289 11.5594C12.6052 11.9984 12.2483 12.3554 11.8092 12.5791C11.4926 12.7404 11.1779 12.7918 10.9112 12.8136C10.6683 12.8335 10.3816 12.8334 10.0871 12.8334H5.5794C5.28494 12.8334 4.99818 12.8335 4.75532 12.8136C4.48863 12.7918 4.17394 12.7404 3.85728 12.5791C3.41824 12.3554 3.06128 11.9984 2.83758 11.5594C2.67623 11.2427 2.62482 10.928 2.60303 10.6613C2.58319 10.4185 2.58322 10.1317 2.58325 9.83726C2.58326 9.82487 2.58326 9.81246 2.58326 9.80005V5.36671C2.58326 5.3543 2.58326 5.34189 2.58325 5.3295C2.58322 5.03505 2.58319 4.7483 2.60303 4.50545C2.62482 4.23875 2.67623 3.92406 2.83758 3.6074C3.06128 3.16836 3.41824 2.8114 3.85728 2.5877C4.17394 2.42635 4.48863 2.37494 4.75532 2.35315C4.99817 2.33331 5.28493 2.33334 5.57938 2.33338Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57938 15.1667C7.08198 15.1669 8.5875 15.1669 10.0871 15.1667C10.3816 15.1667 10.6683 15.1666 10.9112 15.1865C11.1779 15.2083 11.4926 15.2597 11.8092 15.421C12.2483 15.6447 12.6052 16.0017 12.8289 16.4407C12.9903 16.7574 13.0417 17.0721 13.0635 17.3388C13.0833 17.5816 13.0833 17.8684 13.0833 18.1629V22.6706C13.0833 22.965 13.0833 23.2518 13.0635 23.4946C13.0417 23.7613 12.9903 24.076 12.8289 24.3927C12.6052 24.8317 12.2483 25.1887 11.8092 25.4124C11.4926 25.5737 11.1779 25.6252 10.9112 25.6469C10.6683 25.6668 10.3816 25.6668 10.0871 25.6667H5.5794C5.28494 25.6668 4.99818 25.6668 4.75532 25.6469C4.48863 25.6252 4.17394 25.5737 3.85728 25.4124C3.41824 25.1887 3.06128 24.8317 2.83758 24.3927C2.67623 24.076 2.62482 23.7613 2.60303 23.4946C2.58319 23.2518 2.58322 22.965 2.58325 22.6706C2.58326 22.6582 2.58326 22.6458 2.58326 22.6334V18.2C2.58326 18.1876 2.58326 18.1752 2.58325 18.1628C2.58322 17.8684 2.58319 17.5816 2.60303 17.3388C2.62482 17.0721 2.67623 16.7574 2.83758 16.4407C3.06128 16.0017 3.41824 15.6447 3.85728 15.421C4.17394 15.2597 4.48863 15.2083 4.75532 15.1865C4.99817 15.1666 5.28493 15.1667 5.57938 15.1667Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4127 2.33338C19.9153 2.33356 21.4208 2.33356 22.9205 2.33338C23.2149 2.33334 23.5017 2.33331 23.7445 2.35315C24.0112 2.37494 24.3259 2.42635 24.6426 2.5877C25.0816 2.8114 25.4386 3.16836 25.6623 3.6074C25.8236 3.92406 25.875 4.23875 25.8968 4.50545C25.9167 4.7483 25.9166 5.03507 25.9166 5.32952V9.83724C25.9166 10.1317 25.9167 10.4185 25.8968 10.6613C25.875 10.928 25.8236 11.2427 25.6623 11.5594C25.4386 11.9984 25.0816 12.3554 24.6426 12.5791C24.3259 12.7404 24.0112 12.7918 23.7445 12.8136C23.5017 12.8335 23.2149 12.8334 22.9205 12.8334H18.4127C18.1183 12.8334 17.8315 12.8335 17.5887 12.8136C17.322 12.7918 17.0073 12.7404 16.6906 12.5791C16.2516 12.3554 15.8946 11.9984 15.6709 11.5594C15.5096 11.2427 15.4581 10.928 15.4364 10.6613C15.4165 10.4185 15.4166 10.1317 15.4166 9.83726C15.4166 9.82487 15.4166 9.81246 15.4166 9.80005V5.36671C15.4166 5.3543 15.4166 5.34189 15.4166 5.3295C15.4166 5.03505 15.4165 4.7483 15.4364 4.50545C15.4581 4.23875 15.5096 3.92406 15.6709 3.6074C15.8946 3.16836 16.2516 2.8114 16.6906 2.5877C17.0073 2.42635 17.322 2.37494 17.5887 2.35315C17.8315 2.33331 18.1183 2.33334 18.4127 2.33338Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4127 15.1667C19.9153 15.1669 21.4208 15.1669 22.9205 15.1667C23.2149 15.1667 23.5017 15.1666 23.7445 15.1865C24.0112 15.2083 24.3259 15.2597 24.6426 15.421C25.0816 15.6447 25.4386 16.0017 25.6623 16.4407C25.8236 16.7574 25.875 17.0721 25.8968 17.3388C25.9167 17.5816 25.9166 17.8684 25.9166 18.1629V22.6706C25.9166 22.965 25.9167 23.2518 25.8968 23.4946C25.875 23.7613 25.8236 24.076 25.6623 24.3927C25.4386 24.8317 25.0816 25.1887 24.6426 25.4124C24.3259 25.5737 24.0112 25.6252 23.7445 25.6469C23.5017 25.6668 23.2149 25.6668 22.9205 25.6667H18.4127C18.1183 25.6668 17.8315 25.6668 17.5887 25.6469C17.322 25.6252 17.0073 25.5737 16.6906 25.4124C16.2516 25.1887 15.8946 24.8317 15.6709 24.3927C15.5096 24.076 15.4581 23.7613 15.4364 23.4946C15.4165 23.2518 15.4166 22.965 15.4166 22.6706C15.4166 22.6582 15.4166 22.6458 15.4166 22.6334V18.2C15.4166 18.1876 15.4166 18.1752 15.4166 18.1628C15.4166 17.8684 15.4165 17.5816 15.4364 17.3388C15.4581 17.0721 15.5096 16.7574 15.6709 16.4407C15.8946 16.0017 16.2516 15.6447 16.6906 15.421C17.0073 15.2597 17.322 15.2083 17.5887 15.1865C17.8315 15.1666 18.1183 15.1667 18.4127 15.1667Z"
      />
    </svg>
  );
}
