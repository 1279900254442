import {useCallback} from 'react';
import {AxiosError} from 'axios';
import {Toast} from '@/common/types';
import {useAppContext} from './useContexts';

export const useToastQueue = () => {
  const {toasts, setToasts} = useAppContext();

  const removeToast = useCallback(
    (id: string) => {
      setToasts(toasts => toasts.filter(t => t.id !== id));
    },
    [setToasts],
  );

  const addToast = useCallback(
    (toast: Omit<Toast, 'id'>, autoCloseDurationMs: number | null = 4000) => {
      const id = `${Date.now()}_${Math.random()}`.toString();

      setToasts(toasts => [...toasts, {id, ...toast}]);

      if (autoCloseDurationMs) {
        setTimeout(() => {
          removeToast(id);
        }, autoCloseDurationMs);
      }
    },
    [setToasts, removeToast],
  );

  const addSuccessToast = useCallback(
    (toast: Omit<Toast, 'id' | 'type'>) => {
      addToast({...toast, type: 'success'});
    },
    [addToast],
  );

  const addWarningToast = useCallback(
    (toast: Omit<Toast, 'id' | 'type'>) => {
      addToast({...toast, type: 'warning'}, null);
    },
    [addToast],
  );

  const addErrorToast = useCallback(
    (data: Omit<Toast, 'id' | 'type'> | AxiosError) => {
      const toast =
        data instanceof AxiosError
          ? {
              message: (<any>data.response?.data)?.detail ?? data.message,
              type: 'error' as const,
            }
          : data;
      if (toast.message) {
        console.error(toast.message);
      }
      // addToast({...toast, type: 'error'}, null);
    },
    [addToast],
  );

  return {
    toasts,
    addToast,
    addErrorToast,
    addSuccessToast,
    addWarningToast,
    removeToast,
  };
};
