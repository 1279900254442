import {SVGProps} from 'react';

export default function StarBigFilledIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}>
      <path d="M6.26695 27.2339C6.00636 27.0388 5.84664 26.7758 5.7878 26.445C5.73736 26.1142 5.792 25.7197 5.95172 25.2617L8.58704 17.3474L1.85373 12.4615C1.45864 12.1816 1.18544 11.8931 1.03413 11.5963C0.882818 11.2994 0.8576 10.994 0.958473 10.6801C1.05935 10.3748 1.25689 10.1457 1.55111 9.99305C1.84532 9.84037 2.23621 9.76826 2.72376 9.77675L10.9828 9.82764L13.492 1.87524C13.6433 1.4087 13.8325 1.05667 14.0594 0.819158C14.2948 0.581647 14.5722 0.462891 14.8916 0.462891C15.2195 0.462891 15.4969 0.581647 15.7239 0.819158C15.9592 1.05667 16.1526 1.4087 16.3039 1.87524L18.8131 9.82764L27.0721 9.77675C27.5597 9.76826 27.9506 9.84037 28.2448 9.99305C28.539 10.1457 28.7366 10.3748 28.8374 10.6801C28.9383 10.994 28.9131 11.2994 28.7618 11.5963C28.6105 11.8931 28.3373 12.1816 27.9422 12.4615L21.2089 17.3474L23.8442 25.2617C24.0039 25.7197 24.0543 26.1142 23.9955 26.445C23.9451 26.7758 23.7895 27.0388 23.5289 27.2339C23.2684 27.4374 22.9741 27.5053 22.6463 27.4374C22.3185 27.3781 21.9612 27.2084 21.5745 26.9285L14.8916 21.9789L8.22137 26.9285C7.83469 27.2084 7.47743 27.3781 7.14959 27.4374C6.82175 27.5053 6.52754 27.4374 6.26695 27.2339Z" />
    </svg>
  );
}
