import {memo, useState} from 'react';
import {Formik, Form, Field} from 'formik';
import {object, string} from 'yup';
import {Button} from '@nextui-org/react';
import {useLingui} from '@lingui/react';
import {Trans, msg} from '@lingui/macro';
import {useToastQueue} from '@/hooks';
import ErrorMessage from '@/components/ErrorMessage';
import {ApolloError} from '@apollo/client';
import {formatApolloError} from '../helpers';
import NextUIInput from '@/components/inputs/NextUIInput';
import PortalSpinner from '@/components/PortalSpinner';
import BackButton from './BackButton';

const ResetPasswordEmailForm = memo(
  ({
    initialEmail,
    onSubmit,
    onBack,
  }: {
    initialEmail: string;
    onSubmit: (values: {email: string}) => Promise<void>;
    onBack: (values: {email: string}) => Promise<void>;
  }) => {
    const [error, setError] = useState<string | undefined>();
    const {_} = useLingui();
    const {addErrorToast} = useToastQueue();

    return (
      <Formik
        initialValues={{email: initialEmail}}
        validationSchema={object({
          email: string()
            .email(_(msg`Invalid email address`))
            .required(_(msg`Required`)),
        })}
        onSubmit={async values => {
          setError(undefined);

          try {
            await onSubmit(values);
          } catch (e: any) {
            if (e instanceof ApolloError) {
              setError(formatApolloError(e) || undefined);
            } else {
              const {data} = e.response || {};
              const message = data.detail || e.message;

              setError(message);
              addErrorToast({message});
            }
          }
        }}>
        {({isSubmitting, touched, errors, values}) => {
          const isEmailInvalid = Boolean(touched.email && errors.email);

          return (
            <Form className="flex min-h-[5.25rem] w-full flex-col gap-6">
              <Field
                as={NextUIInput}
                name="email"
                type="email"
                label={_(msg`Email`)}
                placeholder={_(msg`Enter your email`)}
                isInvalid={isEmailInvalid}
                errorMessage={isEmailInvalid ? errors.email : undefined}
                isDisabled={isSubmitting}
              />
              <ErrorMessage message={error} />
              <div className="flex w-full flex-col gap-2">
                <Button
                  aria-label="Reset password"
                  fullWidth
                  type="submit"
                  variant="solid"
                  color="primary"
                  radius="sm"
                  className="font-medium text-background"
                  spinner={<PortalSpinner size="sm" />}
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}>
                  <Trans>Reset password</Trans>
                </Button>
                <BackButton
                  isDisabled={isSubmitting}
                  onPress={() => onBack(values)}>
                  <Trans>Back</Trans>
                </BackButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  },
);

ResetPasswordEmailForm.displayName = 'ResetPasswordEmailForm';

export default ResetPasswordEmailForm;
