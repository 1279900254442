import {Trans} from '@lingui/macro';
import {memo} from 'react';
import {useGetBusinessMarginPercentage} from '../hooks';
import InfoSectionHeader from './InfoSectionHeader';
import PercentageInfoItem from './PercentageInfoItem';

const BusinessMarginInfoSection = memo(() => {
  const {marginPercentage} = useGetBusinessMarginPercentage();

  return (
    <div className="flex w-full flex-col gap-2">
      <InfoSectionHeader
        stepId="business_margin_percentage"
        title={<Trans>My Business Margin</Trans>}
      />
      <PercentageInfoItem value={marginPercentage} />
    </div>
  );
});

BusinessMarginInfoSection.displayName = 'BusinessMarginInfoSection';

export default BusinessMarginInfoSection;
