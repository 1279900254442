import {memo} from 'react';
import {twMerge} from 'tailwind-merge';
import PortalChat from '@/assets/portal-chat.png';
import {motion} from 'framer-motion';
import {useMatchesTWBreakpoint} from '@/hooks';
import PortalChatActiveIcon from '@/components/icons/PortalChatActiveIcon';
import PortalChatInactiveIcon from '@/components/icons/PortalChatInactiveIcon';

interface Props {
  isSimple?: boolean;
  isActive: boolean;
  className?: string;
}

const transition = {
  type: 'spring',
  stiffness: 325,
  damping: 25,
};

const PortalChatIcon = memo(({isSimple, isActive, className}: Props) => {
  const increment = isSimple ? 15 : 25;
  const isMD = useMatchesTWBreakpoint('md');
  const arrowVariants = isSimple
    ? {
        active: {
          opacity: 1,
          x: 1.5,
          y: 12.5,
          scale: 1,
          transition,
        },
        inactive: {
          opacity: 0,
          x: 1.5 - increment,
          y: 12.5 + increment,
          scale: 0.75,
          transition,
        },
      }
    : {
        active: {
          opacity: 1,
          x: 12.5,
          y: 23,
          scale: 1,
          transition,
        },
        inactive: {
          opacity: 0,
          x: 12.5 - increment,
          y: 23 + increment,
          scale: 0.75,
          transition,
        },
      };
  const starsVariants = isSimple
    ? {
        active: {opacity: 1, x: -2, y: 8.5, scale: 1, transition},
        inactive: {
          opacity: 0,
          x: -2 - increment,
          y: 8.5 + increment,
          scale: 0.75,
          transition,
        },
      }
    : {
        active: {opacity: 1, x: 11.32, y: 18, scale: 1, transition},
        inactive: {
          opacity: 0,
          x: 11.32 - increment,
          y: 18 + increment,
          scale: 0.75,
          transition,
        },
      };

  const containerProps = isSimple
    ? {
        className: twMerge(
          `flex items-center justify-center relative`,
          className,
        ),
      }
    : {
        style: {
          background: `center / contain no-repeat url(${PortalChat})`,
        },
        className: twMerge(
          'flex min-h-12 min-w-12 max-w-12 shrink-0 items-center justify-center transition-all md:max-h-5 md:min-h-5 md:min-w-5 md:max-w-5 text-background',
          className,
        ),
      };

  if (isSimple && isMD) {
    return isActive ? (
      <PortalChatActiveIcon className={className} />
    ) : (
      <PortalChatInactiveIcon className={className} />
    );
  }

  return (
    <div {...containerProps}>
      <svg
        width={isSimple ? '100%' : '25'}
        height={isSimple ? '100%' : '24'}
        viewBox="0 0 25 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2588 7.2412C19.8876 9.87 21.5568 12.8237 22.1997 15.3951C22.8538 18.0114 22.3869 19.8614 21.3741 20.8741C20.3614 21.8869 18.5114 22.3538 15.8951 21.6997C15.1957 21.5248 14.4679 21.274 13.7262 20.9486L12.2207 22.4542C16.3896 24.4982 20.4894 24.5873 22.7884 22.2884C26.1976 18.8791 24.3551 11.5091 18.673 5.82698C12.9909 0.144879 5.6209 -1.69762 2.21164 1.71164C0.0160312 3.90725 -0.00139546 7.74559 1.78216 11.7179L3.30962 10.1904C3.09904 9.65037 2.9291 9.11999 2.80033 8.60494C2.14624 5.98855 2.61314 4.13857 3.62585 3.12585C4.63857 2.11314 6.48855 1.64624 9.10494 2.30033C11.6763 2.94317 14.63 4.61239 17.2588 7.2412Z"
          fill="currentColor"
        />
      </svg>
      <motion.svg
        width={isSimple ? '21' : '19'}
        height={isSimple ? '21' : '18'}
        viewBox="0 0 19 18"
        initial={false}
        animate={isActive ? 'inactive' : 'active'}
        variants={starsVariants}
        className="absolute left-0 top-0">
        <path
          d="M12.2722 1.23077C12.1608 0.941122 11.8825 0.75 11.5722 0.75C11.2619 0.75 10.9836 0.941122 10.8722 1.23077L9.98823 3.52908C9.76293 4.11486 9.69213 4.28367 9.59529 4.41986C9.49812 4.55652 9.37872 4.67591 9.24206 4.77308C9.10587 4.86992 8.93707 4.94072 8.35128 5.16602L6.05297 6.04999C5.76333 6.16139 5.5722 6.43967 5.5722 6.75C5.5722 7.06033 5.76333 7.33861 6.05297 7.45001L8.35128 8.33398C8.93707 8.55928 9.10587 8.63008 9.24206 8.72692C9.37872 8.82409 9.49812 8.94348 9.59529 9.08014C9.69213 9.21633 9.76293 9.38514 9.98823 9.97092L10.8722 12.2692C10.9836 12.5589 11.2619 12.75 11.5722 12.75C11.8825 12.75 12.1608 12.5589 12.2722 12.2692L13.1562 9.97092C13.3815 9.38514 13.4523 9.21633 13.5491 9.08014C13.6463 8.94348 13.7657 8.82409 13.9023 8.72692C14.0385 8.63008 14.2073 8.55928 14.7931 8.33398L17.0914 7.45001C17.3811 7.33861 17.5722 7.06033 17.5722 6.75C17.5722 6.43967 17.3811 6.16139 17.0914 6.04999L14.7931 5.16602C14.2073 4.94072 14.0385 4.86992 13.9023 4.77308C13.7657 4.67591 13.6463 4.55652 13.5491 4.41986C13.4523 4.28367 13.3815 4.11486 13.1562 3.52908L12.2722 1.23077Z"
          fill="currentColor"
        />
        <path
          d="M5.86802 9.41459C5.74098 9.1605 5.48128 9 5.1972 9C4.91313 9 4.65343 9.1605 4.52638 9.41459L3.93804 10.5913C3.72618 11.015 3.66162 11.1369 3.58317 11.2387C3.50449 11.3408 3.41298 11.4323 3.31091 11.511C3.20914 11.5894 3.08721 11.654 2.66348 11.8658L1.48679 12.4542C1.23271 12.5812 1.0722 12.8409 1.0722 13.125C1.0722 13.4091 1.23271 13.6688 1.48679 13.7958L2.66348 14.3842C3.08721 14.596 3.20914 14.6606 3.31091 14.739C3.41298 14.8177 3.50449 14.9092 3.58317 15.0113C3.66162 15.1131 3.72618 15.235 3.93804 15.6587L4.52638 16.8354C4.65343 17.0895 4.91313 17.25 5.19721 17.25C5.48128 17.25 5.74098 17.0895 5.86803 16.8354L6.45637 15.6587C6.66823 15.235 6.73279 15.1131 6.81124 15.0113C6.88992 14.9092 6.98143 14.8177 7.0835 14.739C7.18527 14.6606 7.3072 14.596 7.73093 14.3842L8.90761 13.7958C9.1617 13.6688 9.3222 13.4091 9.3222 13.125C9.3222 12.8409 9.1617 12.5812 8.90761 12.4542L7.73093 11.8658C7.3072 11.654 7.18527 11.5894 7.0835 11.511C6.98143 11.4323 6.88992 11.3408 6.81124 11.2387C6.73279 11.1369 6.66823 11.015 6.45637 10.5913L5.86802 9.41459Z"
          fill="currentColor"
        />
      </motion.svg>
      <motion.svg
        width={isSimple ? '14' : '13'}
        height={isSimple ? '14' : '13'}
        viewBox="0 0 13 13"
        initial={false}
        animate={isActive ? 'active' : 'inactive'}
        variants={arrowVariants}
        className="absolute left-0 top-0">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.49993 2H10.4999V9H12.4999V1C12.4999 0.447715 12.0522 0 11.4999 0H3.49993V2ZM0.5 5.99991H7.09227V12.5899H9.09227V4.99991C9.09227 4.44763 8.64455 3.99991 8.09227 3.99991H0.5V5.99991Z"
          fill="currentColor"
        />
      </motion.svg>
    </div>
  );
});

PortalChatIcon.displayName = 'PortalChatIcon';

export default PortalChatIcon;
