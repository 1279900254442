import {ComponentProps, memo} from 'react';
import {Trans} from '@lingui/macro';
import ScalesIcon from '@/components/icons/ScalesIcon';
import {RiskIndexGroup} from '@/common/types';
import {getRiskIndexText} from '@/utils';
import riskIndexVeryLow from '@/assets/riskIndex/very-low.svg';
import riskIndexLow from '@/assets/riskIndex/low.svg';
import riskIndexMedium from '@/assets/riskIndex/medium.svg';
import riskIndexHigh from '@/assets/riskIndex/high.svg';
import riskIndexVeryHigh from '@/assets/riskIndex/very-high.svg';
import {twMerge} from 'tailwind-merge';

interface Props {
  riskIndexGroup?: RiskIndexGroup;
  className?: ComponentProps<'div'>['className'];
  style?: ComponentProps<'div'>['style'];
}

const getChipColorClass = (riskIndexGroup: RiskIndexGroup) => {
  switch (riskIndexGroup) {
    case RiskIndexGroup.VeryLow:
      return 'bg-[#7EF30926] text-[#4B9700]';
    case RiskIndexGroup.Low:
      return 'bg-[#09F38326] text-[#00934D]';
    case RiskIndexGroup.Medium:
      return 'bg-[#33AAFF26] text-[#006EBD]';
    case RiskIndexGroup.High:
      return 'bg-[#436AF526] text-[#163CC2]';
    case RiskIndexGroup.VeryHigh:
      return 'bg-[#8B55F626] text-[#5925C0]';
    default:
      return '';
  }
};

const getBackground = (riskIndexGroup: RiskIndexGroup) => {
  switch (riskIndexGroup) {
    case RiskIndexGroup.VeryLow:
      return riskIndexVeryLow;
    case RiskIndexGroup.Low:
      return riskIndexLow;
    case RiskIndexGroup.Medium:
      return riskIndexMedium;
    case RiskIndexGroup.High:
      return riskIndexHigh;
    case RiskIndexGroup.VeryHigh:
      return riskIndexVeryHigh;
    default:
      return '';
  }
};

const RiskIndex = memo(({riskIndexGroup, className, style}: Props) => {
  if (!riskIndexGroup) {
    return null;
  }

  return (
    <div
      style={style}
      className={twMerge(
        `relative flex flex-col justify-between overflow-hidden`,
        className,
      )}>
      <div className="flex flex-col items-center gap-[0.125rem]">
        <ScalesIcon className="h-9 w-9" />
        <div className="text-md font-semibold text-foreground">
          <Trans>Risk Index</Trans>
        </div>
        <div className="text-sm text-default-500">
          <Trans>No actions will be applied without your approval.</Trans>
        </div>
        <div className="flex justify-center py-2">
          <div
            className={`rounded-full px-3 py-1 text-sm font-bold ${getChipColorClass(riskIndexGroup)}`}>
            {getRiskIndexText(riskIndexGroup)}
          </div>
        </div>
      </div>
      <div className="-mb-6 flex w-full items-end justify-center">
        <img
          src={getBackground(riskIndexGroup)}
          alt={`${riskIndexGroup} risk`}
        />
      </div>
    </div>
  );
});

RiskIndex.displayName = 'RiskIndex';

export default RiskIndex;
