import {SVGProps} from 'react';

export default function CircleFilledIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M6.99414 12.8223C6.16602 12.8223 5.38672 12.6641 4.65625 12.3477C3.92578 12.0352 3.28125 11.5996 2.72266 11.041C2.16797 10.4824 1.73242 9.83789 1.41602 9.10742C1.09961 8.37695 0.941406 7.59766 0.941406 6.76953C0.941406 5.94141 1.09961 5.16211 1.41602 4.43164C1.73242 3.70117 2.16797 3.05859 2.72266 2.50391C3.28125 1.94531 3.92383 1.50781 4.65039 1.19141C5.38086 0.875 6.16016 0.716797 6.98828 0.716797C7.82031 0.716797 8.60156 0.875 9.33203 1.19141C10.0625 1.50781 10.707 1.94531 11.2656 2.50391C11.8242 3.05859 12.2617 3.70117 12.5781 4.43164C12.8945 5.16211 13.0527 5.94141 13.0527 6.76953C13.0527 7.59766 12.8945 8.37695 12.5781 9.10742C12.2617 9.83789 11.8242 10.4824 11.2656 11.041C10.707 11.5996 10.0625 12.0352 9.33203 12.3477C8.60156 12.6641 7.82227 12.8223 6.99414 12.8223Z" />
    </svg>
  );
}
