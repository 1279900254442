import {memo, SVGProps} from 'react';
import {buildProgressArc} from './helpers';

const getGradientColorsByPercent = (percent: number) => {
  if (percent < 40) {
    return {
      id: 'donut_chart_low',
      stop1: '#FF8A00',
      stop2: '#FDD500',
      stop3: '#F6FB00',
    };
  } else if (percent < 80) {
    return {
      id: 'donut_chart_medium',
      stop1: '#F7A300',
      stop2: '#FFDC22',
      stop3: '#F3F800',
    };
  } else {
    return {
      id: 'donut_chart_high',
      stop1: '#A1D900',
      stop2: '#57E600',
      stop3: '#00910F',
    };
  }
};

interface Props extends SVGProps<SVGSVGElement> {
  size: number;
  percent: number;
}

const Donut = memo(({className, percent, size, ...props}: Props) => {
  const halfSize = size / 2;
  const strokeWidth = 10;
  const radius = halfSize - strokeWidth / 2;
  const {id, stop1, stop2, stop3} = getGradientColorsByPercent(percent);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      className={className}
      {...props}>
      <defs>
        <marker id="dot" viewBox="0 0 10 10" refX="5" refY="5">
          <circle
            cx="5"
            cy="5"
            r="2"
            strokeWidth="1"
            className="fill-foreground stroke-background"
          />
        </marker>
        <radialGradient
          id={id}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform={`translate(35 75) scale(50 50)`}>
          <stop offset="0%" stopColor={stop1} />
          <stop offset="55%" stopColor={stop2} />
          <stop offset="98%" stopColor={stop3} />
        </radialGradient>
      </defs>
      <circle
        cx={halfSize}
        cy={halfSize}
        r={radius}
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        fill="transparent"
        className="stroke-default-200"
      />
      <path
        d={buildProgressArc(percent, size, strokeWidth)}
        fill="transparent"
        stroke={`url(#${id})`}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        markerStart='url("#dot")'
      />
    </svg>
  );
});

Donut.displayName = 'Donut';

export default Donut;
