import {ComponentProps, memo} from 'react';
import {Trans} from '@lingui/macro';
import PuzzlePieceIcon from '@/components/icons/PuzzlePieceIcon';
import {getIntegrationIcon, getIntegrationTitle} from '@/common/integrations';
import {twMerge} from 'tailwind-merge';
import {DataSource} from '@/__generated__/graphql';

interface Props {
  dataSources: DataSource[];
  className?: ComponentProps<'div'>['className'];
  style?: ComponentProps<'div'>['style'];
}

const DataNeeded = memo(({dataSources, className, style}: Props) => {
  if (!dataSources.length) {
    return null;
  }

  return (
    <div style={style} className={twMerge(`flex flex-col gap-6`, className)}>
      <div className="flex flex-col items-center gap-[0.125rem]">
        <PuzzlePieceIcon className="h-9 w-9 text-primary" />
        <div className="text-md font-semibold text-foreground">
          <Trans>Data Needed</Trans>
        </div>
        <div className="text-sm text-default-500">
          <Trans>This tool requires the following sign-ups:</Trans>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-3">
        {dataSources.map((source, index) => {
          const image =
            source.imageSmall?.url || getIntegrationIcon(source.type, 'small');

          return (
            <div className="flex items-center gap-2" key={index}>
              {image ? (
                <div className="flex h-7 w-7 shrink-0 items-center justify-center rounded-[0.22rem] border-1 border-foreground/5">
                  <img
                    src={image}
                    alt={source.name}
                    className="max-h-5 max-w-5"
                  />
                </div>
              ) : null}
              <div className="text-xs text-foreground">
                {source.name || getIntegrationTitle(source.type)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

DataNeeded.displayName = 'DataNeeded';

export default DataNeeded;
