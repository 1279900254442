import {Trans} from '@lingui/macro';
import {memo, PropsWithChildren} from 'react';
import {twMerge} from 'tailwind-merge';

export const CardMoreButton = memo(
  ({className, children}: PropsWithChildren<{className?: string}>) => (
    <div
      className={twMerge(
        'flex h-9 w-full items-center justify-center rounded-lg text-sm font-medium text-primary',
        className,
      )}>
      {children ? children : <Trans>See more</Trans>}
    </div>
  ),
);

CardMoreButton.displayName = 'CardMoreButton';
