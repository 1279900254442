import {memo, useEffect, useMemo} from 'react';
import {useLingui} from '@lingui/react';
import {msg, Trans} from '@lingui/macro';
import {Button, Link} from '@nextui-org/react';
import {useAuthContext, useCheckoutSession} from '@/hooks';
import {getDayjsDate} from '@/utils';
import RouteContainer from '../components/RouteContainer';
import {useApolloClient, useMutation, useQuery} from '@apollo/client';
import PortalSpinner from '@/components/PortalSpinner';
import {
  BillingInterval,
  GetProductInfoQuery,
  GetUserQuery,
} from '@/__generated__/graphql';
import {I18n} from '@lingui/core';
import {gql} from '@/__generated__';
import FileSearchIcon from '@/components/icons/subscription/FileSearchIcon';
import BarChartIcon from '@/components/icons/subscription/BarChartIcon';
import CoinSwapIcon from '@/components/icons/subscription/CoinSwapIcon';

const SUBSCRIPTION_LOGO =
  'https://us-west-2.graphassets.com/clyvmv3no0g9g07n97jcx0woe/clztlbg0h0yhg06n3fne7ni3g';
const PRODUCT_BACKGROUND =
  'https://us-west-2.graphassets.com/clyvmv3no0g9g07n97jcx0woe/clzkc7j0faxa907nar3kmwgi3';

const getShortPeriod = (period: BillingInterval) => {
  switch (period) {
    case BillingInterval.Day:
      return 'day';
    case BillingInterval.Week:
      return 'wk';
    case BillingInterval.Month:
      return 'mo';
    case BillingInterval.Year:
      return 'yr';
    default:
      return '';
  }
};

const getPrice = (
  sub:
    | GetUserQuery['user']['stripeSubscription']
    | GetProductInfoQuery['getProductInfo'],
  i18n: I18n,
  short = false,
) => {
  if (!sub || !sub.amount) {
    return '';
  }

  return `${i18n.number(sub.amount, {
    style: 'currency',
    currency: sub.currency ?? 'usd',
    maximumFractionDigits: 0,
  })}${sub.interval ? ` /${sub.intervalCount && sub.intervalCount > 1 ? ' ' + sub.intervalCount : ''} ${short ? getShortPeriod(sub.interval) : sub.interval}` : ''}`;
};

const CREATE_PORTAL_SESSION = gql(`
  mutation CreatepPortalSession {
    session: createStripePortalSession {
      id
      url
    }
  }
`);
const GET_PRODUCT_INFO = gql(`
  query GetProductInfo {
    getProductInfo {
      id
      name
      image
      currency
      amount
      interval
      intervalCount
      trialPeriodDays
    }
  }
`);

const ProfileSubscription = memo(() => {
  const {user} = useAuthContext();
  const {_, i18n} = useLingui();
  const apolloClient = useApolloClient();
  const {createCheckoutSession, loading: checkoutSessionLoading} =
    useCheckoutSession(() => ({
      successPath: 'stripe-success' + window.location.search,
      cancelPath: window.location.pathname + window.location.search,
    }));
  const [createPortalSession, createPortalSessionResult] = useMutation(
    CREATE_PORTAL_SESSION,
    {
      onCompleted: data => {
        window.location.href = data.session.url;

        // This doesnt work in mobile browsers
        // window.open(data.session.url, '_blank');
      },
    },
  );
  const productInfoQuery = useQuery(GET_PRODUCT_INFO);
  const product = productInfoQuery.data?.getProductInfo;
  const productPrice = getPrice(product, i18n);

  useEffect(() => {
    apolloClient.refetchQueries({
      include: ['GetUser'],
    });
  }, []);

  const sub = user?.stripeSubscription;
  const subPrice = getPrice(sub, i18n);
  const renewDate = useMemo(() => {
    const date = getDayjsDate(sub?.nextPaymentAt);

    if (!date) {
      return '';
    }

    return date.format('ll');
  }, [sub?.nextPaymentAt]);

  if (!user) {
    return (
      <RouteContainer title={_(msg`Subscription`)} backTo="../account">
        <div className="flex w-full grow flex-col justify-between px-6">
          <PortalSpinner size="lg" />
        </div>
      </RouteContainer>
    );
  }

  return (
    <RouteContainer title={_(msg`Subscription`)} backTo="../account">
      {user?.stripeSubscription ? (
        <div className="flex w-full grow flex-col gap-6 px-6">
          <h2 className="text-2xl font-bold">
            <Trans>Active Plan</Trans>
          </h2>
          <div
            style={{
              boxShadow:
                '0px 0px 20px 0px rgba(0, 0, 0, 0.10), 0px 0px 120px 0px rgba(0, 0, 0, 0.05)',
            }}
            className="offset flex gap-4 rounded-2xl p-6">
            <img
              src={SUBSCRIPTION_LOGO}
              className="flex h-14 w-14 shrink-0 object-contain"
            />
            {/* {user.stripeSubscription.image ? (
                <img
                  src={user.stripeSubscription.image}
                  className="flex h-14 w-14 shrink-0 object-contain"
                />
              ) : null} */}
            <div className="flex grow flex-col gap-6">
              <div className="flex w-full flex-col">
                <div className="font-medium">{sub?.name ?? 'Portal AI'}</div>
                {subPrice ? (
                  <div className="text-foreground/50">{subPrice}</div>
                ) : null}
              </div>
              {renewDate ? (
                <div className="text-foreground/50">
                  <Trans>
                    Your subscription will automatically renew on{' '}
                    <div className="inline-flex text-foreground">
                      {renewDate}
                    </div>
                  </Trans>
                </div>
              ) : null}
            </div>
          </div>
          <div className="w-full text-foreground/50">
            <Trans>
              Manage your billing information and subscription{' '}
              <Button
                aria-label="Manage subscription"
                disableAnimation
                disableRipple
                isDisabled={createPortalSessionResult.loading}
                className="h-auto min-h-0 w-auto min-w-0 bg-transparent p-0 text-primary"
                onPress={() => createPortalSession()}>
                via Stripe
              </Button>
            </Trans>
          </div>
        </div>
      ) : product ? (
        <div
          style={{
            backgroundImage: `url(${PRODUCT_BACKGROUND})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
          className="flex w-full grow flex-col gap-3 px-6">
          <div className="flex w-full flex-col gap-6">
            <div className="flex w-full flex-col gap-2">
              <div className="flex w-full items-center justify-center pb-6 pt-8">
                <img
                  src={SUBSCRIPTION_LOGO}
                  className="flex h-[7.125rem] shrink-0 object-contain"
                />
              </div>
              <h2 className="text-center text-2xl font-bold">
                <Trans>
                  Leverage the full power of your data with Portal AI
                </Trans>
              </h2>
            </div>
            <div className="flex w-full flex-col gap-5">
              <div className="flex items-center gap-3 text-medium font-medium">
                <CoinSwapIcon className="h-10 w-10" />
                <Trans>Turn ads into repeat sales</Trans>
              </div>
              <div className="flex items-center gap-3 text-medium font-medium">
                <BarChartIcon className="h-10 w-10" />
                <Trans>Optimize ad spend across channels</Trans>
              </div>
              <div className="flex items-center gap-3 text-medium font-medium">
                <FileSearchIcon className="h-10 w-10" />
                <Trans>Easily audit your marketing programs</Trans>
              </div>
            </div>
            <div className="flex w-full flex-col gap-3">
              <div className="text-center text-xs text-default-400">
                <Trans>
                  By clicking Subscribe, you agree to the{' '}
                  <Link
                    aria-label="Terms of Service"
                    className="text-xs text-default-400 underline"
                    href="/legal/terms">
                    <Trans>Terms of Service</Trans>
                  </Link>
                  . Learn more about our data practices in our{' '}
                  <Link
                    aria-label="Privacy Policy"
                    className="text-xs text-default-400 underline"
                    href="/legal/privacy">
                    <Trans>Privacy Policy</Trans>
                  </Link>
                  .
                </Trans>
              </div>
            </div>
          </div>
          <div className="sticky bottom-0 flex w-full flex-col rounded-[1.5rem] pb-6">
            <div className="flex w-full flex-col rounded-[1.5rem] bg-[#F7F7FA]">
              <div className="flex justify-between gap-2 px-6 py-4">
                <div className="flex grow flex-col gap-1">
                  <div className="text-medium font-semibold">
                    {product?.name}
                  </div>
                  {product.interval ? (
                    <div className="text-default-500">
                      {`Billed ${product.interval}ly`}
                    </div>
                  ) : null}
                </div>
                {productPrice ? (
                  <div className="flex shrink-0 text-medium font-semibold">
                    {productPrice}
                  </div>
                ) : null}
              </div>
              <div className="flex w-full flex-col gap-2">
                <Button
                  aria-label="Subscribe"
                  fullWidth
                  variant="solid"
                  radius="sm"
                  style={{
                    background:
                      'linear-gradient(90deg, #943FFF 0%, #6C3EF0 50%, #474EFF 100%)',
                  }}
                  className="min-h-none flex h-auto flex-col gap-0 rounded-xl px-4 py-2"
                  isDisabled={checkoutSessionLoading}
                  onPress={() => createCheckoutSession()}>
                  <>
                    <div className="text-lg font-semibold text-background">
                      <Trans>{`Subscribe`}</Trans>
                    </div>
                    <div className="font-medium text-background/50">
                      <Trans>Cancel anytime</Trans>
                    </div>
                  </>
                </Button>
                {/* <Button
                  aria-label="Subscribe With Free Trial"
                  fullWidth
                  radius="sm"
                  className="min-h-none flex h-auto flex-col gap-0 rounded-xl bg-background px-4 py-2 text-medium font-semibold text-[#893FFB]"
                  isDisabled={checkoutSessionLoading}
                  onPress={() => createCheckoutSession()}>
                  <Trans>Get a Free Month</Trans>
                </Button> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-full w-full grow flex-col justify-between px-6">
          <PortalSpinner size="md" />
        </div>
      )}
    </RouteContainer>
  );
});

ProfileSubscription.displayName = 'ProfileSubscription';

export default ProfileSubscription;
