import {createContext} from 'react';

export type Theme = 'dark' | 'light' | 'system';

type ThemeProviderState = {
  mode: 'dark' | 'light';
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const initialState: ThemeProviderState = {
  mode: 'light',
  theme: 'system',
  setTheme: () => null,
};

export const ThemeProviderContext =
  createContext<ThemeProviderState>(initialState);
