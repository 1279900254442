import {memo, ReactNode} from 'react';
import {useAuthContext} from '@/hooks';
import {useQuery} from '@apollo/client';
import {IntegrationType} from '@/common/types';
import ConnectGoogleAds from '@/components/Integrations/ConnectGoogleAds';
import {useLocation} from 'react-router-dom';
import {GET_INTEGRATIONS} from '@/common/queries';
import {ContinueButton} from './components/ContinueButton';

const ConnectGoogleAdsForm = memo(
  ({
    onSubmit,
    submitTitle,
  }: {
    onSubmit?: () => Promise<void> | void;
    submitTitle?: ReactNode;
  }) => {
    const location = useLocation();
    const {business} = useAuthContext();
    const {data} = useQuery(GET_INTEGRATIONS, {
      variables: {
        filter: {
          businessId: business?.id,
          type: IntegrationType.googleAds,
        },
        order: {type: 'ASC'},
        first: 1,
      },
      skip: !business?.id,
      fetchPolicy: 'cache-and-network',
    });
    const isConnected = Boolean(data?.connection.edges?.length);

    return (
      <div className="flex w-full flex-col gap-6">
        <ConnectGoogleAds
          isActive={isConnected}
          callbackPath={location.pathname.slice(1) + location.search}
        />
        <ContinueButton
          isDisabled={!isConnected}
          onPress={onSubmit}
          title={submitTitle}
        />
      </div>
    );
  },
);

ConnectGoogleAdsForm.displayName = 'ConnectGoogleAdsForm';

export default ConnectGoogleAdsForm;
