import {ComponentProps, memo} from 'react';
import DataNeeded from './DataNeeded';
import {RiskIndexGroup} from '@/common/types';
import RiskIndex from './RiskIndex';
import {twMerge} from 'tailwind-merge';
import {DataSource} from '@/__generated__/graphql';

interface Props {
  riskIndexGroup?: RiskIndexGroup;
  dataSources: DataSource[];
  className?: ComponentProps<'div'>['className'];
}

const AppExtraSections = memo(
  ({riskIndexGroup, dataSources, className}: Props) => {
    const itemClass = 'p-6 rounded-xl bg-background';
    const itemStyle = {
      boxShadow:
        '0px 0px 20px -3px rgba(0, 0, 0, 0.15), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
    };

    return (
      <div
        className={twMerge(
          `grid w-full grid-cols-1 gap-[0.875rem] md:grid-cols-2 lg:grid-cols-3`,
          className,
        )}>
        <RiskIndex
          riskIndexGroup={riskIndexGroup}
          style={itemStyle}
          className={itemClass}
        />
        <DataNeeded
          dataSources={dataSources}
          style={itemStyle}
          className={itemClass}
        />
      </div>
    );
  },
);

AppExtraSections.displayName = 'AppExtraSections';

export default AppExtraSections;
