import {Button} from '@nextui-org/react';
import {memo, useRef, useState} from 'react';
import CircleEmptyIcon from './icons/rating/CircleEmptyIcon';
import CircleHalfIcon from './icons/rating/CircleHalfIcon';
import CircleFilledIcon from './icons/rating/CircleFilledIcon';
import StarEmptyIcon from './icons/rating/StarEmptyIcon';
import StarHalfIcon from './icons/rating/StarHalfIcon';
import StarFilledIcon from './icons/rating/StarFilledIcon';
import StarBigEmptyIcon from './icons/rating/StarBigEmptyIcon';
import StarBigHalfIcon from './icons/rating/StarBigHalfIcon';
import StarBigFilledIcon from './icons/rating/StarBigFilledIcon';
import {twMerge} from 'tailwind-merge';

interface Props {
  icon: 'star' | 'star-big' | 'circle';
  rating?: number;
  readonly?: boolean;
  itemClass?: string;
  className?: string;
  setRating?: (rating: number) => void;
}

const getCircleIcon = (current: number, item: number) => {
  const diff = item - current;
  const value = Math.round(diff * 2);

  if (value <= 0) {
    return <CircleFilledIcon />;
  } else if (value === 1) {
    return <CircleHalfIcon />;
  }
  return <CircleEmptyIcon />;
};

const getStarIcon = (current: number, item: number) => {
  const diff = item - current;
  const value = Math.round(diff * 2);

  if (value <= 0) {
    return <StarFilledIcon />;
  } else if (value === 1) {
    return <StarHalfIcon />;
  }
  return <StarEmptyIcon />;
};

const getStarBigIcon = (current: number, item: number) => {
  const diff = item - current;
  const value = Math.round(diff * 2);

  if (value <= 0) {
    return <StarBigFilledIcon />;
  } else if (value === 1) {
    return <StarBigHalfIcon />;
  }
  return <StarBigEmptyIcon />;
};

const getIcon = (icon: Props['icon'], current: number, item: number) => {
  switch (icon) {
    case 'star':
      return getStarIcon(current, item);
    case 'star-big':
      return getStarBigIcon(current, item);
    default:
      return getCircleIcon(current, item);
  }
};

const getItemClass = (
  icon: Props['icon'],
  readonly: boolean,
  itemClass?: string,
) => {
  const sizeClass = icon === 'star-big' ? 'w-7 h-7' : 'w-4 h-4';

  return twMerge(
    `${sizeClass} ${readonly ? '[&>svg]:fill-default-500' : '[&>svg]:fill-primary'}`,
    itemClass,
  );
};

const RatingBar = memo(
  ({
    icon,
    rating: ratingProps = 0,
    itemClass,
    readonly = false,
    className,
    setRating,
  }: Props) => {
    const resetHoverTimerRef = useRef(0);
    const [hoveredRating, setHoveredRating] = useState<number | null>(null);
    const rating = hoveredRating ?? ratingProps;

    return (
      <div className={twMerge(`flex`, className)}>
        {[1, 2, 3, 4, 5].map(rtn => {
          return (
            <Button
              aria-label={`Rating ${rtn}`}
              key={rtn}
              className={twMerge(
                `flex min-h-0 w-auto min-w-0 flex-[0_0_auto] items-center justify-center bg-transparent !opacity-100`,
                getItemClass(icon, readonly, itemClass),
              )}
              isIconOnly
              disableRipple
              isDisabled={readonly}
              onMouseEnter={() => {
                clearTimeout(resetHoverTimerRef.current);
                setHoveredRating(rtn);
              }}
              onMouseLeave={() => {
                resetHoverTimerRef.current = setTimeout(() => {
                  setHoveredRating(null);
                }, 100);
              }}
              onPress={() => {
                setRating?.(rtn);
              }}>
              {getIcon(icon, rating, rtn)}
            </Button>
          );
        })}
      </div>
    );
  },
);

RatingBar.displayName = 'RatingBar';

export default RatingBar;
