import {memo, useCallback, useEffect, useState} from 'react';
import {msg} from '@lingui/macro';
import {gql} from '@/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import AccountSelectorView, {
  AccountSelectorEntry,
} from './components/AccountSelectorView';
import {useDisclosure} from '@nextui-org/react';
import {GoogleAdsIntegrationEdge} from '@/__generated__/graphql';
import {useLingui} from '@lingui/react';
import {useAppContext} from '@/hooks';

const GET_GOOGLE_ADS_PENDING_INTEGRAIONS = gql(`
  query GetGoogleAdsPendingIntegrationConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {
    connection: getGoogleAdsPendingIntegrationConnection(filter: $filter, order: $order, first: $first, after: $after) {
      edges {
        node {
          id
          googleId
          parentGoogleId
          customerName
          isManager
          status
          error {
            summary
            detail
          }
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
`);

const SET_PERMITTED_ACCOUNTS = gql(`
  mutation SetPermittedGoogleAdsIntegrations($permittedIds: [UUID!]!) {
    selectConnectedGoogleAdsIntegrations(permittedIds: $permittedIds) {
      id
    }
  }
`);

const formatGoogleID = (googleId: string) => {
  if (googleId && googleId.length === 10) {
    return googleId.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  return googleId;
};

const GoogleAdsAccountSelector = memo(() => {
  const {_} = useLingui();
  const {isAuthenticated} = useAppContext();
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [accounts, setAccounts] = useState<AccountSelectorEntry[]>([]);
  const {data} = useQuery(GET_GOOGLE_ADS_PENDING_INTEGRAIONS, {
    variables: {
      first: 500,
    },
    skip: !isAuthenticated,
  });
  const [setPermittedAccounts, {loading}] = useMutation(
    SET_PERMITTED_ACCOUNTS,
    {refetchQueries: ['GetIntegrationConnection']},
  );

  const getErrorStatus = useCallback(
    (status: GoogleAdsIntegrationEdge['node']['status']) => {
      const GAStatus = status?.toUpperCase();

      switch (GAStatus) {
        case 'CLOSED':
          return _(msg`Account closed`);
        case 'SUSPENDED':
          return _(msg`Account suspended`);
        case 'CANCELLED':
          return _(msg`Account cancelled`);
      }

      return;
    },
    [_],
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    const hasPendingPermit = data.connection.edges.length;
    const items: AccountSelectorEntry[] = data.connection.edges.map(
      ({node}) => {
        return {
          id: node.id,
          title: node?.customerName ?? '',
          subtitle: formatGoogleID(node?.googleId ?? ''),
          errorStatus: node?.error?.summary || getErrorStatus(node?.status),
          errorDetail: node?.error?.detail ?? '',
          isSelected: !node.error,
          isDisabled: Boolean(node.error),
        };
      },
    );
    const allItemsAreDisabled =
      items.length > 0 && items.every(item => item.isDisabled);

    setAccounts(items);

    if (hasPendingPermit) {
      onOpen();
    }

    if (allItemsAreDisabled) {
      setPermittedAccounts({
        variables: {
          permittedIds: [],
        },
      });
    }
  }, [data?.connection.edges]);

  const toggleItem = useCallback(
    (id: string) => {
      setAccounts(prev =>
        prev.map(item =>
          item.id === id ? {...item, isSelected: !item.isSelected} : item,
        ),
      );
    },
    [setAccounts],
  );

  const submit = useCallback(() => {
    setPermittedAccounts({
      variables: {
        permittedIds: accounts
          .filter(item => !item.isDisabled && item.isSelected)
          .map(item => item.id),
      },
    }).then(() => {
      onOpenChange();
    });
  }, [accounts, setPermittedAccounts]);

  return (
    <AccountSelectorView
      items={accounts}
      isLoading={loading}
      submit={submit}
      {...{isOpen, onOpenChange, toggleItem}}
    />
  );
});

GoogleAdsAccountSelector.displayName = 'GoogleAdsAccountSelector';

export default GoogleAdsAccountSelector;
