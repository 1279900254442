import {GET_CUSTOMER_DATA_CONNECTION} from '@/common/queries';
import {useAuthContext} from '@/hooks';
import {useQuery, WatchQueryFetchPolicy} from '@apollo/client';
import {useMemo} from 'react';

interface QueryOptions {
  fetchPolicy?: WatchQueryFetchPolicy;
}

export const useGetBusinessUrl = ({
  fetchPolicy = 'cache-and-network',
}: QueryOptions = {}) => {
  const {user} = useAuthContext();
  const {data, loading} = useQuery(GET_CUSTOMER_DATA_CONNECTION, {
    variables: {
      filter: {
        key: 'website_url',
        userId: user?.id,
      },
      first: 1,
    },
    skip: !user,
    fetchPolicy,
  });

  return useMemo(
    () => ({
      isLoading: loading,
      businessUrl: data?.connection.edges[0]?.node.value,
    }),
    [data?.connection.edges, loading],
  );
};

export const useGetCompetitorUrls = ({
  fetchPolicy = 'cache-and-network',
}: QueryOptions = {}) => {
  const {user} = useAuthContext();
  const {data, loading} = useQuery(GET_CUSTOMER_DATA_CONNECTION, {
    variables: {
      filter: {
        key: {$ilike: 'competitor_url_%'},
        userId: user?.id,
      },
    },
    skip: !user,
    fetchPolicy,
  });

  return useMemo(
    () => ({
      isLoading: loading,
      competitorUrlNodes: data?.connection.edges.map(e => e.node) ?? [],
    }),
    [data?.connection.edges, loading],
  );
};

export const useGetBusinessMarginPercentage = ({
  fetchPolicy = 'cache-and-network',
}: QueryOptions = {}) => {
  const {user} = useAuthContext();
  const {data, loading} = useQuery(GET_CUSTOMER_DATA_CONNECTION, {
    variables: {
      filter: {
        key: 'business_margin_percentage',
        userId: user?.id,
      },
      first: 1,
    },
    skip: !user,
    fetchPolicy,
  });

  return useMemo(
    () => ({
      isLoading: loading,
      marginPercentage: data?.connection.edges[0]?.node.value,
    }),
    [data?.connection.edges, loading],
  );
};
