import {ComponentProps, memo, useEffect} from 'react';
import {Button, ButtonProps} from '@nextui-org/react';
import {animate, motion, useMotionValue} from 'framer-motion';
import CarouselArrowIcon, {
  Direction,
} from '@/components/icons/CarouselArrowIcon';
import {twMerge} from 'tailwind-merge';

interface Props extends ButtonProps {
  direction: Direction;
  isDisabled: boolean;
  className?: ComponentProps<'div'>['className'];
  onPress: () => void;
}

export const CarouselButton = memo(
  ({direction, isDisabled, className = '', onPress, ...buttonProps}: Props) => {
    const opacityValue = useMotionValue(isDisabled ? 0 : 1);

    useEffect(() => {
      animate(opacityValue, isDisabled ? 0 : 1, {
        duration: 0.1,
        type: 'tween',
      });
    }, [isDisabled]);

    return (
      <Button
        aria-label={`Carousel ${direction}`}
        as={motion.button}
        isIconOnly
        disableRipple
        isDisabled={isDisabled}
        onPress={onPress}
        // @ts-expect-error typing issue
        style={{opacity: opacityValue}}
        className={twMerge(
          `relative z-[100] h-auto min-h-0 w-auto min-w-0 rounded-full bg-foreground/75 p-2 data-[hover=true]:bg-foreground/75 ${isDisabled ? 'pointer-events-none' : 'pointer-events-auto'}`,
          className,
        )}
        {...buttonProps}>
        <CarouselArrowIcon
          direction={direction}
          className="h-6 w-6 stroke-background"
        />
      </Button>
    );
  },
);

CarouselButton.displayName = 'CarouselButton';

export default CarouselButton;
