import {memo, useMemo} from 'react';
import Markdown from 'react-markdown';
import {
  MinibrowserTestSection as MinibrowserTestSectionType,
  MinibrowserTestState as MinibrowserTestStateType,
  MinibrowserTestStateName,
} from './types';
import {twMerge} from 'tailwind-merge';
import HelpIcon from '@/components/icons/minibrowser/HelpIcon';
import CalendarCheckIcon from '@/components/icons/minibrowser/CalendarCheckIcon';
import AlertIcon from '@/components/icons/minibrowser/AlertIcon';
import FacebookImage from '@/assets/socials/minibrowser/facebook.png';
import InstagramImage from '@/assets/socials/minibrowser/instagram.png';
import MetaImage from '@/assets/integrations/iconsMedium/facebook-ads.png';
import {Button} from '@nextui-org/react';
import {useMutation, useQuery} from '@apollo/client';
import {GET_CUSTOMER_DATA_CONNECTION} from '@/common/queries';
import {UPSERT_CUSTOMER_DATA} from '@/common/mutations';
import {useAuthContext} from '@/hooks';
import PortalSpinner from '@/components/PortalSpinner';
import {motion} from 'framer-motion';

type MinibrowserTestSectionProps = {
  className?: string;
} & MinibrowserTestSectionType;

type MinibrowserTestStateProps = {
  customer_data_field: string;
  headerClassName?: string;
  markdownClassName?: string;
  iconPlacement?: 'leading' | 'trailing';
  className?: string;
} & MinibrowserTestStateType;

const KNOWN_ACTIONS = ['state_change'];

const getIcon = (icon: MinibrowserTestStateType['icon']) => {
  switch (icon) {
    case 'meta':
      return <img src={MetaImage} className="aspect-square w-8" alt="Meta" />;
    case 'help':
      return <HelpIcon />;
    case 'warning':
      return <AlertIcon />;
    case 'calendar-check':
      return <CalendarCheckIcon />;
    case 'instagram':
      return (
        <img
          src={InstagramImage}
          className="aspect-square w-8"
          alt="Instagram"
        />
      );
    case 'facebook':
      return (
        <img src={FacebookImage} className="aspect-square w-8" alt="Facebook" />
      );
  }
};

const MinibrowserTestState = memo(
  ({
    icon,
    title,
    customer_data_field,
    description,
    actions,
    headerClassName,
    markdownClassName,
    iconPlacement = 'leading',
    className,
  }: MinibrowserTestStateProps) => {
    const {user} = useAuthContext();
    const [upsertCustomerData, {loading}] = useMutation(UPSERT_CUSTOMER_DATA, {
      refetchQueries: ['GetCustomerDataConnection'],
    });

    return (
      <div className={twMerge('flex flex-col gap-2 p-6', className)}>
        <div
          className={twMerge(
            `text-bg flex flex-col gap-4 text-medium font-semibold`,
            headerClassName,
          )}>
          {iconPlacement === 'leading' ? getIcon(icon) : null}
          <div className="flex grow">{title}</div>
          {iconPlacement === 'trailing' ? getIcon(icon) : null}
        </div>
        <div className="flex w-full flex-col gap-6">
          <Markdown
            className={twMerge(
              'prose prose-sm w-full text-sm text-default-700',
              markdownClassName,
            )}>
            {description}
          </Markdown>
          {actions?.length ? (
            <div className="flex w-full gap-2">
              {actions
                .filter(a => KNOWN_ACTIONS.includes(a.action))
                .map((action, index) => (
                  <Button
                    aria-label="Update minibrowser test state"
                    key={index}
                    disableRipple
                    disableAnimation
                    isDisabled={!user}
                    isLoading={loading}
                    style={{
                      flexBasis: `${100 / actions.length}%`,
                    }}
                    className={`min-h-auto min-w-auto flex h-auto max-h-none w-auto shrink-0 grow items-center rounded-lg px-4 py-2 font-semibold ${action.type === 'primary' ? 'bg-primary text-background' : 'bg-[#D9ECFF] text-primary'}`}
                    onClick={() => {
                      if (action.action === 'state_change') {
                        upsertCustomerData({
                          variables: {
                            input: {
                              key: customer_data_field,
                              userId: user?.id,
                              value: action.to,
                            },
                          },
                        });
                      }
                    }}>
                    {action.label}
                  </Button>
                ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

MinibrowserTestState.displayName = 'MinibrowserTestState';

const MinibrowserTestSection = memo(
  ({
    guide,
    customer_data_field,
    states,
    className,
  }: MinibrowserTestSectionProps) => {
    const customerData = useQuery(GET_CUSTOMER_DATA_CONNECTION, {
      variables: {
        first: 1,
        filter: {
          key: customer_data_field,
        },
      },
    });
    const stateName: MinibrowserTestStateName = useMemo(() => {
      const node = customerData.data?.connection.edges[0]?.node;

      if (!node) {
        return 'initial';
      }

      return node.value as MinibrowserTestStateName;
    }, [customerData.data]);
    const state = states[stateName];
    const isGuideShown = stateName === 'initial';

    if (!customerData.data && customerData.loading) {
      return (
        <div
          className={twMerge(
            'flex min-h-[4rem] w-full items-center justify-center rounded-2xl',
            className,
          )}>
          <PortalSpinner size="md" />
        </div>
      );
    }

    if (!state && !isGuideShown) {
      return null;
    }

    return (
      <motion.div
        key={stateName}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        className={twMerge('flex flex-col rounded-2xl', className)}>
        {isGuideShown ? (
          <MinibrowserTestState
            {...guide}
            customer_data_field={customer_data_field}
            iconPlacement="trailing"
            className="gap-6"
            headerClassName="flex-row gap-3 text-[1.125rem]/[1.75rem] items-center"
          />
        ) : null}
        {isGuideShown && state ? (
          <div className="h-[1px] w-full bg-divider" />
        ) : null}
        {state ? (
          <MinibrowserTestState
            {...state}
            customer_data_field={customer_data_field}
            className="items-center"
            headerClassName="items-center text-center"
            markdownClassName={'text-center'}
          />
        ) : null}
      </motion.div>
    );
  },
);

MinibrowserTestSection.displayName = 'MinibrowserTestSection';

export default MinibrowserTestSection;
