import {ToolUnlockStepId} from '@/common/types';
import BusinessUrlForm from './forms/BusinessUrlForm';
import CompetitorUrlsForm from './forms/CompetitorUrlsForm';
import ConnectShopifyForm from './forms/ConnectShopifyForm';
import ConnectGoogleAdsForm from './forms/ConnectGoogleAdsForm';
import BusinessMarginForm from './forms/BusinessMarginForm';

export const formByStepId = {
  ['website_url' as ToolUnlockStepId]: BusinessUrlForm,
  ['competitor_urls' as ToolUnlockStepId]: CompetitorUrlsForm,
  ['connect_shopify' as ToolUnlockStepId]: ConnectShopifyForm,
  ['connect_google_ads' as ToolUnlockStepId]: ConnectGoogleAdsForm,
  ['business_margin_percentage' as ToolUnlockStepId]: BusinessMarginForm,
};
