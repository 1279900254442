import snakeCase from 'lodash/snakeCase';
import merge from 'lodash/merge';
import {utmPropertiesVar, UTM_PROPERTY_NAMES} from '@/utmProperties';

export interface InstrumentEventMetadata {
  user_id?: string;
  user_properties?: Record<string, any>;
  event_properties?: Record<string, any>;
  event_id?: number;
  session_id?: number;
  device_id?: string;
  platform?: string;
  os_name?: string;
  os_version?: string;
  device_brand?: string;
  device_manufacturer?: string;
  device_model?: string;
  carrier?: string;
  country?: string;
  region?: string;
  city?: string;
  app_version?: string;
}

export enum InstrumentEventName {
  SIGNIN_SUCCESS = 'fe_sign_in_success',
  SIGNUP_SUCCESS = 'fe_sign_up_success',
  LOGOUT = 'fe_logout_start',
  AUTH_STARTED_EMAIL = 'fe_email_auth_start',
  AUTH_STARTED_GOOGLE = 'fe_google_auth_start',
  AUTH_STARTED_APPLE = 'fe_apple_auth_start',
  SUBSCRIBE_STARTED = 'fe_stripe_subscribe_start',
  ACTION_APPLY_STARTED = 'fe_action_apply_start',
  ACTION_APPLIED = 'fe_action_applied',
  INTEGRATION_START = 'fe_integration_start',
  INTEGRATION_PROVIDED = 'fe_integration_provided',
  CONNECT_INTEGRATION_SUCCESS = 'fe_integration_connect_success',
}

export abstract class InstrumentTransport {
  abstract track(
    eventName: string,
    eventProperties?: Record<string, any>,
    eventMetadata?: InstrumentEventMetadata,
  ): Promise<void>;

  abstract getMetadata(): Promise<InstrumentEventMetadata | undefined>;
  abstract flushQueue(): Promise<void>;
  abstract setUserId(userId?: string): void;
}

export class InstrumentTracker {
  constructor(private readonly transports: InstrumentTransport[]) {}

  async track(
    eventName: string,
    eventProperties?: Record<string, any>,
    eventMetadata?: InstrumentEventMetadata,
  ): Promise<void> {
    const defaultProperties: Record<string, any> = {
      ...this._getPageMetadata(),
    };
    eventProperties = eventProperties || {};

    const results = await Promise.allSettled(
      this.transports.map(transport => {
        return transport.track(
          eventName,
          {...defaultProperties, ...eventProperties},
          eventMetadata,
        );
      }),
    );
    const errors = results.filter(result => result.status === 'rejected');

    if (errors.length > 0) {
      throw new Error(
        `Failed to track event ${eventName} with properties ${JSON.stringify(
          eventProperties,
        )} and metadata ${JSON.stringify(eventMetadata)}: ${errors
          .map((error: any) => error.reason?.message || error.reason)
          .join(', ')}`,
      );
    }
  }

  async flushQueue(): Promise<void> {
    await Promise.allSettled(
      this.transports.map(transport => transport.flushQueue()),
    );
  }

  async getMetadata(): Promise<Record<string, InstrumentEventMetadata>> {
    const metadata = await Promise.all(
      this.transports.map(transport => transport.getMetadata()),
    );
    const extraMetadata: Partial<InstrumentEventMetadata> = {
      event_properties: this._getPageMetadata(),
    };

    return merge({}, ...metadata, extraMetadata);
  }

  setUserId(userId?: string): void {
    this.transports.forEach(transport => transport.setUserId(userId));

    if (!userId) {
      utmPropertiesVar({});
    }
  }

  async buildHeaders(): Promise<Record<string, string>> {
    const metadata = await this.getMetadata();

    return {'x-session-metadata': btoa(JSON.stringify(metadata))};
  }

  _getPageMetadata(): Record<string, any> {
    const pageSearchParams: Record<string, any> = {};
    const locationUtmParams: Record<string, any> = {};

    for (const [key, value] of new URLSearchParams(window.location.search)) {
      if (UTM_PROPERTY_NAMES.has(key)) {
        locationUtmParams[key] = value;
      } else {
        pageSearchParams['page_search_' + snakeCase(key)] = value;
      }
    }

    return {
      page_domain: window.location.hostname,
      page_location: window.location.href,
      page_title: document.title,
      page_path: window.location.pathname,
      page_url: window.location.origin + window.location.pathname,
      ...utmPropertiesVar(),
      ...pageSearchParams,
      ...locationUtmParams,
    };
  }
}
