import {ComponentProps, memo} from 'react';
import {Avatar} from '@nextui-org/react';
import {ChatMessageHeaderItem} from '@/common/types/chat';

interface Props extends ChatMessageHeaderItem {
  className?: ComponentProps<'div'>['className'];
}

const MessageHeader = memo(({type, name, avatarUrl, className = ''}: Props) => {
  const initials = (() => {
    const split = name
      .trim()
      .split(' ')
      .filter(v => v);

    if (!split.length) {
      return '';
    }

    return split.length > 2
      ? split[0][0]
      : split.map(chunk => chunk[0]).join('');
  })();
  const classByAuthor = 'justify-start';

  return (
    <div
      className={`mb-2 flex flex-row items-center gap-2 ${className} ${classByAuthor}`}>
      <Avatar
        className={`h-6 w-6 min-w-6 shrink-0 rounded-full [&>*]:text-xs [&>*]:font-semibold [&>*]:text-foreground ${type === 'user' ? 'bg-cyan-300' : 'bg-background'}`}
        isBordered={false}
        src={avatarUrl}
        name={type === 'user' ? initials : 'P'}
        // avoid Forbidden error for Google avatars
        imgProps={{referrerPolicy: 'no-referrer'}}
      />
      <div className="text-sm font-semibold text-[#464646]">{name}</div>
    </div>
  );
});

MessageHeader.displayName = 'MessageHeader';

export default MessageHeader;
