import {FC, memo, useCallback, useRef} from 'react';
import {Trans} from '@lingui/macro';
import {useMutation} from '@apollo/client';
import {REQUEST_RESET_PASSWORD} from '@/common/mutations';
import ResetPasswordEmailForm from '@/routes/components/ResetPasswordEmailForm';
import {useAuthContext} from '@/hooks';
import {useLocation, useNavigate} from 'react-router-dom';

const ResetPasswordEmail: FC = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const {user, resetPasswordEmail, setResetPasswordEmail} = useAuthContext();
  const initialResetPasswordEmailRef = useRef(
    user?.email || resetPasswordEmail,
  );
  const [resetPassword] = useMutation(REQUEST_RESET_PASSWORD, {
    onCompleted: () => {
      navigate('../requested');
    },
  });
  const onSubmit = useCallback(
    async ({email}: {email: string}) => {
      setResetPasswordEmail(email);

      const result = await resetPassword({
        variables: {
          input: {
            email,
            qs: location.search,
            basePath: location.pathname.replace('/reset-password/request', ''),
          },
        },
      });

      if (result.errors) {
        throw result.errors;
      }
    },
    [location.search, location.pathname, resetPassword, setResetPasswordEmail],
  );

  const onBack = useCallback(async () => {
    setResetPasswordEmail('');
    navigate(-1);
  }, [navigate, setResetPasswordEmail]);

  return (
    <div className="flex w-full flex-col gap-12">
      <div className="flex flex-col items-center gap-3">
        <div className="text-center text-foreground">
          <Trans>
            Enter the email address you use for Portal AI and we’ll send you a
            reset link.
          </Trans>
        </div>
      </div>
      <div className="flex w-full flex-col">
        <ResetPasswordEmailForm
          initialEmail={initialResetPasswordEmailRef.current}
          onSubmit={onSubmit}
          onBack={onBack}
        />
      </div>
    </div>
  );
});

ResetPasswordEmail.displayName = 'ResetPasswordEmail';

export default ResetPasswordEmail;
