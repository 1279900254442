import {gql} from '@/__generated__';
import {GET_AGENT, GET_AGENT_BUSINESS_STATUS_DOCUMENT} from '@/common/queries';
import {UNLOCK_TOOL} from '@/common/mutations';
import {ToolContext} from '@/contexts/ToolContext';
import {useAuthContext, usePrevious} from '@/hooks';
import {isToolUnlocked} from '@/utils';
import {useApolloClient, useMutation, useQuery} from '@apollo/client';
import {memo, PropsWithChildren, useCallback, useEffect, useMemo} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {FORCE_RUN_TOOLS} from '@/config';

const RUN_TOOL_DOCUMENT = gql(`
  mutation RunAgent($id: ID!) {
    runAgent(id: $id) {
      id  
    }
  }
`);

const UPDATE_AGENT_BUSINESS_STATUS_DOCUMENT = gql(`
  mutation UpdateAgentBusiness(
    $id: ID!
    $input: AgentBusinessUpdateInput!
  ) {
    agentBusiness: updateAgentBusiness(id: $id, input: $input) {
      id
      agentId
      businessId
      status
      createdAt
      updatedAt
    }
  }
`);

const RESET_CACHE_DELAY = 500;

export const ToolProvider = memo(({children}: PropsWithChildren) => {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const {appName} = useParams();
  const {business, isLoading} = useAuthContext();
  const isUnlocked = isToolUnlocked(business, appName!);
  const prevIsUnlocked = usePrevious(isUnlocked);
  const [updateAgentBusiness] = useMutation(
    UPDATE_AGENT_BUSINESS_STATUS_DOCUMENT,
  );
  const agentResult = useQuery(GET_AGENT, {
    variables: {
      template: appName!,
    },
    skip: !appName,
  });
  const agent = agentResult.data?.agent;
  const agentBusinessData = useQuery(GET_AGENT_BUSINESS_STATUS_DOCUMENT, {
    variables: {
      filter: {
        businessId: business?.id,
        agentId: agent?.id,
      },
      first: 1,
    },
    skip: !agent?.id || !isUnlocked,
  });
  const agentBusiness = agentBusinessData.data?.connection.edges[0]?.node;
  const [runTool] = useMutation(RUN_TOOL_DOCUMENT, {
    onCompleted: () => {
      setTimeout(() => {
        apolloClient.resetStore();
      }, RESET_CACHE_DELAY);
    },
    onError: e => {
      console.error(e);
      setTimeout(() => {
        apolloClient.resetStore();
      }, RESET_CACHE_DELAY);
    },
  });
  const rerunTool = useCallback(
    (forceRun: boolean = false) => {
      updateAgentBusiness({
        variables: {id: agentBusiness!.id, input: {status: null}},
        onCompleted: () => {
          if (FORCE_RUN_TOOLS.has(agent?.templateName as string) || forceRun) {
            runTool({variables: {id: agent?.id}});
          }
        },
      });
    },
    [agentBusiness, agent, runTool, updateAgentBusiness],
  );
  const [unlock, unlockResult] = useMutation(UNLOCK_TOOL, {
    refetchQueries: ['GetUser', 'GetAgentByTemplate'],
    onCompleted: () => {
      if (FORCE_RUN_TOOLS.has(agent?.templateName as string)) {
        runTool({variables: {id: agent?.id}});
      }
    },
  });
  const unlockTool = useCallback(() => {
    unlock({
      variables: {
        input: {
          agentId: agent?.id,
          businessId: business?.id,
        },
      },
    });
  }, [agent?.id, business?.id, unlock]);
  const toolState = useMemo(
    () => ({
      appName: appName!,
      isLoading: agentResult.loading || isLoading,
      isUnlocked,
      agent,
      rerunTool,
      unlockTool,
      error: agentResult.error ?? unlockResult.error,
      unlockSteps: agent?.setupConfig?.steps ?? [],
      runTool: () => runTool({variables: {id: agent?.id}}),
    }),
    [
      isUnlocked,
      agent,
      rerunTool,
      unlockTool,
      isLoading,
      agentResult,
      unlockResult,
      appName,
      runTool,
    ],
  );

  useEffect(() => {
    if (prevIsUnlocked === false && isUnlocked) {
      navigate(`/catalog/${appName}/unlocked`, {replace: true});
    }
  }, [isUnlocked, prevIsUnlocked, navigate, appName]);

  return (
    <ToolContext.Provider value={toolState}>
      {children ? children : <Outlet />}
    </ToolContext.Provider>
  );
});

ToolProvider.displayName = 'ToolProvider';
