import {useSearchParamsContext} from './useContexts';
import {useCallback} from 'react';

export const useSearchParam = (
  searchParamName: string,
  defaultValue: string = '',
): readonly [
  searchParamsState: string,
  setSearchParamsState: (newState?: string | undefined) => void,
] => {
  const {searchParams, setSearchParams} = useSearchParamsContext();
  const searchParamsState = searchParams.get(searchParamName) ?? defaultValue;

  const setSearchParamsState = useCallback(
    (newState?: string | undefined) => {
      setSearchParams({[searchParamName]: newState ?? defaultValue});
    },
    [searchParamName, defaultValue, setSearchParams],
  );

  return [searchParamsState, setSearchParamsState];
};

export default useSearchParam;
