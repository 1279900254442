import {reportEmailSignIn} from '@/common/analytics';
import {instrumentTracker} from '@/common/instrumentTracker';
import {TokenResponse} from '@/common/types';
import {ApolloError} from '@apollo/client';
import axios from 'axios';

export const signinWithPassword = async (
  email: string,
  password: string,
  skipEvent?: boolean, // for sign up flow
) => {
  const form = new FormData();

  form.append('username', email);
  form.append('password', password);
  form.append('grant_type', 'password');

  const resp = await axios.post<TokenResponse>(
    'token',
    form,
    skipEvent
      ? {
          headers: {
            'x-skip-instrumentation': true,
          },
        }
      : undefined,
  );

  if (resp.data.user?.id) {
    instrumentTracker.setUserId(resp.data.user?.id);
  }

  if (!skipEvent) {
    reportEmailSignIn();
  }

  return resp;
};

export const formatApolloError = (error: ApolloError) => {
  if (error instanceof ApolloError) {
    let errorMessage = '';

    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      const getPrefix = (idx: number) =>
        error.graphQLErrors.length > 1 ? `Error ${idx + 1}: ` : '';

      error.graphQLErrors.forEach((graphqlError, index) => {
        errorMessage += `${getPrefix(index)}${graphqlError.message}\n`;
      });
    }

    if (error.networkError) {
      errorMessage += `Network Error: ${error.networkError.message}\n`;
    }

    if (!error.graphQLErrors.length && error.message) {
      errorMessage += `Message: ${error.message}\n`;
    }

    return errorMessage.trim();
  }

  return '';
};
