import {lazy, memo} from 'react';
import {motion} from 'framer-motion';
import {Trans} from '@lingui/macro';

const OrbAnimation = lazy(() => import('@/components/animations/OrbAnimation'));

const ToolRunningLoader = memo(
  ({toolRunningProgress}: {toolRunningProgress?: number | null}) => {
    return (
      <motion.div
        className="m-auto flex w-[16rem] flex-col gap-6 rounded-3xl p-6"
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}>
        <div className=" px-4">
          <OrbAnimation className="h-[7rem] scale-[168%]" play speed={1.5} />
        </div>
        <div className="flex w-full flex-col gap-3">
          <div className="w-full px-[2rem]">
            <div className="h-1 w-full rounded bg-default-300">
              {toolRunningProgress !== null && (
                <motion.div
                  className="h-full rounded bg-primary"
                  initial={{width: 0}}
                  animate={{
                    width: `${toolRunningProgress || 0}%`,
                  }}></motion.div>
              )}
            </div>
          </div>
          <div className="whitespace-pre-line text-center text-sm text-foreground/75">
            <Trans>Tailoring your experience,</Trans>
            {' \n'}
            <Trans>This may take a few minutes…</Trans>
          </div>
        </div>
      </motion.div>
    );
  },
);

ToolRunningLoader.displayName = 'ToolRunningLoader';

export default ToolRunningLoader;
