export const compareObjectsByDate = <
  T1 extends Record<K, string>,
  T2 extends Record<K, string>,
  K extends string,
>(
  a: T1,
  b: T2,
  dateKey: K,
) => a[dateKey].localeCompare(b[dateKey]);

export const pickHighestDate = <
  T1 extends Record<K, string>,
  T2 extends Record<K, string>,
  K extends string,
>(
  a: T1,
  b: T2,
  dateKey: K,
) => {
  return compareObjectsByDate(a, b, dateKey) > 0 ? a[dateKey] : b[dateKey];
};

// merges and deduplicates two sorted by date lists in two passes
export const mergeTwoSortedLists = <
  T extends Record<K, string>,
  K extends string,
>(
  a: T[],
  b: T[],
  idKey: K,
  dateKey: K,
  order: 'asc' | 'desc' = 'desc',
  shouldReplace?: (existingItem: T, newItem: T) => boolean,
): T[] => {
  const orderValue = order === 'asc' ? 1 : -1;
  const totalLength = a.length + b.length;
  const seenWithIndex = new Map<string, number>();
  let sorted: T[] = [];
  let indexA = 0;
  let indexB = 0;
  let dedupIndex = totalLength;
  let dedupCount = 0;

  while (indexA < a.length && indexB < b.length) {
    if (compareObjectsByDate(a[indexA], b[indexB], dateKey) * orderValue > 0) {
      sorted.push(b[indexB++]);
    } else {
      sorted.push(a[indexA++]);
    }
  }

  if (indexB < b.length) {
    sorted = sorted.concat(b.slice(indexB));
  } else {
    sorted = sorted.concat(a.slice(indexA));
  }

  while (--dedupIndex >= 0) {
    const index =
      order === 'asc' ? totalLength - dedupIndex - 1 - dedupCount : dedupIndex;
    const item = sorted[index];

    if (!seenWithIndex.has(item[idKey])) {
      seenWithIndex.set(item[idKey], index);
    } else {
      const existingIndex = seenWithIndex.get(item[idKey])!;
      const existingItem = sorted[existingIndex];

      if (shouldReplace?.(existingItem, item)) {
        sorted.splice(existingIndex, 1, item);
      }

      sorted.splice(index, 1);

      dedupCount++;
    }
  }

  return sorted;
};

// Adds or updates an item in a sorted by date list in one pass.
export const addOrUpdateInSortedList = <
  T extends Record<K, string>,
  K extends string,
>(
  prevItems: T[],
  incomingItem: T,
  idKey: K,
  dateKey: K,
  order: 'asc' | 'desc' = 'desc',
  shouldReplace?: (existingItem: T, newItem: T) => boolean,
): T[] => {
  const orderValue = order === 'asc' ? 1 : -1;
  const nextItems: T[] = [];
  let newInserted = false;

  if (!prevItems.length) {
    return [incomingItem];
  }

  for (let i = 0; i < prevItems.length; i++) {
    const currentItem = prevItems[i];

    if (currentItem[idKey] === incomingItem[idKey]) {
      if (shouldReplace && !shouldReplace(currentItem, incomingItem)) {
        nextItems.push(currentItem);
        newInserted = true;
        continue;
      }
      continue;
    }

    const dateComparison = compareObjectsByDate(
      currentItem,
      incomingItem,
      dateKey,
    );
    if (
      !newInserted &&
      (dateComparison * orderValue > 0 ||
        (dateComparison === 0 &&
          i > 0 &&
          prevItems[i - 1][idKey] === incomingItem[idKey]))
    ) {
      nextItems.push(incomingItem);
      newInserted = true;
    }

    nextItems.push(currentItem);
  }

  if (!newInserted) {
    nextItems.push(incomingItem);
  }

  return nextItems;
};
