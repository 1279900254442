import CheckCircleSolidIcon from '@/components/icons/CheckCircleSolidIcon';
import CloseButton from '@/components/Modal';
import {useToolContext} from '@/hooks';
import {memo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';

const ToolInfoHeader = memo(({className}: {className?: string}) => {
  const {agent} = useToolContext();
  const {logo, name, description} = agent!;

  return (
    <div className={twMerge(`flex w-full flex-col p-5`, className)}>
      <div className="flex justify-start">
        <div className="flex flex-1 items-center gap-3">
          {logo?.url ? (
            <div className="relative w-12 shrink-0 self-center rounded-xl border-medium border-success p-[0.125rem] pr-[1.5px]">
              <img
                className="aspect-square w-full rounded-[0.625rem]"
                src={logo?.url}
              />
              <CheckCircleSolidIcon className="absolute -right-2 -top-2 h-[1.125rem] w-[1.125rem] rounded-full bg-background p-[1px] text-success" />
            </div>
          ) : null}
          <div className="flex flex-1 flex-col justify-between">
            <h1 className="text-lg font-semibold text-foreground">{name}</h1>
            <h3 className="line-clamp-1 text-xs font-normal text-default-500">
              {description}
            </h3>
          </div>
        </div>
        {/* @ts-expect-error it's ok */}
        <CloseButton className="shrink-0" as={RouterLink} to={-1} />
      </div>
    </div>
  );
});

ToolInfoHeader.displayName = 'ToolInfoHeader';

export default ToolInfoHeader;
