import {FC, memo, useCallback, useEffect, useRef, useState} from 'react';
import {Trans} from '@lingui/macro';
import {useAppContext} from '@/hooks';
import {Navigate, useNavigate, useSearchParams} from 'react-router-dom';
import {useApolloClient, useMutation} from '@apollo/client';
import {signinWithPassword} from '../helpers';
import ResetPasswordNewPasswordForm from '@/routes/components/ResetPasswordNewPasswordForm';
import {gql} from '@/__generated__';
import {AnimatePresence, motion} from 'framer-motion';

const SUBMIT_NEW_PASSWORD = gql(`
  mutation SubmitNewPassword($input: SubmitPasswordResetInput!) {
    submitNewPassword(input: $input) {
      id
      firstName
      lastName
      email
    }
  }
`);

const REDIRECT_DELAY_SEC = 4;

const ResetPasswordNewPassword: FC = memo(() => {
  const navigate = useNavigate();
  const {login} = useAppContext();
  const apolloClient = useApolloClient();
  const [searchParams] = useSearchParams();
  const [submittedData, setSubmittedData] = useState<
    | {
        email: string;
        password: string;
      }
    | undefined
  >();
  const [_, setSeconds] = useState(REDIRECT_DELAY_SEC);
  const secondsRef = useRef(REDIRECT_DELAY_SEC);
  const [isSuccess, setIsSuccess] = useState(false);
  const intervalRef = useRef<number | undefined>();
  const state = searchParams.get('state');
  const [submitNewPassword] = useMutation(SUBMIT_NEW_PASSWORD, {
    onCompleted: () => {
      apolloClient.refetchQueries({include: ['GetUser']});
    },
  });
  const handleLogin = useCallback(
    async ({email, password}: {email: string; password: string}) => {
      return signinWithPassword(email, password).then(({data}) => {
        setIsSuccess(true);
        login(data);
        intervalRef.current = setInterval(() => {
          if (secondsRef.current > 0) {
            secondsRef.current -= 1;
            setSeconds(secondsRef.current);
          } else {
            navigate('../../..');
          }
        }, 1000);
      });
    },
    [login, navigate],
  );

  const onSubmit = useCallback(
    async (values: {newPassword: string}) => {
      const newPassword = values.newPassword;

      if (submittedData && newPassword === submittedData.password) {
        return await handleLogin(submittedData);
      }

      const submitResult = await submitNewPassword({
        variables: {
          input: {
            newPassword,
            state: state!,
          },
        },
      });

      if (submitResult.errors) {
        throw submitResult.errors;
      }

      if (!submitResult.data) {
        return;
      }

      const {email} = submitResult.data.submitNewPassword;

      setSubmittedData({email, password: newPassword});

      return await handleLogin({
        email,
        password: newPassword,
      });
    },
    [submittedData, state, submitNewPassword, handleLogin],
  );

  const onBack = useCallback(async () => {
    navigate('../../login');
  }, [navigate]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  if (!state) {
    return <Navigate to="../../login" />;
  }

  return (
    <AnimatePresence mode="wait">
      {isSuccess ? (
        <motion.div
          key="success"
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{duration: 0.2}}
          className="flex w-full flex-col gap-12">
          <div className="text-center text-xl font-semibold text-foreground">
            <Trans>Your password has been updated!</Trans>
          </div>
        </motion.div>
      ) : (
        <motion.div
          key="form"
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{duration: 0.2}}
          className="flex w-full flex-col gap-12">
          <div className="flex w-full flex-col">
            <ResetPasswordNewPasswordForm onSubmit={onSubmit} onBack={onBack} />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

ResetPasswordNewPassword.displayName = 'ResetPasswordNewPassword';

export default ResetPasswordNewPassword;
