import {SVGProps} from 'react';

export default function BarChartIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0 20.627C0 9.58126 8.95431 0.626953 20 0.626953C31.0457 0.626953 40 9.58126 40 20.627C40 31.6726 31.0457 40.627 20 40.627C8.95431 40.627 0 31.6726 0 20.627Z"
        fill="url(#paint0_radial_2306_33002)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5681 14.5078C11.5787 14.5078 11.5894 14.5078 11.6 14.5078H12.4C12.4106 14.5078 12.4213 14.5078 12.4319 14.5078C12.6843 14.5078 12.9301 14.5078 13.1382 14.5248C13.3668 14.5434 13.6366 14.5875 13.908 14.7258C14.2843 14.9176 14.5903 15.2235 14.782 15.5998C14.9203 15.8713 14.9644 16.141 14.9831 16.3696C15.0001 16.5777 15 16.8235 15 17.0759C15 17.0865 15 17.0972 15 17.1078L15 27.9397C15 28.1921 15.0001 28.4379 14.9831 28.646C14.9644 28.8746 14.9203 29.1444 14.782 29.4158C14.5903 29.7921 14.2843 30.0981 13.908 30.2898C13.6366 30.4281 13.3668 30.4722 13.1382 30.4909C12.9301 30.5079 12.6843 30.5079 12.4319 30.5078H11.5681C11.3157 30.5079 11.0699 30.5079 10.8618 30.4909C10.6332 30.4722 10.3634 30.4281 10.092 30.2898C9.7157 30.0981 9.40974 29.7921 9.21799 29.4158C9.07969 29.1444 9.03563 28.8746 9.01695 28.646C8.99994 28.4379 8.99997 28.1921 9 27.9397L9.00001 17.1078C9.00001 17.0972 9 17.0865 9 17.0759C8.99997 16.8235 8.99994 16.5777 9.01695 16.3696C9.03563 16.141 9.07969 15.8713 9.21799 15.5998C9.40974 15.2235 9.7157 14.9176 10.092 14.7258C10.3634 14.5875 10.6332 14.5434 10.8618 14.5248C11.0699 14.5078 11.3157 14.5078 11.5681 14.5078Z"
        fill="url(#paint1_linear_2306_33002)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5681 10.5078C19.5787 10.5078 19.5894 10.5078 19.6 10.5078L20.4 10.5078C20.4106 10.5078 20.4213 10.5078 20.4319 10.5078C20.6843 10.5078 20.9301 10.5078 21.1382 10.5248C21.3668 10.5434 21.6366 10.5875 21.908 10.7258C22.2843 10.9176 22.5903 11.2235 22.782 11.5998C22.9203 11.8713 22.9644 12.141 22.9831 12.3696C23.0001 12.5777 23 12.8235 23 13.0759C23 13.0865 23 13.0972 23 13.1078L23 27.9397C23 28.1921 23.0001 28.4379 22.9831 28.646C22.9644 28.8746 22.9203 29.1444 22.782 29.4158C22.5903 29.7921 22.2843 30.0981 21.908 30.2898C21.6366 30.4281 21.3668 30.4722 21.1382 30.4909C20.9301 30.5079 20.6843 30.5079 20.4319 30.5078H19.5681C19.3157 30.5079 19.0699 30.5079 18.8618 30.4909C18.6332 30.4722 18.3634 30.4281 18.092 30.2898C17.7157 30.0981 17.4097 29.7921 17.218 29.4158C17.0797 29.1444 17.0356 28.8746 17.017 28.646C16.9999 28.4379 17 28.1921 17 27.9397L17 13.1078C17 13.0972 17 13.0865 17 13.0759C17 12.8235 16.9999 12.5777 17.017 12.3696C17.0356 12.141 17.0797 11.8713 17.218 11.5998C17.4097 11.2235 17.7157 10.9176 18.092 10.7258C18.3634 10.5875 18.6332 10.5434 18.8618 10.5248C19.0699 10.5078 19.3157 10.5078 19.5681 10.5078Z"
        fill="url(#paint2_linear_2306_33002)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5681 18.5078C27.5787 18.5078 27.5894 18.5078 27.6 18.5078H28.4C28.4106 18.5078 28.4213 18.5078 28.4319 18.5078C28.6843 18.5078 28.9301 18.5078 29.1382 18.5248C29.3668 18.5434 29.6366 18.5875 29.908 18.7258C30.2843 18.9176 30.5903 19.2235 30.782 19.5998C30.9203 19.8713 30.9644 20.141 30.9831 20.3696C31.0001 20.5777 31 20.8235 31 21.0759C31 21.0865 31 21.0972 31 21.1078L31 27.9397C31 28.1921 31.0001 28.4379 30.9831 28.646C30.9644 28.8746 30.9203 29.1444 30.782 29.4158C30.5903 29.7921 30.2843 30.0981 29.908 30.2898C29.6366 30.4281 29.3668 30.4722 29.1382 30.4909C28.9301 30.5079 28.6843 30.5079 28.4319 30.5078H27.5681C27.3157 30.5079 27.0699 30.5079 26.8618 30.4909C26.6332 30.4722 26.3634 30.4281 26.092 30.2898C25.7157 30.0981 25.4097 29.7921 25.218 29.4158C25.0797 29.1444 25.0356 28.8746 25.017 28.646C24.9999 28.4379 25 28.1921 25 27.9397L25 21.1078C25 21.0972 25 21.0865 25 21.0759C25 20.8235 24.9999 20.5777 25.017 20.3696C25.0356 20.141 25.0797 19.8713 25.218 19.5998C25.4097 19.2235 25.7157 18.9176 26.092 18.7258C26.3634 18.5875 26.6332 18.5434 26.8618 18.5248C27.0699 18.5078 27.3157 18.5078 27.5681 18.5078Z"
        fill="url(#paint3_linear_2306_33002)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2306_33002"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40 0.626953) rotate(135) scale(52.254)">
          <stop stopColor="#FFB36C" />
          <stop offset="1" stopColor="#EB3C1B" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_2306_33002"
          x1="20"
          y1="10.5078"
          x2="20"
          y2="30.5078"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFCDAD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2306_33002"
          x1="20"
          y1="10.5078"
          x2="20"
          y2="30.5078"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFCDAD" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2306_33002"
          x1="20"
          y1="10.5078"
          x2="20"
          y2="30.5078"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFCDAD" />
        </linearGradient>
      </defs>
    </svg>
  );
}
