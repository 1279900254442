import {
  ChatItem,
  ChatMessageAuthorType,
  ChatMessageHeaderItem,
  ChatSessionWithMessages,
} from '@/common/types/chat';
import {getUserName} from '@/utils';
import {AuthContextValue} from '@/contexts';
import portalAvatar from '@/assets/portal-avatar.png';

export const getNotConnectedIntegrations = (
  requiredTypes: string[],
  connectedTypes: string[],
) => requiredTypes.filter(integration => !connectedTypes.includes(integration));

const getChatUserName = (
  from: ChatMessageAuthorType,
  user: AuthContextValue['user'],
  activeSession: ChatSessionWithMessages | undefined,
) => {
  if (from === 'user') {
    return getUserName(user, 'You');
  }

  return activeSession?.agent?.name ?? 'Portal AI';
};

const getUserAvatarUrl = (
  from: ChatMessageAuthorType,
  user: AuthContextValue['user'],
  activeSession: ChatSessionWithMessages | undefined,
) => {
  if (from === 'user') {
    return user?.avatar ?? undefined;
  }

  return (
    (activeSession?.agent?.chatAvatar?.url ||
      activeSession?.agent?.logo?.url) ??
    portalAvatar
  );
};

export const getChatItemsList = ({
  user,
  activeSession,
  reversed = false,
}: {
  user: AuthContextValue['user'];
  activeSession: ChatSessionWithMessages | undefined;
  reversed?: boolean;
}): ChatItem[] => {
  const messages = activeSession?.messages?.filter(m => !m.hidden) ?? [];

  if (!messages.length) {
    return [];
  }

  const processedMessages: ChatItem[] = [];
  let unreadSeparatorInserted = false;

  for (let i = messages.length - 1; i >= 0; i--) {
    const currentMessage = messages[i];

    let insertHeader = true;

    if (i < messages.length - 1) {
      const previousMessage = messages[i + 1];

      if (previousMessage.author_type === currentMessage.author_type) {
        insertHeader = false;
      }
    }

    if (
      !unreadSeparatorInserted &&
      activeSession?.last_read_at &&
      currentMessage.created_at.localeCompare(activeSession.last_read_at!) > 0
    ) {
      unreadSeparatorInserted = true;
      processedMessages.push({
        itemType: 'unreadSeparator',
      });
    }

    if (insertHeader) {
      const header: ChatMessageHeaderItem = {
        itemType: 'header',
        type: currentMessage.author_type,
        name: getChatUserName(currentMessage.author_type, user, activeSession),
        avatarUrl: getUserAvatarUrl(
          currentMessage.author_type,
          user,
          activeSession,
        ),
      };
      processedMessages.push(header);
    }

    processedMessages.push(currentMessage);
  }

  return reversed ? processedMessages.reverse() : processedMessages;
};

export const getMessageElementId = (id: string) => `chat-message-${id}`;
