import {FC} from 'react';
import {Navigate, NavigateProps, Path, useSearchParams} from 'react-router-dom';

const NavigateWithSearch: FC<
  {to: string | Partial<Path>} & Omit<NavigateProps, 'to'>
> = ({to, ...props}) => {
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to={{
        search: searchParams.toString(),
        ...(typeof to === 'string' ? {pathname: to} : to),
      }}
      {...props}
    />
  );
};

export default NavigateWithSearch;
