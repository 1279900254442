import {SVGProps} from 'react';

export default function StarFilledIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M2.93359 12.5117C2.79688 12.4102 2.71289 12.2734 2.68164 12.1016C2.6543 11.9336 2.67969 11.7344 2.75781 11.5039L3.96484 7.92383L0.888672 5.71484C0.689453 5.57422 0.550781 5.42773 0.472656 5.27539C0.394531 5.11914 0.382812 4.95898 0.4375 4.79492C0.492188 4.63477 0.595703 4.51562 0.748047 4.4375C0.900391 4.35547 1.09961 4.31641 1.3457 4.32031L5.11328 4.34375L6.26172 0.746094C6.33594 0.511719 6.43359 0.333984 6.55469 0.212891C6.67969 0.0878906 6.82617 0.0253906 6.99414 0.0253906C7.16602 0.0253906 7.3125 0.0878906 7.43359 0.212891C7.55859 0.333984 7.6582 0.511719 7.73242 0.746094L8.88086 4.34375L12.6484 4.32031C12.8945 4.31641 13.0938 4.35547 13.2461 4.4375C13.3984 4.51562 13.502 4.63477 13.5566 4.79492C13.6113 4.95898 13.5996 5.11914 13.5215 5.27539C13.4434 5.42773 13.3047 5.57422 13.1055 5.71484L10.0293 7.92383L11.2363 11.5039C11.3145 11.7344 11.3379 11.9336 11.3066 12.1016C11.2793 12.2734 11.1973 12.4102 11.0605 12.5117C10.9238 12.6211 10.7695 12.6602 10.5977 12.6289C10.4258 12.5977 10.2422 12.5117 10.0469 12.3711L6.99414 10.127L3.94727 12.3711C3.75195 12.5117 3.56836 12.5977 3.39648 12.6289C3.22461 12.6602 3.07031 12.6211 2.93359 12.5117Z" />
    </svg>
  );
}
