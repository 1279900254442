import {useState, useEffect, useRef} from 'react';

export const useMatchesMedia = (query: string) => {
  const matchesRef = useRef(window.matchMedia(query).matches);
  const [matches, setMatches] = useState(matchesRef.current);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const documentChangeHandler = (e: MediaQueryListEvent) => {
      if (e.matches !== matchesRef.current) {
        matchesRef.current = e.matches;
        setMatches(e.matches);
      }
    };

    mediaQueryList.addEventListener('change', documentChangeHandler);

    return () =>
      mediaQueryList.removeEventListener('change', documentChangeHandler);
  }, [query]);

  return matches;
};
