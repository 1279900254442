import {memo, ReactNode} from 'react';
import {useAuthContext} from '@/hooks';
import {useQuery} from '@apollo/client';
import {IntegrationType} from '@/common/types';
import ConnectShopify from '@/components/Integrations/ConnectShopify';
import {useLocation} from 'react-router-dom';
import {GET_INTEGRATIONS} from '@/common/queries';
import {ContinueButton} from './components/ContinueButton';

const ConnectShopifyForm = memo(
  ({
    onSubmit,
    submitTitle,
  }: {
    onSubmit?: () => Promise<void> | void;
    submitTitle?: ReactNode;
  }) => {
    const location = useLocation();
    const {business} = useAuthContext();
    const {data} = useQuery(GET_INTEGRATIONS, {
      variables: {
        filter: {
          businessId: business?.id,
          type: IntegrationType.shopify,
        },
        order: {type: 'ASC'},
        first: 1,
      },
      skip: !business?.id,
      fetchPolicy: 'cache-and-network',
    });
    const isConnected = Boolean(data?.connection.edges?.length);

    return (
      <div className="flex w-full flex-col gap-6">
        <ConnectShopify
          isActive={isConnected}
          callbackPath={location.pathname.slice(1) + location.search}>
          <ContinueButton
            isDisabled={!isConnected}
            onPress={onSubmit}
            title={submitTitle}
          />
        </ConnectShopify>
      </div>
    );
  },
);

ConnectShopifyForm.displayName = 'ConnectShopifyForm';

export default ConnectShopifyForm;
