import {memo, ReactNode, useState} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';
import NumberInput from '@/components/inputs/NumberInput';
import {ApolloError, useApolloClient, useMutation} from '@apollo/client';
import {UPSERT_CUSTOMER_DATA} from '@/common/mutations';
import {useAuthContext, useToastQueue} from '@/hooks';
import ErrorMessage from '@/components/ErrorMessage';
import {formatApolloError} from '@/routes/helpers';
import {useGetBusinessMarginPercentage} from '../hooks';
import {ContinueButton} from './components/ContinueButton';
import {twMerge} from 'tailwind-merge';

interface Props {
  className?: string;
  buttonClassName?: string;
  label?: string;
  submitTitle?: ReactNode;
  onSubmit?: () => Promise<void> | void;
}

interface FormValues {
  marginPercentage: string;
}

type FormContentProps = FormikProps<FormValues> &
  Props & {
    errorMessage: string | undefined;
  };

const BusinessMarginFormContent = ({
  isSubmitting,
  touched,
  errors,
  values,
  errorMessage,
  label,
  submitTitle,
  className = '',
  buttonClassName = '',
  setFieldValue,
}: FormContentProps) => {
  const {_} = useLingui();
  const isMarginPercentageInvalid = Boolean(
    touched.marginPercentage && errors.marginPercentage,
  );

  return (
    <Form className={twMerge(`flex w-full flex-col gap-6`, className)}>
      <NumberInput
        name="marginPercentage"
        label={label ?? _(msg`Business margin (%)`)}
        isInvalid={isMarginPercentageInvalid}
        errorMessage={
          isMarginPercentageInvalid ? errors.marginPercentage : undefined
        }
        classNames={{
          inputWrapper: '!bg-background',
        }}
        isDisabled={isSubmitting}
        endContent={
          <div className={`pr-[0.125rem] text-medium text-foreground`}>%</div>
        }
        value={String(values.marginPercentage)}
        setFieldValue={setFieldValue}
      />
      <ErrorMessage message={errorMessage} />
      <ContinueButton
        type="submit"
        isLoading={isSubmitting}
        title={submitTitle}
        className={buttonClassName}
      />
    </Form>
  );
};

const BusinessMarginForm = memo((props: Props) => {
  const [error, setError] = useState<string | undefined>();
  const {_} = useLingui();
  const {user} = useAuthContext();
  const {addErrorToast} = useToastQueue();
  const {marginPercentage} = useGetBusinessMarginPercentage();
  const apolloClient = useApolloClient();
  const [upsertCustomerData] = useMutation(UPSERT_CUSTOMER_DATA, {
    onCompleted: props.onSubmit,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        marginPercentage: marginPercentage ?? '',
      }}
      validationSchema={Yup.object({
        marginPercentage: Yup.number()
          .min(0, _(msg`Business margin must be greater than 0`))
          .max(100, _(msg`Margin must be less than 100`))
          .required(_(msg`Required`)),
      })}
      onSubmit={async values => {
        if (!user) {
          return;
        }

        try {
          await upsertCustomerData({
            variables: {
              input: {
                key: 'business_margin_percentage',
                value: values.marginPercentage,
                userId: user.id,
              },
            },
          });

          apolloClient.refetchQueries({
            include: ['GetCustomerDataConnection'],
          });
        } catch (e: any) {
          if (e instanceof ApolloError) {
            setError(formatApolloError(e) || undefined);
          } else {
            const {data} = e.response || {};
            const message = data.detail || e.message;

            setError(message);
            addErrorToast({message});
          }
        }
      }}>
      {formikProps => {
        return (
          <BusinessMarginFormContent
            {...formikProps}
            {...props}
            errorMessage={error}
          />
        );
      }}
    </Formik>
  );
});

BusinessMarginForm.displayName = 'BusinessMarginForm';

export default BusinessMarginForm;
