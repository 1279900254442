import {gql} from '@/__generated__';
import {useMutation} from '@apollo/client';
import {useCallback, useState} from 'react';

const GENERATE_AGENT_DOWNLOAD_LINK = gql(`
  mutation GenerateAgentFileLink($id: ID!) {
    data: generateAgentFileLink(id: $id)
  }
`);

const GENERATE_DOWNLOAD_LINK = gql(`
  mutation GenerateFileLink($name: String!, $displayName: String) {
    data: generateFileLink(name: $name, displayName: $displayName)
  }
`);

type IdPrams = {
  id: string;
  displayName?: string;
};

type FilenameParams = {
  name: string;
  displayName?: string;
};

export const useFileDownload = (params: IdPrams | FilenameParams) => {
  const [isError, setIsError] = useState(false);
  const [generateAgentDownloadLink, agentResult] = useMutation(
    GENERATE_AGENT_DOWNLOAD_LINK,
  );
  const [generateDownloadLink, result] = useMutation(GENERATE_DOWNLOAD_LINK);
  const loading = agentResult.loading || result.loading;

  const downloadFile = useCallback(async () => {
    const mutation =
      'id' in params ? generateAgentDownloadLink : generateDownloadLink;
    const {data: downloadLink} = await mutation({
      variables: params as any,
    });
    const url = downloadLink?.data;

    if (!url) {
      setIsError(true);
      return;
    }

    setIsError(false);

    const link = document.createElement('a');

    document.body.appendChild(link);

    link.href = url;
    link.download = params.displayName || `file_${Date.now()}`;
    link.click();

    document.body.removeChild(link);
  }, [params, generateDownloadLink]);

  return {downloadFile, loading, isError};
};
