import {ComponentProps, Fragment, memo} from 'react';
import startCase from 'lodash/startCase';
import {Plural, Trans} from '@lingui/macro';
import {getRiskIndexRating, getRiskIndexText} from '@/utils';
import {useLingui} from '@lingui/react';
import RatingBar from '@/components/RatingBar';
import {RiskIndexGroup} from '@/common/types';
import {twMerge} from 'tailwind-merge';
import {DataSource, Language} from '@/__generated__/graphql';

interface Props {
  riskIndexGroup?: RiskIndexGroup;
  unlocks: number;
  activationsText: string | null | undefined;
  rating: number;
  categoryName: string | null | undefined;
  categoryImage: string | null | undefined;
  dataSources: DataSource[];
  languages: Language[];
  className?: ComponentProps<'div'>['className'];
}

type Stat =
  | {
      key: 'riskIndex';
      value: RiskIndexGroup;
    }
  | {
      key: 'activations';
      value: string;
    }
  | {
      key: 'rating';
      value: Props['rating'];
    }
  | {
      key: 'category';
      value: Props['categoryName'];
    }
  | {
      key: 'data';
      value: Props['dataSources'];
    }
  | {
      key: 'language';
      value: Props['languages'];
    };

const AppStats = memo(
  ({
    // riskIndexGroup,
    unlocks,
    activationsText,
    rating,
    categoryName,
    categoryImage,
    dataSources,
    languages,
    className,
  }: Props) => {
    const {i18n} = useLingui();
    const activations =
      activationsText ||
      i18n.number(unlocks, {
        maximumSignificantDigits: 2,
        maximumFractionDigits: Math.abs(unlocks) > 1 ? 1 : 2,
        notation: 'compact',
        compactDisplay: 'short',
      });
    const stats: Stat[] = [
      // {key: 'riskIndex' as const, value: riskIndexGroup},
      {key: 'activations' as const, value: activations},
      {key: 'rating' as const, value: rating},
      {key: 'category' as const, value: categoryName},
      {key: 'data' as const, value: dataSources},
      {key: 'language' as const, value: languages},
    ].filter(stat => stat.value != null);

    const itemClass =
      'flex flex-col justify-between h-full self-start items-center text-center min-w-[6.5rem] max-w-[6.5rem] text-xs font-medium text-default-400';
    const valueClass = 'text-lg font-medium text-default-500';
    const subtitleClass = 'text-xs text-default-500 font-normal';

    const renderItem = (stat: Stat) => {
      switch (stat.key) {
        case 'riskIndex':
          return (
            <div className={itemClass}>
              <div className="uppercase">{startCase(stat.key)}</div>
              <div className={valueClass}>{getRiskIndexText(stat.value)}</div>
              <RatingBar
                readonly
                icon="circle"
                rating={getRiskIndexRating(stat.value)}
              />
            </div>
          );
        case 'activations':
          return (
            <div className={itemClass}>
              <div className="uppercase">
                <Trans>Activations</Trans>
              </div>
              <div className={valueClass}>{stat.value}</div>
              <div className={subtitleClass}>
                <Trans>Times</Trans>
              </div>
            </div>
          );
        case 'rating':
          return (
            <div className={itemClass}>
              <div className="uppercase">{startCase(stat.key)}</div>
              <div className={valueClass}>
                {i18n.number(stat.value, {
                  maximumSignificantDigits: 2,
                  maximumFractionDigits: 1,
                })}
              </div>
              <RatingBar readonly icon="star" rating={stat.value} />
            </div>
          );
        case 'category':
          return (
            <div className={itemClass}>
              <div className="uppercase">{startCase(stat.key)}</div>
              {categoryImage ? (
                <img
                  src={categoryImage}
                  className="flex h-5 w-5 flex-[0_0_auto]"
                />
              ) : null}
              <div className={subtitleClass}>{stat.value}</div>
            </div>
          );
        case 'data':
          return (
            <div className={itemClass}>
              <div className="uppercase">{startCase(stat.key)}</div>
              <div className={valueClass}>
                {stat.value.length > 2 ? `2+` : stat.value.length}
              </div>
              <div className={subtitleClass}>
                <Plural
                  value={stat.value.length}
                  one="Source"
                  other="Sources"
                />
              </div>
            </div>
          );
        case 'language': {
          const langs = stat.value
            .filter(lang => lang.iso2.toLowerCase() !== 'en')
            .map(lang => lang.shortName);

          return (
            <div className={itemClass}>
              <div className="uppercase">{startCase(stat.key)}</div>
              <div className={valueClass}>{'EN'}</div>
              <div className={subtitleClass}>
                {langs.length ? (
                  <Trans>{`+ ${langs.length} more`}</Trans>
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            </div>
          );
        }
        default:
          return null;
      }
    };

    if (!stats.length) {
      return null;
    }

    return (
      <div
        style={{WebkitOverflowScrolling: 'touch'}}
        className={twMerge(
          `-mb-2 flex h-[5rem] items-center overflow-auto pb-2 sm:justify-around md:h-[7rem] md:py-4`,
          className,
        )}>
        {stats.map((stat, idx) => (
          <Fragment key={idx}>
            {renderItem(stat)}
            {idx < stats.length - 1 ? (
              <div className="flex h-[2rem] w-[1px] shrink-0 bg-default-200" />
            ) : null}
          </Fragment>
        ))}
      </div>
    );
  },
);

AppStats.displayName = 'AppStats';

export default AppStats;
