import CloseButton from '@/components/Modal';
import {useAuthContext} from '@/hooks';
import ErrorMessage from '@/components/ErrorMessage';
import {useMutation} from '@apollo/client';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button} from '@nextui-org/react';
import {ModalHeader, ModalBody, ModalFooter} from '@/components/Modal';
import {Field, Formik, Form} from 'formik';
import {FC} from 'react';
import * as Yup from 'yup';
import {gql} from '@/__generated__';
import {ImageInput} from '@/components/inputs/ImageInput';
import NextUIInput from '@/components/inputs/NextUIInput';
import PortalSpinner from '@/components/PortalSpinner';

const UPDATE_USER = gql(`
  mutation UpdateUser($id: ID!, $input: UserUpdateInput!) {
    user: updateUser(id: $id, input: $input) {
      ...UpdateUserFragment
    }
  }
`);

const EditProfileForm: FC<{onClose: () => void}> = ({onClose}) => {
  const {_} = useLingui();
  const {user} = useAuthContext();
  const [updateUser, updateUserResult] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      onClose();
    },
  });

  return (
    <Formik
      initialValues={{
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        avatar: user?.avatar ?? '',
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .min(1, _(msg`First name is too short`))
          .required(_(msg`Required`)),
        lastName: Yup.string().min(1, _(msg`Last name is too short`)),
      })}
      onSubmit={async values => {
        await updateUser({
          variables: {
            id: user?.id,
            input: {
              firstName: values.firstName,
              lastName: values.lastName,
              avatar: values.avatar,
            },
          },
        }).catch(() => null);
      }}>
      {({isSubmitting, touched, errors, dirty}) => {
        const isFirstNameInvalid = Boolean(
          touched.firstName && errors.firstName,
        );
        const isLastNameInvalid = Boolean(touched.lastName && errors.lastName);
        const isAvatarInvalid = Boolean(touched.avatar && errors.avatar);

        return (
          <Form className="relative flex flex-1 flex-col">
            <ModalHeader>
              <h1 className="text-lg font-semibold">
                <Trans>Edit Profile</Trans>
              </h1>
              <CloseButton
                className="absolute right-5 top-5 md:right-10"
                onPress={onClose}
              />
            </ModalHeader>
            <ModalBody className="gap-6">
              <div className="flex flex-col gap-2">
                <h3 className="text-sm font-medium">
                  <Trans>Profile image</Trans>
                </h3>
                <ImageInput
                  className="flex-row items-center gap-4"
                  imageProps={{
                    radius: 'full',
                    width: 80,
                    height: 80,
                  }}
                  triggerProps={{radius: 'sm'}}
                  classNames={{image: 'h-20 w-20 object-cover'}}
                  name="avatar"
                  label={_(msg`Update avatar`)}
                  isInvalid={isAvatarInvalid}
                  errorMessage={isAvatarInvalid ? errors.avatar : undefined}
                  isDisabled={isSubmitting}
                />
              </div>
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                <Field
                  as={NextUIInput}
                  name="firstName"
                  variant="bordered"
                  label={_(msg`First name`)}
                  classNames={{label: 'text-sm font-medium'}}
                  radius="sm"
                  labelPlacement="outside"
                  placeholder={_(msg`Enter first name`)}
                  isInvalid={isFirstNameInvalid}
                  errorMessage={
                    isFirstNameInvalid ? errors.firstName : undefined
                  }
                  isRequired
                  isDisabled={isSubmitting}
                />
                <Field
                  as={NextUIInput}
                  name="lastName"
                  variant="bordered"
                  label={_(msg`Last name`)}
                  classNames={{label: 'text-sm font-medium'}}
                  radius="sm"
                  labelPlacement="outside"
                  placeholder={_(msg`Enter last name`)}
                  isInvalid={isLastNameInvalid}
                  errorMessage={isLastNameInvalid ? errors.lastName : undefined}
                  isDisabled={isSubmitting}
                />
              </div>
              <ErrorMessage message={updateUserResult.error?.message} />
            </ModalBody>
            <ModalFooter>
              <Button
                aria-label="Update profile"
                fullWidth
                type="submit"
                variant="solid"
                radius="sm"
                color="primary"
                className="text-medium font-semibold text-background"
                spinner={<PortalSpinner size="sm" />}
                isLoading={isSubmitting}
                isDisabled={!dirty || isSubmitting}>
                <Trans>Update</Trans>
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditProfileForm;
