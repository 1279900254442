import {FC, memo, useCallback, useEffect} from 'react';
import {Button, Divider} from '@nextui-org/react';
import {Trans} from '@lingui/macro';
import {gql} from '@/__generated__';
import {useMutation} from '@apollo/client';
import GoogleSvg from '@/assets/socials/auth/google.svg';
import AppleSvg from '@/assets/socials/auth/apple.svg';
import {motion} from 'framer-motion';
import {reportAppleAuthStart, reportGoogleAuthStart} from '@/common/analytics';
import {
  useAuthContext,
  useIsInAppBrowser,
  useSearchParamsContext,
} from '@/hooks';
import LoginEmail from './LoginEmail';
import {setParamsAfterLogin} from '@/utils';

const GOOGLE_LOGIN = gql(`
  mutation GoogleLogin {
    href: googleLogin
  }
`);

const APPLE_LOGIN = gql(`
  mutation AppleLogin {
    href: appleLogin
  }
`);

const LoginOptions: FC = memo(() => {
  const {setSignupEmail} = useAuthContext();
  const {searchParams} = useSearchParamsContext();
  const {isInApp} = useIsInAppBrowser();
  const [googleLoginMutation, {loading: googleLoading}] = useMutation(
    GOOGLE_LOGIN,
    {
      onCompleted: data => {
        if (data.href) {
          window.location.href = data.href;
        }
      },
    },
  );
  const [appleLoginMutation, {loading: appleLoading}] = useMutation(
    APPLE_LOGIN,
    {
      onCompleted: data => {
        if (data.href) {
          window.location.href = data.href;
        }
      },
    },
  );

  const setInitialParams = useCallback(() => {
    setParamsAfterLogin(searchParams);
  }, [searchParams]);

  useEffect(() => {
    setSignupEmail('');
  }, []);

  return (
    <div className="flex w-full flex-col gap-6">
      {!isInApp && (
        <>
          <motion.div
            key="social-login"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            className="flex min-h-[5.25rem] w-full flex-col gap-4">
            <Button
              aria-label="Continue with Google"
              fullWidth
              variant="solid"
              color="primary"
              radius="sm"
              isDisabled={googleLoading}
              startContent={<img src={GoogleSvg} alt="Continue with Google" />}
              onPress={() => {
                setInitialParams();
                reportGoogleAuthStart().finally(() => {
                  googleLoginMutation();
                });
              }}
              className="h-auto items-center gap-3 border border-foreground/10 bg-background px-4 py-2 text-sm font-medium text-foreground">
              <Trans>Continue with Google</Trans>
            </Button>
            <Button
              aria-label="Continue with Apple"
              fullWidth
              variant="solid"
              color="primary"
              radius="sm"
              isDisabled={appleLoading}
              startContent={<img src={AppleSvg} alt="Continue with Apple" />}
              onPress={() => {
                setInitialParams();
                reportAppleAuthStart().finally(() => {
                  appleLoginMutation();
                });
              }}
              className="h-auto items-center gap-3 border border-foreground/10 bg-background px-4 py-2 text-sm font-medium text-foreground">
              <Trans>Continue with Apple</Trans>
            </Button>
          </motion.div>
          <div className="flex items-center gap-3">
            <Divider className="flex-1 bg-foreground/[0.08]" />
            <span className="text-sm text-foreground/50">or</span>
            <Divider className="flex-1 bg-foreground/[0.08]" />
          </div>
        </>
      )}
      <LoginEmail />
    </div>
  );
});

LoginOptions.displayName = 'LoginMain';

export default LoginOptions;
