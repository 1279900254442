import {memo} from 'react';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';
import sortBy from 'lodash/sortBy';
import indexOf from 'lodash/indexOf';
import RouteContainer from '../components/RouteContainer';
import ModalOutlet from '@/components/ModalOutlet';
import {
  infoSectionByStepId,
  infoSectionsOrdered,
} from '@/routes/CatalogueApp/config/infoSectionByStepId';
// import {useLocation} from 'react-router-dom';
// import {useAuthContext} from '@/hooks';
// import {useQuery} from '@apollo/client';
// import {GET_INTEGRATIONS} from '@/common/queries';
// import {IntegrationType} from '@/common/types';

// type IntegrationData = {
//   type: IntegrationType;
//   name: string;
//   description: string;
// };

const OPTIONS = sortBy(
  Object.entries(infoSectionByStepId)
    .filter(([__, Component]) => Component)
    .map(([stepId, Component]) => ({name: stepId, Component})),
  step => indexOf(infoSectionsOrdered, step.name),
);
const OPTIONS_KEYS = Object.keys(infoSectionByStepId);
// const ALL_INTEGRAIONS: IntegrationData[] = [
//   {
//     type: IntegrationType.shopify,
//     name: 'Shopify',
//     description: 'Get insights on increasing budget for effective campaigns',
//   },
//   {
//     type: IntegrationType.googleAds,
//     name: 'Google Ads',
//     description: 'Get insights on increasing budget for effective campaigns',
//   },
// ];

const ProfileSettings = memo(() => {
  // const {business} = useAuthContext();
  // const location = useLocation();
  const {_} = useLingui();
  // const integrationsQuery = useQuery(GET_INTEGRATIONS, {
  //   variables: {
  //     filter: {
  //       businessId: business?.id,
  //     },
  //     order: {type: 'ASC'},
  //     first: 20,
  //   },
  //   skip: !business?.id,
  //   fetchPolicy: 'cache-and-network',
  // });
  // const [availableIntegrations, connectedIntegrations] = useMemo(() => {
  //   if (!integrationsQuery.data) {
  //     return [[], []];
  //   }

  //   const available: IntegrationData[] = [];
  //   const connected: IntegrationData[] = [];

  //   for (const integration of ALL_INTEGRAIONS) {
  //     const data = integrationsQuery.data.connection.edges.find(
  //       ({node}) => node.type === integration.type,
  //     );

  //     if (data) {
  //       connected.push(integration);
  //     } else {
  //       available.push(integration);
  //     }
  //   }

  //   return [available, connected];
  // }, [integrationsQuery.data]);

  return (
    <RouteContainer title={_(msg`Data`)} backTo="../account">
      <ModalOutlet
        hideCloseButton
        basePath="/profile/data"
        subPath={OPTIONS_KEYS}
      />
      <div className="flex w-full grow flex-col gap-8 p-6">
        {OPTIONS.map(({name, Component}) => {
          return <Component key={name} />;
        })}
      </div>
    </RouteContainer>
  );

  // return (
  //   <RouteContainer title={_(msg`Data`)} backTo="../account">
  //     <ModalOutlet hideCloseButton basePath="/profile/data" subPath={OPTIONS} />
  //     <div className="flex w-full grow flex-col gap-8 p-6">
  //       {Object.entries(OPTIONS_MAP).map(([stepId, Component]) => {
  //         return <Component key={stepId} />;
  //       })}
  //       {availableIntegrations.length > 0 ? (
  //         <div className="flex w-full flex-col gap-6">
  //           <div className="flex flex-col gap-1 *:w-full">
  //             <div className="text-medium font-semibold">
  //               <Trans>Available Connections</Trans>
  //             </div>
  //             {/* <div className="text-zinc-500">
  //               <Trans>Description and explanation of the section.</Trans>
  //             </div> */}
  //           </div>
  //           <div className="flex flex-col gap-4">
  //             {availableIntegrations.map((integration, idx) => {
  //               const Connector =
  //                 integrationConnectors[
  //                   integration.type as keyof typeof integrationConnectors
  //                 ];

  //               if (!Connector) {
  //                 return null;
  //               }

  //               return (
  //                 <Fragment key={integration.type}>
  //                   <Connector
  //                     callbackPath={
  //                       location.pathname.slice(1) + location.search
  //                     }
  //                     subtitle={integration.description}
  //                     classNames={{
  //                       iconContainer: 'w-[4rem] h-[4rem]',
  //                       title: 'text-sm',
  //                     }}
  //                   />
  //                   {idx < availableIntegrations.length - 1 ? (
  //                     <Divider className="bg-default-200" />
  //                   ) : null}
  //                 </Fragment>
  //               );
  //             })}
  //           </div>
  //         </div>
  //       ) : null}
  //       {connectedIntegrations.length > 0 ? (
  //         <div className="flex w-full flex-col gap-6">
  //           <div className="flex flex-col gap-1 *:w-full">
  //             <div className="text-medium font-semibold">
  //               <Trans>Connected</Trans>
  //             </div>
  //             {/* <div className="text-zinc-500">
  //               <Trans>Description and explanation of the section.</Trans>
  //             </div> */}
  //           </div>
  //           <div className="flex flex-col gap-4">
  //             {connectedIntegrations.map((integration, idx) => {
  //               const Connector =
  //                 integrationConnectors[
  //                   integration.type as keyof typeof integrationConnectors
  //                 ];

  //               if (!Connector) {
  //                 return null;
  //               }

  //               return (
  //                 <Fragment key={integration.type}>
  //                   <Connector
  //                     key={integration.type}
  //                     callbackPath={
  //                       location.pathname.slice(1) + location.search
  //                     }
  //                     subtitle={integration.description}
  //                     isActive
  //                     classNames={{
  //                       iconContainer: 'w-[4rem] h-[4rem]',
  //                       title: 'text-sm',
  //                       button: 'hidden',
  //                     }}
  //                   />
  //                   {idx < connectedIntegrations.length - 1 ? (
  //                     <Divider className="ml-[4rem] bg-default-200" />
  //                   ) : null}
  //                 </Fragment>
  //               );
  //             })}
  //           </div>
  //         </div>
  //       ) : null}
  //     </div>
  //   </RouteContainer>
  // );
});

ProfileSettings.displayName = 'ProfileSettings';

export default ProfileSettings;
