import {memo} from 'react';
import {Link as RouterLink, To} from 'react-router-dom';
import CloseButton from '@/components/Modal';

interface Props {
  title: string;
  backTo: To | number;
  children: React.ReactNode;
}

const RouteContainer = memo(({title, backTo, children}: Props) => {
  return (
    <div
      style={{WebkitOverflowScrolling: 'touch'}}
      className="relative flex h-full w-full max-w-2xl flex-col items-center overflow-auto">
      <div className="flex w-full max-w-full justify-between bg-background px-6 py-5">
        <h1 className="text-xl font-semibold">{title}</h1>
        {/* @ts-expect-error it's ok */}
        <CloseButton className="shrink-0" as={RouterLink} to={backTo} />
      </div>
      {children}
    </div>
  );
});

RouteContainer.displayName = 'RouteContainer';

export default RouteContainer;
