import {memo} from 'react';
import {ConnectedTrackersSection as ConnectedTrackersSectionType} from './types';
import {twMerge} from 'tailwind-merge';
import {Trans} from '@lingui/macro';
import CheckIcon from '@/components/icons/CheckIcon';
import AlertTriangleIcon from '@/components/icons/AlertTriangleIcon';
import UpdatedAt from './UpdatedAt';

const ConnectedTrackersSection = memo(
  ({
    connected_trackers: {rows, updated_at},
    className,
  }: {
    className?: string;
  } & ConnectedTrackersSectionType) => {
    return (
      <div className={twMerge('flex flex-col items-center gap-4', className)}>
        <div className="flex w-full flex-col gap-2 self-stretch">
          {rows.map(row => (
            <div
              key={row.name}
              className="flex items-center justify-between rounded-lg border-default-200 bg-foreground/[0.03] p-3 text-xs">
              <div className="flex items-center gap-1">
                <img
                  className="h-6 w-6 shrink-0"
                  alt={row.name}
                  src={row.icon_url}></img>
                <div className="font-medium">{row.name}</div>
              </div>
              {row.is_connected ? (
                <div className="flex items-center gap-1 font-medium text-[#1A9800]">
                  <CheckIcon className="h-4 w-4" />
                  <Trans>Connected</Trans>
                </div>
              ) : (
                <div className="flex items-center gap-1 font-medium text-[#FF7A00]">
                  <AlertTriangleIcon className="h-4 w-4" />
                  <Trans>Not connected</Trans>
                </div>
              )}
            </div>
          ))}
        </div>
        <UpdatedAt updatedAt={updated_at} />
      </div>
    );
  },
);

ConnectedTrackersSection.displayName = 'ConnectedTrackersSection';

export default ConnectedTrackersSection;
