import {FC, memo, useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {NextUIProvider} from '@nextui-org/react';
import {deleteSignupPersistedForms} from '@/utils';
import ToastQueue from '@/components/ToastQueue';
import useHandleIntegrationConnection from '@/hooks/useHandleIntegrationConnection';
import NavigationBar from './components/NavigationBar';
import GoogleAdsAccountSelector from '@/components/GoogleAdsAccountSelector';

const Root: FC = memo(() => {
  return (
    <>
      <GoogleAdsAccountSelector />
      <div className="main-background flex h-[100dvh] max-h-[100dvh] min-h-[100dvh] w-[100dvw] min-w-[100dvw] max-w-[100dvw] flex-col">
        <div className="flex h-full grow flex-col items-center">
          <div
            style={{WebkitOverflowScrolling: 'touch'}}
            className="flex h-full w-full grow flex-col items-center overflow-auto pb-[var(--bottom-nav-height)] md:pb-0 md:pl-[var(--side-nav-width)]">
            <Outlet />
          </div>
          <NavigationBar />
        </div>
        <ToastQueue />
      </div>
    </>
  );
});

Root.displayName = 'Root';

const RootWithState = memo(() => {
  useHandleIntegrationConnection();

  useEffect(() => {
    deleteSignupPersistedForms();
  }, []);

  return <Root />;
});

RootWithState.displayName = 'RootWithState';

const RootWithContexts: FC = memo(() => {
  const navigate = useNavigate();

  return (
    <NextUIProvider navigate={navigate}>
      <RootWithState />
    </NextUIProvider>
  );
});

RootWithContexts.displayName = 'RootWithContexts';

export default RootWithContexts;
