import {memo} from 'react';
import {BusinessMarginFormSection as BusinessMarginFormSectionType} from './types';
import {twMerge} from 'tailwind-merge';
import BusinessMarginForm from '@/routes/CatalogueApp/config/forms/BusinessMarginForm';
import {useToolContext} from '@/hooks';
import {useLocation, useNavigate} from 'react-router-dom';

type Props = {
  isDrillDown?: boolean;
  appName?: string;
  anchor?: string;
  className?: string;
} & BusinessMarginFormSectionType;

const BusinessMarginFormSection = memo(
  ({business_margin_form, anchor, className}: Props) => {
    const {rerunTool} = useToolContext();
    const location = useLocation();
    const navigate = useNavigate();

    return (
      <div
        id={anchor}
        className={twMerge(
          'relative flex flex-col overflow-hidden',
          className,
        )}>
        <BusinessMarginForm
          className="flex flex-col"
          label={business_margin_form.label}
          submitTitle={business_margin_form.button_title}
          buttonClassName={'rounded-lg'}
          onSubmit={() => {
            switch (business_margin_form.action) {
              case 'rerun_tool':
                rerunTool(true);
                if (!location.pathname.endsWith('/unlocked')) {
                  navigate('..');
                }
                return;
              default:
                return;
            }
          }}
        />
      </div>
    );
  },
);

BusinessMarginFormSection.displayName = 'BusinessMarginFormSection';

export default BusinessMarginFormSection;
