import {makeVar} from '@apollo/client';

export const utmPropertiesVar = makeVar<{[key: string]: any}>({});

export const UTM_PROPERTY_NAMES = new Set([
  'dclid',
  'fbclid',
  'gbraid',
  'gclid',
  'ko_click_id',
  'li_fat_id',
  'msclkid',
  'referrer',
  'referring_domain',
  'rtd_cid',
  'ttclid',
  'twclid',
  'utm_campaign',
  'utm_content',
  'utm_id',
  'utm_medium',
  'utm_source',
  'utm_term',
  'wbraid',
]);
