import {gql} from '@/__generated__';

export const CREATE_SESSION = gql(`
  mutation CreateSession($input: SessionCreateInput!) {
    session: createSession(input: $input) {
      ...SessionFragment
    }
  }
`);

export const CREATE_MESSAGE = gql(`
  mutation CreateMessage($input: MessageCreateInput!) {
    message: createMessage(input: $input) {
      ...MessageFragment
    }
  }
`);

export const CLAIM_SESSIONS = gql(`
  mutation ClaimSessions($ids: [ID!]!) {
    claimSessions(ids: $ids) {
      id
      userId
    }
  }
`);

export const UPSERT_CUSTOMER_DATA = gql(`
  mutation UpsertCustomerData($input: CustomerDataCreateInput!) {
    customerData: createOrUpdateCustomerData(input: $input) {
      id
    }
  }
`);

export const DELETE_CUSTOMER_DATA = gql(`
  mutation DeleteCustomerData($id: ID!) {
    customerData: deleteCustomerData(id: $id) {
      id
    }
  }
`);

export const UNLOCK_TOOL = gql(`
  mutation UnlockTool($input: AgentBusinessCreateInput!) {
    agentBusiness: createAgentBusiness(input: $input) {
      id
      agentId
      business {
        id
      }
    }
  }
`);

export const INIT_SIGNUP = gql(`
  mutation Mutation($input: InitSignupInput!) {
    initSignup(input: $input)
  }
`);

export const REQUEST_RESET_PASSWORD = gql(`
  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input)
  }
`);

export const APPROVE_DECISION = gql(`
  mutation ApproveDecision($id: ID!) {
    approveDecision(id: $id) {
      ...DecisionFragment
    }
  }
`);

export const UPDATE_DECISION = gql(`
  mutation UpdateDecision($id: ID!, $input: DecisionUpdateInput!) {
    updateDecision(id: $id, input: $input) {
      ...DecisionFragment
    }
  }
`);

export const UPDATE_ACTION = gql(`
  mutation UpdateAction($id: ID!, $input: ActionUpdateInput!) {
    updateAction(id: $id, input: $input) {
      ...ActionFragment
    }
  }
`);
