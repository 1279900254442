import {SVGProps} from 'react';

export default function AlertIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.719 2.50243C18.943 2.15739 18.0572 2.15739 17.2812 2.50243C16.6819 2.76891 16.2926 3.23829 16.0217 3.62075C15.7553 3.99679 15.4702 4.48942 15.1566 5.03121L2.75584 26.4507C2.44097 26.9944 2.15484 27.4886 1.96083 27.9079C1.76366 28.3341 1.54975 28.907 1.61792 29.5609C1.70614 30.4071 2.14945 31.176 2.83752 31.6763C3.36927 32.0629 3.97226 32.1648 4.4399 32.2077C4.90002 32.25 5.47101 32.2499 6.09934 32.2499H30.9008C31.5291 32.2499 32.1001 32.25 32.5603 32.2077C33.0279 32.1648 33.6309 32.0629 34.1626 31.6763C34.8507 31.176 35.294 30.4071 35.3822 29.5609C35.4504 28.907 35.2365 28.3341 35.0393 27.9079C34.8453 27.4886 34.5592 26.9945 34.2444 26.4508L21.8436 5.03116C21.53 4.4894 21.2448 3.99677 20.9785 3.62075C20.7076 3.23829 20.3183 2.76891 19.719 2.50243ZM20.0001 13.4999C20.0001 12.6715 19.3286 11.9999 18.5001 11.9999C17.6717 11.9999 17.0001 12.6715 17.0001 13.4999V19.4999C17.0001 20.3283 17.6717 20.9999 18.5001 20.9999C19.3286 20.9999 20.0001 20.3283 20.0001 19.4999V13.4999ZM18.5001 23.9999C17.6717 23.9999 17.0001 24.6714 17.0001 25.4999C17.0001 26.3283 17.6717 26.9999 18.5001 26.9999H18.5151C19.3436 26.9999 20.0151 26.3283 20.0151 25.4999C20.0151 24.6714 19.3436 23.9999 18.5151 23.9999H18.5001Z"
        fill="url(#paint0_linear_3015_203136)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3015_203136"
          x1="18.5001"
          y1="2.24365"
          x2="18.5001"
          y2="32.2499"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF500" />
          <stop offset="1" stopColor="#FFA800" />
        </linearGradient>
      </defs>
    </svg>
  );
}
