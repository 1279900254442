import {ComponentProps, memo, useEffect, useMemo} from 'react';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import RatingBar from '@/components/RatingBar';
// import {useMatchesMedia} from '@/hooks';
import {useNumberOfSlides} from '../helpers';
import {getDayjsDate} from '@/utils';
import dayjs from 'dayjs';
import {I18n} from '@lingui/core';
import Markdown from 'react-markdown';
import {useQuery} from '@apollo/client';
import {GET_AGENT_REVIEW_CONNECTION} from '@/common/queries';
import {useInView} from 'react-intersection-observer';

interface Props {
  templateName: string;
  rating: number;
  ratingClassName?: ComponentProps<'div'>['className'];
  reviewsClassName?: ComponentProps<'div'>['className'];
  setRating: (rating: number) => void;
}

const getReviewDateText = (date: string, now: dayjs.Dayjs, _: I18n['_']) => {
  const dayjsDate = getDayjsDate(date);

  if (!dayjsDate) {
    return;
  }

  const diffDays = now.diff(dayjsDate.startOf('day'), 'day');

  if (diffDays === 0) {
    return _(msg`Today`);
  } else if (diffDays === 1) {
    return _(msg`Yesterday`);
  } else if (diffDays < 30) {
    return _(msg`${diffDays} days ago`);
  }

  return dayjsDate.format('LL');
};

const AppReviews = memo(
  ({
    templateName,
    rating,
    ratingClassName,
    reviewsClassName,
    setRating,
  }: Props) => {
    const {_, i18n} = useLingui();
    const {ref: reviewsEndRef, inView: endReached} = useInView();
    const reviewConnection = useQuery(GET_AGENT_REVIEW_CONNECTION, {
      variables: {
        template: templateName,
        first: 5,
      },
    });
    const reviews = useMemo(() => {
      return (
        reviewConnection.data?.connection.edges.map(edge => edge.node) ?? []
      );
    }, [reviewConnection.data]);
    const pageInfo = reviewConnection.data?.connection.pageInfo;
    const endCursor = pageInfo?.endCursor;
    const hasMore = pageInfo?.hasNextPage ?? false;
    // const isTouchDevice = useMatchesMedia('(any-hover: none)');
    const numberOfSlides = useNumberOfSlides();
    const now = dayjs();

    useEffect(() => {
      if (endReached && hasMore && !reviewConnection.loading) {
        reviewConnection.fetchMore({
          variables: {
            after: endCursor,
          },
        });
      }
    }, [endReached, hasMore, reviewConnection.loading, endCursor]);

    return (
      <div className={`flex w-full flex-col gap-4`}>
        <div className={`flex w-full flex-col ${ratingClassName}`}>
          <div className="flex justify-between gap-2">
            {Number.isFinite(rating) ? (
              <div className="flex flex-col items-center gap-1">
                <div className="text-[3.75rem]/[100%] font-semibold text-default-600">
                  {i18n.number(rating, {
                    maximumSignificantDigits: 2,
                    maximumFractionDigits: 1,
                  })}
                </div>
                <div className="flex text-center text-sm font-semibold text-default-300">
                  <Trans>out of 5</Trans>
                </div>
              </div>
            ) : null}
            <div className="flex flex-[1_0_auto] flex-col items-end gap-2">
              <RatingBar
                icon="star-big"
                readonly
                rating={rating}
                className="h-10 items-center gap-[0.875rem]"
                setRating={setRating}
              />
              {/* <div className="text-sm font-medium text-default-400">
                {isTouchDevice ? (
                  <Trans>Tap to rate</Trans>
                ) : (
                  <Trans>Click to rate</Trans>
                )}
              </div> */}
            </div>
          </div>
        </div>
        <div
          style={{WebkitOverflowScrolling: 'touch'}}
          className={`-mb-2 flex max-h-[16.5rem] w-full max-w-full items-stretch gap-2 overflow-auto pb-2 ${reviewsClassName ?? ''}`}>
          {reviews.map((review, index) => (
            <div
              key={index}
              style={{
                width: `calc((100% - ${(numberOfSlides - 1) * 0.5}rem) / ${numberOfSlides})`,
              }}
              className="flex shrink-0 flex-col gap-4 rounded-[0.75rem] bg-default-100 p-6">
              <div className="flex flex-col">
                <div className="flex justify-between gap-4">
                  <div className="break-all text-sm font-semibold text-foreground">
                    {review.title}
                  </div>
                  <div className="text-xs text-default-500">
                    {getReviewDateText(review.createdAt, now, _)}
                  </div>
                </div>
                <div className="flex justify-between gap-4">
                  {Number.isFinite(review.rating) ? (
                    <RatingBar
                      itemClass="[&>svg]:fill-[#FE8208]"
                      readonly
                      icon="star"
                      rating={review.rating!}
                    />
                  ) : (
                    <div />
                  )}
                  <div className="text-xs text-default-500">
                    {review.author}
                  </div>
                </div>
              </div>
              {review.content ? (
                <Markdown className="w-full overflow-y-auto text-xs text-foreground">
                  {review.content.markdown}
                </Markdown>
              ) : null}
            </div>
          ))}
          {hasMore ? <div ref={reviewsEndRef} className="h-full w-0" /> : null}
        </div>
      </div>
    );
  },
);

AppReviews.displayName = 'AppReviews';

export default AppReviews;
