import {memo, ReactNode, useState} from 'react';
import {Formik, Form, Field, FieldArray} from 'formik';
import {array, object, string} from 'yup';
import {Button} from '@nextui-org/react';
import {useLingui} from '@lingui/react';
import {Trans, msg} from '@lingui/macro';
import {useAuthContext, useToastQueue, useToolContext} from '@/hooks';
import ErrorMessage from '@/components/ErrorMessage';
import {ApolloError, useApolloClient, useMutation} from '@apollo/client';
import NextUIInput from '@/components/inputs/NextUIInput';
import PortalSpinner from '@/components/PortalSpinner';
import {formatApolloError} from '@/routes/helpers';
import {AnimatePresence, motion} from 'framer-motion';
import CloseIcon from '../../../../components/icons/CloseIcon';
import {DELETE_CUSTOMER_DATA, UPSERT_CUSTOMER_DATA} from '@/common/mutations';
import {useGetCompetitorUrls} from '../hooks';
import {ContinueButton} from './components/ContinueButton';
import {URL_INPUT_PROPS} from '@/components/inputs';
import {twMerge} from 'tailwind-merge';

const MAX_COMPETITOR_URLS = 2;

const motionProps = {
  initial: {height: 0, opacity: 0},
  animate: {height: 'auto', opacity: 1},
  exit: {height: 0, opacity: 0},
};

const CompetitorUrlsForm = memo(
  ({
    onSubmit,
    showEmptyField,
    submitTitle,
    className,
    classNames,
    showDivider,
  }: {
    onSubmit?: () => Promise<void> | void;
    showEmptyField?: boolean;
    submitTitle?: ReactNode;
    className?: string;
    classNames?: {
      itemsWrapper?: string;
      itemWrapper?: string;
      input?: string;
    };
    showDivider?: boolean;
  }) => {
    const apolloClient = useApolloClient();
    const {isLoading: isToolLoading} = useToolContext();
    const {user} = useAuthContext();
    const [error, setError] = useState<string | undefined>();
    const {_} = useLingui();
    const {addErrorToast} = useToastQueue();
    const [upsertCustomerData] = useMutation(UPSERT_CUSTOMER_DATA);
    const [deleteCustomerData] = useMutation(DELETE_CUSTOMER_DATA, {
      update: (cache, {data}) => {
        if (data?.customerData) {
          cache.evict({id: cache.identify(data.customerData)});
        }
      },
    });
    const {competitorUrlNodes, isLoading: isCompetitorUrlsLoading} =
      useGetCompetitorUrls();
    const isLoading = Boolean(isToolLoading || isCompetitorUrlsLoading);

    return (
      <Formik
        enableReinitialize
        initialValues={{
          competitorUrls:
            competitorUrlNodes.length || !showEmptyField
              ? competitorUrlNodes.map(e => e.value)
              : [''],
        }}
        validationSchema={object({
          competitorUrls: array().of(string().url(_(msg`Invalid URL`))),
        })}
        onSubmit={async values => {
          setError(undefined);

          const customerDataToUpdate = values.competitorUrls
            .filter(Boolean)
            .map((url, index) => ({
              key: `competitor_url_${index + 1}`,
              value: url,
            }));
          const customerDataToDelete =
            competitorUrlNodes
              .filter(
                node => !customerDataToUpdate.some(i => node.key === i.key),
              )
              .map(node => node.id) ?? [];

          try {
            // update customer data
            for (const {key, value} of customerDataToUpdate) {
              await upsertCustomerData({
                variables: {input: {key, value, userId: user?.id}},
              });
            }
            // delete customer data
            try {
              for (const id of customerDataToDelete) {
                await deleteCustomerData({variables: {id}});
              }
            } finally {
              apolloClient.refetchQueries({
                include: ['GetCustomerDataConnection'],
              });
            }

            await onSubmit?.();
          } catch (e: any) {
            if (e instanceof ApolloError) {
              setError(formatApolloError(e) || undefined);
            } else {
              const {data} = e.response || {};
              const message = data.detail || e.message;

              setError(message);
              addErrorToast({message});
            }
          }
        }}>
        {({values, isSubmitting, touched, errors}) => (
          <Form
            className={twMerge(
              'relative flex w-full flex-col gap-6',
              className,
            )}>
            {isLoading && (
              <PortalSpinner
                classNames={{base: 'backdrop-blur-sm fixed inset-0 z-40'}}
              />
            )}
            <FieldArray name="competitorUrls">
              {({remove, push}) => (
                <div
                  className={twMerge(
                    'flex flex-col gap-3',
                    classNames?.itemsWrapper,
                  )}>
                  <AnimatePresence initial={false}>
                    {values.competitorUrls.map((_url, index) => (
                      <motion.div
                        key={index}
                        className={twMerge(
                          'flex flex-col gap-3',
                          classNames?.itemWrapper,
                        )}
                        {...motionProps}>
                        <Field
                          as={NextUIInput}
                          {...URL_INPUT_PROPS}
                          className={twMerge('flex-1', classNames?.input)}
                          classNames={{
                            mainWrapper: 'flex-1',
                            input: '!pl-0',
                          }}
                          name={`competitorUrls.${index}`}
                          label={_(msg`Competitors URL ${index + 1}`)}
                          startContent="https://"
                          placeholder=" "
                          isInvalid={Boolean(
                            (touched.competitorUrls?.[
                              index as unknown as keyof typeof touched.competitorUrls
                            ] as any) &&
                              errors.competitorUrls?.[index] &&
                              _url,
                          )}
                          errorMessage={errors.competitorUrls?.[index]}
                          isDisabled={isSubmitting}
                          endContent={
                            <Button
                              aria-label="Remove competitor"
                              disableRipple
                              className="h-5 w-5 min-w-0 shrink-0"
                              isIconOnly
                              type="button"
                              radius="full"
                              variant="flat"
                              onPress={() => remove(index)}
                              isDisabled={isSubmitting}>
                              <CloseIcon className="h-4 w-4 stroke-default-500" />
                            </Button>
                          }
                        />
                        {showDivider &&
                          index < values.competitorUrls.length - 1 && (
                            <div className="my-6 border-t-[length:thin] border-foreground/[0.08]" />
                          )}
                      </motion.div>
                    ))}
                  </AnimatePresence>
                  <Button
                    aria-label="Add competitor"
                    className="mt-4 text-base font-semibold text-primary"
                    type="button"
                    color="default"
                    variant="flat"
                    radius="sm"
                    onPress={() => push('')}
                    isDisabled={
                      isSubmitting ||
                      values.competitorUrls.length >= MAX_COMPETITOR_URLS
                    }>
                    <Trans>+ Add competitor</Trans>
                  </Button>
                </div>
              )}
            </FieldArray>
            <ErrorMessage message={error} />
            <ContinueButton
              type="submit"
              isLoading={isSubmitting}
              title={submitTitle}
            />
          </Form>
        )}
      </Formik>
    );
  },
);

CompetitorUrlsForm.displayName = 'CompetitorUrlsForm';

export default CompetitorUrlsForm;
