import {ToolUnlockStepId} from '@/common/types';
import BusinessUrlInfoSection from './infoSections/BusinessUrlInfoSection';
import CompetitorUrlsInfoSection from './infoSections/CompetitorUrlsInfoSection';
import BusinessMarginInfoSection from './infoSections/BusinessMarginInfoSection';
import ConnectShopifyInfoSection from './infoSections/ConnectShopifyInfoSection';
import ConnectGoogleAdsInfoSection from './infoSections/ConnectGoogleAdsInfoSection';

export const infoSectionByStepId: Record<ToolUnlockStepId, any> = {
  website_url: BusinessUrlInfoSection,
  competitor_urls: CompetitorUrlsInfoSection,
  business_margin_percentage: BusinessMarginInfoSection,
  connect_shopify: ConnectShopifyInfoSection,
  connect_google_ads: ConnectGoogleAdsInfoSection,
};

export const infoSectionsOrdered: ToolUnlockStepId[] = [
  'website_url',
  'competitor_urls',
  'business_margin_percentage',
  'connect_shopify',
  'connect_google_ads',
];
