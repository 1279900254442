import {FC, PropsWithChildren} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useToolContext} from '@/hooks';
import PortalSpinner from '@/components/PortalSpinner';

const ProtectedToolRoute: FC<PropsWithChildren<{redirectTo?: string}>> = ({
  redirectTo = '../info',
  children,
}) => {
  const {isUnlocked, isLoading} = useToolContext();

  if (isLoading) {
    return (
      <PortalSpinner
        classNames={{
          base: 'backdrop-blur-sm fixed inset-0 z-40',
        }}
        size="lg"
      />
    );
  }

  if (!isUnlocked) {
    return <Navigate to={redirectTo} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedToolRoute;
