import {memo, useMemo} from 'react';
import {
  HorizontalBarsChart as HorizontalBarsChartType,
  HorizontalBarsChartItem,
} from '../../types';
import {twMerge} from 'tailwind-merge';
import {useLingui} from '@lingui/react';

interface Props {
  className?: string;
  chart: HorizontalBarsChartType;
}

const HorizontalBarsChart = memo(({chart, className}: Props) => {
  const {i18n} = useLingui();
  const defaultMaxValue = useMemo(
    () =>
      Math.max(
        ...chart.data.flatMap(
          item => chart['data-keys']?.map(key => item[key.key]) ?? [0],
        ),
      ),
    [chart],
  );

  return (
    <div className={twMerge('flex flex-col items-center gap-6', className)}>
      <div className="flex w-full flex-col gap-3">
        <div className="flex w-full flex-col gap-1">
          <div className="font-semibold">{chart.title}</div>
          {chart.subtitle ? (
            <div className="text-xs text-foreground/50">{chart.subtitle}</div>
          ) : null}
        </div>
        {chart['show-legend'] ? (
          <div className="flex flex-wrap gap-6">
            {chart['data-keys']?.map((key, index) => (
              <div key={index} className="flex items-center gap-1">
                <div
                  className="h-2 w-2 rounded-full"
                  style={{background: key['chart-color']}}
                />
                <div className="text-xs text-foreground/50">{key.label}</div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="flex w-full flex-col gap-4">
        {chart.data.map((item: HorizontalBarsChartItem, index: number) => (
          <div key={index} className="grid grid-cols-3 items-center gap-2">
            <div className="col-span-1 flex text-xs">{item.label}</div>
            <div className="col-span-2 flex flex-col">
              {chart['data-keys']?.map((key, keyIndex) => {
                const maxValue = key['max-value'] || defaultMaxValue;

                return (
                  <div className="flex items-center gap-2" key={keyIndex}>
                    <div
                      style={{
                        background: key['chart-color'],
                        width: `${(item[key.key] / maxValue) * 70}%`,
                      }}
                      className="flex h-[0.625rem] rounded-r-sm"
                    />
                    <div
                      style={{fontWeight: key['font-weight'] ?? 'normal'}}
                      className="text-xs">
                      {i18n.number(item[key.key], {
                        ...chart.format,
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

HorizontalBarsChart.displayName = 'HorizontalBarsChart';

export default HorizontalBarsChart;
