import {useEffect} from 'react';
import {INTEGRATION_CONNECTION_PARAM} from '@/config';
import {decodeIntegrationConnectionParam} from '@/utils';
import {reportIntegrationConnect} from '@/common/analytics';
import {useSearchParam} from './useSearchParam';

export const useHandleIntegrationConnection = () => {
  const [integrationConnection, setIntegrationConnection] = useSearchParam(
    INTEGRATION_CONNECTION_PARAM,
  );

  useEffect(() => {
    if (integrationConnection) {
      const integrationInfo = decodeIntegrationConnectionParam(
        integrationConnection!,
      );

      if (integrationInfo?.success) {
        reportIntegrationConnect(integrationInfo.type);
      }

      setIntegrationConnection();
    }
  }, [integrationConnection]);
};

export default useHandleIntegrationConnection;
