import {memo, useCallback} from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import startCase from 'lodash/startCase';
import {msg, Trans} from '@lingui/macro';
import {formByStepId} from '@/routes/CatalogueApp/config/formByStepId';
import {ToolUnlockStepId} from '@/common/types';
import {useLingui} from '@lingui/react';
import RouteContainer from '../components/RouteContainer';

const DataOption = memo(() => {
  const navigate = useNavigate();
  const {_} = useLingui();
  const {option} = useParams<{option: string}>();

  const renderOption = useCallback(() => {
    if (!option) {
      return null;
    }
    const StepEditForm = formByStepId[option as ToolUnlockStepId];

    if (!StepEditForm) {
      return <Navigate to=".." replace />;
    }

    return (
      <StepEditForm
        submitTitle={<Trans>Update</Trans>}
        onSubmit={async () => navigate(-1)}
      />
    );
  }, [option, navigate]);

  return (
    <RouteContainer
      title={_(msg`Edit ${startCase(option).replace('Url', 'URL')}`)}
      backTo="..">
      <div className="flex w-full flex-col p-6">{renderOption()}</div>
    </RouteContainer>
  );
});

DataOption.displayName = 'DataOption';

export default DataOption;
