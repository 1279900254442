import {ReactNode} from 'react';
import {GetUserQuery, ExplainButton, ToolSetup} from '@/__generated__/graphql';

export type User = Omit<GetUserQuery['user'], 'businesses'>;

export type Business = GetUserQuery['user']['businesses']['edges'][0]['node'];

export type FormPersistKey = 'loginSignup' | 'loginSetBusiness';

export type TokenResponse = {
  access_token: string;
  access_token_expires_at?: string;
  refresh_token?: string;
  refresh_token_expires_at?: string;
  scope?: string[];
  token_type: string;
  user?: {
    id: string;
  };
};

export type Toast = {
  id: string;
  type: 'success' | 'error' | 'warning';
  title?: ReactNode;
  message: ReactNode;
};

export enum IntegrationType {
  shopify = 'shopify',
  googleAds = 'google_ads',
  amazonSp = 'amazon_sp',
  amazonAds = 'amazon_ads',
  metaAds = 'meta_ads',
  custom_db = 'custom_db',
}

export enum RiskIndexGroup {
  VeryLow = 'very_low',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  VeryHigh = 'very_high',
}

export type ToolUnlockStepId =
  | 'website_url'
  | 'competitor_urls'
  | 'business_margin_percentage'
  | 'connect_shopify'
  | 'connect_google_ads';

export type ToolSetupConfig = ToolSetup;

export type Explain = ExplainButton;

export type BaseErrorURLParam = {
  message: string;
  status?: number;
};

export type AuthErrorURLParam = BaseErrorURLParam & {
  type: 'auth';
  auth_provider: 'google' | 'apple';
};

export type ConnectIntegrationErrorURLParam = BaseErrorURLParam & {
  type: 'integration_connection';
  integration_type: IntegrationType;
};

export type ErrorURLParam = AuthErrorURLParam | ConnectIntegrationErrorURLParam;

export type NarrowKey<T, K extends keyof T, N> = Omit<T, K> & Record<K, N>;
