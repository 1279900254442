import {msg, Trans} from '@lingui/macro';
import {Link as RouterLink} from 'react-router-dom';
import {memo} from 'react';
import HeaderWithProfile from '../components/HeaderWithProfile';
import {useLingui} from '@lingui/react';
import InsightPreview from '../CatalogueApp/unlocked/components/InsightPreview';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon';
import {useActiveTools, usePaginatedInsights} from './hooks';
import ActiveItem from '../Catalogue/components/ActiveItem';
import UpdatesBanner from './components/UpdatesBanner';
import ModalOutlet from '@/components/ModalOutlet';

const Live = memo(() => {
  const {_} = useLingui();
  const {insights} = usePaginatedInsights();
  const {activeTools} = useActiveTools();

  return (
    <>
      <ModalOutlet basePath="/live" subPath="auth" />
      <div className="flex w-full max-w-2xl flex-col">
        <HeaderWithProfile title={_(msg`Live`)} />
        <div className="flex w-full flex-col gap-8 p-6">
          <UpdatesBanner />
          {insights.length ? (
            <div className="flex flex-col gap-2">
              <RouterLink
                aria-label="Recent Insights"
                to={'insights'}
                className="flex gap-2 py-4">
                <div className="text-xl font-semibold">
                  <Trans>Recent Insights</Trans>
                </div>
                <ChevronLeftIcon className="h-7 w-7 rotate-180 text-foreground/50" />
              </RouterLink>
              <div
                style={{WebkitOverflowScrolling: 'touch'}}
                className="-mx-6 -my-2 overflow-auto px-6 py-2">
                <div
                  style={{gridAutoColumns: '100%'}}
                  className={
                    'grid min-w-full grow grid-flow-col grid-rows-3 gap-x-3 gap-y-6'
                  }>
                  {insights.map(insight => (
                    <InsightPreview
                      key={insight.id}
                      insight={insight}
                      appName={insight.agent?.templateName}
                      appIcon={insight.agent?.logo?.url}
                      isDrillDown={false}
                      onlyActions
                      className={'w-full min-w-full max-w-full'}
                      href={`insight/${insight.id}`}
                    />
                  ))}
                  <div className="row-span-3 w-full min-w-3 max-w-3"></div>
                </div>
              </div>
            </div>
          ) : null}
          {activeTools.length ? (
            <div className="flex flex-col gap-2">
              <RouterLink
                aria-label="Active Tools"
                to={'active-tools'}
                className="flex gap-2 py-4">
                <div className="text-xl font-semibold">
                  <Trans>Active Tools</Trans>
                </div>
                <ChevronLeftIcon className="h-7 w-7 rotate-180 text-foreground/50" />
              </RouterLink>
              <div className="grid grid-cols-3 gap-2 rounded-3xl bg-[#F7F7FA] py-6">
                {activeTools.map(tool => (
                  <ActiveItem key={tool.id} {...tool} />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
});

Live.displayName = 'Live';

export default Live;
