import {FC, memo, useCallback, useRef, useState} from 'react';
import {useAppContext, useAuthContext} from '@/hooks';
import {useLazyQuery, useMutation} from '@apollo/client';
import EmailLoginForm from '../components/EmailLoginForm';
import {UserStatus} from '@/__generated__/graphql';
import {GET_USER_STATUS} from '@/common/queries';
import {INIT_SIGNUP} from '@/common/mutations';
import {signinWithPassword} from '../helpers';
import {reportEmailAuthStart} from '@/common/analytics';
import {useLocation, useNavigate} from 'react-router-dom';

const LoginEmail: FC = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const {login} = useAppContext();
  const {signupEmail, setSignupEmail} = useAuthContext();
  const [userStatus, setUserStatus] = useState<UserStatus | undefined>();
  const initialSignupEmailRef = useRef(signupEmail);
  const [getUserStatus] = useLazyQuery(GET_USER_STATUS, {
    onCompleted: data => {
      setUserStatus(data.getUserStatus ?? undefined);
    },
    fetchPolicy: 'network-only',
  });
  const [initSignup, {loading: initIsLoading}] = useMutation(INIT_SIGNUP, {
    onCompleted: () => {
      navigate('invited');
    },
  });
  const handleLogin = useCallback(
    async ({email, password}: {email: string; password: string}) => {
      return signinWithPassword(email, password).then(({data}) => {
        login(data);
      });
    },
    [login],
  );
  const handleInitSignup = useCallback(
    async (email: string) => {
      const result = await initSignup({
        variables: {
          input: {
            email,
            qs: location.search,
            basePath: location.pathname.replace('/login', ''),
          },
        },
      });

      if (result.errors) {
        throw result.errors;
      }
    },
    [location.search, initSignup],
  );

  const onSubmit = useCallback(
    async ({email, password}: {email: string; password: string}) => {
      setSignupEmail(email);

      if (!userStatus) {
        reportEmailAuthStart();

        const statusResult = await getUserStatus({variables: {email}});
        const status = statusResult.data?.getUserStatus;

        if (statusResult?.error) {
          throw statusResult.error;
        }

        if (
          status === UserStatus.NotFound ||
          status === UserStatus.Unverified
        ) {
          await handleInitSignup(email);
          return;
        }
      }

      switch (userStatus) {
        case UserStatus.Verified: {
          await handleLogin({email, password});

          return;
        }
        case UserStatus.NotFound:
        case UserStatus.Unverified: {
          await handleInitSignup(email);
          return;
        }
      }
    },
    [userStatus, getUserStatus, handleLogin, handleInitSignup, setSignupEmail],
  );

  return (
    <EmailLoginForm
      initialEmail={initialSignupEmailRef.current}
      userStatus={userStatus}
      isLoading={initIsLoading}
      onSubmit={onSubmit}
    />
  );
});

LoginEmail.displayName = 'LoginEmail';

export default LoginEmail;
