import {memo, useMemo, useState} from 'react';
import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import WidgetSection from '../components/WidgetSection';
import InsightHeader from './InsightHeader';
import {gql} from '@/__generated__';
import {
  StyleConfigItem,
  Widget,
  WidgetSectionFilter,
} from '../components/sections/types';
import dayjs from 'dayjs';
import UpdatedAt from '../components/sections/UpdatedAt';
import WidgetContainer from '../components/WidgetContainer';

const GET_INSIGHT_BY_ID_DOCUMENT = gql(`
  query GetInsightById($id: ID!, $firstTopics: Int) {
    insight: getInsight(id: $id) {
      id
      title
      shortDescription
      impact
      status
      widgets
      styleConfig
      topics(first: $firstTopics) {
        edges {
          node {
            id
            agent {
              id
              name
              templateName
              logo {
                url
              }
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`);

const InsightDrilldown = memo(() => {
  const {insightId} = useParams<{insightId: string}>();
  const insightResult = useQuery(GET_INSIGHT_BY_ID_DOCUMENT, {
    variables: {id: insightId!},
  });
  const insight = insightResult.data?.insight;
  const agent = insight?.topics.edges[0]?.node.agent;
  const [widgetFilter, setWidgetFilter] = useState<WidgetSectionFilter>({});
  const widgets = useMemo(() => {
    const allWidgets = (insight?.widgets as Widget[]) ?? [];

    return allWidgets.filter(widget => {
      if (!widgetFilter) {
        return true;
      }

      return Object.entries(widgetFilter).every(([key, value]) => {
        if (!widget[key as keyof Widget]) {
          return true;
        }

        return widget[key as keyof Widget] === value;
      });
    });
  }, [insight?.widgets, widgetFilter]);
  const styles =
    (insight?.styleConfig?.Drilldown as StyleConfigItem)?.style ?? {};

  return (
    <div
      style={{
        WebkitOverflowScrolling: 'touch',
        ...(styles?.container ?? {}),
      }}
      className="relative flex h-full w-full max-w-2xl grow flex-col items-center overflow-auto">
      <InsightHeader
        className="sticky top-0 z-20 bg-background/75 backdrop-blur-xl"
        title={insight?.title}
        impact={insight?.impact}
        agent={agent}
      />

      <div
        style={styles?.content}
        className="flex w-full flex-1 flex-col gap-6 bg-default-100 pb-6">
        {widgets.map((widget, index) => {
          const sections = widget.sections;
          const maxUpdatedAt = Math.max(
            ...sections
              .filter(s => (s.json as any).updated_at)
              .map(s => dayjs((s.json as any).updated_at).valueOf()),
          );
          const updatedAt =
            maxUpdatedAt > 0 ? dayjs(maxUpdatedAt).toDate() : null;

          return (
            <div key={index} className="flex flex-col gap-6">
              <WidgetContainer
                key={index}
                widget={widget}
                appName={agent?.templateName}
                isDrillDown
                updatedAtNode={<UpdatedAt updatedAt={updatedAt} />}>
                <>
                  {sections.map((section, index) => (
                    <WidgetSection
                      key={index}
                      {...{widgetFilter, setWidgetFilter, section}}
                      anchor={widget.anchor}
                      title={widget.title}
                      appName={agent?.templateName}
                      isDrillDown
                    />
                  ))}

                  {Boolean(widget.notes) && (
                    <div className="text-center text-sm text-foreground">
                      {widget.notes}
                    </div>
                  )}
                </>
              </WidgetContainer>
            </div>
          );
        })}
      </div>
    </div>
  );
});

InsightDrilldown.displayName = 'InsightDrilldown';

export default InsightDrilldown;
