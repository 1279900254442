import CloseButton from '@/components/Modal';
import {useToolContext} from '@/hooks';
import {memo, ReactNode} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';

const ToolSettingsHeader = memo(
  ({className, title}: {className?: string; title: ReactNode}) => {
    const {agent} = useToolContext();
    const {name} = agent!;

    return (
      <div className={twMerge(`flex w-full flex-col px-6 py-5`, className)}>
        <div className="flex justify-start">
          <div className="flex flex-1 flex-col justify-between">
            <h1 className="text-xl font-semibold text-foreground">{title}</h1>
            <h3 className="text-sm font-normal">{name}</h3>
          </div>
          {/* @ts-expect-error it's ok */}
          <CloseButton className="shrink-0" as={RouterLink} to={-1} />
        </div>
      </div>
    );
  },
);

ToolSettingsHeader.displayName = 'ToolSettingsHeader';

export default ToolSettingsHeader;
