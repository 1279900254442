import {memo} from 'react';
import ToolSettingsHeader from './ToolSettingsHeader';
import {useGetRelativeTime, useToolContext} from '@/hooks';
import {
  infoSectionByStepId,
  infoSectionsOrdered,
} from '../../config/infoSectionByStepId';
import sortBy from 'lodash/sortBy';
import indexOf from 'lodash/indexOf';
import {ToolUnlockStepId} from '@/common/types';
import {Trans} from '@lingui/macro';
import {ContinueButton} from '../../config/forms/components/ContinueButton';
import {useNavigate} from 'react-router-dom';
import {gql} from '@/__generated__';
import {useQuery} from '@apollo/client';

const GET_TOOL_TOPIC_CONNECTION_DATES = gql(`
  query GetTopicConnectionDates(
    $filter: JSON
    $order: JSON
    $first: Int
    $after: String
  ) {
    connection: getTopicConnection(
      filter: $filter
      order: $order
      first: $first
      after: $after
    ) {
      edges {
        node {
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const ToolSettings = memo(() => {
  const {agent, unlockSteps, rerunTool} = useToolContext();
  const navigate = useNavigate();
  const getRelativeTime = useGetRelativeTime();
  const topicsQuery = useQuery(GET_TOOL_TOPIC_CONNECTION_DATES, {
    variables: {
      filter: {
        agentId: agent?.id,
      },
      order: {createdAt: 'DESC'},
      first: 1,
    },
    skip: !agent,
  });
  const lastRefreshedAt = topicsQuery.data?.connection.edges[0]?.node.createdAt;

  return (
    <div
      style={{WebkitOverflowScrolling: 'touch'}}
      className="relative flex h-full w-full max-w-2xl grow flex-col items-center overflow-auto">
      <ToolSettingsHeader
        className="sticky top-0 z-20 bg-background/75 backdrop-blur-xl"
        title={<Trans>Settings</Trans>}
      />
      <div className="flex w-full flex-1 flex-col gap-12 p-6">
        {sortBy(unlockSteps, step =>
          indexOf(infoSectionsOrdered, step.name),
        ).map(step => {
          const InfoSection =
            infoSectionByStepId[step.name as ToolUnlockStepId];

          if (!InfoSection) {
            return null;
          }

          return <InfoSection key={step.name} />;
        })}
        <div className="flex flex-col gap-2">
          <ContinueButton
            className="mx-auto w-full sm:max-w-xs"
            aria-label="Refresh data"
            onClick={() => {
              rerunTool(true);
              navigate('..');
            }}
            title={<Trans>Refresh Insights</Trans>}
          />
          {lastRefreshedAt ? (
            <div className="text-center text-xs text-foreground/40">
              <Trans>Updated</Trans>{' '}
              {getRelativeTime(lastRefreshedAt, {preposition: true})}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
});

ToolSettings.displayName = 'ToolSettings';

export default ToolSettings;
