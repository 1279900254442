import {memo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useLingui} from '@lingui/react';
import {msg, Trans} from '@lingui/macro';
import {Avatar, Button} from '@nextui-org/react';
import {useAuthContext} from '@/hooks';
import {getUserName} from '@/utils';
import RouteContainer from '../components/RouteContainer';
import ModalOutlet from '@/components/ModalOutlet';

const OPTIONS = [
  'edit-profile',
  'change-email',
  'change-password',
  'delete-account',
  'auth',
];

const ProfileSettings = memo(() => {
  const {user} = useAuthContext();
  const {_} = useLingui();
  const userName = getUserName(user);
  const userAvatar = user?.avatar ?? undefined;

  return (
    <RouteContainer title={_(msg`Account`)} backTo="../account">
      <ModalOutlet basePath="/profile/settings" subPath={OPTIONS} />
      <div className="flex w-full grow flex-col gap-8 px-6 pb-2">
        <div className="flex w-full items-center gap-3">
          <Avatar
            src={userAvatar}
            alt={userName}
            className="flex h-[4.5rem] w-[4.5rem] shrink-0"
          />
          <div className="flex grow flex-col gap-1">
            <div className="line-clamp-2 flex grow overflow-hidden text-ellipsis text-medium font-semibold text-foreground">
              {userName}
            </div>
          </div>
          <Button
            aria-label="Edit profile"
            as={RouterLink}
            to={'edit-profile'}
            disableAnimation
            disableRipple
            className="h-auto max-h-none min-h-0 min-w-0 shrink-0 gap-1 rounded-full bg-[#F2F2F5] px-4 py-2 text-medium font-bold text-primary">
            <Trans>Edit</Trans>
          </Button>
        </div>

        <div className="flex w-full flex-col gap-6">
          <h2 className="text-2xl font-bold">
            <Trans>Security</Trans>
          </h2>
          {user?.authProvider === 'email' && (
            <div className="flex grow items-center gap-2">
              <div className="flex grow flex-col gap-1">
                <div className="text-medium font-semibold">
                  <Trans>Email</Trans>
                </div>
                <div className="overflow-hidden text-ellipsis break-all text-zinc-500">
                  {user.email}
                </div>
              </div>
              <Button
                aria-label="Change email"
                as={RouterLink}
                to={'change-email'}
                disableAnimation
                disableRipple
                className="h-auto max-h-none min-h-0 min-w-0 shrink-0 gap-1 rounded-full bg-[#F2F2F5] px-4 py-2 text-medium font-bold text-primary">
                <Trans>Edit</Trans>
              </Button>
            </div>
          )}
          <div className="flex grow items-center gap-2">
            <div className="flex grow flex-col gap-1">
              <div className="text-medium font-semibold">
                <Trans>Password</Trans>
              </div>
              <div className=" text-zinc-500">
                <Trans>Reset your password</Trans>
              </div>
            </div>
            <Button
              aria-label="Request reset password"
              as={RouterLink}
              to={'auth/reset-password/request'}
              disableAnimation
              disableRipple
              className="h-auto max-h-none min-h-0 min-w-0 shrink-0 gap-1 rounded-full bg-[#F2F2F5] px-4 py-2 text-medium font-bold text-primary">
              <Trans>Reset</Trans>
            </Button>
          </div>
        </div>

        <div className="flex w-full flex-col gap-6">
          <div className="flex w-full flex-col gap-1">
            <h3 className="text-medium font-medium">
              <Trans>Delete my account</Trans>
            </h3>
            <div className="text-zinc-500">
              <Trans>
                Permanently remove your account and all of its contents from the
                Portal AI platform. This action is not reversible, so please
                continue with caution.
              </Trans>
            </div>
          </div>
          <Button
            aria-label="Delete account"
            as={RouterLink}
            to={'delete-account'}
            disableAnimation
            disableRipple
            className="h-auto max-h-none min-h-0 min-w-0 shrink-0 gap-1 self-start rounded-full bg-[#FFF1F1] px-4 py-2 text-medium font-bold text-[#F00]">
            <Trans>Delete my account</Trans>
          </Button>
        </div>
      </div>
    </RouteContainer>
  );
});

ProfileSettings.displayName = 'ProfileSettings';

export default ProfileSettings;
