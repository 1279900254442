import {memo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useLingui} from '@lingui/react';
import {msg, Trans} from '@lingui/macro';
import {Avatar, Button, Link} from '@nextui-org/react';
import {useAppContext, useAuthContext} from '@/hooks';
import {getUserName} from '@/utils';
import RouteContainer from '../components/RouteContainer';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon';
import {useApolloClient} from '@apollo/client';

const ProfileAccount = memo(() => {
  const {logout} = useAppContext();
  const {user} = useAuthContext();
  const {_} = useLingui();
  const apolloClient = useApolloClient();
  const userName = getUserName(user);
  const userAvatar = user?.avatar ?? undefined;

  const items = [
    {
      title: _(msg`Settings`),
      to: '../settings',
    },
    {
      title: _(msg`Data`),
      to: '../data',
    },
    {
      title: _(msg`Subscription`),
      to: '../subscription',
    },
  ];

  const renderItem = ({title, to}: {title: string; to: string}) => {
    return (
      <Button
        aria-label="Account menu item"
        key={to}
        as={RouterLink}
        to={to}
        disableAnimation
        disableRipple
        className="flex h-auto max-h-none min-h-0 w-full min-w-0 justify-between rounded-none border-b border-zinc-200 bg-transparent px-0 py-3 text-lg font-medium text-foreground [&:hover_svg]:text-foreground [&>svg]:text-default-400">
        {title}
        <ChevronLeftIcon className="h-7 w-7 rotate-180" />
      </Button>
    );
  };

  return (
    <RouteContainer title={_(msg`Account`)} backTo="../..">
      <div className="flex w-full grow flex-col justify-between px-6 py-2">
        <div className="flex w-full flex-col gap-6">
          <div className="flex w-full items-center gap-4 bg-background">
            <Avatar
              src={userAvatar}
              alt={userName}
              className="flex h-[4.5rem] w-[4.5rem] shrink-0"
            />
            <div className="flex flex-col gap-1">
              <div className="text-lg font-semibold">{userName}</div>
              <div className="break-all text-zinc-500">{user?.email}</div>
            </div>
          </div>
          <div className="flex w-full flex-col">{items.map(renderItem)}</div>
        </div>
        <div className="flex items-center justify-between gap-2 py-3">
          <Button
            aria-label="Log Out"
            disableAnimation
            disableRipple
            className="h-auto max-h-none min-h-0 min-w-0 gap-1 rounded-full bg-transparent p-0 text-lg font-medium text-[#F00]"
            onPress={() => {
              logout(apolloClient);
            }}>
            <Trans>Log Out</Trans>
          </Button>
          <div className="flex gap-3">
            <Link
              aria-label="Terms of Service"
              className="text-xs text-foreground/50"
              href="/legal/terms">
              <Trans>Terms of Service</Trans>
            </Link>
            <Link
              aria-label="Privacy Policy"
              className="text-xs text-foreground/50"
              href="/legal/privacy">
              <Trans>Privacy Policy</Trans>
            </Link>
          </div>
        </div>
      </div>
    </RouteContainer>
  );
});

ProfileAccount.displayName = 'ProfileAccount';

export default ProfileAccount;
