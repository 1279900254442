import {memo, useCallback, useEffect, useState} from 'react';
import {Button} from '@nextui-org/react';
import {Trans} from '@lingui/macro';
import {useMutation} from '@apollo/client';
import {REQUEST_RESET_PASSWORD} from '@/common/mutations';
import {useAuthContext} from '@/hooks';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';

const RESEND_DELAY = 59;

const ResetPasswordInvited = memo(() => {
  const [seconds, setSeconds] = useState(RESEND_DELAY);
  const location = useLocation();
  const {resetPasswordEmail, setResetPasswordEmail} = useAuthContext();
  const [resetPassword, {loading}] = useMutation(REQUEST_RESET_PASSWORD);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      setResetPasswordEmail('');
    };
  }, []);

  const resendEmail = useCallback(async () => {
    if (resetPasswordEmail) {
      setSeconds(RESEND_DELAY);

      await resetPassword({
        variables: {
          input: {
            email: resetPasswordEmail,
            qs: location.search,
            basePath: location.pathname.replace(
              '/reset-password/requested',
              '',
            ),
          },
        },
      });
    }
  }, [resetPasswordEmail, location.search, location.pathname, resetPassword]);

  const goBack = useCallback(async () => {
    navigate('../../login');
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0 && resetPasswordEmail) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [resetPasswordEmail, seconds]);

  if (!resetPasswordEmail) {
    return <Navigate to="../../login" />;
  }

  return (
    <div className="flex w-full flex-col gap-12">
      <div className="flex flex-col items-center gap-3">
        <div className="flex w-full flex-col text-sm text-foreground">
          <div className="text-center text-foreground/60">
            <Trans>
              We just sent you an email to{' '}
              <span className="font-medium text-foreground">
                {resetPasswordEmail}
              </span>
              . It has a link to change your password.
            </Trans>
          </div>
        </div>
      </div>
      <div className="flex min-h-[5.25rem] w-full flex-col items-center gap-12">
        <div className="flex w-full justify-center gap-2 text-foreground/60">
          {seconds > 0 ? (
            <Trans>
              Didn’t get the email? Resend in 0:
              {seconds.toString().padStart(2, '0')}
            </Trans>
          ) : (
            <div className="flex w-full flex-col gap-4">
              <Button
                aria-label="Resend"
                fullWidth
                variant="solid"
                radius="sm"
                color="primary"
                disableAnimation
                disableRipple
                className="font-medium text-background"
                isLoading={loading}
                isDisabled={loading}
                onPress={resendEmail}>
                <Trans>Resend</Trans>
              </Button>
              <Button
                aria-label="Back"
                variant="solid"
                radius="sm"
                disableAnimation
                disableRipple
                className="w-auto self-center bg-transparent font-medium"
                isLoading={loading}
                isDisabled={loading}
                onPress={goBack}>
                <Trans>Back</Trans>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

ResetPasswordInvited.displayName = 'ResetPasswordInvited';

export default ResetPasswordInvited;
