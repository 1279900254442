import {Trans} from '@lingui/macro';
import {memo} from 'react';
import {useGetCompetitorUrls} from '../hooks';
import UrlInfoItem from './UrlInfoItem';
import InfoSectionHeader from './InfoSectionHeader';

const CompetitorUrlsInfoSection = memo(() => {
  const {competitorUrlNodes} = useGetCompetitorUrls();

  return (
    <div className="flex w-full flex-col gap-2">
      <InfoSectionHeader
        stepId="competitor_urls"
        title={<Trans>My Competitors</Trans>}
      />
      {competitorUrlNodes.map((node, index) => (
        <UrlInfoItem key={index} url={node.value} />
      ))}
    </div>
  );
});

CompetitorUrlsInfoSection.displayName = 'CompetitorUrlsInfoSection';

export default CompetitorUrlsInfoSection;
