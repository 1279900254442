import {gql} from '@/__generated__';
import OrbAnimation from '@/components/animations/OrbAnimation';
import {useAppContext, useAuthContext, useResizeObserver} from '@/hooks';
import {useQuery} from '@apollo/client';
import dayjs from 'dayjs';
import {
  animate,
  interpolate,
  motion,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import {
  ComponentProps,
  HTMLProps,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {twMerge} from 'tailwind-merge';

const GET_EVENT_LOGS = gql(`
  query GetEventLogConnection($filter: JSON, $order: JSON, $first: Int) {
    connection: getEventLogConnection(filter: $filter, order: $order, first: $first) {
      edges {
        cursor
        node {
          userId
          visibleMarquee
          visibleUntil
          updatedAt
          summary
          id
          eventType
          eventName
          eventMetadata
          details
          deletedAt
          createdAt
          businessId
        }
      }
    }
  }
`);

interface Props {
  className?: ComponentProps<'div'>['className'];
}

const AnimatedBackground = memo((props: HTMLProps<HTMLDivElement>) => {
  const colors = ['#F1BCF9', '#F9E5BC', '#C2F9BC', '#BCF9F9', '#F1BCF9'];

  return (
    <div {...props}>
      <motion.div
        className="absolute bottom-0 left-0 h-[228px] w-[228px] transform rounded-full blur-[50px]"
        animate={{
          backgroundColor: colors,
        }}
        // fixes artifacts on Safari
        style={{
          transform: 'translate3d(-35%, 55%, 0)',
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          repeatType: 'loop',
          ease: 'linear',
        }}
      />
    </div>
  );
});

AnimatedBackground.displayName = 'AnimatedBackground';

const SIZE = 5;
const STEP = 24;

function circularArrayFill<T>(sourceArray: T[], size: number, offset = 0) {
  if (size <= 0 || sourceArray.length === 0) {
    return [];
  }

  const result = new Array(size);
  const sourceLength = sourceArray.length;

  // Normalize the offset to be within the bounds of sourceArray
  offset = ((offset % sourceLength) + sourceLength) % sourceLength;

  for (let i = 0; i < size; i++) {
    const sourceIndex = (offset + i) % sourceLength;
    result[i] = sourceArray[sourceIndex];
  }

  return result;
}

interface UpdateRecordProps {
  isActive: boolean;
  index: number;
  text: string;
  className?: ComponentProps<'div'>['className'];
  shiftItems: () => void;
}

const opacityInterpolation = interpolate([0, 1, 2, 3, 4], [0, 0.2, 1, 0.2, 0]);

const UpdateRecord = memo(
  ({isActive, index, text, className, shiftItems}: UpdateRecordProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);
    const [measured, setMeasured] = useState({
      container: false,
      inner: false,
    });
    const containerWidth = useMotionValue(0);
    const innerWidth = useMotionValue(0);
    const innerLeft = useMotionValue(0);
    const animatedIndex = useMotionValue(index);
    const top = useTransform(animatedIndex, idx => (idx - 1) * STEP);
    const opacity = useTransform(animatedIndex, idx =>
      opacityInterpolation(idx),
    );

    useEffect(() => {
      animate(animatedIndex, index, {
        duration: 0.4,
        ease: 'easeInOut',
      });
    }, [index]);

    const measureContainer = useCallback((entry: ResizeObserverEntry) => {
      containerWidth.set(entry.contentRect.width);

      setMeasured(prev => ({
        ...prev,
        container: true,
      }));
    }, []);

    const measureInner = useCallback((entry: ResizeObserverEntry) => {
      innerWidth.set(entry.contentRect.width);

      setMeasured(prev => ({
        ...prev,
        inner: true,
      }));
    }, []);

    useResizeObserver(containerRef, measureContainer);

    useResizeObserver(innerRef, measureInner);

    useEffect(() => {
      if (isActive && measured.container && measured.inner) {
        const cw = containerWidth.get();
        const iw = innerWidth.get();
        const diff = Math.max(iw - cw, 1);

        animate(innerLeft, -diff, {
          delay: 2,
          duration: diff / 50,
          ease: 'linear',
          onComplete: () => {
            setTimeout(() => {
              shiftItems();
            }, 1000);
          },
        });
      }
    }, [isActive, measured?.container, measured?.inner, shiftItems]);

    useEffect(() => {
      if (!isActive) {
        const cw = containerWidth.get();
        const iw = innerWidth.get();
        const diff = Math.max(iw - cw, 1);

        animate(innerLeft, 0, {
          duration: Math.max(diff / 200),
          ease: 'easeOut',
        });
      }
    }, [isActive]);

    return (
      <motion.div
        ref={containerRef}
        layout={false}
        style={{
          top,
          opacity,
          height: STEP,
        }}
        className={twMerge(
          'absolute left-0 w-full overflow-hidden text-xs font-medium',
          className,
        )}>
        <motion.div
          ref={innerRef}
          style={{
            translateX: innerLeft,
          }}
          className="relative bottom-0 left-0 top-0 flex flex-[1_0_auto] text-nowrap text-sm">
          {text}
        </motion.div>
      </motion.div>
    );
  },
);

UpdateRecord.displayName = 'UpdateRecord';

interface UpdateRecord {
  key: string;
  text: string;
}

const DEFAULT_UPDATES = [
  'Analyzing your sales data to uncover opportunities...',
  'Optimizing your ad spend across platforms for maximum ROI...',
  'Tracking customer behavior to boost sales...',
  'Generating real-time insights to keep you ahead...',
  'Optimizing your ad spend across platforms for maximum ROI...',
];

const UpdatesBanner = memo(({className}: Props) => {
  const {business} = useAuthContext();
  const {isAuthenticated} = useAppContext();
  const [now] = useState(dayjs());
  const nowStr = now.toISOString().slice(0, -1);
  const [{items}, setItems] = useState<{
    offset: number;
    items: UpdateRecord[];
  }>({
    offset: 0,
    items: [],
  });

  const variables = useMemo(
    () => ({
      filter: [
        {
          businessId: business?.id,
          visibleMarquee: true,
          visibleUntil: {$eq: null},
        },
        {
          businessId: business?.id,
          visibleMarquee: true,
          visibleUntil: {$gte: nowStr},
        },
      ],
      order: {
        createdAt: 'desc',
      },
      first: 8,
    }),
    [business?.id, nowStr],
  );

  const {data, loading} = useQuery(GET_EVENT_LOGS, {
    variables,
    skip: !isAuthenticated || !business?.id,
  });

  const updatesLog = useMemo(() => {
    let list =
      data?.connection?.edges.map(e => e.node.summary)?.filter(e => e) ?? [];

    if (!list.length && !loading) {
      list = DEFAULT_UPDATES;
    }

    if (list.length < SIZE) {
      list = circularArrayFill(list, SIZE, 0);
    }

    return list.map(item => ({
      text: item ?? '',
      key: `${Math.random() * Math.random() * Date.now()}`,
    }));
  }, [data?.connection, loading]);
  const isShown = Boolean(updatesLog.length);

  useEffect(() => {
    setItems({
      offset: 0,
      items: updatesLog,
    });
  }, [updatesLog]);

  const shiftItems = useCallback(() => {
    setItems(({offset, items}) => {
      const newItems = circularArrayFill(updatesLog, SIZE, offset + 1);

      if (newItems.length) {
        newItems[items.length - 1].key =
          `${Math.random() * Math.random() * Date.now()}`;
      }

      return {
        offset: (offset + 1) % items.length,
        items: newItems,
      };
    });
  }, [updatesLog]);

  if (!isShown) {
    return null;
  }

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}>
      <div
        className={twMerge(
          `relative flex gap-4 overflow-hidden rounded-[1.5rem] bg-[#F7F7FA] p-6`,
          className,
        )}>
        <AnimatedBackground className="absolute inset-0 overflow-visible bg-transparent" />
        <div className="flex gap-4">
          <OrbAnimation
            className="aspect-square h-[4.25rem] scale-[168%]"
            play
            speed={1.5}
          />
        </div>
        <div className="relative h-full w-full grow bg-transparent">
          {items.map((item, index) => (
            <UpdateRecord
              key={item.key}
              index={index}
              isActive={index === 2}
              text={item.text}
              className="flex w-full bg-transparent"
              shiftItems={shiftItems}
            />
          ))}
        </div>
      </div>
    </motion.div>
  );
});

UpdatesBanner.displayName = 'UpdatesBanner';

export default UpdatesBanner;
