import {Trans} from '@lingui/macro';
import {Button} from '@nextui-org/react';
import {memo, useCallback} from 'react';
import ExplainIcon from './icons/ExplainIcon';
import {twMerge} from 'tailwind-merge';
import {useCreateSessionWithMessage} from './Chat/hooks';
import {UserChatMessageType} from '@/__generated__/graphql';
import {useChatInputContext} from '@/hooks';

interface Props {
  prompt?: string | null;
  content?: string | null;
  colors?: 'normal' | 'inverted';
  templateName: string;
  className?: string;
  classNames?: {
    content?: string;
    icon?: string;
  };
}

const ExplainButton = memo(
  ({
    prompt,
    content,
    templateName,
    colors = 'normal',
    className,
    classNames,
  }: Props) => {
    const {setText} = useChatInputContext();
    const resetText = useCallback(() => {
      setText('');
    }, [setText]);
    const [createSession, {loading}] = useCreateSessionWithMessage(resetText);

    const onPress = useCallback(() => {
      if (!prompt || !templateName) {
        return;
      }

      createSession({
        variables: {
          input: {
            type: UserChatMessageType.Explain,
            prompt,
            content,
            agentTemplate: templateName,
            hidden: true,
            extraContent: JSON.stringify({
              page: 'main',
            }),
          },
        },
      });
    }, [prompt, content, templateName, createSession]);

    if (!prompt) {
      return null;
    }

    return (
      <Button
        aria-label="Explain"
        as="div"
        style={{
          background:
            colors === 'inverted'
              ? 'linear-gradient(90deg, #943FFF 0%, #6C3EF0 50%, #474EFF 100%)'
              : undefined,
        }}
        className={twMerge(
          'h-[1.75rem] min-h-0 w-auto min-w-0 shrink-0 rounded-full bg-[#F7F7FA] px-3',
          className,
        )}
        startContent={
          <ExplainIcon
            color={colors === 'inverted' ? '#FFF' : undefined}
            className={twMerge('h-4 w-4', classNames?.icon)}
          />
        }
        disableAnimation
        disableRipple
        isDisabled={loading}
        onPress={onPress}>
        <div
          style={{
            backgroundImage:
              colors === 'inverted'
                ? undefined
                : 'linear-gradient(90deg, #943FFF 0%, #6C3EF0 50%, #474EFF 100%)',
          }}
          className={twMerge(
            'text-xs font-medium',
            colors === 'inverted'
              ? 'text-background'
              : 'bg-clip-text text-transparent',
            classNames?.content,
          )}>
          <Trans>Explain</Trans>
        </div>
      </Button>
    );
  },
);

ExplainButton.displayName = 'ExplainButton';

export default ExplainButton;
