import {SVGProps} from 'react';

export type Direction = 'left' | 'right' | 'up' | 'down';

export default function CarouselArrowIcon({
  direction,
  ...props
}: {direction: Direction} & SVGProps<SVGSVGElement>) {
  const rotation = (() => {
    switch (direction) {
      case 'right':
        return 'rotate(0deg)';
      case 'down':
        return 'rotate(90deg)';
      case 'left':
        return 'rotate(180deg)';
      case 'up':
        return 'rotate(270deg)';
    }
  })();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{transform: rotation}}
      {...props}>
      <path
        d="M4 12H20M20 12L14 6M20 12L14 18"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
