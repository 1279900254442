import {Widget} from './sections/types';
import ExplainButton from '@/components/ExplainButton';
import {ReactNode} from 'react';

interface Props {
  widget: Widget;
  appName?: string;
  children: ReactNode;
  isDrillDown?: boolean;
  updatedAtNode?: ReactNode;
}

const WidgetContainer = ({
  widget,
  isDrillDown,
  appName,
  children,
  updatedAtNode,
}: Props) => {
  const hasTitle = Boolean(widget.title) && isDrillDown;
  const styleConfig =
    widget.style_config?.[isDrillDown ? 'DrillDown' : 'Preview'];

  return (
    <div
      style={{
        ...(styleConfig?.style?.container ?? {}),
      }}
      className={`flex flex-col`}>
      {hasTitle && (
        <div
          className={`flex flex-col gap-2 p-6`}
          style={styleConfig?.style?.title}>
          <div className="flex gap-2">
            <div className="flex-1 text-lg font-semibold text-foreground">
              {widget.title}
            </div>
            {widget.explain && appName ? (
              <ExplainButton
                templateName={appName}
                colors={styleConfig?.explain_button_colors}
                {...widget.explain}
                className="bg-background"
              />
            ) : null}
          </div>
          {updatedAtNode}
        </div>
      )}
      <div
        className="flex w-full flex-col gap-4 px-6"
        style={styleConfig?.style?.content}>
        {children}
      </div>
    </div>
  );
};

export default WidgetContainer;
