import {useMatchesTWBreakpoint} from '@/hooks';
import {TableData} from './unlocked/components/sections/types';
import {useMemo} from 'react';

export const useNumberOfSlides = () => {
  const isLargeScreen = useMatchesTWBreakpoint('lg');
  const isSmallScreen = useMatchesTWBreakpoint('sm');

  return isLargeScreen ? 3 : isSmallScreen ? 2 : 1;
};

export const useTableData = (
  table: TableData | undefined,
  isPreview: boolean,
) => {
  const limit = 5;
  const columns = useMemo(
    () =>
      table?.header.map(i => ({
        key: i,
        label: i,
        align: table.rows.every(r => typeof r[i] === 'number')
          ? 'text-right'
          : 'text-left',
      })) ?? [],
    [table?.header, table?.rows],
  );
  const exceedsLimit = table && table.rows.length > limit;
  const items = useMemo(() => {
    let list = table?.rows || [];

    if (isPreview && exceedsLimit) {
      list = list.slice(0, limit);
    }

    return list.map((r, idx) => ({...r, _key: idx}));
  }, [table?.rows, isPreview, exceedsLimit]);

  return {exceedsLimit, columns, items};
};
