import {memo, useCallback} from 'react';
import {Button, Checkbox} from '@nextui-org/react';
import {type AccountSelectorEntry} from './AccountSelectorView';
import {getActionImage} from '@/common/integrations';
import AlertPopover from '@/components/AlertPopover';

interface Props extends AccountSelectorEntry {
  onPress: () => void;
}

const AccountSelectorItem = memo(
  ({
    title,
    subtitle,
    errorStatus,
    errorDetail,
    isSelected,
    isDisabled,
    onPress: onPressProps,
  }: Props) => {
    const onPress = useCallback(() => {
      if (!isDisabled) {
        onPressProps();
      }
    }, [isDisabled, onPressProps]);

    return (
      <Button
        aria-label="Account selector"
        disableAnimation
        disableRipple
        aria-disabled={isDisabled}
        className={`flex h-auto max-h-none w-full flex-row items-start gap-4 rounded-lg bg-transparent p-2 !opacity-100 ${isDisabled ? 'cursor-auto' : 'data-[hover=true]:bg-foreground/5'}`}
        onPress={onPress}>
        <div className="relative flex h-11 w-11 items-center justify-center rounded bg-foreground/5 p-[0.375rem]">
          <img
            className="object-contain"
            src={getActionImage('google_ads')}
            alt={'Google Ads'}
          />
          <AlertPopover text={errorDetail} />
        </div>
        <div className="flex flex-[1_0_auto] flex-col items-start gap-1 text-sm">
          {title ? <div>{title}</div> : null}
          {subtitle ? (
            <div className="text-foreground/50">{subtitle}</div>
          ) : null}
          {errorStatus ? (
            <div className="text-[#F00]">{errorStatus}</div>
          ) : null}
        </div>
        <div className="flex max-h-11 min-h-11 items-center justify-center">
          <Checkbox
            isSelected={isSelected}
            isDisabled={isDisabled}
            className="m-0 px-0 py-1 [&>*]:text-small"
            radius="sm"
            onValueChange={onPress}
          />
        </div>
      </Button>
    );
  },
);

AccountSelectorItem.displayName = 'AccountSelectorItem';

export default AccountSelectorItem;
