import {memo, PropsWithChildren, useMemo, useState} from 'react';
import {Button, Accordion, AccordionItem} from '@nextui-org/react';
import {msg, Trans} from '@lingui/macro';
import {useAuthContext} from '@/hooks';
import {gql} from '@/__generated__';
import {useMutation} from '@apollo/client';
import {IntegrationType} from '@/common/types';
import {useLingui} from '@lingui/react';
import {isUrl} from '@/utils';
import AvailableIntegration, {
  AvailableIntegrationProps,
} from './AvailableIntegration';
import NextUIUrlInput from '../inputs/NextUIUrlInput';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '../Modal';
import {AnimatePresence, motion} from 'framer-motion';
import PortalSpinner from '../PortalSpinner';
import ChevronLeftIcon from '../icons/ChevronLeftIcon';
import {URL_INPUT_PROPS} from '../inputs';

const CONNECT_SHOPIFY_INTEGRATION = gql(`
  mutation ConnectShopifyIntegration($input: ShopifyIntegrationConnectInput!) {
    href: connectShopifyIntegration(input: $input)
  }
`);
const SHOPIFY_STORE_SUFFIX = '.myshopify.com';

type Props = {
  isActive?: boolean;
  callbackPath: string;
  view?: 'default' | 'modal';
} & Omit<AvailableIntegrationProps, 'integrationType' | 'isActive' | 'ref'>;

const ConnectShopify = memo(
  ({
    isActive = false,
    isDisabled = false,
    callbackPath,
    view = 'default',
    children,
    ...triggerProps
  }: PropsWithChildren<Props>) => {
    const {_} = useLingui();
    const [isShowForm, setIsShowForm] = useState(false);
    const [shopUrl, setShopUrl] = useState('');
    const [shopUrlTouched, setShopUrlTouched] = useState(false);
    const {business} = useAuthContext();
    const [connect, {loading}] = useMutation(CONNECT_SHOPIFY_INTEGRATION, {
      onCompleted: data => {
        window.location.href = data.href;
      },
    });
    const shopUrlError = useMemo(() => {
      if (!shopUrlTouched) {
        return null;
      }

      if (!shopUrl) {
        return _(msg`Required`);
      }
      if (shopUrl.toLowerCase().includes(SHOPIFY_STORE_SUFFIX)) {
        return _(msg`Do not include ${SHOPIFY_STORE_SUFFIX}`);
      }
      if (!isUrl(shopUrl + SHOPIFY_STORE_SUFFIX)) {
        return _(msg`Invalid URL`);
      }

      return null;
    }, [shopUrl, _, shopUrlTouched]);

    const renderHeader = () => (
      <div className="flex min-h-8 items-center">
        <p className="text-lg font-semibold text-foreground">
          <Trans>Connect Shopify</Trans>
        </p>
      </div>
    );
    const renderBody = () => (
      <>
        <NextUIUrlInput
          label={_(msg`Your Shopify URL`)}
          {...URL_INPUT_PROPS}
          value={shopUrl}
          onValueChange={setShopUrl}
          onBlur={() => setShopUrlTouched(true)}
          isInvalid={Boolean(shopUrlError)}
          errorMessage={shopUrlError}
          endContent={
            <div className="flex min-h-full items-center border-l-[thin] border-default-200 pl-3 text-sm text-foreground/75">
              {SHOPIFY_STORE_SUFFIX}/
            </div>
          }
        />
        <Accordion
          isCompact
          className="rounded-lg bg-foreground/[0.03] px-5 py-2.5">
          <AccordionItem
            classNames={{
              trigger: 'py-0',
              title: 'text-sm font-medium',
              content: 'text-sm pt-5',
            }}
            key="1"
            indicator={({isOpen}) => (
              <ChevronLeftIcon
                className={`h-4 w-4 transition-[rotate] ${isOpen ? 'rotate-180' : '-rotate-90'}`}
              />
            )}
            aria-label="Where can I find my Shopify URL?"
            title={<Trans>Where can I find my Shopify URL?</Trans>}>
            <Trans>
              1. Go to https://admin.shopify.com/
              <br />
              2. Pick the store you want to connect in the top right corner
              <br />
              3. The navigation bar will have a URL that looks something like
              this:
              <br />- https://admin.shopify.com/store/<b>my-store-name</b>
              <br />
              4. Copy the store name, for example <b>my-store-name</b> and paste
              it into the input field
            </Trans>
          </AccordionItem>
        </Accordion>
      </>
    );

    const renderFooter = () => (
      <Button
        aria-label="Connect Shopify"
        isDisabled={!shopUrl}
        fullWidth
        type="submit"
        variant="solid"
        radius="sm"
        color="primary"
        className="text-base font-semibold text-background"
        spinner={<PortalSpinner size="sm" />}
        isLoading={loading}
        onPress={() => {
          if (shopUrlError) {
            return;
          }

          connect({
            variables: {
              input: {
                shopUrl: shopUrl + SHOPIFY_STORE_SUFFIX,
                callbackPath,
                businessId: business?.id ?? '',
              },
            },
          });
        }}>
        <Trans>Connect</Trans>
      </Button>
    );

    if (view === 'default') {
      return (
        <AnimatePresence initial={false} mode="wait">
          {isShowForm ? (
            <motion.div
              className="flex flex-col gap-4"
              key="form"
              initial={{opacity: 0, height: '7rem'}}
              animate={{opacity: 1, height: 'auto'}}
              exit={{opacity: 0, height: '7rem'}}>
              <div className="flex flex-col gap-4">{renderBody()}</div>
              <div>{renderFooter()}</div>
              <Button
                aria-label="Back"
                radius="sm"
                fullWidth
                variant="light"
                onPress={() => setIsShowForm(false)}>
                Back
              </Button>
            </motion.div>
          ) : (
            <motion.div
              key="trigger"
              className="flex h-[7rem] flex-col gap-6"
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}>
              <AvailableIntegration
                integrationType={IntegrationType.shopify}
                isDisabled={loading || isActive || isDisabled}
                isActive={isActive}
                onPress={() => setIsShowForm(true)}
                {...triggerProps}
              />
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      );
    }

    return (
      <>
        <AvailableIntegration
          integrationType={IntegrationType.shopify}
          isDisabled={loading || isActive || isDisabled}
          isActive={isActive}
          onPress={() => setIsShowForm(true)}
          {...triggerProps}
        />
        <Modal isOpen={isShowForm} onOpenChange={setIsShowForm}>
          <ModalContent>
            <ModalHeader>{renderHeader()}</ModalHeader>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  },
);

ConnectShopify.displayName = 'ConnectShopify';

export default ConnectShopify;
