import {i18n} from '@lingui/core';

export const locales = {
  en: 'English',
  // uk: 'Ukrainian',
};

const navigatorLocale = navigator.language.toLowerCase().split('-')[0];

export const defaultLocale = Object.keys(locales).includes(navigatorLocale)
  ? navigatorLocale
  : 'en';

export const getCurrentLocale = () => {
  const locale = localStorage.getItem('locale');

  if (locale && Object.keys(locales).includes(locale)) return locale;

  return defaultLocale;
};

export const setCurrentLocale = (locale: string) => {
  localStorage.setItem('locale', locale);
  dynamicActivate(locale);
};

export const dynamicActivate = async (localeToActivate?: string) => {
  const locale = localeToActivate || getCurrentLocale();
  const {messages} = await import(`./locales/${locale}.po`);

  i18n.load(locale, messages);
  i18n.activate(locale);
};
