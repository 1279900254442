import dayjs from '@/common/dayjs';
import {LRUCache} from 'lru-cache';
import axios from 'axios';
import {
  LS_AUTH_TOKEN_KEY,
  LS_INITIAL_QS_AFTER_LOGIN,
  LS_REFRESH_TOKEN_KEY,
  LS_UNAUTHENTICATED_CHAT_SESSION_IDS,
  PERSIST_FORMS_IN_SS,
} from './config';
import {AppProviderState} from './contexts';
import {ApolloClient} from '@apollo/client';
import {instrumentTracker} from './common/instrumentTracker';
import startCase from 'lodash/startCase';
import {
  User,
  Business,
  FormPersistKey,
  RiskIndexGroup,
  TokenResponse,
  ErrorURLParam,
} from '@/common/types';
import {riskIndecies} from './common/constants';

const dateCache = new LRUCache<
  string | number | Date | dayjs.Dayjs,
  dayjs.Dayjs
>({
  max: 300,
});

export function getDayjsDate(
  date?: string | number | Date | dayjs.Dayjs | null,
  withTZOffset = true,
): dayjs.Dayjs | undefined {
  if (!date) {
    return;
  }

  const cacheKey = `${(() => {
    if (typeof date === 'string') {
      return date;
    }

    if (date instanceof Date || date instanceof dayjs.Dayjs) {
      return date.toISOString();
    }

    return date;
  })()}_${withTZOffset}`;

  if (dateCache.has(cacheKey)) {
    return dateCache.get(cacheKey);
  }

  const dayjsTZDate = dayjs(date);

  if (!dayjsTZDate.isValid()) {
    return;
  }

  const dayjsDate = withTZOffset
    ? dayjsTZDate.add(dayjsTZDate.utcOffset(), 'minute')
    : dayjsTZDate;

  dateCache.set(cacheKey, dayjsDate);

  return dayjsDate;
}

export function JSONSafeParse<T = any>(
  text: string,
  reviver?: (this: any, key: string, value: any) => any,
): T | undefined {
  try {
    return JSON.parse(text, reviver);
  } catch (error) {
    return;
  }
}

let refreshRequest: Promise<string> | undefined;

const refreshAccessToken = async (
  refreshToken: string,
  login: AppProviderState['login'],
  logout: AppProviderState['logout'],
  client: ApolloClient<any> | undefined,
): Promise<string> => {
  try {
    const form = new FormData();

    form.append('grant_type', 'refresh_token');
    form.append('refresh_token', refreshToken ?? '');

    const resp = await axios.post<TokenResponse>('token', form);
    const accessToken = resp.data?.access_token;

    if (!accessToken) {
      throw new Error('Unauthorized');
    }

    instrumentTracker.setUserId(resp.data.user?.id);
    login(resp.data);

    return accessToken;
  } catch (error) {
    logout(client);

    throw new Error('Unauthorized');
  } finally {
    refreshRequest = undefined;
  }
};

export const getAccessToken = () => {
  return localStorage.getItem(LS_AUTH_TOKEN_KEY);
};

export async function fetchNewToken(
  login: AppProviderState['login'],
  logout: AppProviderState['logout'],
  client: ApolloClient<any> | undefined,
): Promise<string> {
  const refreshToken = localStorage.getItem(LS_REFRESH_TOKEN_KEY);
  const authToken = localStorage.getItem(LS_AUTH_TOKEN_KEY);

  if (!authToken || !refreshToken) {
    logout(client);

    throw new Error('Unauthorized');
  }

  if (refreshRequest) {
    return refreshRequest;
  }

  refreshRequest = refreshAccessToken(refreshToken, login, logout, client);

  return await refreshRequest;
}

export function getCountryCodeByBrowserLocale(): string | undefined {
  const locale = navigator.language || navigator.languages[0];

  if (!locale) {
    return;
  }

  const countryCode = locale.split('-')[1];

  return countryCode?.toUpperCase() ?? undefined;
}

export const getPersistedForm = <T extends object = Record<string, any>>(
  name: FormPersistKey,
) => {
  const maybeState = PERSIST_FORMS_IN_SS
    ? window.sessionStorage.getItem(name)
    : window.localStorage.getItem(name);

  if (maybeState) {
    const data = JSON.parse(maybeState) as any;

    return data?.values ? (data.values as T) : undefined;
  }

  return;
};

export const deletePersistedForm = (name: FormPersistKey) => {
  if (PERSIST_FORMS_IN_SS) {
    window.sessionStorage.removeItem(name);
  } else {
    window.localStorage.removeItem(name);
  }
};

export const deleteSignupPersistedForms = () => {
  deletePersistedForm('loginSignup');
  deletePersistedForm('loginSetBusiness');
};

export const CSSLerp = (t: number, min: string, max: string) => {
  return `(${min}) * (1 - max(min(${t}, 1), 0)) + (${max}) * max(min(${t}, 1), 0)`;
};

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const isUrl = (url?: string) => {
  if (!url) {
    return true;
  }

  try {
    try {
      new URL(url);
      return true;
    } catch (e) {
      new URL(`http://${url}`);
      return true;
    }
  } catch (e) {
    return false;
  }
};

export async function blobToBase64(file: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
  });
}

interface AuthParam {
  access_token: string;
  refresh_token?: string;
  provider: string;
  signup: boolean;
  email: string;
  id: string;
}

export function decodeAuthParam(authParam: string): AuthParam | null {
  try {
    const decodedString = atob(authParam);

    return JSON.parse(decodedString) ?? null;
  } catch (error) {
    console.error('Failed to decode auth parameter:', error);
    return null;
  }
}

interface IntegrationConnection {
  type: string;
  success: boolean;
}

export const encodeIntegrationConnectionParam = (
  param: IntegrationConnection,
) => {
  return JSON.stringify(param);
};

export function decodeIntegrationConnectionParam(
  param: string,
): IntegrationConnection | null {
  try {
    return JSON.parse(param) ?? null;
  } catch (error) {
    console.error('Failed to decode auth parameter:', error);
    return null;
  }
}

export function getParamsAfterLogin(): URLSearchParams | undefined {
  try {
    const paramsString = sessionStorage.getItem(LS_INITIAL_QS_AFTER_LOGIN);

    if (!paramsString) {
      return;
    }

    return new URLSearchParams(paramsString);
  } catch {
    return;
  }
}

export function setParamsAfterLogin(params: URLSearchParams) {
  const paramsString = params.toString();

  try {
    sessionStorage.setItem(LS_INITIAL_QS_AFTER_LOGIN, paramsString);
  } catch {
    // pass
  }
}

export function deleteParamsAfterLogin() {
  try {
    sessionStorage.removeItem(LS_INITIAL_QS_AFTER_LOGIN);
  } catch {
    // pass
  }
}

export function getRiskIndexGroup(index: number): RiskIndexGroup {
  const rounded = Math.floor(index) ?? 0;

  return riskIndecies[rounded] ?? RiskIndexGroup.VeryLow;
}

export function getRiskIndexText(indexGroup: RiskIndexGroup) {
  return startCase(indexGroup);
}

export function getRiskIndexRating(indexGroup: RiskIndexGroup) {
  const idx = riskIndecies.indexOf(indexGroup);

  return Math.max(0.5, idx + 0.5);
}

export const getUserName = (user?: User, defaultName: string = '') => {
  return (
    `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim() || defaultName
  );
};

export const getUserInitials = (user?: User, defaultName: string = '') => {
  if (!user) {
    return defaultName;
  }

  return (
    [user.firstName?.[0], user.lastName?.[0]].filter(Boolean).join('') ??
    defaultName
  );
};

export const isToolUnlocked = (
  business?: Business | null,
  appName?: string,
) => {
  return business?.agents.edges.some(e => e.node.templateName === appName);
};

export const getUnauthenticatedSessionIds = (): string[] => {
  try {
    return (
      JSONSafeParse(
        localStorage.getItem(LS_UNAUTHENTICATED_CHAT_SESSION_IDS) || '[]',
      ) ?? []
    );
  } catch {
    return [];
  }
};

export function decodeErrorParam(errorParam: string): ErrorURLParam | null {
  try {
    const decodedString = atob(
      errorParam.replace(/-/g, '+').replace(/_/g, '/'),
    );

    return JSON.parse(decodedString) ?? null;
  } catch (error) {
    console.error('Failed to decode error parameter:', error);
    return null;
  }
}
