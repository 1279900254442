import {memo} from 'react';
import {useActiveTools} from '../hooks';
import BackButton from '@/components/buttons/BackButton';
import {Trans} from '@lingui/macro';
import CatalogueItem from '@/routes/Catalogue/components/CatalogueItem';

const LiveActiveTools = memo(() => {
  const {activeTools} = useActiveTools();

  return (
    <div className="flex w-full max-w-2xl flex-col">
      <div className="space-between flex max-w-full items-center gap-2 py-3">
        <div className="flex flex-1 justify-center">
          <BackButton className="mr-auto" to=".." />
        </div>
        <div className="flex-5 flex justify-center text-center text-medium font-semibold">
          <Trans>Active Tools</Trans>
        </div>
        <div className="flex flex-1" />
      </div>
      <div className="flex flex-col gap-11 py-6">
        {activeTools.map(tool => (
          <CatalogueItem key={tool.id} isUnlocked={true} {...tool} />
        ))}
      </div>
    </div>
  );
});

LiveActiveTools.displayName = 'LiveActiveTools';

export default LiveActiveTools;
