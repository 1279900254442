import {FC, memo, useMemo} from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {AnimatePresence, motion} from 'framer-motion';
import {Divider, Image} from '@nextui-org/react';
import PortalSpinner from '@/components/PortalSpinner';
import {ModalBody, ModalHeader} from '@/components/Modal';
import {UnlockProgress} from './UnlockProgress';
import {ToolUnlockStepId} from '@/common/types';
import {isToolUnlocked} from '@/utils';
import {useAuthContext, useToolContext} from '@/hooks';
import ExplainButton from '@/components/ExplainButton';
import {formByStepId} from '../config/formByStepId';

const UnlockStep: FC = memo(() => {
  const navigate = useNavigate();
  const {stepId} = useParams<{stepId: string}>();
  const {appName, agent, unlockTool, unlockSteps} = useToolContext();
  const {business} = useAuthContext();
  const stepIdx = useMemo(
    () => unlockSteps.findIndex(i => i.name === stepId),
    [stepId, unlockSteps],
  );
  const onSubmit = useMemo(() => {
    if (stepIdx === unlockSteps.length - 1) {
      return unlockTool;
    }

    const nextStepId = unlockSteps[stepIdx + 1]?.name;

    return async () => navigate(`../unlock/${nextStepId}`);
  }, [navigate, stepIdx, unlockSteps, unlockTool]);

  if (!agent || !business) {
    return (
      <PortalSpinner
        classNames={{base: 'backdrop-blur-sm fixed inset-0 z-[1000]'}}
      />
    );
  }

  const stepData = unlockSteps.find(i => i.name === stepId) ?? null;
  const StepForm = formByStepId[stepId! as ToolUnlockStepId];
  const isUnlocked = isToolUnlocked(business, appName!);

  if (!StepForm) {
    console.error(`No form found for step ${stepId}`);

    return <Navigate to=".." replace />;
  }

  if (isUnlocked) {
    return <Navigate to=".." replace />;
  }

  return (
    <>
      <ModalHeader className="!px-0 pb-0">
        <div
          style={{
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.05) 100%)',
          }}
          className="flex w-full gap-3 px-5 pb-5 md:px-10">
          <Image
            removeWrapper
            radius="sm"
            src={agent.logo?.url ?? undefined}
            alt={agent.name}
            width={56}
            height={56}
            className="flex shrink-0 self-start"
          />
          <div className="flex flex-col gap-1">
            <h3 className="text-sm font-semibold uppercase text-foreground/60">
              {agent.name}
            </h3>
            <h1 className="text-lg font-semibold text-primary-900">
              {stepData?.title}
            </h1>
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="max-w-lg gap-6">
        <UnlockProgress stepsLength={unlockSteps.length} stepIdx={stepIdx} />
        {stepData?.explain ? (
          <div className="flex w-full items-center gap-4">
            <Divider className="flex w-auto flex-[1_0_auto] bg-foreground/[0.08]" />
            <ExplainButton
              {...stepData.explain}
              templateName={agent.templateName}
              className="h-[2rem]"
              classNames={{
                content: 'text-sm',
                icon: 'h-5 w-5',
              }}
            />
            <Divider className="flex w-auto flex-[1_0_auto] bg-foreground/[0.08]" />
          </div>
        ) : (
          <Divider className="w-full bg-foreground/[0.08]" />
        )}
        <AnimatePresence mode="wait">
          <motion.div
            className="flex shrink flex-col items-center justify-center self-stretch"
            key={stepId}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.2}}>
            <StepForm showEmptyField onSubmit={onSubmit} />
          </motion.div>
        </AnimatePresence>
      </ModalBody>
    </>
  );
});

UnlockStep.displayName = 'UnlockStep';

export default UnlockStep;
