import {
  GetAgentConnectionQuery,
  ResponsiveImage,
} from '@/__generated__/graphql';
import BlurhashImage from '@/components/BlurHashImage';
import CheckVerifiedIcon from '@/components/icons/CheckVerifiedIcon';
import PuzzlePieceIcon from '@/components/icons/PuzzlePieceIcon';
import StarIcon from '@/components/icons/StarIcon';
import StarsIcon from '@/components/icons/StarsIcon';
import {useResponsiveImage} from '@/hooks';
import {Plural, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button, Card} from '@nextui-org/react';
import {memo, useCallback} from 'react';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';

type Props = GetAgentConnectionQuery['connection']['edges'][0]['node'] & {
  isUnlocked: boolean;
  className?: string;
};

type Stat =
  | {
      key: 'suggested';
      value: boolean;
    }
  | {
      key: 'unlocks';
      value: string;
    }
  | {
      key: 'rating';
      value: Props['rating'];
    }
  | {
      key: 'data';
      value: Props['dataSources'];
    }
  | {
      key: 'language';
      value: Props['languages'];
    };

const CatalogueItemImage = memo(
  ({
    image,
    onPress,
    ...restProps
  }: {
    image: ResponsiveImage;
    onPress?: () => void;
    [key: string]: any;
  }) => {
    const imageData = useResponsiveImage(image);

    if (!imageData?.url) {
      return null;
    }

    return (
      <Button
        disableRipple
        disableAnimation
        className="flex h-auto max-h-none w-full max-w-none shrink-0 rounded-[1.25rem] bg-transparent p-0 !opacity-100"
        onPress={onPress}>
        <BlurhashImage
          {...imageData}
          alt="App Image"
          scaleByWidth
          className="flex w-full shrink-0 rounded-[1.5rem] object-cover"
          {...restProps}
        />
      </Button>
    );
  },
);

CatalogueItemImage.displayName = 'CatalogueItemImage';

const CatalogueItem = memo(
  ({
    isUnlocked,
    templateName,
    logo,
    name,
    subtitle,
    unlocks,
    activationsText,
    rating,
    dataSources,
    languages,
    catalogueImages,
    cataloguePromoText,
    featured,
  }: Props) => {
    const location = useLocation();
    const {i18n} = useLingui();
    const navigate = useNavigate();
    const activations =
      activationsText ||
      i18n.number(unlocks, {
        maximumSignificantDigits: 2,
        maximumFractionDigits: Math.abs(unlocks) > 1 ? 1 : 2,
        notation: 'compact',
        compactDisplay: 'short',
      });
    const stats: Stat[] = [
      {key: 'suggested' as const, value: featured ?? false},
      {key: 'unlocks' as const, value: activations},
      {key: 'rating' as const, value: rating},
      {key: 'data' as const, value: dataSources},
      {key: 'language' as const, value: languages},
    ]
      .filter(stat => stat.value || stat.value === 0)
      .slice(0, 3);
    const statItemClass = 'flex gap-1 [&>svg]:w-4 [&>svg]:h-4 items-center';
    const linkUrl = `/catalog/${templateName}/${isUnlocked ? 'unlocked' : 'info'}`;
    const applyLinkUrl = linkUrl + (isUnlocked ? '' : '?showUnlock=true');
    const openTool = useCallback(() => {
      navigate(linkUrl, {state: {from: location}});
    }, [navigate, linkUrl, location]);

    return (
      <div className="flex w-full max-w-full flex-col gap-3 px-6">
        <Card
          aria-label="Open tool"
          isPressable
          disableAnimation
          disableRipple
          onPress={openTool}
          className="flex w-full max-w-full flex-row items-center gap-3 rounded-none border-none bg-transparent p-0 text-start shadow-none">
          {logo?.url ? (
            <img
              alt={name}
              className="aspect-square w-[4rem] self-center rounded-2xl"
              src={logo?.url}
            />
          ) : null}
          <div className="flex grow flex-col justify-center gap-1">
            <div className="line-clamp-1 overflow-hidden text-ellipsis break-all text-base font-medium text-foreground">
              {name}
            </div>
            <div className="line-clamp-1 overflow-hidden text-ellipsis break-all text-small text-default-500">
              {subtitle}
            </div>
          </div>
          <div className="flex items-center">
            <Button
              aria-label={isUnlocked ? 'Open' : 'Apply'}
              disableAnimation
              disableRipple
              className="max-h-auto min-h-0 min-w-0 rounded-full bg-[#F2F2F5] px-4 py-2 text-medium font-semibold text-primary"
              as={RouterLink}
              to={applyLinkUrl}>
              {isUnlocked ? 'Open' : 'Apply'}
            </Button>
          </div>
        </Card>
        <div className="flex w-full justify-between text-xs font-medium text-default-500">
          {stats.map(stat => {
            switch (stat.key) {
              case 'suggested':
                return stat.value ? (
                  <div key={stat.key} className={statItemClass}>
                    <StarsIcon />
                    <Trans>Featured</Trans>
                  </div>
                ) : null;
              case 'unlocks':
                return (
                  <div key={stat.key} className={statItemClass}>
                    <CheckVerifiedIcon />
                    <Trans>{stat.value} Activations</Trans>
                  </div>
                );
              case 'rating':
                return (
                  <div key={stat.key} className={statItemClass}>
                    <StarIcon />
                    <Trans>
                      {i18n.number(stat.value!, {
                        maximumSignificantDigits: 2,
                        maximumFractionDigits: 1,
                      })}
                    </Trans>
                  </div>
                );
              case 'data':
                return (
                  <div key={stat.key} className={statItemClass}>
                    <PuzzlePieceIcon />
                    {stat.value.length > 2 ? `2+` : stat.value.length}
                  </div>
                );
              case 'language':
                return (
                  <div key={stat.key} className={statItemClass}>
                    <Plural
                      value={stat.value.length}
                      one="# Language"
                      other="# Languages"
                    />
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
        <div
          style={{WebkitOverflowScrolling: 'touch'}}
          className={`relative -mx-6 flex w-[calc(100%+3rem)] items-stretch gap-3 overflow-auto px-6`}>
          {catalogueImages.map((image, index) => (
            <CatalogueItemImage key={index} image={image} onPress={openTool} />
          ))}
          {isUnlocked ? null : (
            <div className="flex w-full shrink-0 flex-col items-center justify-center gap-4 rounded-[1.25rem] bg-[#F5F5F7] p-4 text-center">
              {cataloguePromoText ? (
                <div className="max-w-[12rem] text-base font-medium">
                  {cataloguePromoText}
                </div>
              ) : null}
              <Button
                aria-label={isUnlocked ? 'Open' : 'Apply'}
                disableAnimation
                disableRipple
                as={RouterLink}
                to={applyLinkUrl}
                className="max-h-auto min-h-0 min-w-0 rounded-full bg-primary px-4 py-2 text-small font-semibold text-background">
                {isUnlocked ? 'Open' : 'Apply'}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  },
);

CatalogueItem.displayName = 'CatalogueItem';

export default CatalogueItem;
