import {memo} from 'react';
import {
  ActionsSection as ActionsSectionType,
  DecisidedActionsSection,
} from './types';
import {ActionList} from '../../action/ActionList';

const ActionsSection = memo(
  ({
    actions,
    className,
  }: {className?: string} & (ActionsSectionType | DecisidedActionsSection)) => {
    return (
      <ActionList
        className="flex flex-col gap-2 rounded-3xl"
        classNames={{action: className}}
        ids={actions.map(i => i.action_id)}
      />
    );
  },
);

ActionsSection.displayName = 'ActionsSection';

export default ActionsSection;
