const polarToCartesian = (
  cx: number,
  cy: number,
  r: number,
  angleInDegrees: number,
) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: cx + r * Math.cos(angleInRadians),
    y: cy + r * Math.sin(angleInRadians),
  };
};

export const buildProgressArc = (
  percent: number | null | undefined,
  size: number,
  strokeWidth: number,
  angleOffset: number = -180,
) => {
  const halfSize = size / 2;
  const radius = halfSize - strokeWidth / 2;
  const startAngle = 0 + angleOffset;
  const endAngle = Math.min(((percent ?? 0) / 100) * 360, 359.99) + angleOffset;
  const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
  const start = polarToCartesian(halfSize, halfSize, radius, endAngle);
  const end = polarToCartesian(halfSize, halfSize, radius, startAngle);

  return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`;
};
