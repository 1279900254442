import CloseButton from '@/components/Modal';
import {useToolContext} from '@/hooks';
import {memo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';

const TopicHeader = memo(
  ({className, title}: {className?: string; title?: string}) => {
    const {agent} = useToolContext();
    const {logo, name} = agent!;

    return (
      <div className={twMerge(`flex w-full flex-col px-6 py-4`, className)}>
        <div className="flex justify-start">
          <div className="flex flex-1 items-center gap-3">
            {logo?.url ? (
              <img
                className="flex aspect-square w-[3.25rem] shrink-0 self-center rounded-xl"
                src={logo?.url}
              />
            ) : null}
            <div className="flex flex-1 flex-col justify-between">
              {title ? (
                <h3 className="text-xs font-medium uppercase text-foreground/50">
                  {name}
                </h3>
              ) : null}
              <h1 className="text-lg font-semibold text-foreground">
                {title || name}
              </h1>
            </div>
          </div>
          {/* @ts-expect-error it's ok */}
          <CloseButton className="shrink-0" as={RouterLink} to=".." />
        </div>
      </div>
    );
  },
);

TopicHeader.displayName = 'TopicHeader';

export default TopicHeader;
