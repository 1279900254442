import {memo} from 'react';
import {Link as RouterLink, To} from 'react-router-dom';
import ChevronLeftIcon from '../icons/ChevronLeftIcon';
import {Trans} from '@lingui/macro';
import {Button} from '@nextui-org/react';
import {twMerge} from 'tailwind-merge';

const BackButton = memo(
  ({to = -1, className}: {to?: To | number; className?: string}) => {
    return (
      <Button
        aria-label="Back"
        as={RouterLink}
        // @ts-expect-error - typing issue
        to={to}
        disableAnimation
        disableRipple
        className={twMerge(
          'h-auto min-h-0 w-auto min-w-0 gap-0 rounded-none bg-transparent p-0 text-medium font-medium text-primary',
          className,
        )}
        startContent={<ChevronLeftIcon className="h-7 w-7 shrink-0" />}>
        <Trans>Back</Trans>
      </Button>
    );
  },
);

BackButton.displayName = 'BackButton';

export default BackButton;
