import {memo} from 'react';
import {BannerSection as BannerSectionType} from './types';
import {twMerge} from 'tailwind-merge';
import ExplainButton from '@/components/ExplainButton';
import {Button} from '@nextui-org/react';

type Props = {
  isDrillDown?: boolean;
  appName?: string;
  className?: string;
} & BannerSectionType;

const BannerSection = memo(
  ({background_url, banner, isDrillDown, appName, className}: Props) => {
    return (
      <div
        className={twMerge(
          'relative flex min-h-[7rem] flex-col overflow-hidden bg-background',
          isDrillDown ? 'rounded-2xl border-1 border-background' : '',
          className,
        )}>
        <img src={background_url} className="h-auto w-full" />
        <div
          style={{background: banner.background}}
          className="flex w-full flex-col gap-4 p-6 pt-0">
          <div className="flex w-full justify-between gap-1">
            <div className="text-xl font-semibold">{banner.title}</div>
            {appName && banner?.explain ? (
              <ExplainButton
                templateName={appName}
                {...banner.explain}
                className="bg-background px-2"
              />
            ) : null}
          </div>
          <div className="flex w-full flex-col">
            <div className="text-xs">{banner.description}</div>
            {banner.link
              ? (() => {
                  switch (banner.link.href) {
                    case '#recalculate_margin':
                      return (
                        <Button
                          aria-label="Recalculate margin"
                          as={'div'}
                          disableAnimation
                          disableRipple
                          className="h-auto min-h-0 min-w-0 items-start self-start bg-transparent p-0 text-start text-xs text-primary"
                          onPress={() => {
                            const element = document.querySelector(
                              banner.link!.href!,
                            );

                            if (!element) {
                              return;
                            }

                            element.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }}>
                          {banner.link?.text}
                        </Button>
                      );
                  }
                })()
              : null}
          </div>
        </div>
      </div>
    );
  },
);

BannerSection.displayName = 'BannerSection';

export default BannerSection;
