import {memo, useState} from 'react';
import {Formik, Form, Field} from 'formik';
import {object, string} from 'yup';
import {Button, Checkbox} from '@nextui-org/react';
import {useLingui} from '@lingui/react';
import {Trans, msg} from '@lingui/macro';
import {usePasswordRequirements, useToastQueue} from '@/hooks';
import ErrorMessage from '@/components/ErrorMessage';
import ShowPasswordButton from '@/components/ShowPasswordButton';
import {ApolloError} from '@apollo/client';
import {formatApolloError} from '../helpers';
import NextUIInput from '@/components/inputs/NextUIInput';
import PortalSpinner from '@/components/PortalSpinner';
import {LegalFooter} from './LegalFooter';

type Values = {
  firstName: string;
  lastName: string;
  password: string;
  newsletter: boolean;
};

const EmailProfileForm = memo(
  ({onSubmit}: {onSubmit: (values: Values) => Promise<void>}) => {
    const [error, setError] = useState<string | undefined>();
    const {_} = useLingui();
    const {addErrorToast} = useToastQueue();
    const [showPassword, setShowPassword] = useState(false);
    const {getPasswordRequirementsValidator, renderPasswordRequirements} =
      usePasswordRequirements();

    return (
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          password: '',
          newsletter: false,
        }}
        validationSchema={object({
          firstName: string()
            .min(1, _(msg`First name is too short`))
            .required(_(msg`Required`)),
          lastName: string()
            .min(1, _(msg`Last name is too short`))
            .required(_(msg`Required`)),
          password: getPasswordRequirementsValidator(),
        })}
        onSubmit={async values => {
          setError(undefined);

          try {
            await onSubmit(values);
          } catch (e: any) {
            if (e instanceof ApolloError) {
              setError(formatApolloError(e) || undefined);
            } else {
              const {data} = e.response || {};
              const message = data.detail || e.message;

              setError(message);
              addErrorToast({message});
            }
          }
        }}>
        {({isSubmitting, touched, errors, values, setFieldValue}) => {
          const isFirstNameInvalid = Boolean(
            touched.firstName && errors.firstName,
          );
          const isLastNameInvalid = Boolean(
            touched.lastName && errors.lastName,
          );
          const isPasswordInvalid = Boolean(
            touched.password && errors.password,
          );

          return (
            <Form className="flex min-h-[5.25rem] w-full flex-col gap-6">
              <div className="flex gap-3">
                <Field
                  as={NextUIInput}
                  name="firstName"
                  placeholder={_(msg`First name`)}
                  label={_(msg`First name`)}
                  isInvalid={isFirstNameInvalid}
                  errorMessage={
                    isFirstNameInvalid ? errors.firstName : undefined
                  }
                  isDisabled={isSubmitting}
                />
                <Field
                  as={NextUIInput}
                  name="lastName"
                  variant="bordered"
                  placeholder={_(msg`Last name`)}
                  label={_(msg`Last name`)}
                  isInvalid={isLastNameInvalid}
                  errorMessage={isLastNameInvalid ? errors.lastName : undefined}
                  isDisabled={isSubmitting}
                />
              </div>
              <Field
                as={NextUIInput}
                name="password"
                type={showPassword ? 'text' : 'password'}
                label={_(msg`Password`)}
                placeholder={_(msg`Password`)}
                endContent={
                  <ShowPasswordButton
                    showPassword={showPassword}
                    onPress={setShowPassword}
                  />
                }
                classNames={{
                  inputWrapper: 'pr-0.5',
                }}
                isInvalid={isPasswordInvalid}
                errorMessage={isPasswordInvalid ? errors.password : undefined}
                isDisabled={isSubmitting}
              />
              {renderPasswordRequirements({password: values.password})}
              <div className="flex w-full flex-col gap-6">
                <Checkbox
                  name="newsletter"
                  isSelected={values.newsletter}
                  onChange={e => setFieldValue('newsletter', e.target.checked)}
                  className="m-0 px-0 py-1"
                  classNames={{
                    label: 'text-small text-foreground',
                    icon: 'text-background',
                  }}>
                  <Trans>
                    I agree to receive emails with updates, tips, and special
                    offers about Portal Al
                  </Trans>
                </Checkbox>
              </div>

              <ErrorMessage message={error} />

              <Button
                aria-label="Continue"
                fullWidth
                type="submit"
                variant="solid"
                color="primary"
                radius="sm"
                className="font-medium text-background"
                spinner={<PortalSpinner size="sm" />}
                isLoading={isSubmitting}
                isDisabled={isSubmitting}>
                <Trans>Continue</Trans>
              </Button>
              <LegalFooter />
            </Form>
          );
        }}
      </Formik>
    );
  },
);

EmailProfileForm.displayName = 'EmailProfileForm';

export default EmailProfileForm;
