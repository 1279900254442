import {FC, memo, useCallback, useEffect, useState} from 'react';
import {useAppContext, useAuthContext} from '@/hooks';
import {Navigate, useLocation} from 'react-router-dom';
import {gql} from '@/__generated__';
import {useMutation} from '@apollo/client';
import EmailProfileForm from '../components/EmailProfileForm';
import {signinWithPassword} from '../helpers';
import {reportEmailSignUp} from '@/common/analytics';
import {instrumentTracker} from '@/common/instrumentTracker';

const SIGNUP_MUTATION = gql(`
  mutation Signup($input: SignupInput!) {
    signup(input: $input) {
      id
      firstName
      lastName
      email
    }
  }
`);

const LoginProfile: FC = memo(() => {
  const {login} = useAppContext();
  const {setSignupEmail} = useAuthContext();
  const [signedUpEmail, setSignedUpEmail] = useState<string | undefined>();
  const {state} = useLocation();

  const handleLogin = useCallback(
    async ({email, password}: {email: string; password: string}) => {
      return signinWithPassword(email, password, true).then(({data}) => {
        login(data);
      });
    },
    [login],
  );
  const [signup] = useMutation(SIGNUP_MUTATION, {
    refetchQueries: ['GetUser'],
    onCompleted: ({signup}) => {
      instrumentTracker.setUserId(signup.id);
      reportEmailSignUp();
    },
  });

  const onSubmit = useCallback(
    async (values: {
      firstName: string;
      lastName: string;
      password: string;
      newsletter: boolean;
    }) => {
      if (signedUpEmail) {
        return await handleLogin({
          email: signedUpEmail,
          password: values.password,
        });
      }

      const signupResult = await signup({
        variables: {
          input: {
            ...values,
            state: state!,
          },
        },
      });

      if (signupResult.errors) {
        throw signupResult.errors;
      }

      if (!signupResult.data) {
        return;
      }

      const {email} = signupResult.data.signup;

      setSignedUpEmail(email);

      return await handleLogin({
        email,
        password: values.password,
      });
    },
    [signedUpEmail, state, signup, handleLogin],
  );

  useEffect(() => {
    setSignupEmail('');
  }, []);

  if (!state) {
    return <Navigate to="../.." replace />;
  }

  return <EmailProfileForm onSubmit={onSubmit} />;
});

LoginProfile.displayName = 'LoginProfile';

export default LoginProfile;
