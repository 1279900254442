import * as amplitude from '@amplitude/analytics-browser';
import {
  EnrichmentPlugin,
  Event,
  SpecialEventType,
} from '@amplitude/analytics-types';
import * as Sentry from '@sentry/react';
import {useEffect} from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {
  SENTRY_DSN,
  ENVIRONMENT,
  AMPLITUDE_API_KEY,
  AMPLITUDE_OPT_OUT,
  SENTRY_OPT_OUT,
} from '@/config.ts';
import {utmPropertiesVar, UTM_PROPERTY_NAMES} from './utmProperties';

class EventUtmEnrichmentPlugin implements EnrichmentPlugin {
  name = 'event-utm-enrichment-plugin';
  type = 'enrichment' as any;

  async setup(): Promise<void> {
    this.setUtmProperties(new URLSearchParams(window.location.search));

    return undefined;
  }

  async execute(event: Event): Promise<Event> {
    if (event.event_type === SpecialEventType.IDENTIFY) {
      this.setUtmProperties(Object.entries(event.user_properties ?? {}));
    } else {
      event.event_properties = {
        ...utmPropertiesVar(),
        ...(event.event_properties ?? {}),
      };
    }

    return event;
  }

  private setUtmProperties(entries: Iterable<string[]>) {
    const utmEntries = [...entries].filter(([key]) =>
      UTM_PROPERTY_NAMES.has(key),
    );

    utmPropertiesVar(Object.fromEntries(utmEntries));
  }
}

amplitude.add(new EventUtmEnrichmentPlugin());

amplitude.init(AMPLITUDE_API_KEY, {
  autocapture: {elementInteractions: true},
  optOut: AMPLITUDE_OPT_OUT,
});

if (!SENTRY_OPT_OUT) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/\//, /https:\/\/app.portal\.ai/],

    // Capture Replay for 1% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  });
}
