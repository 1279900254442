import {getSmallIntegrationIcon} from '@/common/integrations';
import {IntegrationType} from '@/common/types';
import {useErrorParamHandlerContext} from '@/hooks';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button, ButtonProps} from '@nextui-org/react';
import {forwardRef, useCallback} from 'react';
import AlertPopover from '../AlertPopover';
import RefreshCcw01Icon from '../icons/RefreshCcw01Icon';
import {twJoin, twMerge} from 'tailwind-merge';

export type AvailableIntegrationProps = {
  integrationType: IntegrationType;
  isActive?: boolean;
  subtitle?: string;
  classNames?: {
    iconContainer?: string;
    title?: string;
    button?: string;
  };
} & Omit<ButtonProps, 'children' | 'classNames'>;

const AvailableIntegration = forwardRef<
  HTMLButtonElement,
  AvailableIntegrationProps
>(
  (
    {
      integrationType,
      className,
      subtitle,
      isActive = false,
      isDisabled = false,
      classNames = {},
      ...props
    },
    ref,
  ) => {
    const {_} = useLingui();
    const {error} = useErrorParamHandlerContext();
    const hasError =
      error?.type === 'integration_connection' &&
      error.integration_type === integrationType;
    const errorText = hasError
      ? error.message || _(msg`Unknown error. Please try again later`)
      : '';

    const renderButtonContent = useCallback(() => {
      if (hasError) {
        return (
          <div className="flex items-center gap-2">
            <RefreshCcw01Icon
              className={twJoin(
                'h-4 w-4 shrink-0 grow-0',
                isActive
                  ? 'text-primary'
                  : isDisabled
                    ? 'text-primary/50'
                    : 'text-primary',
              )}
            />
            <Trans>Retry</Trans>
          </div>
        );
      }

      return isActive ? <Trans>Connected</Trans> : <Trans>Connect</Trans>;
    }, [hasError, isActive, isDisabled]);

    return (
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-3">
          <div
            className={twMerge(
              'relative flex h-12 w-12 items-center justify-center rounded-xl border-[length:thin] border-foreground/10',
              classNames?.iconContainer,
            )}>
            <img
              className="h-8 w-auto object-contain"
              src={getSmallIntegrationIcon(integrationType)}
              alt={integrationType}
            />
            <AlertPopover text={errorText} />
          </div>
          <div className="flex flex-1 flex-col gap-1">
            <h5
              className={twMerge(
                'text-base font-semibold capitalize',
                classNames?.title,
              )}>
              {integrationType.split('_').join(' ')}
            </h5>
            {subtitle ? (
              <div className="line-clamp-2 overflow-hidden text-ellipsis text-xs text-default-500">
                {subtitle}
              </div>
            ) : null}
          </div>
        </div>

        <Button
          aria-label={hasError ? 'Retry' : isActive ? 'Connected' : 'Connect'}
          ref={ref}
          variant="solid"
          radius="full"
          className={twMerge(
            'h-8 min-w-0 shrink-0 bg-[#F2F2F5] text-base font-semibold text-primary',
            classNames?.button,
          )}
          disableRipple
          isDisabled={isDisabled}
          {...props}>
          {renderButtonContent()}
        </Button>
      </div>
    );
  },
);

AvailableIntegration.displayName = 'AvailableIntegration';

export default AvailableIntegration;
