import {memo, useCallback, useEffect, useRef, useState} from 'react';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@nextui-org/react';
import AlertTriangleIcon from './icons/AlertTriangleIcon';
import {useMatchesMedia} from '@/hooks';

interface Props {
  text: string | undefined | null;
  className?: string;
}

const AlertPopover = memo(({text, className}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const isTouchDevice = useMatchesMedia('(any-hover: none)');
  const closeOnExitTimerRef = useRef<number | undefined>();
  const popoverContentRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const isShown = Boolean(text);

  const handleClickOutsidePopover = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (
        isOpen &&
        popoverContentRef.current &&
        !popoverContentRef.current.contains(event.target as any) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as any)
      ) {
        setIsOpen(false);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    if (isOpen && !isShown) {
      setIsOpen(false);
    }
  }, [isOpen, isShown]);

  useEffect(() => {
    if (!isOpen) {
      document.removeEventListener('mousedown', handleClickOutsidePopover);
      document.removeEventListener('touchstart', handleClickOutsidePopover);
      return;
    }

    document.addEventListener('mousedown', handleClickOutsidePopover);
    document.addEventListener('touchstart', handleClickOutsidePopover);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePopover);
      document.removeEventListener('touchstart', handleClickOutsidePopover);
    };
  }, [isOpen, handleClickOutsidePopover]);

  useEffect(() => {
    clearTimeout(closeOnExitTimerRef.current);
  }, [isTouchDevice]);

  useEffect(() => {
    return () => {
      clearTimeout(closeOnExitTimerRef.current);
      document.removeEventListener('mousedown', handleClickOutsidePopover);
      document.removeEventListener('touchstart', handleClickOutsidePopover);
    };
  }, []);

  return (
    <Popover
      showArrow
      placement="top-start"
      classNames={{
        base: `border-none before:!left-[1.75rem] ${isShown ? 'pointer-events-auto' : 'pointer-events-none'}`,
        content: 'rounded-lg shadow-xl shadow-foreground/15',
      }}
      offset={16}
      isOpen={isOpen}>
      <PopoverTrigger className="!scale-100 !opacity-100">
        <div
          className={`absolute h-full w-full ${isShown ? 'block' : 'hidden'}`}>
          <Button
            aria-label="Alert popover trigger"
            as={'div'}
            ref={buttonRef}
            isIconOnly
            disableRipple
            disableAnimation
            onMouseEnter={() => {
              if (!isTouchDevice) {
                clearTimeout(closeOnExitTimerRef.current);
                setIsOpen(true);
              }
            }}
            onMouseLeave={() => {
              if (!isTouchDevice) {
                closeOnExitTimerRef.current = setTimeout(() => {
                  setIsOpen(false);
                }, 1000);
              }
            }}
            onPress={() => {
              if (isTouchDevice) {
                setIsOpen(prev => !prev);
              }
            }}
            className={`h-full w-full min-w-0 overflow-visible border-none !bg-transparent !opacity-100 ${className}`}>
            <div className="absolute -right-2 -top-2 z-[20]">
              <AlertTriangleIcon className="h-7 w-7 text-warning" />
            </div>
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent
        className="flex max-h-[40dvh] max-w-[min(28rem,100dvw-2rem)] cursor-pointer items-start justify-start gap-6 overflow-auto px-4 py-3 text-sm"
        onMouseEnter={() => {
          if (!isTouchDevice) {
            clearTimeout(closeOnExitTimerRef.current);
          }
        }}
        onMouseLeave={() => {
          if (!isTouchDevice) {
            setIsOpen(false);
          }
        }}>
        <div ref={popoverContentRef}>{text}</div>
      </PopoverContent>
    </Popover>
  );
});

AlertPopover.displayName = 'AlertPopover';

export default AlertPopover;
