import './instrument';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import {API_URL, MODE} from './config';
import {registerSW} from 'virtual:pwa-register';
import {AppProvider, ThemeProvider} from './components/providers';
import App from '@/App';
import './yupSetup';
import './index.css';
import {loadErrorMessages, loadDevMessages} from '@apollo/client/dev';
import {instrumentTracker} from './common/instrumentTracker';

if (MODE === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

axios.defaults.baseURL = API_URL;
axios.interceptors.request.use(async config => {
  const trackerHeaders = await instrumentTracker.buildHeaders();

  for (const [key, value] of Object.entries(trackerHeaders)) {
    config.headers[key] = value;
  }

  return config;
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ThemeProvider defaultTheme="light" storageKey="ui-theme">
    <AppProvider>
      <App />
    </AppProvider>
  </ThemeProvider>,
);

registerSW({immediate: true});
