import {
  useCallback,
  useMemo,
  memo,
  PropsWithChildren,
  useReducer,
  useRef,
  useState,
  useEffect,
} from 'react';
import {ChatSessionWithMessages, ChatMessage} from '@/common/types/chat';
import {
  compareObjectsByDate,
  addOrUpdateInSortedList,
  mergeTwoSortedLists,
  pickHighestDate,
} from './helpers';
import {ChatSessionsState, ChatActions, ChatActionType} from './types';
import {ChatContext, ChatContextValue} from '@/contexts';
import {useGetSessions} from './hooks';
import {useNavigate, useParams} from 'react-router-dom';
import {PATH, LS_UNAUTHENTICATED_CHAT_SESSION_IDS} from '@/config';
import {useMutation, useQuery} from '@apollo/client';
import {GET_AGENT_CONNECTION} from '@/common/queries';
import {getUnauthenticatedSessionIds} from '@/utils';
import {useAppContext} from '@/hooks';
import {CLAIM_SESSIONS} from '@/common/mutations';
import {useMessageEvents} from '@/components/Chat/hooks';

const CHAT_TOOL = 'business-analyst-agent';

const chatSessionsReducer = (
  state: ChatSessionsState,
  action: ChatActions,
): ChatSessionsState => {
  switch (action.type) {
    case ChatActionType.SET_SESSIONS: {
      const sessionMessageMap = Object.fromEntries(
        state.sessions.map(obj => [obj.id, obj.messages as ChatMessage[]]),
      );
      const unsortedSessions = [...action.payload].map(session => {
        const messages = mergeTwoSortedLists(
          sessionMessageMap[session.id] || [],
          session.messages.sort((a, b) =>
            compareObjectsByDate(b, a, 'created_at'),
          ),
          'id',
          'created_at',
          'desc',
          (existing, incoming) =>
            compareObjectsByDate(incoming, existing, 'updated_at') > 0,
        );

        return {
          ...session,
          updated_at: messages.length
            ? pickHighestDate(session, messages[0], 'updated_at')
            : session.updated_at,
          messages,
        };
      });
      const sessions = unsortedSessions.sort((a, b) =>
        compareObjectsByDate(b, a, 'updated_at'),
      );

      return {
        ...state,
        sessions,
      };
    }

    case ChatActionType.ADD_OR_UPDATE_MESSAGE: {
      const {message, finished} = action.payload;
      let session = state.sessions.find(({id}) => id === message.session_id);

      if (!session) {
        return state;
      }

      const messages = addOrUpdateInSortedList(
        session.messages,
        message,
        'id',
        'created_at',
        'desc',
        (existing, incoming) =>
          compareObjectsByDate(incoming, existing, 'updated_at') > 0,
      );
      const latestMessage = messages[0];

      session = {
        ...session,
        messages,
        // update the session's updated_at if the latest message is finished generating
        ...(finished
          ? {
              updated_at: pickHighestDate(session, latestMessage, 'updated_at'),
            }
          : {}),
      };

      const sessions = addOrUpdateInSortedList(
        state.sessions,
        session,
        'id',
        'updated_at',
        'desc',
      );

      return {
        ...state,
        sessions,
      };
    }

    case ChatActionType.ADD_OR_UPDATE_SESSION:
    case ChatActionType.ADD_SESSION_MESSAGES: {
      const sessionId =
        action.type === ChatActionType.ADD_OR_UPDATE_SESSION
          ? action.payload.session.id
          : action.payload.sessionId;

      const {session, prevMessages, nextMessages} = (() => {
        const prevSession = state.sessions.find(s => s.id === sessionId);

        if (action.type === ChatActionType.ADD_OR_UPDATE_SESSION) {
          return {
            session: action.payload.session,
            prevMessages: prevSession?.messages || [],
            nextMessages: action.payload.session.messages || [],
          };
        }

        return {
          session: prevSession,
          prevMessages: prevSession?.messages || [],
          nextMessages: action.payload.messages,
        };
      })();

      if (!session) {
        return state;
      }

      const newSortedMessages = [...nextMessages].sort((a, b) =>
        compareObjectsByDate(b, a, 'created_at'),
      );
      const newSessionMessages = mergeTwoSortedLists(
        prevMessages,
        newSortedMessages,
        'id',
        'created_at',
        'desc',
        (existing, incoming) =>
          compareObjectsByDate(incoming, existing, 'updated_at') > 0,
      );
      const newSession: ChatSessionWithMessages = {
        ...session,
        updated_at: newSessionMessages.length
          ? pickHighestDate(session, newSessionMessages[0], 'updated_at')
          : session.updated_at,
        messages: newSessionMessages,
      };
      const newSessions = addOrUpdateInSortedList(
        state.sessions,
        newSession,
        'id',
        'updated_at',
        'desc',
      );

      return {
        ...state,
        sessions: newSessions,
      };
    }
    case ChatActionType.UPDATE_SESSION_DATA: {
      const {sessionId, data} = action.payload;
      const session = state.sessions.find(({id}) => id === sessionId);

      if (!session) {
        return state;
      }

      const sessions = addOrUpdateInSortedList(
        state.sessions,
        {...session, ...data},
        'id',
        'updated_at',
        'desc',
      );

      return {
        ...state,
        sessions,
      };
    }
    default:
      return state;
  }
};

const useCreateChatContext = (): ChatContextValue => {
  const [fromLocation, setFromLocation] = useState<Location | null>(null);
  const [skipGetSessions, setSkipGetSessions] = useState(true);
  const {isAuthenticated} = useAppContext();
  const navigate = useNavigate();
  const params = useParams();
  const [activeSessionId, setActiveSessionId] = useState<string | undefined>(
    params.sessionId,
  );
  const [unauthenticatedSessionIds, setUnauthenticatedSessionIds] = useState<
    string[]
  >(getUnauthenticatedSessionIds());
  const [claimChatSessions] = useMutation(CLAIM_SESSIONS, {
    update: cache => {
      localStorage.removeItem(LS_UNAUTHENTICATED_CHAT_SESSION_IDS);
      cache.evict({id: 'ROOT_QUERY', fieldName: 'getSessionConnection'});
      cache.gc();
      setSkipGetSessions(false);
    },
  });
  const [isInlineChatOpen, setIsInlineChatOpen] = useState(false);
  const [{sessions}, dispatch] = useReducer(chatSessionsReducer, {
    sessions: [],
  } as ChatSessionsState);
  const readAtCacheRef = useRef<
    Record<string, {lastReadAt?: string; total: number; unread: number}>
  >({});

  const activeSession = useMemo(
    () =>
      activeSessionId
        ? sessions.find(({id}) => id === activeSessionId)
        : undefined,
    [sessions, activeSessionId],
  );

  const agents = useQuery(GET_AGENT_CONNECTION, {
    variables: {
      filter: {
        is_new: true,
      },
    },
  });

  const activeAgent = useMemo(() => {
    if (agents.loading || !agents.data) {
      return null;
    }

    const template = activeSession
      ? activeSession.agent?.templateName
      : CHAT_TOOL;

    return (
      agents.data.connection.edges.find(e => e.node.templateName === template)
        ?.node ?? null
    );
  }, [activeSession, agents?.data, agents.loading]);

  const unreadCountBySession = useMemo(() => {
    const countMap: Record<string, number> = {};

    for (const session of sessions) {
      const cacheEntry = readAtCacheRef.current[session.id];
      let unread = cacheEntry?.unread || 0;
      let total = cacheEntry?.total || 0;
      let lastReadAt = cacheEntry?.lastReadAt ?? undefined;

      if (
        !cacheEntry ||
        total !== session.messages.length ||
        lastReadAt !== session.last_read_at
      ) {
        total = session.messages.filter(m => m.author_type === 'agent').length;
        unread = session.last_read_at
          ? session.messages.reduce((acc, message) => {
              if (
                message.created_at.localeCompare(session.last_read_at!) > 0 &&
                message.author_type === 'agent'
              ) {
                return acc + 1;
              }

              return acc;
            }, 0)
          : total;
        lastReadAt = session.last_read_at ?? undefined;

        readAtCacheRef.current[session.id] = {unread, total, lastReadAt};
      }

      countMap[session.id] = unread;
    }

    return countMap;
  }, [sessions]);

  const totalUnreadCount = useMemo(
    () =>
      Object.values(unreadCountBySession).reduce(
        (acc, count) => acc + count,
        0,
      ),
    [unreadCountBySession],
  );

  const addUnauthenticatedSessionId = useCallback((sessionId: string) => {
    const newIds = [...new Set([...getUnauthenticatedSessionIds(), sessionId])];

    localStorage.setItem(
      LS_UNAUTHENTICATED_CHAT_SESSION_IDS,
      JSON.stringify(newIds),
    );

    setUnauthenticatedSessionIds(newIds);
  }, []);

  const addOrUpdateMessage: ChatContextValue['addOrUpdateMessage'] =
    useCallback((message: ChatMessage, finished: boolean) => {
      dispatch({
        type: ChatActionType.ADD_OR_UPDATE_MESSAGE,
        payload: {
          message,
          finished,
        },
      });
    }, []);

  const addOrUpdateSession: ChatContextValue['addOrUpdateSession'] =
    useCallback(
      session => {
        if (!isAuthenticated) {
          addUnauthenticatedSessionId(session.id);
        }

        dispatch({
          type: ChatActionType.ADD_OR_UPDATE_SESSION,
          payload: {
            session,
          },
        });
      },
      [isAuthenticated, addUnauthenticatedSessionId],
    );

  const addSessionMessages: ChatContextValue['addSessionMessages'] =
    useCallback((sessionId, messages) => {
      dispatch({
        type: ChatActionType.ADD_SESSION_MESSAGES,
        payload: {
          sessionId,
          messages,
        },
      });
    }, []);

  const setSessions: ChatContextValue['setSessions'] = useCallback(sessions => {
    dispatch({
      type: ChatActionType.SET_SESSIONS,
      payload: sessions,
    });
  }, []);

  const updateSessionData: ChatContextValue['updateSessionData'] = useCallback(
    (sessionId, data) => {
      dispatch({
        type: ChatActionType.UPDATE_SESSION_DATA,
        payload: {
          sessionId,
          data,
        },
      });
    },
    [],
  );

  const setSessionId = useCallback(
    (sessionId?: string) => {
      navigate(
        sessionId
          ? `/${PATH.segment.chat}/${PATH.segment.sessions}/${sessionId}`
          : `/${PATH.segment.chat}`,
      );
    },
    [navigate],
  );

  useEffect(() => {
    if (params.sessionId) {
      setActiveSessionId(params.sessionId);
    }
    if (location.pathname.endsWith(`/${PATH.segment.chat}`)) {
      setActiveSessionId(undefined);
    }
  }, [params.sessionId]);

  useEffect(() => {
    const ids = getUnauthenticatedSessionIds();

    if (isAuthenticated && ids.length) {
      claimChatSessions({variables: {ids}});
    } else {
      setSkipGetSessions(false);
    }
  }, [isAuthenticated]);

  useGetSessions(unauthenticatedSessionIds, setSessions, {
    skip: skipGetSessions,
  });

  useMessageEvents({
    unauthenticatedSessionIds,
    addOrUpdateMessage,
    updateSessionData,
  });

  return useMemo(
    () => ({
      activeSessionId,
      sessions,
      activeSession,
      activeAgent,
      totalUnreadCount,
      unreadCountBySession,
      isInlineChatOpen,
      unauthenticatedSessionIds,
      setSessions,
      addSessionMessages,
      setActiveSession: setSessionId,
      updateSessionData,
      addOrUpdateMessage,
      addOrUpdateSession,
      setIsInlineChatOpen,
      setUnauthenticatedSessionIds,
      fromLocation,
      setFromLocation,
    }),
    [
      activeSessionId,
      sessions,
      activeSession,
      activeAgent,
      totalUnreadCount,
      unreadCountBySession,
      isInlineChatOpen,
      unauthenticatedSessionIds,
      setSessions,
      addSessionMessages,
      setSessionId,
      updateSessionData,
      addOrUpdateMessage,
      addOrUpdateSession,
      setIsInlineChatOpen,
      setUnauthenticatedSessionIds,
      fromLocation,
    ],
  );
};

export const ChatProvider = memo(({children}: PropsWithChildren) => {
  const value = useCreateChatContext();

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
});

ChatProvider.displayName = 'ChatProvider';
