import {useAuthContext, usePrevious, useToolContext} from '@/hooks';
import {memo, useEffect, useState} from 'react';
import ToolOverviewHeader from './components/ToolHeader';
import ToolRunningLoader from './components/ToolRunningLoader';
import {AnimatePresence} from 'framer-motion';
import ToolTopicList from './components/ToolTopicList';
import {useQuery} from '@apollo/client';
import {GET_AGENT_BUSINESS_STATUS_DOCUMENT} from '@/common/queries';
import {AgentBusinessStatusEnum} from '@/__generated__/graphql';

const ToolOverview = memo(() => {
  const [toolRunningProgress, setToolRunningProgress] = useState<null | number>(
    null,
  );
  const {business} = useAuthContext();
  const {agent, isUnlocked} = useToolContext();
  const agentBusinessData = useQuery(GET_AGENT_BUSINESS_STATUS_DOCUMENT, {
    variables: {
      filter: {businessId: business?.id, agentId: agent?.id},
      first: 1,
    },
    skip: !agent?.id || !business || !isUnlocked,
  });
  const agentBusiness = agentBusinessData.data?.connection.edges[0]?.node;
  const status = agentBusiness?.status;
  const isToolRunning =
    status !== AgentBusinessStatusEnum.Finished &&
    status !== AgentBusinessStatusEnum.Failed;
  const prevStatus = usePrevious(status);

  useEffect(() => {
    if (status === AgentBusinessStatusEnum.Failed) {
      console.error('Tool failed to run');
    }

    switch (status) {
      case null:
        setToolRunningProgress(5);
        break;
      case AgentBusinessStatusEnum.Calculation:
        setToolRunningProgress(50);
        break;
      case AgentBusinessStatusEnum.Finished:
      case AgentBusinessStatusEnum.Failed:
        if (prevStatus) {
          setToolRunningProgress(100);
        }
        break;
    }
  }, [status]);

  useEffect(() => {
    let interval: number;

    if (isToolRunning) {
      interval = setInterval(() => {
        agentBusinessData.refetch();
      }, 1500);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isToolRunning]);

  useEffect(() => {
    let timeout: number;

    if (toolRunningProgress === 100) {
      timeout = setTimeout(() => {
        setToolRunningProgress(null);
      }, 750);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [toolRunningProgress]);

  return (
    <div
      style={{WebkitOverflowScrolling: 'touch'}}
      className="relative flex h-full w-full max-w-2xl grow flex-col items-center overflow-auto">
      <ToolOverviewHeader className="sticky top-0 z-20 bg-background/75 backdrop-blur-xl" />
      <AnimatePresence initial={false} mode="wait">
        {isToolRunning || toolRunningProgress !== null ? (
          <ToolRunningLoader
            key="loader"
            toolRunningProgress={toolRunningProgress}
          />
        ) : (
          <ToolTopicList
            key="topics"
            className="flex w-full flex-1 flex-col gap-4 p-5"
          />
        )}
      </AnimatePresence>
    </div>
  );
});

ToolOverview.displayName = 'ToolOverview';

export default ToolOverview;
