import {Insight} from '@/__generated__/graphql';
import GradientIcon from '@/components/GradientIcon';
import {useGetRelativeTime} from '@/hooks';
import {Trans} from '@lingui/macro';
import {Button, Card, CardBody} from '@nextui-org/react';
import {memo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';

export type CompactInsightProps = {
  insight: Omit<Partial<Insight>, 'agent'> & {
    agent?: Partial<Insight['agent']>;
  };
  appName?: string;
  appIcon?: string;
  isDrillDown: boolean;
  className?: string;
  href?: string;
};

const CompactInsight = memo(
  ({
    insight,
    appName,
    isDrillDown,
    appIcon,
    className,
    href,
  }: CompactInsightProps) => {
    const getRelativeTime = useGetRelativeTime();

    return (
      <Card
        className={twMerge(
          'overflow-visible bg-transparent p-0 data-[hover=true]:bg-transparent',
          className,
        )}
        isHoverable
        isPressable
        isDisabled={!appName}
        aria-label="Open insight"
        as={RouterLink}
        to={href ?? `/catalog/${appName}/unlocked/insight/${insight.id}`}
        shadow="none">
        <CardBody className="flex flex-row gap-2 overflow-visible p-0">
          <div className="relative">
            {appIcon ? (
              <img
                className="absolute -right-1 -top-1 z-10 flex h-5 w-5 shrink-0 rounded-[5px]"
                src={appIcon}
              />
            ) : null}
            <GradientIcon impact={insight.impact ?? 0} />
          </div>
          <div className="flex flex-1 flex-col justify-center gap-1">
            <div className="text-xs font-semibold text-foreground">
              {insight.title}
            </div>
            <div className="text-xs font-normal text-default-500">
              {insight.shortDescription}
            </div>
          </div>
          <div className="flex flex-col items-center gap-1">
            <Button
              aria-label="Open insight"
              as="div"
              variant="solid"
              radius="full"
              className={`h-8 min-w-0 ${isDrillDown ? 'bg-background' : 'bg-[#F2F2F5]'} font-semibold text-primary`}
              disableRipple>
              <Trans>Open</Trans>
            </Button>
            <div className="text-[0.625rem] font-normal leading-[0.5rem] text-foreground/40 sm:text-xs sm:leading-4">
              {getRelativeTime(insight.createdAt)}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  },
);

CompactInsight.displayName = 'CompactInsight';

export default CompactInsight;
