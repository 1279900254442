import {ComponentProps, memo, useCallback, useMemo} from 'react';
import {motion} from 'framer-motion';
import {useAuthContext, useChatContext} from '@/hooks';
import {ChatItem} from '@/common/types/chat';
import {useQuery} from '@apollo/client';
import {getChatItemsList} from '../helpers';
import PortalSpinner from '@/components/PortalSpinner';
import ChatView from './ChatView';
import {GET_MESSAGE_CONNECTION} from '@/common/queries';
import {twMerge} from 'tailwind-merge';

interface Props {
  isLoading?: boolean;
  className?: ComponentProps<'div'>['className'];
}

const ChatWindow = memo(({isLoading, className = ''}: Props) => {
  const {user} = useAuthContext();
  const {activeSession, addOrUpdateMessage} = useChatContext();
  const activeSessionId = activeSession?.id ?? '';

  const messagesData = useQuery(GET_MESSAGE_CONNECTION, {
    variables: {
      filter: {
        sessionId: activeSessionId,
      },
      order: {createdAt: 'desc'},
      first: 15,
      after: activeSession?.messagesPageInfo?.endCursor,
    },
    skip: !activeSessionId,
    notifyOnNetworkStatusChange: true, // fixes onCompleted not being called after refetch
    onCompleted: data => {
      data.connection.edges.forEach(e => addOrUpdateMessage(e.node, true));
    },
  });

  const itemsList: ChatItem[] = useMemo(
    () =>
      getChatItemsList({
        user,
        activeSession,
      }),
    [activeSession?.messages, activeSession?.last_read_at, user],
  );
  const hasMore = messagesData.data?.connection.pageInfo.hasNextPage ?? false;
  const fetchMore = useCallback(async () => {
    await messagesData.fetchMore({
      variables: {
        after: messagesData.data?.connection.pageInfo.endCursor,
      },
    });
  }, [messagesData]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex h-full w-full grow flex-col items-center justify-center">
          <PortalSpinner size="lg" />
        </div>
      );
    }

    return (
      <ChatView
        items={itemsList}
        activeSessionId={activeSession?.id}
        hasMore={hasMore}
        scrollClassName="px-5 pb-4 max-w-[48rem]"
        fetchMore={fetchMore}
      />
    );
  };

  return (
    <motion.div className={twMerge(`relative flex h-0 shrink grow`, className)}>
      {renderContent()}
    </motion.div>
  );
});

ChatWindow.displayName = 'ChatWindow';

export default ChatWindow;
