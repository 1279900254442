import PortalSpinner from '@/components/PortalSpinner';
import {Trans} from '@lingui/macro';
import {Button, ButtonProps} from '@nextui-org/react';
import {memo, ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';

export const ContinueButton = memo(
  ({
    title,
    className,
    ...props
  }: {title?: ReactNode} & Omit<ButtonProps, 'children' | 'title'>) => (
    <Button
      aria-label="Submit"
      fullWidth
      variant="solid"
      radius="full"
      color="primary"
      className={twMerge('text-base font-semibold text-background', className)}
      spinner={<PortalSpinner size="sm" />}
      {...props}>
      {title ? title : <Trans>Continue</Trans>}
    </Button>
  ),
);

ContinueButton.displayName = 'ContinueButton';
