import {SVGProps} from 'react';

export default function PortalChatInactiveIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_2550_42024)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.5083 16.4642C25.8209 13.7142 24.0357 10.5554 21.2244 7.74406C18.413 4.9327 15.2542 3.14756 12.5043 2.46008C9.70618 1.76056 7.72773 2.25989 6.64468 3.34293C5.56164 4.42597 5.06232 6.40443 5.76184 9.20251C5.92454 9.8533 6.14871 10.527 6.43341 11.2136L4.81353 12.8334H4.81162C2.76597 8.47876 2.72861 4.23416 5.13226 1.8305C8.77828 -1.81551 16.6601 0.15494 22.7368 6.23164C28.8135 12.3083 30.7839 20.1901 27.1379 23.8362C24.7347 26.2394 20.4912 26.2025 16.1373 24.1579V24.1549L17.7565 22.5357C18.4425 22.8201 19.1157 23.044 19.7659 23.2066C22.564 23.9061 24.5425 23.4068 25.6255 22.3237C26.7085 21.2407 27.2079 19.2622 26.5083 16.4642Z"
          fill="currentColor"
        />
        <path
          d="M14.4846 8.43589C14.3547 8.09798 14.03 7.875 13.668 7.875C13.3059 7.875 12.9813 8.09798 12.8513 8.43589L11.82 11.1173C11.5571 11.8007 11.4745 11.9976 11.3616 12.1565C11.2482 12.3159 11.1089 12.4552 10.9495 12.5686C10.7906 12.6816 10.5936 12.7642 9.91023 13.027L7.22886 14.0583C6.89094 14.1883 6.66797 14.513 6.66797 14.875C6.66797 15.2371 6.89094 15.5617 7.22886 15.6917L9.91023 16.723C10.5936 16.9858 10.7906 17.0684 10.9495 17.1814C11.1089 17.2948 11.2482 17.4341 11.3616 17.5935C11.4745 17.7524 11.5571 17.9493 11.82 18.6327L12.8513 21.3141C12.9813 21.652 13.3059 21.875 13.668 21.875C14.03 21.875 14.3547 21.652 14.4846 21.3141L15.5159 18.6327C15.7788 17.9493 15.8614 17.7524 15.9744 17.5935C16.0877 17.4341 16.227 17.2948 16.3865 17.1814C16.5454 17.0684 16.7423 16.9858 17.4257 16.723L20.1071 15.6917C20.445 15.5617 20.668 15.2371 20.668 14.875C20.668 14.513 20.445 14.1883 20.1071 14.0583L17.4257 13.027C16.7423 12.7642 16.5454 12.6816 16.3865 12.5686C16.227 12.4552 16.0877 12.3159 15.9744 12.1565C15.8614 11.9976 15.7788 11.8007 15.5159 11.1173L14.4846 8.43589Z"
          fill="currentColor"
        />
        <path
          d="M7.01309 17.9837C6.86487 17.6873 6.56189 17.5 6.23047 17.5C5.89904 17.5 5.59606 17.6873 5.44784 17.9837L4.76144 19.3565C4.51427 19.8508 4.43895 19.9931 4.34743 20.1118C4.25564 20.2309 4.14888 20.3377 4.02979 20.4295C3.91106 20.521 3.76881 20.5963 3.27446 20.8435L1.90166 21.5299C1.60522 21.6781 1.41797 21.9811 1.41797 22.3125C1.41797 22.6439 1.60522 22.9469 1.90166 23.0951L3.27446 23.7815C3.76881 24.0287 3.91107 24.104 4.02979 24.1955C4.14888 24.2873 4.25564 24.3941 4.34743 24.5132C4.43895 24.6319 4.51427 24.7742 4.76144 25.2685L5.44785 26.6413C5.59606 26.9377 5.89904 27.125 6.23047 27.125C6.5619 27.125 6.86488 26.9377 7.01309 26.6413L7.69949 25.2685C7.94667 24.7742 8.02199 24.6319 8.11351 24.5132C8.2053 24.3941 8.31206 24.2873 8.43115 24.1955C8.54987 24.104 8.69213 24.0287 9.18648 23.7815L10.5593 23.0951C10.8557 22.9469 11.043 22.6439 11.043 22.3125C11.043 21.9811 10.8557 21.6781 10.5593 21.5299L9.18648 20.8435C8.69213 20.5963 8.54987 20.521 8.43115 20.4295C8.31206 20.3377 8.2053 20.2309 8.11351 20.1118C8.02199 19.9931 7.94667 19.8508 7.69949 19.3565L7.01309 17.9837Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2550_42024">
          <rect
            width="28.4147"
            height="28"
            fill="white"
            transform="translate(0.542969)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
