/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment InsightFragment on Insight {\n    id\n    impact\n    title\n    shortDescription\n    createdAt\n    status\n    layoutType\n  }\n": types.InsightFragmentFragmentDoc,
    "\n  fragment PageInfoFragment on PageInfo {\n    hasNextPage\n    endCursor\n  }\n": types.PageInfoFragmentFragmentDoc,
    "\n  fragment UpdateUserFragment on User {\n    id\n    firstName\n    lastName\n    email\n    phone\n    newsletter\n    description\n    avatar\n    verified\n    createdAt\n    updatedAt\n  }\n": types.UpdateUserFragmentFragmentDoc,
    "\n  fragment MessageFragment on Message {\n    id\n    author_type: authorType\n    content\n    session_id: sessionId\n    created_at: createdAt\n    updated_at: updatedAt\n  }\n": types.MessageFragmentFragmentDoc,
    "\n  fragment DataSourceFragment on DataSource {\n    type\n    name\n    imageSmall {\n      url\n    }\n  }\n": types.DataSourceFragmentFragmentDoc,
    "\n  fragment LanguageFragment on Language {\n    iso2\n    name\n    shortName\n  }\n": types.LanguageFragmentFragmentDoc,
    "\n  fragment ToolReviewFragment on ToolReview {\n    rating\n    content {\n      markdown\n    }\n    author\n    title\n    createdAt\n  }\n": types.ToolReviewFragmentFragmentDoc,
    "\n  fragment ResponsiveImageFragment on ResponsiveImage {\n    default {\n      url\n      blurHash\n      width\n      height\n    }\n    small {\n      url\n      blurHash\n      width\n      height\n    }\n    medium {\n      url\n      blurHash\n      width\n      height\n    }\n    large {\n      url\n      blurHash\n      width\n      height\n    }\n    xLarge {\n      url\n      blurHash\n      width\n      height\n    }\n    xxLarge {\n      url\n      blurHash\n      width\n      height\n    }\n  }\n": types.ResponsiveImageFragmentFragmentDoc,
    "\n  fragment AgentFragment on Agent {\n    id\n    templateName\n    catalogueImages {\n      ...ResponsiveImageFragment\n    }\n    cataloguePromoText\n    bannerImage {\n      ...ResponsiveImageFragment\n    }\n    bannerText\n    logo {\n      url\n      blurHash\n      width\n      height\n    }\n    chatAvatar {\n      url\n    }\n    name\n    subtitle\n    description\n    riskIndex\n    images {\n      ...ResponsiveImageFragment\n    }\n    categoryName\n    categoryImage {\n      url\n    }\n    dataSources {\n      ...DataSourceFragment\n    }\n    languages {\n      ...LanguageFragment\n    }\n    rating\n    unlocks\n    activationsText\n    developer\n    copyright\n    explain {\n      prompt\n      content\n    }\n    setupConfig {\n      steps {\n        title\n        name\n        explain {\n          prompt\n          content\n        }\n      }\n    }\n    featured\n  }\n": types.AgentFragmentFragmentDoc,
    "\n  fragment UpdateCustomerDataFragment on CustomerData {\n    id\n    userId\n    key\n    value\n    createdAt\n    updatedAt\n  }\n": types.UpdateCustomerDataFragmentFragmentDoc,
    "\n  fragment SessionFragment on Session {\n    id\n    summary\n    locked\n    agent {\n      id\n      name\n      templateName\n      logo {\n        url\n      }\n      chatAvatar {\n        url\n      }\n    }\n    created_at: createdAt\n    updated_at: updatedAt\n    last_read_at: lastReadAt\n  }\n": types.SessionFragmentFragmentDoc,
    "\n  fragment SessionWithMessagesFragment on Session {\n    ...SessionFragment\n    messages(order: $messagesOrder, first: $messagesFirst) {\n      edges {\n        node {\n          ...MessageFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.SessionWithMessagesFragmentFragmentDoc,
    "\n  fragment DecisionFragment on Decision {\n    id\n    type\n    actionId\n    integrationId\n    status\n    title\n    description\n    approveButtonText\n    approvedButtonText\n    body\n    iconUrl\n    sortValue\n    createdAt\n    updatedAt\n  }\n": types.DecisionFragmentFragmentDoc,
    "\n  fragment ActionFragment on Action {\n    id\n    status\n    insightId\n    title\n    description\n    iconUrl\n    createdAt\n    updatedAt\n  }\n": types.ActionFragmentFragmentDoc,
    "\n  mutation CreateSession($input: SessionCreateInput!) {\n    session: createSession(input: $input) {\n      ...SessionFragment\n    }\n  }\n": types.CreateSessionDocument,
    "\n  mutation CreateMessage($input: MessageCreateInput!) {\n    message: createMessage(input: $input) {\n      ...MessageFragment\n    }\n  }\n": types.CreateMessageDocument,
    "\n  mutation ClaimSessions($ids: [ID!]!) {\n    claimSessions(ids: $ids) {\n      id\n      userId\n    }\n  }\n": types.ClaimSessionsDocument,
    "\n  mutation UpsertCustomerData($input: CustomerDataCreateInput!) {\n    customerData: createOrUpdateCustomerData(input: $input) {\n      id\n    }\n  }\n": types.UpsertCustomerDataDocument,
    "\n  mutation DeleteCustomerData($id: ID!) {\n    customerData: deleteCustomerData(id: $id) {\n      id\n    }\n  }\n": types.DeleteCustomerDataDocument,
    "\n  mutation UnlockTool($input: AgentBusinessCreateInput!) {\n    agentBusiness: createAgentBusiness(input: $input) {\n      id\n      agentId\n      business {\n        id\n      }\n    }\n  }\n": types.UnlockToolDocument,
    "\n  mutation Mutation($input: InitSignupInput!) {\n    initSignup(input: $input)\n  }\n": types.MutationDocument,
    "\n  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {\n    requestPasswordReset(input: $input)\n  }\n": types.RequestPasswordResetDocument,
    "\n  mutation ApproveDecision($id: ID!) {\n    approveDecision(id: $id) {\n      ...DecisionFragment\n    }\n  }\n": types.ApproveDecisionDocument,
    "\n  mutation UpdateDecision($id: ID!, $input: DecisionUpdateInput!) {\n    updateDecision(id: $id, input: $input) {\n      ...DecisionFragment\n    }\n  }\n": types.UpdateDecisionDocument,
    "\n  mutation UpdateAction($id: ID!, $input: ActionUpdateInput!) {\n    updateAction(id: $id, input: $input) {\n      ...ActionFragment\n    }\n  }\n": types.UpdateActionDocument,
    "\n  query Query($email: String!) {\n    getUserStatus(email: $email)\n  }\n": types.QueryDocument,
    "\n  query GetAgentConnection($filter: JSON, $after: String) {\n    connection: getAgentConnection(filter: $filter, after: $after) {\n      edges {\n        node {\n          ...AgentFragment\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.GetAgentConnectionDocument,
    "\n  query GetAgentByTemplate($template: String!) {\n    agent: getAgentByTemplate(template: $template) {\n      ...AgentFragment\n    }\n  }\n": types.GetAgentByTemplateDocument,
    "\n  query GetCustomerDataConnection(\n    $filter: JSON\n    $first: Int\n    $after: String\n    $order: JSON\n  ) {\n    connection: getCustomerDataConnection(\n      filter: $filter\n      first: $first\n      after: $after\n      order: $order\n    ) {\n      edges {\n        node {\n          id\n          key\n          value\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.GetCustomerDataConnectionDocument,
    "\n  query GetSessionConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n    $messagesOrder: JSON\n    $messagesFirst: Int\n  ) {\n    connection: getSessionConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          ...SessionWithMessagesFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.GetSessionConnectionDocument,
    "\n  query GetMessageConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {\n    connection: getMessageConnection(filter: $filter, order: $order, first: $first, after: $after) {\n      edges {\n        node {\n          ...MessageFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.GetMessageConnectionDocument,
    "\n  query GetAgentReviewConnection($template: String!, $after: String, $first: Int) {\n    connection: getAgentReviewConnection(template: $template, after: $after, first: $first) {\n      edges {\n        node {\n          ...ToolReviewFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.GetAgentReviewConnectionDocument,
    "\n  query GetIntegrationConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getIntegrationConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          id\n          type\n          displayName\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.GetIntegrationConnectionDocument,
    "\n  query GetInsightConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getInsightConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          ...InsightFragment\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.GetInsightConnectionDocument,
    "\n  query GetInsightWithAgentConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getInsightConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          ...InsightFragment\n          agent {\n            name\n            templateName\n            logo {\n              url\n            }\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.GetInsightWithAgentConnectionDocument,
    "\n  query GetActionConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {\n    connection: getActionConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          ...ActionFragment\n          decisions {\n            edges {\n              node {\n                ...DecisionFragment\n              }\n            }\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.GetActionConnectionDocument,
    "\n  query GetAgentBusinessConnection($filter: JSON, $first: Int) {\n    connection: getAgentBusinessConnection(filter: $filter, first: $first) {\n      edges {\n        node {\n          id\n          status\n        }\n      }\n    }\n  }\n": types.GetAgentBusinessConnectionDocument,
    "\n  mutation AddFileToSession($id: ID!, $input: SessionAddFileInput!) {\n    result: addFileToSession(id: $id, input: $input)\n  }\n": types.AddFileToSessionDocument,
    "\n  mutation UpdateSessionReadTime($input: SessionReadTimeCreateOrUpdateInput!) {\n    createOrUpdateSessionReadTime(input: $input) {\n      sessionId\n      lastReadAt\n    }\n  }\n": types.UpdateSessionReadTimeDocument,
    "\n  query GetGoogleAdsPendingIntegrationConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {\n    connection: getGoogleAdsPendingIntegrationConnection(filter: $filter, order: $order, first: $first, after: $after) {\n      edges {\n        node {\n          id\n          googleId\n          parentGoogleId\n          customerName\n          isManager\n          status\n          error {\n            summary\n            detail\n          }\n        }\n      }\n      pageInfo {\n        ...PageInfoFragment\n      }\n    }\n  }\n": types.GetGoogleAdsPendingIntegrationConnectionDocument,
    "\n  mutation SetPermittedGoogleAdsIntegrations($permittedIds: [UUID!]!) {\n    selectConnectedGoogleAdsIntegrations(permittedIds: $permittedIds) {\n      id\n    }\n  }\n": types.SetPermittedGoogleAdsIntegrationsDocument,
    "\n  mutation ConnectGoogleAdsIntegration(\n    $input: GoogleAdsIntegrationConnectInput!\n  ) {\n    href: connectGoogleAdsIntegration(input: $input)\n  }\n": types.ConnectGoogleAdsIntegrationDocument,
    "\n  mutation ConnectShopifyIntegration($input: ShopifyIntegrationConnectInput!) {\n    href: connectShopifyIntegration(input: $input)\n  }\n": types.ConnectShopifyIntegrationDocument,
    "\n  query GetUser($userId: ID) {\n    user: getUser(id: $userId) {\n      id\n      createdAt\n      updatedAt\n      firstName\n      lastName\n      email\n      phone\n      newsletter\n      description\n      avatar\n      verified\n      stripeCustomerId\n      authProvider\n      authProviderId\n      stripeSubscription {\n        id\n        status\n        nextPaymentAt\n        name\n        image\n        currency\n        amount\n        interval\n        intervalCount\n      }\n      businesses {\n        edges {\n          node {\n            id\n            name\n            agents {\n              edges {\n                node {\n                  id\n                  templateName\n                }\n              }\n            }\n          }\n        }\n      }\n      ipGeolocation\n    }\n  }\n": types.GetUserDocument,
    "\n  mutation StoreUserGeolocation($input: StoreUserGeolocationInput!) {\n    storeUserGeolocation(input: $input) {\n      id\n    }\n  }\n": types.StoreUserGeolocationDocument,
    "\n  mutation RunAgent($id: ID!) {\n    runAgent(id: $id) {\n      id  \n    }\n  }\n": types.RunAgentDocument,
    "\n  mutation UpdateAgentBusiness(\n    $id: ID!\n    $input: AgentBusinessUpdateInput!\n  ) {\n    agentBusiness: updateAgentBusiness(id: $id, input: $input) {\n      id\n      agentId\n      businessId\n      status\n      createdAt\n      updatedAt\n    }\n  }\n": types.UpdateAgentBusinessDocument,
    "\n  mutation CreateCheckoutSession($input: CheckoutSessionCreateInput!) {\n    checkoutSession: createStripeCheckoutSession(input: $input) {\n      id\n      url\n    }\n  }\n": types.CreateCheckoutSessionDocument,
    "\n  mutation GenerateAgentFileLink($id: ID!) {\n    data: generateAgentFileLink(id: $id)\n  }\n": types.GenerateAgentFileLinkDocument,
    "\n  mutation GenerateFileLink($name: String!, $displayName: String) {\n    data: generateFileLink(name: $name, displayName: $displayName)\n  }\n": types.GenerateFileLinkDocument,
    "\n  mutation CreateAmazonAdsIntegration($input: AmazonAdsIntegrationCreateInput!) {\n    createAmazonAdsIntegration(input: $input) {\n      href\n    }\n  }\n": types.CreateAmazonAdsIntegrationDocument,
    "\n  mutation CreateAmazonSpIntegration($input: AmazonSpIntegrationCreateInput!) {\n    createAmazonSpIntegration(input: $input) {\n      href\n    }\n  }\n": types.CreateAmazonSpIntegrationDocument,
    "\n  query GetTopicConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getTopicConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          id\n          title\n          widgets\n          order\n          hasDrilldown\n          explain\n          styleConfig\n          createdAt\n          updatedAt\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.GetTopicConnectionDocument,
    "\n  query GetInsightById($id: ID!, $firstTopics: Int) {\n    insight: getInsight(id: $id) {\n      id\n      title\n      shortDescription\n      impact\n      status\n      widgets\n      styleConfig\n      topics(first: $firstTopics) {\n        edges {\n          node {\n            id\n            agent {\n              id\n              name\n              templateName\n              logo {\n                url\n              }\n            }\n          }\n        }\n      }\n      createdAt\n      updatedAt\n    }\n  }\n": types.GetInsightByIdDocument,
    "\n  query GetTopicConnectionDates(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getTopicConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          createdAt\n          updatedAt\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.GetTopicConnectionDatesDocument,
    "\n  query GetTopicById($id: ID!) {\n    topic: getTopic(id: $id) {\n      id\n      title\n      widgets\n      explain\n      hasDrilldown\n      styleConfig\n      createdAt\n      updatedAt\n    }\n  }\n": types.GetTopicByIdDocument,
    "\n  query GetEventLogConnection($filter: JSON, $order: JSON, $first: Int) {\n    connection: getEventLogConnection(filter: $filter, order: $order, first: $first) {\n      edges {\n        cursor\n        node {\n          userId\n          visibleMarquee\n          visibleUntil\n          updatedAt\n          summary\n          id\n          eventType\n          eventName\n          eventMetadata\n          details\n          deletedAt\n          createdAt\n          businessId\n        }\n      }\n    }\n  }\n": types.GetEventLogConnectionDocument,
    "\n  mutation ChangeUserEmail($id: ID!, $input: ChangeUserEmailInput!) {\n    user: changeUserEmail(id: $id, input: $input) {\n      ...UpdateUserFragment\n    }\n  }\n": types.ChangeUserEmailDocument,
    "\n  mutation DeleteUserAccount($input: DeleteUserAccountInput!, $id: ID) {\n    user: deleteUserAccount(input: $input, id: $id) {\n      id\n    }\n  }\n": types.DeleteUserAccountDocument,
    "\n  mutation UpdateUser($id: ID!, $input: UserUpdateInput!) {\n    user: updateUser(id: $id, input: $input) {\n      ...UpdateUserFragment\n    }\n  }\n": types.UpdateUserDocument,
    "\n  mutation CreatepPortalSession {\n    session: createStripePortalSession {\n      id\n      url\n    }\n  }\n": types.CreatepPortalSessionDocument,
    "\n  query GetProductInfo {\n    getProductInfo {\n      id\n      name\n      image\n      currency\n      amount\n      interval\n      intervalCount\n      trialPeriodDays\n    }\n  }\n": types.GetProductInfoDocument,
    "\n  mutation SetUserStripeCustomerId($checkoutSessionId: String!) {\n    user: setUserStripeCustomerId(checkoutSessionId: $checkoutSessionId) {\n      id\n      createdAt\n      updatedAt\n      firstName\n      lastName\n      email\n      phone\n      newsletter\n      description\n      avatar\n      verified\n      stripeCustomerId\n      stripeSubscription {\n        id\n        status\n      }\n    }\n  }\n": types.SetUserStripeCustomerIdDocument,
    "\n  mutation GoogleLogin {\n    href: googleLogin\n  }\n": types.GoogleLoginDocument,
    "\n  mutation AppleLogin {\n    href: appleLogin\n  }\n": types.AppleLoginDocument,
    "\n  mutation Signup($input: SignupInput!) {\n    signup(input: $input) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n": types.SignupDocument,
    "\n  mutation SubmitNewPassword($input: SubmitPasswordResetInput!) {\n    submitNewPassword(input: $input) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n": types.SubmitNewPasswordDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment InsightFragment on Insight {\n    id\n    impact\n    title\n    shortDescription\n    createdAt\n    status\n    layoutType\n  }\n"): (typeof documents)["\n  fragment InsightFragment on Insight {\n    id\n    impact\n    title\n    shortDescription\n    createdAt\n    status\n    layoutType\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PageInfoFragment on PageInfo {\n    hasNextPage\n    endCursor\n  }\n"): (typeof documents)["\n  fragment PageInfoFragment on PageInfo {\n    hasNextPage\n    endCursor\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UpdateUserFragment on User {\n    id\n    firstName\n    lastName\n    email\n    phone\n    newsletter\n    description\n    avatar\n    verified\n    createdAt\n    updatedAt\n  }\n"): (typeof documents)["\n  fragment UpdateUserFragment on User {\n    id\n    firstName\n    lastName\n    email\n    phone\n    newsletter\n    description\n    avatar\n    verified\n    createdAt\n    updatedAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MessageFragment on Message {\n    id\n    author_type: authorType\n    content\n    session_id: sessionId\n    created_at: createdAt\n    updated_at: updatedAt\n  }\n"): (typeof documents)["\n  fragment MessageFragment on Message {\n    id\n    author_type: authorType\n    content\n    session_id: sessionId\n    created_at: createdAt\n    updated_at: updatedAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DataSourceFragment on DataSource {\n    type\n    name\n    imageSmall {\n      url\n    }\n  }\n"): (typeof documents)["\n  fragment DataSourceFragment on DataSource {\n    type\n    name\n    imageSmall {\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment LanguageFragment on Language {\n    iso2\n    name\n    shortName\n  }\n"): (typeof documents)["\n  fragment LanguageFragment on Language {\n    iso2\n    name\n    shortName\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ToolReviewFragment on ToolReview {\n    rating\n    content {\n      markdown\n    }\n    author\n    title\n    createdAt\n  }\n"): (typeof documents)["\n  fragment ToolReviewFragment on ToolReview {\n    rating\n    content {\n      markdown\n    }\n    author\n    title\n    createdAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ResponsiveImageFragment on ResponsiveImage {\n    default {\n      url\n      blurHash\n      width\n      height\n    }\n    small {\n      url\n      blurHash\n      width\n      height\n    }\n    medium {\n      url\n      blurHash\n      width\n      height\n    }\n    large {\n      url\n      blurHash\n      width\n      height\n    }\n    xLarge {\n      url\n      blurHash\n      width\n      height\n    }\n    xxLarge {\n      url\n      blurHash\n      width\n      height\n    }\n  }\n"): (typeof documents)["\n  fragment ResponsiveImageFragment on ResponsiveImage {\n    default {\n      url\n      blurHash\n      width\n      height\n    }\n    small {\n      url\n      blurHash\n      width\n      height\n    }\n    medium {\n      url\n      blurHash\n      width\n      height\n    }\n    large {\n      url\n      blurHash\n      width\n      height\n    }\n    xLarge {\n      url\n      blurHash\n      width\n      height\n    }\n    xxLarge {\n      url\n      blurHash\n      width\n      height\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AgentFragment on Agent {\n    id\n    templateName\n    catalogueImages {\n      ...ResponsiveImageFragment\n    }\n    cataloguePromoText\n    bannerImage {\n      ...ResponsiveImageFragment\n    }\n    bannerText\n    logo {\n      url\n      blurHash\n      width\n      height\n    }\n    chatAvatar {\n      url\n    }\n    name\n    subtitle\n    description\n    riskIndex\n    images {\n      ...ResponsiveImageFragment\n    }\n    categoryName\n    categoryImage {\n      url\n    }\n    dataSources {\n      ...DataSourceFragment\n    }\n    languages {\n      ...LanguageFragment\n    }\n    rating\n    unlocks\n    activationsText\n    developer\n    copyright\n    explain {\n      prompt\n      content\n    }\n    setupConfig {\n      steps {\n        title\n        name\n        explain {\n          prompt\n          content\n        }\n      }\n    }\n    featured\n  }\n"): (typeof documents)["\n  fragment AgentFragment on Agent {\n    id\n    templateName\n    catalogueImages {\n      ...ResponsiveImageFragment\n    }\n    cataloguePromoText\n    bannerImage {\n      ...ResponsiveImageFragment\n    }\n    bannerText\n    logo {\n      url\n      blurHash\n      width\n      height\n    }\n    chatAvatar {\n      url\n    }\n    name\n    subtitle\n    description\n    riskIndex\n    images {\n      ...ResponsiveImageFragment\n    }\n    categoryName\n    categoryImage {\n      url\n    }\n    dataSources {\n      ...DataSourceFragment\n    }\n    languages {\n      ...LanguageFragment\n    }\n    rating\n    unlocks\n    activationsText\n    developer\n    copyright\n    explain {\n      prompt\n      content\n    }\n    setupConfig {\n      steps {\n        title\n        name\n        explain {\n          prompt\n          content\n        }\n      }\n    }\n    featured\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UpdateCustomerDataFragment on CustomerData {\n    id\n    userId\n    key\n    value\n    createdAt\n    updatedAt\n  }\n"): (typeof documents)["\n  fragment UpdateCustomerDataFragment on CustomerData {\n    id\n    userId\n    key\n    value\n    createdAt\n    updatedAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SessionFragment on Session {\n    id\n    summary\n    locked\n    agent {\n      id\n      name\n      templateName\n      logo {\n        url\n      }\n      chatAvatar {\n        url\n      }\n    }\n    created_at: createdAt\n    updated_at: updatedAt\n    last_read_at: lastReadAt\n  }\n"): (typeof documents)["\n  fragment SessionFragment on Session {\n    id\n    summary\n    locked\n    agent {\n      id\n      name\n      templateName\n      logo {\n        url\n      }\n      chatAvatar {\n        url\n      }\n    }\n    created_at: createdAt\n    updated_at: updatedAt\n    last_read_at: lastReadAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SessionWithMessagesFragment on Session {\n    ...SessionFragment\n    messages(order: $messagesOrder, first: $messagesFirst) {\n      edges {\n        node {\n          ...MessageFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment SessionWithMessagesFragment on Session {\n    ...SessionFragment\n    messages(order: $messagesOrder, first: $messagesFirst) {\n      edges {\n        node {\n          ...MessageFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DecisionFragment on Decision {\n    id\n    type\n    actionId\n    integrationId\n    status\n    title\n    description\n    approveButtonText\n    approvedButtonText\n    body\n    iconUrl\n    sortValue\n    createdAt\n    updatedAt\n  }\n"): (typeof documents)["\n  fragment DecisionFragment on Decision {\n    id\n    type\n    actionId\n    integrationId\n    status\n    title\n    description\n    approveButtonText\n    approvedButtonText\n    body\n    iconUrl\n    sortValue\n    createdAt\n    updatedAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ActionFragment on Action {\n    id\n    status\n    insightId\n    title\n    description\n    iconUrl\n    createdAt\n    updatedAt\n  }\n"): (typeof documents)["\n  fragment ActionFragment on Action {\n    id\n    status\n    insightId\n    title\n    description\n    iconUrl\n    createdAt\n    updatedAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateSession($input: SessionCreateInput!) {\n    session: createSession(input: $input) {\n      ...SessionFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSession($input: SessionCreateInput!) {\n    session: createSession(input: $input) {\n      ...SessionFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateMessage($input: MessageCreateInput!) {\n    message: createMessage(input: $input) {\n      ...MessageFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMessage($input: MessageCreateInput!) {\n    message: createMessage(input: $input) {\n      ...MessageFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ClaimSessions($ids: [ID!]!) {\n    claimSessions(ids: $ids) {\n      id\n      userId\n    }\n  }\n"): (typeof documents)["\n  mutation ClaimSessions($ids: [ID!]!) {\n    claimSessions(ids: $ids) {\n      id\n      userId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpsertCustomerData($input: CustomerDataCreateInput!) {\n    customerData: createOrUpdateCustomerData(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpsertCustomerData($input: CustomerDataCreateInput!) {\n    customerData: createOrUpdateCustomerData(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteCustomerData($id: ID!) {\n    customerData: deleteCustomerData(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCustomerData($id: ID!) {\n    customerData: deleteCustomerData(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UnlockTool($input: AgentBusinessCreateInput!) {\n    agentBusiness: createAgentBusiness(input: $input) {\n      id\n      agentId\n      business {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UnlockTool($input: AgentBusinessCreateInput!) {\n    agentBusiness: createAgentBusiness(input: $input) {\n      id\n      agentId\n      business {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Mutation($input: InitSignupInput!) {\n    initSignup(input: $input)\n  }\n"): (typeof documents)["\n  mutation Mutation($input: InitSignupInput!) {\n    initSignup(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {\n    requestPasswordReset(input: $input)\n  }\n"): (typeof documents)["\n  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {\n    requestPasswordReset(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ApproveDecision($id: ID!) {\n    approveDecision(id: $id) {\n      ...DecisionFragment\n    }\n  }\n"): (typeof documents)["\n  mutation ApproveDecision($id: ID!) {\n    approveDecision(id: $id) {\n      ...DecisionFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateDecision($id: ID!, $input: DecisionUpdateInput!) {\n    updateDecision(id: $id, input: $input) {\n      ...DecisionFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateDecision($id: ID!, $input: DecisionUpdateInput!) {\n    updateDecision(id: $id, input: $input) {\n      ...DecisionFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateAction($id: ID!, $input: ActionUpdateInput!) {\n    updateAction(id: $id, input: $input) {\n      ...ActionFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateAction($id: ID!, $input: ActionUpdateInput!) {\n    updateAction(id: $id, input: $input) {\n      ...ActionFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Query($email: String!) {\n    getUserStatus(email: $email)\n  }\n"): (typeof documents)["\n  query Query($email: String!) {\n    getUserStatus(email: $email)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAgentConnection($filter: JSON, $after: String) {\n    connection: getAgentConnection(filter: $filter, after: $after) {\n      edges {\n        node {\n          ...AgentFragment\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAgentConnection($filter: JSON, $after: String) {\n    connection: getAgentConnection(filter: $filter, after: $after) {\n      edges {\n        node {\n          ...AgentFragment\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAgentByTemplate($template: String!) {\n    agent: getAgentByTemplate(template: $template) {\n      ...AgentFragment\n    }\n  }\n"): (typeof documents)["\n  query GetAgentByTemplate($template: String!) {\n    agent: getAgentByTemplate(template: $template) {\n      ...AgentFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomerDataConnection(\n    $filter: JSON\n    $first: Int\n    $after: String\n    $order: JSON\n  ) {\n    connection: getCustomerDataConnection(\n      filter: $filter\n      first: $first\n      after: $after\n      order: $order\n    ) {\n      edges {\n        node {\n          id\n          key\n          value\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCustomerDataConnection(\n    $filter: JSON\n    $first: Int\n    $after: String\n    $order: JSON\n  ) {\n    connection: getCustomerDataConnection(\n      filter: $filter\n      first: $first\n      after: $after\n      order: $order\n    ) {\n      edges {\n        node {\n          id\n          key\n          value\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSessionConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n    $messagesOrder: JSON\n    $messagesFirst: Int\n  ) {\n    connection: getSessionConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          ...SessionWithMessagesFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSessionConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n    $messagesOrder: JSON\n    $messagesFirst: Int\n  ) {\n    connection: getSessionConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          ...SessionWithMessagesFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMessageConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {\n    connection: getMessageConnection(filter: $filter, order: $order, first: $first, after: $after) {\n      edges {\n        node {\n          ...MessageFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetMessageConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {\n    connection: getMessageConnection(filter: $filter, order: $order, first: $first, after: $after) {\n      edges {\n        node {\n          ...MessageFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAgentReviewConnection($template: String!, $after: String, $first: Int) {\n    connection: getAgentReviewConnection(template: $template, after: $after, first: $first) {\n      edges {\n        node {\n          ...ToolReviewFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAgentReviewConnection($template: String!, $after: String, $first: Int) {\n    connection: getAgentReviewConnection(template: $template, after: $after, first: $first) {\n      edges {\n        node {\n          ...ToolReviewFragment\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetIntegrationConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getIntegrationConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          id\n          type\n          displayName\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetIntegrationConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getIntegrationConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          id\n          type\n          displayName\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetInsightConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getInsightConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          ...InsightFragment\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetInsightConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getInsightConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          ...InsightFragment\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetInsightWithAgentConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getInsightConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          ...InsightFragment\n          agent {\n            name\n            templateName\n            logo {\n              url\n            }\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetInsightWithAgentConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getInsightConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          ...InsightFragment\n          agent {\n            name\n            templateName\n            logo {\n              url\n            }\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetActionConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {\n    connection: getActionConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          ...ActionFragment\n          decisions {\n            edges {\n              node {\n                ...DecisionFragment\n              }\n            }\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetActionConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {\n    connection: getActionConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          ...ActionFragment\n          decisions {\n            edges {\n              node {\n                ...DecisionFragment\n              }\n            }\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAgentBusinessConnection($filter: JSON, $first: Int) {\n    connection: getAgentBusinessConnection(filter: $filter, first: $first) {\n      edges {\n        node {\n          id\n          status\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAgentBusinessConnection($filter: JSON, $first: Int) {\n    connection: getAgentBusinessConnection(filter: $filter, first: $first) {\n      edges {\n        node {\n          id\n          status\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddFileToSession($id: ID!, $input: SessionAddFileInput!) {\n    result: addFileToSession(id: $id, input: $input)\n  }\n"): (typeof documents)["\n  mutation AddFileToSession($id: ID!, $input: SessionAddFileInput!) {\n    result: addFileToSession(id: $id, input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateSessionReadTime($input: SessionReadTimeCreateOrUpdateInput!) {\n    createOrUpdateSessionReadTime(input: $input) {\n      sessionId\n      lastReadAt\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSessionReadTime($input: SessionReadTimeCreateOrUpdateInput!) {\n    createOrUpdateSessionReadTime(input: $input) {\n      sessionId\n      lastReadAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetGoogleAdsPendingIntegrationConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {\n    connection: getGoogleAdsPendingIntegrationConnection(filter: $filter, order: $order, first: $first, after: $after) {\n      edges {\n        node {\n          id\n          googleId\n          parentGoogleId\n          customerName\n          isManager\n          status\n          error {\n            summary\n            detail\n          }\n        }\n      }\n      pageInfo {\n        ...PageInfoFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetGoogleAdsPendingIntegrationConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {\n    connection: getGoogleAdsPendingIntegrationConnection(filter: $filter, order: $order, first: $first, after: $after) {\n      edges {\n        node {\n          id\n          googleId\n          parentGoogleId\n          customerName\n          isManager\n          status\n          error {\n            summary\n            detail\n          }\n        }\n      }\n      pageInfo {\n        ...PageInfoFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetPermittedGoogleAdsIntegrations($permittedIds: [UUID!]!) {\n    selectConnectedGoogleAdsIntegrations(permittedIds: $permittedIds) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation SetPermittedGoogleAdsIntegrations($permittedIds: [UUID!]!) {\n    selectConnectedGoogleAdsIntegrations(permittedIds: $permittedIds) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ConnectGoogleAdsIntegration(\n    $input: GoogleAdsIntegrationConnectInput!\n  ) {\n    href: connectGoogleAdsIntegration(input: $input)\n  }\n"): (typeof documents)["\n  mutation ConnectGoogleAdsIntegration(\n    $input: GoogleAdsIntegrationConnectInput!\n  ) {\n    href: connectGoogleAdsIntegration(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ConnectShopifyIntegration($input: ShopifyIntegrationConnectInput!) {\n    href: connectShopifyIntegration(input: $input)\n  }\n"): (typeof documents)["\n  mutation ConnectShopifyIntegration($input: ShopifyIntegrationConnectInput!) {\n    href: connectShopifyIntegration(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUser($userId: ID) {\n    user: getUser(id: $userId) {\n      id\n      createdAt\n      updatedAt\n      firstName\n      lastName\n      email\n      phone\n      newsletter\n      description\n      avatar\n      verified\n      stripeCustomerId\n      authProvider\n      authProviderId\n      stripeSubscription {\n        id\n        status\n        nextPaymentAt\n        name\n        image\n        currency\n        amount\n        interval\n        intervalCount\n      }\n      businesses {\n        edges {\n          node {\n            id\n            name\n            agents {\n              edges {\n                node {\n                  id\n                  templateName\n                }\n              }\n            }\n          }\n        }\n      }\n      ipGeolocation\n    }\n  }\n"): (typeof documents)["\n  query GetUser($userId: ID) {\n    user: getUser(id: $userId) {\n      id\n      createdAt\n      updatedAt\n      firstName\n      lastName\n      email\n      phone\n      newsletter\n      description\n      avatar\n      verified\n      stripeCustomerId\n      authProvider\n      authProviderId\n      stripeSubscription {\n        id\n        status\n        nextPaymentAt\n        name\n        image\n        currency\n        amount\n        interval\n        intervalCount\n      }\n      businesses {\n        edges {\n          node {\n            id\n            name\n            agents {\n              edges {\n                node {\n                  id\n                  templateName\n                }\n              }\n            }\n          }\n        }\n      }\n      ipGeolocation\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation StoreUserGeolocation($input: StoreUserGeolocationInput!) {\n    storeUserGeolocation(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation StoreUserGeolocation($input: StoreUserGeolocationInput!) {\n    storeUserGeolocation(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RunAgent($id: ID!) {\n    runAgent(id: $id) {\n      id  \n    }\n  }\n"): (typeof documents)["\n  mutation RunAgent($id: ID!) {\n    runAgent(id: $id) {\n      id  \n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateAgentBusiness(\n    $id: ID!\n    $input: AgentBusinessUpdateInput!\n  ) {\n    agentBusiness: updateAgentBusiness(id: $id, input: $input) {\n      id\n      agentId\n      businessId\n      status\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateAgentBusiness(\n    $id: ID!\n    $input: AgentBusinessUpdateInput!\n  ) {\n    agentBusiness: updateAgentBusiness(id: $id, input: $input) {\n      id\n      agentId\n      businessId\n      status\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateCheckoutSession($input: CheckoutSessionCreateInput!) {\n    checkoutSession: createStripeCheckoutSession(input: $input) {\n      id\n      url\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCheckoutSession($input: CheckoutSessionCreateInput!) {\n    checkoutSession: createStripeCheckoutSession(input: $input) {\n      id\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateAgentFileLink($id: ID!) {\n    data: generateAgentFileLink(id: $id)\n  }\n"): (typeof documents)["\n  mutation GenerateAgentFileLink($id: ID!) {\n    data: generateAgentFileLink(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateFileLink($name: String!, $displayName: String) {\n    data: generateFileLink(name: $name, displayName: $displayName)\n  }\n"): (typeof documents)["\n  mutation GenerateFileLink($name: String!, $displayName: String) {\n    data: generateFileLink(name: $name, displayName: $displayName)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateAmazonAdsIntegration($input: AmazonAdsIntegrationCreateInput!) {\n    createAmazonAdsIntegration(input: $input) {\n      href\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAmazonAdsIntegration($input: AmazonAdsIntegrationCreateInput!) {\n    createAmazonAdsIntegration(input: $input) {\n      href\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateAmazonSpIntegration($input: AmazonSpIntegrationCreateInput!) {\n    createAmazonSpIntegration(input: $input) {\n      href\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAmazonSpIntegration($input: AmazonSpIntegrationCreateInput!) {\n    createAmazonSpIntegration(input: $input) {\n      href\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTopicConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getTopicConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          id\n          title\n          widgets\n          order\n          hasDrilldown\n          explain\n          styleConfig\n          createdAt\n          updatedAt\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTopicConnection(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getTopicConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          id\n          title\n          widgets\n          order\n          hasDrilldown\n          explain\n          styleConfig\n          createdAt\n          updatedAt\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetInsightById($id: ID!, $firstTopics: Int) {\n    insight: getInsight(id: $id) {\n      id\n      title\n      shortDescription\n      impact\n      status\n      widgets\n      styleConfig\n      topics(first: $firstTopics) {\n        edges {\n          node {\n            id\n            agent {\n              id\n              name\n              templateName\n              logo {\n                url\n              }\n            }\n          }\n        }\n      }\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query GetInsightById($id: ID!, $firstTopics: Int) {\n    insight: getInsight(id: $id) {\n      id\n      title\n      shortDescription\n      impact\n      status\n      widgets\n      styleConfig\n      topics(first: $firstTopics) {\n        edges {\n          node {\n            id\n            agent {\n              id\n              name\n              templateName\n              logo {\n                url\n              }\n            }\n          }\n        }\n      }\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTopicConnectionDates(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getTopicConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          createdAt\n          updatedAt\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTopicConnectionDates(\n    $filter: JSON\n    $order: JSON\n    $first: Int\n    $after: String\n  ) {\n    connection: getTopicConnection(\n      filter: $filter\n      order: $order\n      first: $first\n      after: $after\n    ) {\n      edges {\n        node {\n          createdAt\n          updatedAt\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTopicById($id: ID!) {\n    topic: getTopic(id: $id) {\n      id\n      title\n      widgets\n      explain\n      hasDrilldown\n      styleConfig\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query GetTopicById($id: ID!) {\n    topic: getTopic(id: $id) {\n      id\n      title\n      widgets\n      explain\n      hasDrilldown\n      styleConfig\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetEventLogConnection($filter: JSON, $order: JSON, $first: Int) {\n    connection: getEventLogConnection(filter: $filter, order: $order, first: $first) {\n      edges {\n        cursor\n        node {\n          userId\n          visibleMarquee\n          visibleUntil\n          updatedAt\n          summary\n          id\n          eventType\n          eventName\n          eventMetadata\n          details\n          deletedAt\n          createdAt\n          businessId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetEventLogConnection($filter: JSON, $order: JSON, $first: Int) {\n    connection: getEventLogConnection(filter: $filter, order: $order, first: $first) {\n      edges {\n        cursor\n        node {\n          userId\n          visibleMarquee\n          visibleUntil\n          updatedAt\n          summary\n          id\n          eventType\n          eventName\n          eventMetadata\n          details\n          deletedAt\n          createdAt\n          businessId\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangeUserEmail($id: ID!, $input: ChangeUserEmailInput!) {\n    user: changeUserEmail(id: $id, input: $input) {\n      ...UpdateUserFragment\n    }\n  }\n"): (typeof documents)["\n  mutation ChangeUserEmail($id: ID!, $input: ChangeUserEmailInput!) {\n    user: changeUserEmail(id: $id, input: $input) {\n      ...UpdateUserFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteUserAccount($input: DeleteUserAccountInput!, $id: ID) {\n    user: deleteUserAccount(input: $input, id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteUserAccount($input: DeleteUserAccountInput!, $id: ID) {\n    user: deleteUserAccount(input: $input, id: $id) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateUser($id: ID!, $input: UserUpdateInput!) {\n    user: updateUser(id: $id, input: $input) {\n      ...UpdateUserFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUser($id: ID!, $input: UserUpdateInput!) {\n    user: updateUser(id: $id, input: $input) {\n      ...UpdateUserFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreatepPortalSession {\n    session: createStripePortalSession {\n      id\n      url\n    }\n  }\n"): (typeof documents)["\n  mutation CreatepPortalSession {\n    session: createStripePortalSession {\n      id\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProductInfo {\n    getProductInfo {\n      id\n      name\n      image\n      currency\n      amount\n      interval\n      intervalCount\n      trialPeriodDays\n    }\n  }\n"): (typeof documents)["\n  query GetProductInfo {\n    getProductInfo {\n      id\n      name\n      image\n      currency\n      amount\n      interval\n      intervalCount\n      trialPeriodDays\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetUserStripeCustomerId($checkoutSessionId: String!) {\n    user: setUserStripeCustomerId(checkoutSessionId: $checkoutSessionId) {\n      id\n      createdAt\n      updatedAt\n      firstName\n      lastName\n      email\n      phone\n      newsletter\n      description\n      avatar\n      verified\n      stripeCustomerId\n      stripeSubscription {\n        id\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetUserStripeCustomerId($checkoutSessionId: String!) {\n    user: setUserStripeCustomerId(checkoutSessionId: $checkoutSessionId) {\n      id\n      createdAt\n      updatedAt\n      firstName\n      lastName\n      email\n      phone\n      newsletter\n      description\n      avatar\n      verified\n      stripeCustomerId\n      stripeSubscription {\n        id\n        status\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GoogleLogin {\n    href: googleLogin\n  }\n"): (typeof documents)["\n  mutation GoogleLogin {\n    href: googleLogin\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AppleLogin {\n    href: appleLogin\n  }\n"): (typeof documents)["\n  mutation AppleLogin {\n    href: appleLogin\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Signup($input: SignupInput!) {\n    signup(input: $input) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation Signup($input: SignupInput!) {\n    signup(input: $input) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SubmitNewPassword($input: SubmitPasswordResetInput!) {\n    submitNewPassword(input: $input) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation SubmitNewPassword($input: SubmitPasswordResetInput!) {\n    submitNewPassword(input: $input) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;