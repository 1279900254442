import {SVGProps} from 'react';

export default function StarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M3.43359 12.5117C3.29688 12.4102 3.21289 12.2734 3.18164 12.1016C3.1543 11.9336 3.17969 11.7344 3.25781 11.5039L4.46484 7.92383L1.38867 5.71484C1.18945 5.57422 1.05078 5.42773 0.972656 5.27539C0.894531 5.11914 0.882812 4.95898 0.9375 4.79492C0.992188 4.63477 1.0957 4.51562 1.24805 4.4375C1.40039 4.35547 1.59961 4.31641 1.8457 4.32031L5.61328 4.34375L6.76172 0.746094C6.83594 0.511719 6.93359 0.333984 7.05469 0.212891C7.17969 0.0878906 7.32617 0.0253906 7.49414 0.0253906C7.66602 0.0253906 7.8125 0.0878906 7.93359 0.212891C8.05859 0.333984 8.1582 0.511719 8.23242 0.746094L9.38086 4.34375L13.1484 4.32031C13.3945 4.31641 13.5938 4.35547 13.7461 4.4375C13.8984 4.51562 14.002 4.63477 14.0566 4.79492C14.1113 4.95898 14.0996 5.11914 14.0215 5.27539C13.9434 5.42773 13.8047 5.57422 13.6055 5.71484L10.5293 7.92383L11.7363 11.5039C11.8145 11.7344 11.8379 11.9336 11.8066 12.1016C11.7793 12.2734 11.6973 12.4102 11.5605 12.5117C11.4238 12.6211 11.2695 12.6602 11.0977 12.6289C10.9258 12.5977 10.7422 12.5117 10.5469 12.3711L7.49414 10.127L4.44727 12.3711C4.25195 12.5117 4.06836 12.5977 3.89648 12.6289C3.72461 12.6602 3.57031 12.6211 3.43359 12.5117Z"
        fill="currentColor"
      />
    </svg>
  );
}
