import {SVGProps} from 'react';

export default function FileSearchIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0 20.627C0 9.58126 8.95431 0.626953 20 0.626953C31.0457 0.626953 40 9.58126 40 20.627C40 31.6726 31.0457 40.627 20 40.627C8.95431 40.627 0 31.6726 0 20.627Z"
        fill="url(#paint0_radial_2306_33006)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2413 9.50781H16.7587C15.9537 9.5078 15.2894 9.50779 14.7482 9.55201C14.1861 9.59793 13.6694 9.6965 13.184 9.94379C12.4314 10.3273 11.8195 10.9392 11.436 11.6919C11.1887 12.1772 11.0901 12.6939 11.0442 13.256C11 13.7972 11 14.4615 11 15.2665V25.7491C11 26.5541 11 27.2185 11.0442 27.7596C11.0901 28.3217 11.1887 28.8384 11.436 29.3238C11.8195 30.0764 12.4314 30.6883 13.184 31.0718C13.6694 31.3191 14.1861 31.4177 14.7482 31.4636C15.2894 31.5078 15.9537 31.5078 16.7587 31.5078H19.6333C20.342 31.5078 20.6963 31.5078 20.8283 31.411C20.9626 31.3126 21.0147 31.2208 21.0304 31.055C21.0459 30.8921 20.8354 30.5395 20.4145 29.8342C19.8337 28.8611 19.5 27.7234 19.5 26.5078C19.5 22.918 22.4101 20.0078 26 20.0078C26.6159 20.0078 27.2118 20.0935 27.7764 20.2535C28.2591 20.3904 28.5004 20.4588 28.6372 20.4198C28.7705 20.3817 28.8522 20.32 28.9252 20.202C29 20.0811 29 19.8611 29 19.4209V15.2665C29 14.4615 29 13.7972 28.9558 13.256C28.9099 12.6939 28.8113 12.1772 28.564 11.6919C28.1805 10.9392 27.5686 10.3273 26.816 9.94379C26.3306 9.6965 25.8139 9.59793 25.2518 9.55201C24.7106 9.50779 24.0463 9.5078 23.2413 9.50781ZM15 13.5078C14.4477 13.5078 14 13.9555 14 14.5078C14 15.0601 14.4477 15.5078 15 15.5078H24C24.5523 15.5078 25 15.0601 25 14.5078C25 13.9555 24.5523 13.5078 24 13.5078H15ZM14 18.5078C14 17.9555 14.4477 17.5078 15 17.5078H21C21.5523 17.5078 22 17.9555 22 18.5078C22 19.0601 21.5523 19.5078 21 19.5078H15C14.4477 19.5078 14 19.0601 14 18.5078ZM14 22.5078C14 21.9555 14.4477 21.5078 15 21.5078H17C17.5523 21.5078 18 21.9555 18 22.5078C18 23.0601 17.5523 23.5078 17 23.5078H15C14.4477 23.5078 14 23.0601 14 22.5078Z"
        fill="url(#paint1_linear_2306_33006)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 26.5078C21.5 24.0225 23.5147 22.0078 26 22.0078C28.4853 22.0078 30.5 24.0225 30.5 26.5078C30.5 27.3884 30.2471 28.2098 29.81 28.9036L30.7071 29.8007C31.0976 30.1912 31.0976 30.8244 30.7071 31.2149C30.3166 31.6054 29.6834 31.6054 29.2929 31.2149L28.3957 30.3178C27.702 30.7549 26.8805 31.0078 26 31.0078C23.5147 31.0078 21.5 28.9931 21.5 26.5078ZM26 24.0078C24.6193 24.0078 23.5 25.1271 23.5 26.5078C23.5 27.8885 24.6193 29.0078 26 29.0078C27.3807 29.0078 28.5 27.8885 28.5 26.5078C28.5 25.1271 27.3807 24.0078 26 24.0078Z"
        fill="url(#paint2_linear_2306_33006)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2306_33006"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40 0.626953) rotate(135) scale(52.254)">
          <stop offset="0.16898" stopColor="#283DFF" />
          <stop offset="1" stopColor="#0C145C" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_2306_33006"
          x1="21"
          y1="9.50781"
          x2="21"
          y2="31.5078"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4EEFE" />
          <stop offset="1" stopColor="#8E9AE3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2306_33006"
          x1="21"
          y1="9.50781"
          x2="21"
          y2="31.5078"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4EEFE" />
          <stop offset="1" stopColor="#8E9AE3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
