import {ReactNode, forwardRef, memo, useMemo, useRef} from 'react';
import AnimatedTextCompletion from '@/components/AnimatedTextCompletion';
import {useDidUpdateEffect} from '@/hooks';
import trottle from 'lodash/throttle';

const disableAnimation = true;

const MarkdownComponent = forwardRef(
  (
    {
      Container,
      className = '',
      children,
      ...rest
    }: {
      Container: any;
      className: string;
      children: ReactNode;
    },
    ref,
  ) => (
    <Container ref={ref} className={className} {...rest}>
      {typeof children !== 'string' ? (
        children
      ) : disableAnimation ? (
        <span className={className}>{children}</span>
      ) : (
        <AnimatedTextCompletion
          text={children}
          initialAnimation={true}
          className={className}
        />
      )}
    </Container>
  ),
);

MarkdownComponent.displayName = 'MarkdownComponent';

// forces redraw for ref
// to fix weird padding issues with lists on Safari
// https://app.clickup.com/t/86b0vvz1g
const useForceRedraw = (ref: any, children: any) => {
  const forceRedraw = useMemo(() => {
    return trottle(
      () => {
        if (!ref?.current) {
          return;
        }

        ref.current.style.display = 'block';
        ref.current.offsetHeight;
        ref.current.style.display = 'list-item';
      },
      500,
      {
        leading: true,
        trailing: true,
      },
    );
  }, [ref]);

  useDidUpdateEffect(() => {
    forceRedraw();
  }, [children]);
};

const LiMarkdownComponent = memo(
  ({children, ...rest}: {className: string; children: ReactNode}) => {
    const ref = useRef<HTMLDivElement>(null);

    useForceRedraw(ref, children);

    return (
      <MarkdownComponent Container={'li'} ref={ref} {...rest}>
        {children}
      </MarkdownComponent>
    );
  },
);

LiMarkdownComponent.displayName = 'LiMarkdownComponent';

export default {
  p: ({children, className}: any) => (
    <MarkdownComponent Container={'div'} className={className}>
      {children}
    </MarkdownComponent>
  ),
  li: ({children, node, ...rest}: any) => (
    <LiMarkdownComponent {...rest}>{children}</LiMarkdownComponent>
  ),
  h1: ({children, node, ...rest}: any) => (
    <MarkdownComponent Container={'h1'} {...rest}>
      {children}
    </MarkdownComponent>
  ),
  h2: ({children, node, ...rest}: any) => (
    <MarkdownComponent Container={'h2'} {...rest}>
      {children}
    </MarkdownComponent>
  ),
  h3: ({children, node, ...rest}: any) => (
    <MarkdownComponent Container={'h3'} {...rest}>
      {children}
    </MarkdownComponent>
  ),
  h4: ({children, node, ...rest}: any) => (
    <MarkdownComponent Container={'h4'} {...rest}>
      {children}
    </MarkdownComponent>
  ),
  h5: ({children, node, ...rest}: any) => (
    <MarkdownComponent Container={'h5'} {...rest}>
      {children}
    </MarkdownComponent>
  ),
  h6: ({children, node, ...rest}: any) => (
    <MarkdownComponent Container={'h6'} {...rest}>
      {children}
    </MarkdownComponent>
  ),
  a: ({children, node, ...rest}: any) => {
    return (
      <MarkdownComponent Container={'a'} {...rest}>
        {children}
      </MarkdownComponent>
    );
  },
  strong: ({children, node, ...rest}: any) => (
    <MarkdownComponent Container={'strong'} {...rest}>
      {children}
    </MarkdownComponent>
  ),
  em: ({children, node, ...rest}: any) => (
    <MarkdownComponent Container={'em'} {...rest}>
      {children}
    </MarkdownComponent>
  ),
  table: ({children, node, className, ...rest}: any) => (
    <MarkdownComponent
      Container={'table'}
      className={`${className ?? ''} block w-full min-w-full max-w-full overflow-auto`}
      {...rest}>
      {children}
    </MarkdownComponent>
  ),
  code: ({children, className}: any) => (
    <MarkdownComponent
      Container={'code'}
      className={`text-sm ${className ?? ''}`}>
      {children}
    </MarkdownComponent>
  ),
};
