import {useChatContext} from '@/hooks';
import {Outlet, useLocation} from 'react-router-dom';
import {memo, PropsWithChildren, useEffect} from 'react';

const PortalChatRoot = memo(({children}: PropsWithChildren) => {
  const location = useLocation();
  const {setFromLocation} = useChatContext();

  useEffect(() => {
    if (location.state?.from) {
      setFromLocation(s => {
        return s || location.state.from;
      });
    }
  }, [location.state?.from]);

  useEffect(() => {
    return () => {
      console.log('PortalChatRoot cleanup');
      setFromLocation(null);
    };
  }, []);

  return children ? children : <Outlet />;
});

PortalChatRoot.displayName = 'PortalChatRoot';

export default PortalChatRoot;
