import {FC, Suspense, lazy} from 'react';
import {createBrowserRouter, Outlet, RouteObject} from 'react-router-dom';
import {wrapCreateBrowserRouter} from '@sentry/react';
import PortalSpinner from './components/PortalSpinner';
import NavigateWithSearch from './components/NavigateWithSearch';
import ErrorPage from './ErrorPage';
import AmazonSpAuth from './routes/AmazonSpAuth';
import AmazonAdsAuth from './routes/AmazonAdsAuth';
import StripeSuccess from './routes/StripeSuccess';
import LegalDocument from './routes/legal/LegalDocument';
import ProtectedRoute from './routes/components/ProtectedRoute';
import Login from './routes/login/Login';
import LoginOptions from './routes/login/LoginOptions';
import LoginSignUpInvited from './routes/login/LoginSignUpInvited';
import LoginProfile from './routes/login/LoginProfile';
import AppInvite from './routes/AppInvite';
import AuthRedirect from './routes/AuthRedirect';
import ResetPassword from './routes/resetPassword/ResetPassword';
import ResetPasswordEmail from './routes/resetPassword/ResetPasswordEmail';
import ResetPasswordInvited from './routes/resetPassword/ResetPasswordInvited';
import ResetPasswordNewPassword from './routes/resetPassword/ResetPasswordNewPassword';
import {
  SearchParamsProvider,
  ChatProvider,
  ErrorParamHandlerProvider,
} from './components/providers';
import Root from './routes/Root';
import Catalogue from './routes/Catalogue';
import CatalogueApp from './routes/CatalogueApp';
import PortalChat from './routes/PortalChat';
import PortalChatSessions from './routes/PortalChatSessions';
import UnlockStep from './routes/CatalogueApp/unlock/UnlockStep';
import ToolOverview from './routes/CatalogueApp/unlocked/ToolOverview';
import ToolSettings from './routes/CatalogueApp/unlocked/settings/ToolSettings';
import TopicDrilldown from './routes/CatalogueApp/unlocked/topic/TopicDrilldown';
import InsightDrilldown from './routes/CatalogueApp/unlocked/insight/InsightDrilldown';
import TableDrilldown from './routes/CatalogueApp/unlocked/TableDrilldown';
import StepEditPage from './routes/CatalogueApp/unlocked/settings/StepEditPage';
import Profile from './routes/Profile';
import ProfileAccount from './routes/Profile/routes/Account';
import ProfileSettings from './routes/Profile/routes/Settings';
import ProfileSettingsOption from './routes/Profile/routes/SettingsOption';
import ProfileData from './routes/Profile/routes/Data';
import ProfileDataOption from './routes/Profile/routes/DataOption';
import ProfileSubscription from './routes/Profile/routes/Subscription';
import Live from './routes/Live';
import LiveActiveTools from './routes/Live/routes/ActiveTools';
import {ToolProvider} from './components/providers/ToolProvider';
import ProtectedToolRoute from './routes/components/ProtectedToolRoute';
import {PATH} from './config';
import ChatInputProvider from './components/providers/ChatInputProvider';
import ChatPreview from './components/Chat/components/ChatPreview';
import PortalChatRoot from './routes/PortalChatRoot';
import LiveInsights from './routes/Live/routes/LiveInsights';

/* eslint-disable react-refresh/only-export-components */

const Loader: FC = () => (
  <PortalSpinner
    classNames={{
      base: 'backdrop-blur-sm fixed inset-0 z-[1000]',
    }}
    size="lg"
  />
);

const withSuspense = (Component: FC) => {
  const SuspenseWrapper: FC = () => (
    <Suspense fallback={<Loader />}>
      <Component />
    </Suspense>
  );

  return SuspenseWrapper;
};

const Legal = withSuspense(lazy(() => import('./routes/legal')));
const TermsRoute = withSuspense(() => (
  <LegalDocument file={import('@/assets/legal/terms.md')} />
));
const PrivacyRoute = withSuspense(() => (
  <LegalDocument file={import('@/assets/legal/privacy.md')} />
));
const DataProcessingAddendumRoute = withSuspense(() => (
  <LegalDocument file={import('@/assets/legal/data-processing-addendum.md')} />
));
const SubprocessorsRoute = withSuspense(() => (
  <LegalDocument file={import('@/assets/legal/subprocessors.md')} />
));
const NotFound = withSuspense(lazy(() => import('./routes/NotFound')));

const AmazonSpAuthRoute = withSuspense(AmazonSpAuth);
const AmazonAdsAuthRoute = withSuspense(AmazonAdsAuth);

const LoginOptionsRoute = withSuspense(LoginOptions);
const LoginSignUpInvitedRoute = withSuspense(LoginSignUpInvited);
const LoginProfileRoute = withSuspense(LoginProfile);

const ResetPasswordRoute = withSuspense(ResetPassword);
const ResetPasswordEmailRoute = withSuspense(ResetPasswordEmail);
const ResetPasswordInvitedRoute = withSuspense(ResetPasswordInvited);
const ResetPasswordNewPasswordRoute = withSuspense(ResetPasswordNewPassword);

const CatalogueRoute = withSuspense(Catalogue);
const ChatPreviewRoute = withSuspense(ChatPreview);
const PortalChatRootRoute = withSuspense(PortalChatRoot);
const PortalChatRoute = withSuspense(PortalChat);
const PortalChatSessionsRoute = withSuspense(PortalChatSessions);
const ToolOverviewRoute = withSuspense(ToolOverview);
const ToolSettingsRoute = withSuspense(ToolSettings);
const TopicDrilldownRoute = withSuspense(TopicDrilldown);
const InsightDrilldownRoute = withSuspense(InsightDrilldown);
const TableDrilldownRoute = withSuspense(TableDrilldown);
const StepEditPageRoute = withSuspense(StepEditPage);

const ProfileRoute = withSuspense(Profile);
const ProfileAccountRoute = withSuspense(ProfileAccount);
const ProfileSettingsRoute = withSuspense(ProfileSettings);
const ProfileSettingsOptionRoute = withSuspense(ProfileSettingsOption);
const ProfileDataRoute = withSuspense(ProfileData);
const ProfileDataOptionRoute = withSuspense(ProfileDataOption);
const ProfileSubscriptionRoute = withSuspense(ProfileSubscription);

const LiveRoute = withSuspense(Live);
const LiveInsightsRoute = withSuspense(LiveInsights);
const LiveActiveToolsRoute = withSuspense(LiveActiveTools);

const authPath: RouteObject = {
  path: 'auth',
  element: <Outlet />,
  children: [
    {
      path: 'login',
      element: <Login />,
      children: [
        {
          path: '',
          element: <LoginOptionsRoute />,
        },
        {
          path: 'invited',
          element: <LoginSignUpInvitedRoute />,
        },
        {
          path: 'profile',
          element: <LoginProfileRoute />,
        },
      ],
    },
    {
      path: 'reset-password',
      element: <ResetPasswordRoute />,
      children: [
        {
          path: 'request',
          element: <ResetPasswordEmailRoute />,
        },
        {
          path: 'requested',
          element: <ResetPasswordInvitedRoute />,
        },
        {
          path: 'set',
          element: <ResetPasswordNewPasswordRoute />,
        },
      ],
    },
    {
      path: 'invite',
      element: <AppInvite />,
    },
    {
      path: 'redirect',
      element: <AuthRedirect />,
    },
  ],
};

const router = wrapCreateBrowserRouter(createBrowserRouter)([
  {
    path: '/',
    element: (
      <SearchParamsProvider>
        <ErrorParamHandlerProvider>
          <ChatProvider>
            <ChatInputProvider>
              <Root />
            </ChatInputProvider>
          </ChatProvider>
        </ErrorParamHandlerProvider>
      </SearchParamsProvider>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'stripe-success',
        element: (
          <ProtectedRoute>
            <StripeSuccess />
          </ProtectedRoute>
        ),
      },
      {
        path: 'amazon-sp-auth',
        element: <AmazonSpAuthRoute />,
      },
      {
        path: 'amazon-ads-auth',
        element: <AmazonAdsAuthRoute />,
      },
      {
        path: 'legal',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Legal />,
          },
          {
            path: 'terms',
            element: <TermsRoute />,
          },
          {
            path: 'privacy',
            element: <PrivacyRoute />,
          },
          {
            path: 'data-processing-addendum',
            element: <DataProcessingAddendumRoute />,
          },
          {
            path: 'subprocessors',
            element: <SubprocessorsRoute />,
          },
        ],
      },
      {
        path: 'catalog',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <CatalogueRoute />,
            children: [authPath],
          },
          {
            path: ':appName',
            element: <ToolProvider />,
            children: [
              {
                path: 'info',
                element: <CatalogueApp />,
                children: [
                  authPath,
                  {
                    path: 'unlock/:stepId',
                    element: (
                      <ProtectedRoute redirectTo="../info">
                        <UnlockStep />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: 'unlocked',
                element: (
                  <ProtectedRoute redirectTo="../info">
                    <ProtectedToolRoute />
                  </ProtectedRoute>
                ),
                children: [
                  {
                    index: true,
                    element: <ToolOverviewRoute />,
                  },
                  {
                    path: 'settings',
                    element: <Outlet />,
                    children: [
                      {index: true, element: <ToolSettingsRoute />},
                      {
                        path: ':stepId',
                        element: <StepEditPageRoute />,
                      },
                    ],
                  },
                  {
                    path: 'topic/:topicId',
                    element: <Outlet />,
                    children: [
                      {
                        index: true,
                        element: <TopicDrilldownRoute />,
                      },
                      {
                        path: 'table',
                        element: <TableDrilldownRoute />,
                      },
                      {
                        path: 'insight/:insightId',
                        element: <InsightDrilldownRoute />,
                      },
                      {
                        path: 'insight/:insightId/table',
                        element: <TableDrilldownRoute />,
                      },
                    ],
                  },
                  {
                    path: 'insight/:insightId',
                    element: <InsightDrilldownRoute />,
                  },
                  {
                    path: 'insight/:insightId/table',
                    element: <TableDrilldownRoute />,
                  },
                  {
                    path: 'info',
                    element: <CatalogueApp infoMode />,
                  },
                ],
              },
              {
                path: '',
                element: <NavigateWithSearch to="info" />,
              },
              {
                path: '*',
                element: <NavigateWithSearch to="info" />,
              },
            ],
          },
        ],
      },
      {
        path: 'profile',
        element: (
          <ProtectedRoute>
            <ProfileRoute />
          </ProtectedRoute>
        ),
        children: [
          {
            path: 'account',
            element: <ProfileAccountRoute />,
          },
          {
            path: 'settings',
            element: <ProfileSettingsRoute />,
            children: [
              authPath,
              {
                path: ':option?',
                element: <ProfileSettingsOptionRoute />,
              },
            ],
          },
          {
            path: 'data',
            element: <ProfileDataRoute />,
            children: [
              {
                path: ':option?',
                element: <ProfileDataOptionRoute />,
              },
            ],
          },
          {
            path: 'subscription',
            element: <ProfileSubscriptionRoute />,
          },
        ],
      },
      {
        path: PATH.segment.chat,
        element: <PortalChatRootRoute />,
        children: [
          {
            index: true,
            element: <ChatPreviewRoute />,
          },
          {
            path: PATH.segment.sessions,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <PortalChatSessionsRoute />,
              },
              {
                path: PATH.param.sessionId,
                element: <PortalChatRoute />,
              },
            ],
          },
        ],
      },
      {
        path: 'live',
        element: <ProtectedRoute />,
        children: [
          {
            path: '',
            element: <LiveRoute />,
            children: [authPath],
          },
          {
            path: 'insights',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <LiveInsightsRoute />,
              },
              {
                path: ':insightId',
                element: <InsightDrilldownRoute />,
              },
              {
                path: ':insightId/table',
                element: <TableDrilldownRoute />,
              },
            ],
          },
          {
            path: 'insight/:insightId',
            element: <InsightDrilldownRoute />,
          },
          {
            path: 'insight/:insightId/table',
            element: <TableDrilldownRoute />,
          },
          {
            path: 'active-tools',
            element: <LiveActiveToolsRoute />,
          },
        ],
      },
      {
        path: 'login',
        element: <NavigateWithSearch to="/catalog/auth/login" />,
      },
      {
        path: 'auth/redirect',
        element: <NavigateWithSearch to="/catalog/auth/redirect" />,
      },
      {path: '', element: <NavigateWithSearch to="/catalog" />},
      {path: '*', element: <NotFound />},
    ],
  },
]);

export default router;
