import ChatInput from '@/components/Chat/components/ChatInput';
import {useChatContext} from '@/hooks';
import {Button} from '@nextui-org/react';
import {Link as RouterLink} from 'react-router-dom';
import {memo} from 'react';
import {Trans} from '@lingui/macro';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon';
import ChatWindow from '@/components/Chat/components/ChatWindow';
import PortalChatHeader from './PortalChatHeader';

const PortalChat = memo(() => {
  const {fromLocation} = useChatContext();
  const backTitle = fromLocation?.pathname.includes('insight') ? (
    <Trans>Insight</Trans>
  ) : /^\/catalog\/\w+/.test(fromLocation?.pathname ?? '') ? (
    <Trans>Tool</Trans>
  ) : null;

  return (
    <div className="relative flex w-full max-w-[min(100dvw,48rem)] grow flex-col">
      <PortalChatHeader />
      {Boolean(backTitle) && (
        <Button
          aria-label="Back"
          as={RouterLink}
          to={{pathname: fromLocation?.pathname, search: fromLocation?.search}}
          className="h-9 items-center bg-primary/[0.04] text-sm"
          variant="light"
          color="primary"
          radius="none"
          startContent={<ChevronLeftIcon className="h-5 w-5 shrink-0" />}>
          <Trans>To the</Trans> {backTitle}
        </Button>
      )}
      <div className="flex w-full max-w-full grow flex-col">
        <ChatWindow />
        <ChatInput />
      </div>
    </div>
  );
});

PortalChat.displayName = 'PortalChat';

export default PortalChat;
