import {gql} from '@/__generated__';

export const GET_USER_STATUS = gql(`
  query Query($email: String!) {
    getUserStatus(email: $email)
  }
`);

export const GET_AGENT_CONNECTION = gql(`
  query GetAgentConnection($filter: JSON, $after: String) {
    connection: getAgentConnection(filter: $filter, after: $after) {
      edges {
        node {
          ...AgentFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

export const GET_AGENT = gql(`
  query GetAgentByTemplate($template: String!) {
    agent: getAgentByTemplate(template: $template) {
      ...AgentFragment
    }
  }
`);

export const GET_CUSTOMER_DATA_CONNECTION = gql(`
  query GetCustomerDataConnection(
    $filter: JSON
    $first: Int
    $after: String
    $order: JSON
  ) {
    connection: getCustomerDataConnection(
      filter: $filter
      first: $first
      after: $after
      order: $order
    ) {
      edges {
        node {
          id
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

export const GET_CHAT_SESSIONS = gql(`
  query GetSessionConnection(
    $filter: JSON
    $order: JSON
    $first: Int
    $after: String
    $messagesOrder: JSON
    $messagesFirst: Int
  ) {
    connection: getSessionConnection(
      filter: $filter
      order: $order
      first: $first
      after: $after
    ) {
      edges {
        node {
          ...SessionWithMessagesFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const GET_MESSAGE_CONNECTION = gql(`
  query GetMessageConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {
    connection: getMessageConnection(filter: $filter, order: $order, first: $first, after: $after) {
      edges {
        node {
          ...MessageFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const GET_AGENT_REVIEW_CONNECTION = gql(`
  query GetAgentReviewConnection($template: String!, $after: String, $first: Int) {
    connection: getAgentReviewConnection(template: $template, after: $after, first: $first) {
      edges {
        node {
          ...ToolReviewFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const GET_INTEGRATIONS = gql(`
  query GetIntegrationConnection(
    $filter: JSON
    $order: JSON
    $first: Int
    $after: String
  ) {
    connection: getIntegrationConnection(
      filter: $filter
      order: $order
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          type
          displayName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const GET_INSIGHT_CONNECTION = gql(`
  query GetInsightConnection(
    $filter: JSON
    $order: JSON
    $first: Int
    $after: String
  ) {
    connection: getInsightConnection(
      filter: $filter
      order: $order
      first: $first
      after: $after
    ) {
      edges {
        node {
          ...InsightFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

export const GET_INSIGHT_WITH_AGENT_CONNECTION = gql(`
  query GetInsightWithAgentConnection(
    $filter: JSON
    $order: JSON
    $first: Int
    $after: String
  ) {
    connection: getInsightConnection(
      filter: $filter
      order: $order
      first: $first
      after: $after
    ) {
      edges {
        node {
          ...InsightFragment
          agent {
            name
            templateName
            logo {
              url
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

export const GET_ACTIONS = gql(`
  query GetActionConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {
    connection: getActionConnection(
      filter: $filter
      order: $order
      first: $first
      after: $after
    ) {
      edges {
        node {
          ...ActionFragment
          decisions {
            edges {
              node {
                ...DecisionFragment
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const GET_AGENT_BUSINESS_STATUS_DOCUMENT = gql(`
  query GetAgentBusinessConnection($filter: JSON, $first: Int) {
    connection: getAgentBusinessConnection(filter: $filter, first: $first) {
      edges {
        node {
          id
          status
        }
      }
    }
  }
`);
