import {memo} from 'react';
import {msg} from '@lingui/macro';
import {Navigate, useLocation, Link as RouterLink} from 'react-router-dom';
import {TableData} from './components/sections/types';
import CloseButton from '@/components/Modal';
import {useLingui} from '@lingui/react';
import {
  getKeyValue,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import {useTableData} from '../helpers';

const TableDrilldown = memo(() => {
  const {state, key, pathname} = useLocation();
  const {_} = useLingui();
  const canGoBack = key !== 'default';
  const table = state?.table as TableData | undefined;
  const title = (state?.title || _(msg`Table`)) as string;
  const backTo = canGoBack ? -1 : pathname.replace(/\/[^/]+$/, ''); // go back or go one level up
  const {columns, items} = useTableData(table, false);

  if (!table) {
    // @ts-expect-error it's ok
    return <Navigate to={backTo} replace />;
  }

  return (
    <div
      style={{WebkitOverflowScrolling: 'touch'}}
      className="relative flex h-full w-full max-w-2xl grow flex-col items-center overflow-auto">
      <div
        className={`sticky top-0 z-20 flex w-full bg-background/75 px-6 py-4 backdrop-blur-xl`}>
        <div className="flex flex-1 items-center">
          <h2 className="break-words text-lg font-semibold text-foreground">
            {title}
          </h2>
        </div>
        {/* @ts-expect-error it's ok */}
        <CloseButton className="shrink-0" as={RouterLink} to={backTo} replace />
      </div>
      <Table
        aria-label="Table widget section"
        shadow="none"
        radius="none"
        removeWrapper
        isHeaderSticky
        className="grow bg-default-100"
        classNames={{
          base: 'max-h-full overflow-auto border-separate',
          tbody: '',
          thead:
            'p-6 pb-4 [&>tr]:first:outline-none [&>*:nth-child(2)]:hidden [&>tr]:first:shadow-none after:block after:absolute after:left-0 after:right-0 after:bottom-0 after:h-px after:w-full after:bg-foreground/10',
          th: 'min-w-[4.5rem] max-w-[12rem] text-sm font-medium text-default-400 first:pl-6 last:pr-6',
          td: 'min-w-[4.5rem] max-w-[12rem] text-sm font-medium first:pl-6 last:pr-6',
        }}>
        <TableHeader columns={columns}>
          {column => (
            <TableColumn className={column.align} key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={items}>
          {item => (
            <TableRow key={item._key} className="break-words">
              {columnKey => (
                <TableCell
                  className={columns.find(i => i.key === columnKey)?.align}>
                  {getKeyValue(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
});

TableDrilldown.displayName = 'TableDrilldown';

export default TableDrilldown;
