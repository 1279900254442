import {DetailedHTMLProps, FC, InputHTMLAttributes, useRef} from 'react';
import {useField} from 'formik';
import {Button, ButtonProps, Image, ImageProps} from '@nextui-org/react';
import {twMerge} from 'tailwind-merge';
import {blobToBase64} from '@/utils';

export const ImageInput: FC<
  {
    name: string;
    label?: string;
    classNames?: {
      image?: string;
      triggerWrapper?: string;
      trigger?: string;
      error?: string;
      label?: string;
    };
    isDisabled?: boolean;
    isRequired?: boolean;
    isInvalid?: boolean;
    errorMessage?: string;
    triggerProps?: Omit<
      ButtonProps,
      'className' | 'onPress' | 'isDisabled' | 'children'
    >;
    imageProps?: Omit<ImageProps, 'src' | 'alt' | 'className'>;
    showImage?: boolean;
  } & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({
  label,
  className,
  classNames,
  isDisabled = false,
  isRequired = false,
  isInvalid = false,
  errorMessage,
  triggerProps,
  imageProps,
  showImage = true,
  ...props
}) => {
  const imageWidth = imageProps?.width ?? 200;
  const fieldProps = useField(props);
  const field = fieldProps[0];
  const helpers = fieldProps[2];
  const ref = useRef<HTMLInputElement>(null);

  return (
    <div className={twMerge('flex flex-col gap-2', className)}>
      {showImage && (
        <Image
          {...imageProps}
          className={classNames?.image}
          width={imageWidth}
          alt={field.name}
          src={field.value}
        />
      )}
      <div
        className={twMerge('flex flex-col gap-2', classNames?.triggerWrapper)}>
        <Button
          aria-label="Upload image"
          className={twMerge(
            'h-auto max-h-none min-h-0 min-w-0 shrink-0 gap-1 rounded-full bg-[#F2F2F5] px-4 py-2 text-medium font-bold text-primary',
            classNames?.trigger,
          )}
          isDisabled={isDisabled}
          onPress={() => {
            helpers.setTouched(true);
            ref.current?.click();
          }}
          {...triggerProps}
          color={isInvalid ? 'danger' : triggerProps?.color}>
          <label
            htmlFor={field.name}
            className={twMerge(
              classNames?.label,
              isRequired
                ? "after:ml-0.5 after:text-danger after:content-['*']"
                : '',
            )}>
            {label}
          </label>
        </Button>
        {Boolean(errorMessage) && (
          <div
            className={twMerge(
              'whitespace-pre text-xs text-danger',
              classNames?.error,
            )}>
            {errorMessage}
          </div>
        )}
      </div>
      <input
        className="pointer-events-none absolute h-0 w-0 opacity-0"
        ref={ref}
        type="file"
        accept="image/*"
        name={field.name}
        onChange={e => {
          const file = e.currentTarget?.files?.[0];

          if (file) {
            if (!file.type.startsWith('image/')) {
              helpers.setError('Invalid file type');
            } else {
              blobToBase64(file)
                .then(helpers.setValue)
                .catch(e => helpers.setError(e.message));
            }
          }
        }}
      />
    </div>
  );
};
