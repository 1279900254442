import {useGetRelativeTime} from '@/hooks';
import {Trans} from '@lingui/macro';
import {memo} from 'react';
import {twMerge} from 'tailwind-merge';

export const UpdatedAt = memo(
  ({
    updatedAt,
    className,
  }: {
    updatedAt?: string | Date | null;
    className?: string;
  }) => {
    const getRelativeTime = useGetRelativeTime();

    if (!updatedAt) {
      return null;
    }

    return (
      <div className={twMerge('flex items-center gap-2', className)}>
        <div className="text-xs font-normal text-foreground/40">
          <Trans>Updated</Trans>{' '}
          {getRelativeTime(updatedAt, {preposition: true})}
        </div>
      </div>
    );
  },
);

UpdatedAt.displayName = 'UpdatedAt';

export default UpdatedAt;
