import {Toast, TokenResponse} from '@/common/types';
import {ApolloClient} from '@apollo/client';
import {Dispatch, SetStateAction, createContext} from 'react';

export type AppProviderState = {
  toasts: Toast[];
  setToasts: Dispatch<SetStateAction<Toast[]>>;
  login: (
    tokenResponse: Pick<TokenResponse, 'access_token' | 'refresh_token'>,
  ) => void;
  logout: (apolloClient: ApolloClient<any> | undefined) => void;
  isAuthenticated: boolean;
};

const initialState: AppProviderState = {
  toasts: [],
  setToasts: () => null,
  login: () => null,
  logout: () => null,
  isAuthenticated: false,
};

export const AppProviderContext = createContext<AppProviderState>(initialState);
