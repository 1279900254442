import {SVGProps} from 'react';

export default function Mail01SolidIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}>
      <path d="M1.77663 6.81786C1.52669 6.63933 1.40172 6.55007 1.29276 6.53848C1.12982 6.52114 0.966526 6.60518 0.885923 6.74784C0.832025 6.84324 0.832027 6.99513 0.832032 7.29891V12.7011C0.832021 13.3719 0.832013 13.9255 0.86886 14.3765C0.907131 14.8449 0.989268 15.2755 1.19534 15.68C1.51492 16.3072 2.02486 16.8171 2.65206 17.1367C3.05651 17.3428 3.4871 17.4249 3.95551 17.4632C4.4065 17.5 4.96013 17.5 5.63093 17.5H14.3665C15.0373 17.5 15.5909 17.5 16.0419 17.4632C16.5103 17.4249 16.9409 17.3428 17.3453 17.1367C17.9725 16.8171 18.4825 16.3072 18.8021 15.68C19.0081 15.2755 19.0903 14.8449 19.1285 14.3765C19.1654 13.9255 19.1654 13.3719 19.1654 12.7011V7.2989C19.1654 7.15001 19.1654 7.07557 19.1517 7.02358C19.0901 6.78856 18.8346 6.65468 18.6062 6.73776C18.5557 6.75614 18.4937 6.79905 18.3698 6.88487L11.918 11.3516C11.4605 11.6693 11.0589 11.9482 10.6026 12.0588C10.2035 12.1556 9.78672 12.1525 9.38909 12.0499C8.93447 11.9326 8.53697 11.6478 8.08424 11.3234L1.77663 6.81786Z" />
      <path d="M18.4427 4.80727C18.5965 4.70077 18.6735 4.64752 18.7207 4.56187C18.7574 4.49536 18.7784 4.39003 18.77 4.31454C18.7592 4.21733 18.7178 4.15314 18.6352 4.02478C18.3186 3.53304 17.8631 3.12713 17.3453 2.86331C16.9409 2.65724 16.5103 2.5751 16.0419 2.53683C15.5909 2.49998 15.0373 2.49999 14.3665 2.5H5.63095C4.96015 2.49999 4.4065 2.49998 3.95551 2.53683C3.4871 2.5751 3.05651 2.65724 2.65206 2.86331C2.1862 3.10068 1.78353 3.44384 1.47734 3.86112C1.381 3.99241 1.33284 4.05805 1.31697 4.15767C1.30456 4.23558 1.32189 4.34381 1.358 4.41395C1.40418 4.50364 1.48427 4.56085 1.64447 4.67527L8.95706 9.89856C9.56308 10.3314 9.69168 10.4067 9.80548 10.4361C9.93803 10.4703 10.077 10.4713 10.21 10.4391C10.3242 10.4114 10.4539 10.338 11.0662 9.91406L18.4427 4.80727Z" />
    </svg>
  );
}
