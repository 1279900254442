import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import dayjs from 'dayjs';
import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import {API_URL, PATH} from '@/config';
import {JSONSafeParse, fetchNewToken, getAccessToken} from '@/utils';
import {
  SessionSSEEventType,
  ChatSessionWithMessages,
} from '@/common/types/chat';
import {useChatContext, useAppContext} from '@/hooks';
import {useToastQueue} from '@/hooks';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';
import {getDayjsDate} from '@/utils';
import {useApolloClient, useMutation} from '@apollo/client';
import {gql} from '@/__generated__';
import {UserChatMessageType} from '@/__generated__/graphql';
import {CREATE_SESSION} from '@/common/mutations';
import {useLocation, useNavigate} from 'react-router-dom';
import {ChatContextValue} from '@/contexts';

const ADD_FILE_TO_SESSION = gql(`
  mutation AddFileToSession($id: ID!, $input: SessionAddFileInput!) {
    result: addFileToSession(id: $id, input: $input)
  }
`);
const UPDATE_SESSION_READ_TIME = gql(`
  mutation UpdateSessionReadTime($input: SessionReadTimeCreateOrUpdateInput!) {
    createOrUpdateSessionReadTime(input: $input) {
      sessionId
      lastReadAt
    }
  }
`);

const RETRY_LIMIT = 3;
const ALLOW_RETRY_AFTER = 1000 * 60 * 5;

class RetriableError extends Error {}
class FatalError extends Error {}

export const useFileUpload = () => {
  const {_} = useLingui();
  const {addWarningToast} = useToastQueue();
  const {activeSession} = useChatContext();
  const [createSessionWithMessage] = useCreateSessionWithMessage();
  const [uploadFile] = useMutation(ADD_FILE_TO_SESSION);

  const onFileChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (!file) {
        return;
      }

      if (file.type !== 'application/pdf') {
        addWarningToast({
          title: _(msg`Only PDF files are supported`),
          message: null,
        });

        return;
      }

      let sessionId = activeSession?.id;

      if (!sessionId) {
        const result = await createSessionWithMessage({
          variables: {
            input: {
              type: UserChatMessageType.File,
              filename: file.name,
            },
          },
        }).catch(() => null);

        if (!result) {
          return;
        }

        if (result.data && 'session' in result.data) {
          sessionId = result.data?.session.id;
        }
      }

      uploadFile({
        variables: {
          id: sessionId as string,
          input: {filename: file.name},
        },
      });
    },
    [uploadFile, activeSession, createSessionWithMessage, addWarningToast, _],
  );

  return onFileChange;
};

export const useCreateSessionWithMessage = (onComplete?: () => any) => {
  const location = useLocation();
  const isChatRoute = location.pathname.startsWith(`/${PATH.segment.chat}`);
  const navigate = useNavigate();
  const {addOrUpdateSession} = useChatContext();
  const [createSessionWithMessage, {loading}] = useMutation(CREATE_SESSION, {
    onCompleted: ({session}) => {
      addOrUpdateSession({messages: [], ...session});
      navigate(
        `/${PATH.segment.chat}/${PATH.segment.sessions}/${session.id}`,
        isChatRoute ? undefined : {state: {from: location}},
      );
      onComplete?.();
    },
  });

  return [createSessionWithMessage, {loading}] as const;
};

export const useHandleReadAt = () => {
  const readTimerRef = useRef<number | undefined>();
  const {activeSession, updateSessionData} = useChatContext();

  const [setLastReadAtMutate] = useMutation(UPDATE_SESSION_READ_TIME, {
    onCompleted: ({createOrUpdateSessionReadTime}) => {
      updateSessionData(createOrUpdateSessionReadTime.sessionId, {
        last_read_at: createOrUpdateSessionReadTime.lastReadAt,
      });
    },
  });
  const setLastReadAt = useCallback(
    (sessionId: string, lastReadAt: string) => {
      updateSessionData(sessionId, {last_read_at: lastReadAt});
      setLastReadAtMutate({
        variables: {
          input: {
            sessionId,
            lastReadAt,
          },
        },
      });
    },
    [setLastReadAtMutate, updateSessionData],
  );

  useEffect(() => {
    const activeSessionId = activeSession?.id;

    if (!activeSessionId) {
      return;
    }

    return () => {
      setLastReadAt(activeSessionId, dayjs().add(3, 'seconds').toISOString());
    };
  }, [activeSession?.id, activeSession?.messages?.length]);

  useEffect(() => {
    const activeSessionId = activeSession?.id;

    if (!activeSessionId) {
      return;
    }

    clearTimeout(readTimerRef.current);

    readTimerRef.current = setTimeout(() => {
      setLastReadAt?.(activeSessionId, dayjs().add(3, 'seconds').toISOString());
    }, 3000);
  }, [activeSession?.id]);
};

export const useMessageEvents = ({
  unauthenticatedSessionIds,
  addOrUpdateMessage,
  updateSessionData,
}: {
  unauthenticatedSessionIds: ChatContextValue['unauthenticatedSessionIds'];
  addOrUpdateMessage: ChatContextValue['addOrUpdateMessage'];
  updateSessionData: ChatContextValue['updateSessionData'];
}) => {
  const {isAuthenticated, login, logout} = useAppContext();
  const [accessToken, setAccessToken] = useState(getAccessToken());
  const apolloClient = useApolloClient();
  const {addErrorToast} = useToastQueue();
  const abortConstrollerRef = useRef<AbortController | undefined>();
  const retryRef = useRef(RETRY_LIMIT);
  const lastRetryAtRef = useRef<number | undefined>();
  const isRefreshingTokenRef = useRef(false);
  const fatalMessage = 'Server closed the connection, please refresh the page.';

  useEffect(() => {
    retryRef.current = RETRY_LIMIT;
    lastRetryAtRef.current = undefined;

    if (abortConstrollerRef.current) {
      abortConstrollerRef.current.abort();
    }

    abortConstrollerRef.current = new AbortController();

    fetchEventSource(`${API_URL}/chats/events`, {
      method: isAuthenticated ? 'GET' : 'POST',
      body: isAuthenticated
        ? undefined
        : JSON.stringify({
            sessionIds: unauthenticatedSessionIds,
          }),
      headers: isAuthenticated
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : {},
      signal: abortConstrollerRef.current?.signal,
      openWhenHidden: true,
      async onopen(response) {
        if (
          !response.ok ||
          !response.headers
            .get('content-type')
            ?.includes(EventStreamContentType)
        ) {
          throw new RetriableError(
            response.status === 401 ? 'Unauthorized' : '',
          );
        }
      },
      onmessage(msg) {
        if (abortConstrollerRef.current?.signal.aborted) {
          throw new FatalError();
        }
        const data = JSONSafeParse(msg.data) || {};

        (() => {
          switch (msg.event) {
            case SessionSSEEventType.messageAdded:
            case SessionSSEEventType.messageUpdated:
            case SessionSSEEventType.messageSummaryAdded:
            case SessionSSEEventType.messageError: {
              const {message, finished} = data;

              return message && addOrUpdateMessage(message, finished);
            }
            case SessionSSEEventType.summary: {
              const {sessionId, summary} = data;

              // !!! WARNING !!!
              // This breaks chat messages sent by SSE (triggers hook to store sessions,
              // but the cache doesn't contain SSE sent messages so they are empty)
              // Needed for filtering by category
              //
              // apolloClient.cache.modify({
              //   id: apolloClient.cache.identify({
              //     __typename: 'Session',
              //     id: sessionId,
              //   }),
              //   fields: {
              //     summary() {
              //       return summary;
              //     },
              //   },
              // });

              return sessionId && updateSessionData(sessionId, {summary});
            }
            case SessionSSEEventType.sessionLocked: {
              const {sessionId, locked} = data;

              return sessionId && updateSessionData(sessionId, {locked});
            }
            case SessionSSEEventType.terminated: {
              const {message = 'Unknown Error'} = data;

              throw new FatalError(`${fatalMessage}\n\nError: ${message}`);
            }
          }
        })();

        if (!Array.isArray(data.updates)) {
          return;
        }

        for (const update of data.updates) {
          switch (update?.name) {
            case 'insight_deleted': {
              const id = update?.data.id;

              apolloClient.cache.modify({
                fields: {
                  getInsightConnection(existing: any, {readField}) {
                    const node = existing?.edges.find(
                      (e: any) => readField('id', e.node) === id,
                    );

                    if (!node) {
                      return existing;
                    }

                    const edges = existing.edges.filter(
                      (e: any) => readField('id', e.node) !== id,
                    );

                    return {...existing, edges};
                  },
                },
              });
            }
          }
        }
      },
      onerror(err) {
        const setupRetry = () => {
          retryRef.current = Math.max(retryRef.current - 1, 0);
          lastRetryAtRef.current = Date.now();
          console.warn('EventSource failed, reconnecting:', err);
        };

        if (
          isAuthenticated &&
          err.message === 'Unauthorized' &&
          !isRefreshingTokenRef.current
        ) {
          isRefreshingTokenRef.current = true;

          fetchNewToken(login, logout, apolloClient)
            .then(() => {
              setAccessToken(getAccessToken());
            })
            .finally(() => {
              isRefreshingTokenRef.current = false;
            });
        } else if (
          err instanceof FatalError ||
          (retryRef.current <= 0 &&
            lastRetryAtRef.current &&
            Date.now() - lastRetryAtRef.current > ALLOW_RETRY_AFTER)
        ) {
          const {message} = err;

          if (message) {
            addErrorToast({message});
          }

          throw new FatalError(message); // rethrow to stop the operation
        } else {
          setupRetry();
        }
      },
    });
  }, [isAuthenticated, accessToken, unauthenticatedSessionIds]);

  useEffect(() => {
    return () => {
      abortConstrollerRef.current?.abort();
    };
  }, []);
};

export const useChatSessions = ({
  groupSessions = false,
}: {
  groupSessions?: boolean;
} = {}) => {
  const {i18n} = useLingui();
  const {activeSession, sessions, setActiveSession} = useChatContext();
  const today = useMemo(() => dayjs(), []);
  const yesterday = useMemo(() => today.subtract(1, 'day'), [today]);

  const getDateHeader = useCallback(
    (date: string) => {
      const dayjsDate = getDayjsDate(date);

      if (!dayjsDate) {
        return '';
      }

      if (dayjsDate.isSame(today, 'day')) {
        return 'Today';
      }

      if (dayjsDate.isSame(yesterday, 'day')) {
        return 'Yesterday';
      }

      return i18n.date(dayjsDate.toDate(), {month: 'short', day: 'numeric'});
    },
    [today, yesterday, i18n],
  );

  const onSessionPress = useCallback(
    (id: string) => {
      setActiveSession(id);
    },
    [setActiveSession],
  );

  const activeSessionId = activeSession?.id;
  const processedSessions = useMemo(() => {
    if (!groupSessions) {
      return sessions;
    }

    const array: (ChatSessionWithMessages | {header: string})[] = [];
    let lastHeader: string | undefined;

    for (let i = 0; i < sessions.length; i++) {
      const session = sessions[i];
      const header = getDateHeader(session.updated_at);

      if (!header) {
        continue;
      }

      if (!lastHeader || header !== lastHeader) {
        array.push({header});
      }

      array.push(session);
      lastHeader = header;
    }

    return array;
  }, [sessions, getDateHeader]);

  return useMemo(
    () => ({
      activeSessionId,
      sessions: processedSessions,
      onSessionPress,
    }),
    [activeSessionId, processedSessions, onSessionPress],
  );
};
