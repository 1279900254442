import {Trans} from '@lingui/macro';
import {memo, useMemo} from 'react';
import {twMerge} from 'tailwind-merge';
import orderBy from 'lodash/orderBy';
import {Action as ActionType} from './types';
import {Decision} from './Decision';
import AppDescription from '../../components/AppDescription';
import {ActionStatusEnum, DecisionStatusEnum} from '@/__generated__/graphql';
import {Button} from '@nextui-org/react';
import {useMutation} from '@apollo/client';
import {UPDATE_ACTION} from '@/common/mutations';
import PortalSpinner from '@/components/PortalSpinner';
import {AnimatePresence, motion} from 'framer-motion';

export const Action = memo(
  ({className, action}: {className?: string; action: ActionType}) => {
    const decisions = useMemo(
      () =>
        orderBy(
          action.decisions.edges.map(edge => edge.node),
          ['sortValue'],
          ['desc'],
        ),
      [action.decisions.edges],
    );
    const approvedDescision = useMemo(
      () => decisions.find(d => d.status === DecisionStatusEnum.Approved),
      [decisions],
    );
    const isDismissed = action.status === ActionStatusEnum.Dismissed;
    const [updateAction, updateActionResult] = useMutation(UPDATE_ACTION);

    return (
      <div
        className={twMerge(
          'flex flex-col overflow-hidden rounded-3xl',
          className,
        )}>
        <div className="flex flex-col gap-3 bg-[linear-gradient(180deg,#CCE7FF,#FFFFFF)] p-6">
          <div className="flex items-center gap-3">
            <img
              src={action.iconUrl ?? undefined}
              alt={action.title}
              className="aspect-square w-11 shrink-0 rounded-xl border-[length:thin] border-foreground/10"
            />
            <div className="text-ellipsis text-base font-medium">
              {action.title}
            </div>
          </div>
          <AppDescription description={action.description} />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col px-6 pt-3">
            <AnimatePresence initial={false}>
              {!approvedDescision && !isDismissed && (
                <motion.div
                  key="suggested-actions"
                  initial={{
                    marginTop: 0,
                    marginBottom: 0,
                    height: 0,
                    opacity: 0,
                  }}
                  animate={{
                    marginTop: '1rem',
                    marginBottom: '2.5rem',
                    height: 'auto',
                    opacity: 1,
                  }}
                  exit={{marginTop: 0, marginBottom: 0, height: 0, opacity: 0}}
                  transition={{duration: 0.5}}
                  className="px-6 text-sm font-semibold">
                  <Trans>Suggested Actions</Trans>
                </motion.div>
              )}
              {decisions
                .filter(decision => {
                  if (isDismissed) {
                    return false;
                  }

                  if (approvedDescision) {
                    return decision.id === approvedDescision.id;
                  }

                  return true;
                })
                .map(decision => (
                  <Decision
                    key={decision.id}
                    decision={decision}
                    isDisabled={
                      isDismissed ||
                      Boolean(
                        approvedDescision &&
                          decision.id !== approvedDescision.id,
                      )
                    }
                  />
                ))}
            </AnimatePresence>
            <AnimatePresence initial={false}>
              {!approvedDescision && (
                <Button
                  aria-label="Skip"
                  as={motion.button}
                  initial={{opacity: 0, height: 0, marginBottom: 0}}
                  animate={{
                    opacity: 1,
                    height: '2.5rem',
                    marginBottom: '1.5rem',
                  }}
                  exit={{opacity: 0, height: 0, marginBottom: 0}}
                  // @ts-expect-error it's ok
                  transition={{duration: 0.5}}
                  className={twMerge(
                    'w-full max-w-xs self-center font-semibold text-primary',
                    isDismissed ? 'bg-transparent text-foreground/50' : '',
                  )}
                  type="button"
                  color="default"
                  variant="flat"
                  radius="sm"
                  spinner={<PortalSpinner size="sm" />}
                  isLoading={updateActionResult.loading}
                  onPress={() =>
                    updateAction({
                      variables: {
                        id: action.id,
                        input: {status: ActionStatusEnum.Dismissed},
                      },
                    })
                  }
                  isDisabled={isDismissed}>
                  {isDismissed ? <Trans>Skipped</Trans> : <Trans>Skip</Trans>}
                </Button>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    );
  },
);

Action.displayName = 'ActionList';
