import {memo} from 'react';
import {useNumberOfSlides} from '../helpers';
import {twMerge} from 'tailwind-merge';
import {ResponsiveImage} from '@/__generated__/graphql';
import {useResponsiveImage} from '@/hooks';
import BlurhashImage from '@/components/BlurHashImage';

interface Props {
  images: ResponsiveImage[];
  className?: string;
}

const AppImage = memo(
  ({
    image,
    className,
    ...restProps
  }: {
    image: ResponsiveImage;
    className?: string;
    [key: string]: any;
  }) => {
    const imageData = useResponsiveImage(image);

    if (!imageData?.url) {
      return null;
    }

    return (
      <BlurhashImage
        {...imageData}
        alt="App Image"
        scaleByWidth
        className="flex shrink-0 rounded-[1.5rem] object-cover"
        {...restProps}
      />
    );
  },
);

AppImage.displayName = 'AppImage';

const AppImagesCarousel = memo(
  ({images, numberOfSlides, className}: Props & {numberOfSlides: number}) => {
    return (
      <div
        style={{WebkitOverflowScrolling: 'touch'}}
        className={twMerge(
          `relative -mb-2 flex w-full max-w-full items-stretch gap-2 overflow-auto pb-2`,
          className,
        )}>
        {images.map((image, index) => (
          <AppImage
            key={index}
            image={image}
            style={{
              width: `calc((100% - ${(numberOfSlides - 1) * 0.5}rem) / ${numberOfSlides})`,
            }}
          />
        ))}
      </div>
    );
  },
);

AppImagesCarousel.displayName = 'AppImagesCarousel';

const AppImages = memo((props: Props) => {
  const numberOfSlides = useNumberOfSlides();

  return <AppImagesCarousel {...props} numberOfSlides={numberOfSlides} />;
});

AppImages.displayName = 'AppImages';

export default AppImages;
