import {Dispatch, memo, SetStateAction} from 'react';
import {
  WidgetSectionFilter,
  WidgetSection as WidgetSectionType,
} from './sections/types';
import JsonSection from './sections/JsonSection';

const WidgetSection = memo(
  ({
    section,
    ...props
  }: {
    section: WidgetSectionType;
    isDrillDown?: boolean;
    appName?: string;
    anchor?: string;
    title?: string;
    widgetFilter?: WidgetSectionFilter;
    setWidgetFilter?: Dispatch<SetStateAction<WidgetSectionFilter>>;
  }) => {
    const styles =
      section.style_config?.[props.isDrillDown ? 'DrillDown' : 'Preview']
        ?.style;

    return (
      <div
        style={{
          ...(styles?.container ?? {}),
        }}
        className={`flex w-full flex-col`}>
        {(() => {
          switch (section.format) {
            case 'json':
              return <JsonSection json={section.json} {...props} />;
            default:
              throw new Error(`Unsupported chart format: ${section.format}`);
          }
        })()}
      </div>
    );
  },
);

WidgetSection.displayName = 'WidgetSection';

export default WidgetSection;
