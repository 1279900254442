import {Dispatch, memo, SetStateAction} from 'react';
import {WidgetSection, WidgetSectionFilter} from './types';
import MarkdownSection from './MarkdownSection';
import ScoreSection from './ScoreSection';
import ChartSection from './ChartSection';
import TableSection from './TableSection';
import SuggestedEmailSection from './SuggestedEmailSection';
import ConnectedTrackersSection from './ConnectedTrackersSection';
import WordCloudSection from './WordCloudSection';
import TreeSection from './TreeSection';
import MinibrowserTestSection from './MinibrowserTestSection';
import NoticeSection from './NoticeSection';
import ActionsSection from './ActionsSection';
import TabsSection from './TabsSection';
import BannerSection from './BannerSection';
import BusinessMarginFormSection from './BusinessMarginFormSection';

const JsonSection = memo(
  ({
    json,
    isDrillDown,
    appName,
    anchor,
    title,
    widgetFilter,
    setWidgetFilter,
  }: {
    json: WidgetSection['json'];
    isDrillDown?: boolean;
    appName?: string;
    anchor?: string;
    title?: string;
    widgetFilter?: WidgetSectionFilter;
    setWidgetFilter?: Dispatch<SetStateAction<WidgetSectionFilter>>;
  }) => {
    const className = isDrillDown ? 'bg-background' : 'bg-[#F7F7FA]';

    switch (json.template) {
      case 'tabs':
        return (
          <TabsSection
            {...json}
            {...{isDrillDown, widgetFilter, setWidgetFilter}}
          />
        );
      case 'markdown':
        return <MarkdownSection {...json} />;
      case 'score':
        return <ScoreSection {...json} />;
      case 'charts':
        return <ChartSection {...json} isDrillDown={isDrillDown} />;
      case 'table':
        return <TableSection {...json} title={title} className={className} />;
      case 'suggested_email':
        return <SuggestedEmailSection {...json} className={className} />;
      case 'connected_trackers':
        return <ConnectedTrackersSection {...json} />;
      case 'wordcloud':
        return <WordCloudSection {...json} isDrillDown={isDrillDown} />;
      case 'tree':
        return <TreeSection {...json} />;
      case 'minibrowser_test':
        return <MinibrowserTestSection {...json} className={className} />;
      case 'notice':
        return <NoticeSection {...json} className={className} />;
      case 'actions':
      case 'decided_actions':
        return <ActionsSection {...json} className={className} />;
      case 'banner':
        return <BannerSection {...json} {...{appName, isDrillDown}} />;
      case 'business_margin_form':
        return (
          <BusinessMarginFormSection
            {...json}
            {...{appName, isDrillDown, anchor}}
          />
        );
      default:
        // todo: throw error here
        return JSON.stringify(json, null, 2);
    }
  },
);

JsonSection.displayName = 'JsonSection';

export default JsonSection;
