import {SVGProps} from 'react';

export default function NotificationTextIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}>
      <path
        fill="currentColor"
        d="M24.808 3.35862C26.1748 4.72546 26.1748 6.94153 24.808 8.30837C23.4411 9.6752 21.225 9.6752 19.8582 8.30837C18.4914 6.94153 18.4914 4.72546 19.8582 3.35862C21.225 1.99179 23.4411 1.99179 24.808 3.35862Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6664 12.6066C24.6664 12.1296 24.6664 11.8911 24.589 11.7563C24.5112 11.6208 24.4322 11.554 24.2858 11.4997C24.1399 11.4456 23.8611 11.4924 23.3035 11.586C21.5063 11.8876 19.5951 11.345 18.2083 9.95829C16.8215 8.57153 16.279 6.66025 16.5806 4.86306C16.6742 4.30546 16.7209 4.02666 16.6669 3.88082C16.6126 3.73435 16.5458 3.65536 16.4103 3.57759C16.2754 3.50016 16.0369 3.50016 15.56 3.50016H9.21824C8.27913 3.50015 7.50401 3.50014 6.87263 3.55172C6.21685 3.6053 5.61403 3.72029 5.0478 4.0088C4.16971 4.45621 3.4558 5.17012 3.00839 6.04821C2.71989 6.61443 2.6049 7.21725 2.55132 7.87303C2.49973 8.50442 2.49974 9.2795 2.49976 10.2186V18.9483C2.49974 19.8875 2.49973 20.6626 2.55132 21.294C2.6049 21.9497 2.71989 22.5526 3.00839 23.1188C3.4558 23.9969 4.16971 24.7108 5.0478 25.1582C5.61403 25.4467 6.21685 25.5617 6.87263 25.6153C7.50401 25.6669 8.27909 25.6668 9.21821 25.6668H17.948C18.8871 25.6668 19.6622 25.6669 20.2936 25.6153C20.9493 25.5617 21.5522 25.4467 22.1184 25.1582C22.9965 24.7108 23.7104 23.9969 24.1578 23.1188C24.4463 22.5526 24.5613 21.9497 24.6149 21.294C24.6664 20.6626 24.6664 19.8875 24.6664 18.9484V12.6066ZM7.16642 15.1668C7.16642 14.5225 7.68876 14.0002 8.33309 14.0002H17.6664C18.3108 14.0002 18.8331 14.5225 18.8331 15.1668C18.8331 15.8112 18.3108 16.3335 17.6664 16.3335H8.33309C7.68876 16.3335 7.16642 15.8112 7.16642 15.1668ZM7.16642 19.8335C7.16642 19.1892 7.68876 18.6668 8.33309 18.6668H15.3331C15.9774 18.6668 16.4998 19.1892 16.4998 19.8335C16.4998 20.4778 15.9774 21.0002 15.3331 21.0002H8.33309C7.68876 21.0002 7.16642 20.4778 7.16642 19.8335Z"
      />
    </svg>
  );
}
