import {DecisionStatusEnum, Insight} from '@/__generated__/graphql';
import {GET_ACTIONS} from '@/common/queries';
import {useQuery} from '@apollo/client';
import {Button, Card, CardBody} from '@nextui-org/react';
import {memo, useCallback, useMemo} from 'react';
import rehypeRaw from 'rehype-raw';
import {twMerge} from 'tailwind-merge';
import {DecisionButton} from '../../action/DecisionButton';
import Markdown from '@/components/Markdown';
import {Trans} from '@lingui/macro';
import {useGetRelativeTime} from '@/hooks';

const rehypePlugins = [rehypeRaw];

export type DetailedInsightProps = {
  insight: Omit<Partial<Insight>, 'agent'> & {
    agent?: Partial<Insight['agent']>;
  };
  appName?: string;
  appIcon?: string;
  isDrillDown: boolean;
  onlyActions?: boolean;
  paginated?: boolean;
  className?: string;
};

const DetailedInsight = memo(
  ({
    insight,
    isDrillDown,
    onlyActions,
    appIcon,
    className,
  }: DetailedInsightProps) => {
    const getRelativeTime = useGetRelativeTime();
    const {data, loading, fetchMore} = useQuery(GET_ACTIONS, {
      variables: {
        filter: {
          insightId: insight.id,
        },
        order: {
          sortValue: 'DESC',
        },
        ...(onlyActions ? {} : {first: 3}),
      },
      fetchPolicy: 'network-only',
    });
    const pageInfo = data?.connection?.pageInfo;
    const hasMore = pageInfo?.hasNextPage;
    const actions = useMemo(() => {
      return data?.connection?.edges.map(edge => edge.node) ?? [];
    }, [data?.connection]);

    const loadMore = useCallback(() => {
      if (hasMore) {
        fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
        });
      }
    }, [pageInfo, hasMore, fetchMore]);

    const renderActions = () => {
      return actions.map(action => {
        return (
          <Card
            key={action.id}
            className={twMerge(
              'overflow-visible rounded-none bg-transparent p-0 data-[hover=true]:bg-transparent',
              className,
            )}
            shadow="none">
            <CardBody className="flex flex-row gap-2 overflow-visible p-0">
              {action.iconUrl ? (
                <div className="relative">
                  <img
                    className="flex h-[3.25rem] w-[3.25rem] shrink-0"
                    src={action.iconUrl}
                  />
                  {appIcon ? (
                    <img
                      className="absolute -right-1 -top-1 z-10 flex h-5 w-5 shrink-0 rounded-[5px]"
                      src={appIcon}
                    />
                  ) : null}
                </div>
              ) : null}
              <div className="flex flex-1 flex-col justify-center gap-1">
                <div className="text-xs font-semibold text-foreground">
                  {action.title}
                </div>
                <Markdown
                  rehypePlugins={rehypePlugins}
                  className="text-xs font-normal text-default-500">
                  {action.description}
                </Markdown>
              </div>
              <div className="flex flex-col gap-3">
                {action.decisions?.edges.map(({node: decision}) => {
                  return (
                    <div
                      key={decision.id}
                      className="flex flex-col items-center gap-1">
                      <DecisionButton
                        decision={decision}
                        className={`${isDrillDown ? 'bg-background' : 'bg-[#F3F3F5]'} ${decision.status === DecisionStatusEnum.Approved ? 'text-foreground/50' : 'text-primary'}`}
                      />
                      <div className="text-[0.625rem] font-normal leading-[0.5rem] text-foreground/40 sm:text-xs sm:leading-4">
                        {getRelativeTime(decision.updatedAt)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </CardBody>
          </Card>
        );
      });
    };

    if (onlyActions) {
      return renderActions();
    }

    return (
      <div
        className={`flex w-full flex-col gap-4 ${isDrillDown ? 'py-6' : ''}`}>
        {insight.title ? (
          <div className={`text-lg font-semibold text-foreground`}>
            {insight.title}
          </div>
        ) : null}
        {insight.shortDescription ? (
          <div className={`text-default-600 ${isDrillDown ? '' : 'px-6'}`}>
            {insight.shortDescription}
          </div>
        ) : null}
        <div className="flex w-full flex-col gap-6">
          {renderActions()}
          {hasMore && isDrillDown ? (
            <Button
              aria-label="Load More Actions"
              fullWidth
              radius="sm"
              className="min-h-none flex h-auto flex-col gap-0 rounded-xl bg-background px-4 py-2 text-medium font-semibold text-primary"
              isLoading={loading}
              onPress={() => loadMore()}>
              <Trans>More</Trans>
            </Button>
          ) : null}
        </div>
      </div>
    );
  },
);

DetailedInsight.displayName = 'DetailedInsight';

export default DetailedInsight;
