import {memo, useEffect, useState} from 'react';
import {Formik, Form, Field} from 'formik';
import {object, string} from 'yup';
import {Button} from '@nextui-org/react';
import {useLingui} from '@lingui/react';
import {Trans, msg} from '@lingui/macro';
import {useAuthContext, useIsInAppBrowser, useToastQueue} from '@/hooks';
import ErrorMessage from '@/components/ErrorMessage';
import {AnimatePresence, motion} from 'framer-motion';
import ShowPasswordButton from '@/components/ShowPasswordButton';
import {UserStatus} from '@/__generated__/graphql';
import {ApolloError} from '@apollo/client';
import {formatApolloError} from '../helpers';
import NextUIInput from '@/components/inputs/NextUIInput';
import PortalSpinner from '@/components/PortalSpinner';
import {useNavigate} from 'react-router-dom';

const EmailLoginForm = memo(
  ({
    initialEmail,
    isLoading,
    userStatus,
    onSubmit,
  }: {
    initialEmail: string;
    isLoading: boolean;
    userStatus: UserStatus | undefined;
    onSubmit: (values: {email: string; password: string}) => Promise<void>;
  }) => {
    const isExistingUser = userStatus === UserStatus.Verified;
    const [error, setError] = useState<string | undefined>();
    const {_} = useLingui();
    const navigate = useNavigate();
    const {addErrorToast} = useToastQueue();
    const [showPassword, setShowPassword] = useState(false);
    const {isInApp} = useIsInAppBrowser();
    const {setResetPasswordEmail} = useAuthContext();

    useEffect(() => {
      if (!isExistingUser) {
        setShowPassword(false);
      }
    }, [isExistingUser]);

    return (
      <Formik
        initialValues={{email: initialEmail, password: ''}}
        validationSchema={object({
          email: string()
            .email(_(msg`Invalid email address`))
            .required(_(msg`Required`)),
          password: isExistingUser
            ? string()
                .min(8, _(msg`Password is too short`))
                .required(_(msg`Required`))
            : string(),
        })}
        onSubmit={async values => {
          setError(undefined);

          try {
            await onSubmit(values);
          } catch (e: any) {
            if (e instanceof ApolloError) {
              setError(formatApolloError(e) || undefined);
            } else {
              const {data} = e.response || {};
              const message = data.detail || e.message;

              setError(message);
              addErrorToast({message});
            }
          }
        }}>
        {({isSubmitting, touched, errors, values}) => {
          const isEmailInvalid = Boolean(touched.email && errors.email);
          const isPasswordInvalid = Boolean(
            touched.password && errors.password,
          );

          return (
            <Form className="flex min-h-[5.25rem] w-full flex-col gap-6">
              <Field
                as={NextUIInput}
                name="email"
                type="email"
                label={_(msg`Email`)}
                placeholder={_(msg`Enter your email`)}
                isInvalid={isEmailInvalid}
                errorMessage={isEmailInvalid ? errors.email : undefined}
                isDisabled={isSubmitting || isLoading || isExistingUser}
                description={
                  isInApp && !isExistingUser ? (
                    <Trans>We will send you a magic link to sign you up.</Trans>
                  ) : null
                }
              />
              <AnimatePresence>
                {isExistingUser && (
                  <motion.div
                    className="flex flex-col"
                    initial={{opacity: 0, height: 0}}
                    animate={{opacity: 1, height: 'auto'}}
                    exit={{opacity: 0, height: 0}}>
                    <div className="flex w-full flex-col gap-2">
                      <Field
                        as={NextUIInput}
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        label={_(msg`Password`)}
                        placeholder={_(msg`Enter your password`)}
                        endContent={
                          <ShowPasswordButton
                            showPassword={showPassword}
                            onPress={setShowPassword}
                          />
                        }
                        classNames={{
                          inputWrapper: 'pr-0.5',
                        }}
                        isInvalid={isPasswordInvalid}
                        errorMessage={
                          isPasswordInvalid ? errors.password : undefined
                        }
                        isDisabled={isSubmitting || isLoading}
                        autoFocus
                      />
                      <Button
                        aria-label="Forgot password"
                        disableAnimation
                        disableRipple
                        className="h-auto w-auto min-w-0 self-start rounded-none bg-transparent px-0 text-sm font-medium text-foreground/50 underline underline-offset-[0.2rem]"
                        isDisabled={isSubmitting || isLoading}
                        onPress={() => {
                          setResetPasswordEmail(values.email);
                          navigate(`../reset-password/request`);
                        }}>
                        <Trans>Forgot password?</Trans>
                      </Button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <ErrorMessage message={error} />
              <div className="flex w-full flex-col gap-2">
                <Button
                  aria-label="Continue"
                  fullWidth
                  type="submit"
                  variant="solid"
                  radius="sm"
                  color="primary"
                  className="text-base font-semibold text-background"
                  spinner={<PortalSpinner size="sm" />}
                  isLoading={isSubmitting || isLoading}
                  isDisabled={isSubmitting || isLoading}>
                  <Trans>Continue</Trans>
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  },
);

EmailLoginForm.displayName = 'EmailLoginForm';

export default EmailLoginForm;
