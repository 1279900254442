import {FC, memo} from 'react';
import {useLocation} from 'react-router-dom';
import AnimatedOutlet from '@/components/AnimatedOutlet';
import {AnimatePresence, motion} from 'framer-motion';
import {ModalBody, ModalHeader} from '@/components/Modal';
import {Trans} from '@lingui/macro';

const ResetPassword: FC = memo(() => {
  const location = useLocation();

  return (
    <>
      <ModalHeader className="!px-0 pb-0">
        <div
          style={{
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.05) 100%)',
          }}
          className="flex w-full gap-3 px-5 pb-5 md:px-10">
          <h1 className="text-lg font-semibold text-primary-900">
            {location.pathname.endsWith('/requested') ? (
              <Trans>Check your email</Trans>
            ) : location.pathname.endsWith('/set') ? (
              <Trans>Change your password</Trans>
            ) : (
              <Trans>Reset your password</Trans>
            )}
          </h1>
        </div>
      </ModalHeader>
      <ModalBody>
        <AnimatePresence mode="wait">
          <motion.div
            className="flex shrink flex-col items-center justify-center self-stretch"
            key={location.pathname}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.2}}>
            <AnimatedOutlet />
          </motion.div>
        </AnimatePresence>
      </ModalBody>
    </>
  );
});

ResetPassword.displayName = 'ResetPassword';

export default ResetPassword;
