import {
  ComponentProps,
  memo,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';
import ScrollToBottom from 'react-scroll-to-bottom';
import {ChatItem, ChatMessage} from '@/common/types/chat';
import Message from './Message';
import MessageHeader from './MessageHeader';
import {Trans} from '@lingui/macro';
import {twMerge} from 'tailwind-merge';
import PortalSpinner from '@/components/PortalSpinner';
import {useHandleReadAt} from '../hooks';
import {useInView} from 'react-intersection-observer';
import {sleep} from '@/utils';
import {getMessageElementId} from '../helpers';

interface ContainerProps {
  items: ChatItem[];
  activeSessionId: string | undefined;
  hasMore: boolean;
  headerContent?: ReactNode;
  className?: ComponentProps<'div'>['className'];
  scrollClassName?: ComponentProps<'div'>['className'];
  style?: ComponentProps<'div'>['style'];
  fetchMore: () => Promise<void>;
}

type ContentProps = Pick<
  ContainerProps,
  'items' | 'activeSessionId' | 'scrollClassName' | 'style'
>;

const ChatViewContent = memo(
  ({items, activeSessionId, scrollClassName = '', style}: ContentProps) => {
    const now = useMemo(() => dayjs(), [activeSessionId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <div
        className={twMerge(
          'flex grow flex-col self-stretch !overflow-visible [&>div]:!overflow-visible',
          scrollClassName,
        )}
        style={style}>
        {items.map((item, idx, arr) => {
          if (!('itemType' in item)) {
            const prevItem = arr[idx - 1];
            let className = prevItem && !('itemType' in prevItem) ? 'mt-8' : '';

            className +=
              idx === 0 ? ' [overflow-anchor:auto]' : ' [overflow-anchor:none]';

            return (
              <Message
                key={item.id}
                {...item}
                now={now}
                className={className}
              />
            );
          }

          switch (item.itemType) {
            case 'header':
              return (
                <MessageHeader
                  key={idx}
                  {...item}
                  className={idx === arr.length - 1 ? '' : 'mt-6 md:mt-12'}
                />
              );
            case 'unreadSeparator':
              return (
                <div
                  className="mt-6 flex w-full items-center gap-4 pr-8 text-xs text-[#FF00A8] md:mt-12"
                  key={idx}>
                  <div className="flex h-[1px] flex-[1_1_auto] bg-[#FF00A8]" />
                  <Trans>New</Trans>
                  <div className="flex h-[1px] flex-[1_1_auto] bg-[#FF00A8]" />
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    );
  },
);

ChatViewContent.displayName = 'ChatViewContent';

const ChatViewWithState = memo(
  ({
    items,
    activeSessionId,
    hasMore,
    fetchMore,
    className,
    headerContent = null,
    ...contentProps
  }: ContainerProps) => {
    const {ref: endRef, inView: endReached} = useInView();
    const [loadMoreState, setLoadMoreState] = useState({
      loading: false,
      canLoadMore: true,
      scrollItemId: '',
    });
    const itemsRef = useRef(items);
    const initialScrollerRef = useRef<HTMLDivElement>(null);

    itemsRef.current = items;

    useEffect(() => {
      if (
        !endReached ||
        !loadMoreState.canLoadMore ||
        loadMoreState.loading ||
        !hasMore
      ) {
        return;
      }

      const firstMessage = itemsRef.current.find(item => !('itemType' in item));
      const idToScroll = firstMessage && (firstMessage as ChatMessage).id;

      setLoadMoreState({
        loading: true,
        canLoadMore: false,
        scrollItemId: idToScroll ?? '',
      });

      (async () => {
        try {
          await fetchMore();
        } finally {
          setLoadMoreState(prev => ({
            ...prev,
            loading: false,
            canLoadMore: !idToScroll,
          }));
        }
      })();
    }, [endReached, loadMoreState.canLoadMore, loadMoreState.loading, hasMore]);

    useLayoutEffect(() => {
      if (!loadMoreState.scrollItemId || loadMoreState.loading) {
        return;
      }

      (async () => {
        const el = document.getElementById(
          getMessageElementId(loadMoreState.scrollItemId),
        );

        // instant scroll to the top of the previous last message
        el?.scrollIntoView({
          behavior: 'instant',
          block: 'start',
          inline: 'nearest',
        });

        // wait for scroll to finish
        await sleep(75);

        // scroll previous last message to the center of the screen
        el?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });

        // wait for scroll to finish
        await sleep(400);

        setLoadMoreState(prev => ({
          ...prev,
          scrollItemId: '',
          canLoadMore: true,
        }));
      })();
    }, [loadMoreState.scrollItemId, loadMoreState.loading]);

    useLayoutEffect(() => {
      if (!activeSessionId) {
        return;
      }

      // when changing active session, the list might start from top and smoothly scroll to the bottom
      // to avoid this, we scroll to the bottom instantly
      requestAnimationFrame(() => {
        initialScrollerRef?.current?.scrollIntoView({
          behavior: 'instant',
          block: 'end',
          inline: 'nearest',
        });
      });
    }, [activeSessionId]);

    return (
      <ScrollToBottom
        initialScrollBehavior="auto"
        followButtonClassName="hidden"
        className={twMerge(
          `flex min-h-full min-w-full max-w-full flex-col [&>div]:before:flex [&>div]:before:flex-col [&>div]:before:[content:''] [&>div]:before:[flex:1]`,
          className,
        )}>
        {headerContent}
        <div
          ref={endRef}
          className={`flex w-full justify-center overflow-hidden transition-all ${loadMoreState.loading ? 'h-5 opacity-100' : 'h-[0px] opacity-0'}`}>
          <div>
            <PortalSpinner size="sm" />
          </div>
        </div>
        <ChatViewContent
          items={items}
          activeSessionId={activeSessionId}
          {...contentProps}
        />
        <div ref={initialScrollerRef} className="h-[0px] w-full" />
      </ScrollToBottom>
    );
  },
);

ChatViewWithState.displayName = 'ChatViewWithState';

const withReadAtHandler = (Component: typeof ChatViewWithState) => {
  const WrappedComponent = (props: ContainerProps) => {
    useHandleReadAt();

    return <Component {...props} />;
  };

  WrappedComponent.displayName = 'ChatViewWithReadAtHandler';

  return WrappedComponent;
};

const ChatViewWithStateAndReadAt = withReadAtHandler(ChatViewWithState);

const ChatView = memo(
  ({
    handleReadAt = true,
    ...props
  }: ContainerProps & {handleReadAt?: boolean}) => {
    const Component = handleReadAt
      ? ChatViewWithStateAndReadAt
      : ChatViewWithState;

    return <Component {...props} />;
  },
);

ChatView.displayName = 'ChatView';

export {ChatViewContent};

export default ChatView;
