import {memo} from 'react';
import {DonutChartData} from '../../types';
import Donut from './Donut';
import {twMerge} from 'tailwind-merge';

interface Props {
  size: number;
  data: DonutChartData;
  className?: string;
}

const DonutChart = memo(({data, size, className}: Props) => {
  return (
    <div className={twMerge('flex flex-col items-center gap-3', className)}>
      <div className="relative items-center justify-center">
        <Donut
          percent={(data.value * 100) / data.max_value}
          size={size}
          className="aspect-square w-full rotate-180"
        />
        <div
          style={{color: data.text_color}}
          className="absolute inset-0 flex items-center justify-center text-3xl font-bold">
          {Math.round(data.value)}
        </div>
      </div>
      <div
        style={{color: data.text_color}}
        className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-center text-sm font-semibold sm:text-sm">
        {data.label}
      </div>
    </div>
  );
});

DonutChart.displayName = 'DonutChart';

export default DonutChart;
