import {createContext, Dispatch, SetStateAction} from 'react';
import {User, Business} from '@/common/types';

export interface AuthContextValue {
  isLoading: boolean;
  isDemo: boolean;
  user?: User;
  business?: Business | null;
  signupEmail: string;
  setSignupEmail: Dispatch<SetStateAction<string>>;
  resetPasswordEmail: string;
  setResetPasswordEmail: Dispatch<SetStateAction<string>>;
}

export const AuthContext = createContext<AuthContextValue | undefined>(
  undefined,
);
