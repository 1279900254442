import {memo, useCallback, useEffect, useRef, useState} from 'react';
import {animate, motion, useMotionValue, useTransform} from 'framer-motion';
import {Trans} from '@lingui/macro';
import {useMatchesTWBreakpoint, useResizeObserver} from '@/hooks';

interface Props {
  count: number;
}

const ChatCounter = memo(({count: countProps}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const countTimerRef = useRef<number | undefined>();
  const [count, setCount] = useState(countProps);
  const isShown = count > 0;
  const isShownValue = useMotionValue(isShown ? 1 : 0);
  const contentWidthValue = useMotionValue(0);
  const widthValue = useTransform(() => {
    const width = contentWidthValue.get();
    const isShown = isShownValue.get();

    return width * isShown;
  });
  const opacityValue = useTransform(isShownValue, [0.5, 1], [0, 1]);

  const onResize = useCallback((entry: ResizeObserverEntry) => {
    contentWidthValue.set(entry.contentRect.width);
  }, []);

  useResizeObserver(contentRef, onResize);

  useEffect(() => {
    animate(isShownValue, isShown ? 1 : 0, {
      type: 'tween',
      duration: 0.2,
    });
  }, [isShown]);

  useEffect(() => {
    clearTimeout(countTimerRef.current);

    countTimerRef.current = setTimeout(() => {
      setCount(countProps);
    }, 500);
  }, [countProps]);

  return (
    <motion.div
      style={{
        opacity: opacityValue,
        width: widthValue,
      }}
      className="flex shrink-0 overflow-hidden">
      <div
        ref={contentRef}
        className="font-xs hidden shrink-0 rounded-full bg-[#FFE5E5] text-xs font-medium text-[#FF0000] md:flex">
        <div className="flex items-center px-3 py-[0.375rem]">
          <Trans>{count} unread</Trans>
        </div>
      </div>
    </motion.div>
  );
});

ChatCounter.displayName = 'ChatCounter';

const ChatCounterContainer = memo((props: Props) => {
  const isShown = props.count > 0;
  const isMediumScreen = useMatchesTWBreakpoint('md');

  if (isMediumScreen) {
    return <ChatCounter {...props} />;
  }

  if (isShown) {
    return (
      <div className="absolute right-0 top-0 flex h-3 w-3 shrink-0 rounded-full bg-[#FF0000]" />
    );
  }

  return null;
});

ChatCounterContainer.displayName = 'ChatCounterContainer';

export default ChatCounterContainer;
