import GradientIcon from '@/components/GradientIcon';
import CloseButton from '@/components/Modal';
import {memo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {ImpactLevel} from '../components/sections/types';

const InsightHeader = memo(
  ({
    className,
    title,
    impact,
    agent,
  }: {
    className?: string;
    title?: string;
    impact?: ImpactLevel;
    agent?: {
      name: string;
      logo: {
        url: string;
      };
    } | null;
  }) => {
    const {name, logo} = agent ?? {};

    return (
      <div className={twMerge(`flex w-full flex-col px-6 py-4`, className)}>
        <div className="flex justify-start">
          <div className="flex flex-1 items-center gap-3">
            <GradientIcon className="relative" impact={impact ?? 0}>
              {logo?.url ? (
                <img
                  className="absolute -right-1 -top-1 flex aspect-square w-5 shrink-0 self-center rounded"
                  src={logo?.url}
                />
              ) : null}
            </GradientIcon>
            <div className="flex flex-1 flex-col justify-between">
              <h3 className="text-xs font-medium uppercase text-foreground/50">
                {name}
              </h3>
              <h1 className="text-lg font-semibold text-foreground">{title}</h1>
            </div>
          </div>
          {/* @ts-expect-error it's ok */}
          <CloseButton className="shrink-0" as={RouterLink} to=".." />
        </div>
      </div>
    );
  },
);

InsightHeader.displayName = 'InsightHeader';

export default InsightHeader;
