import PortalLogo from '@/components/icons/PortalLogo';
import {memo, ReactNode, useMemo} from 'react';
import GridIcon from '@/components/icons/GridIcon';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';
import {Avatar, Button, Link} from '@nextui-org/react';
import {Link as RouterLink} from 'react-router-dom';
import {useLocation, Location} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {useAppContext, useAuthContext, useChatContext} from '@/hooks';
import {getUserName} from '@/utils';
import PortalChat from '@/assets/portal-chat.png';
import NotificationTextIcon from '@/components/icons/NotificationTextIcon';
import PortalChatIcon from './PortalChatIcon';
import {PATH} from '@/config';

interface Page {
  name: string;
  href: string;
  isActive: boolean;
  Icon: ReactNode;
  from?: Location;
}

const NavigationBar = memo(() => {
  const location = useLocation();
  const {_} = useLingui();
  const {isAuthenticated} = useAppContext();
  const {user} = useAuthContext();
  const {activeSessionId} = useChatContext();
  const pages: Page[] = useMemo(() => {
    const chatHref = activeSessionId
      ? `/${PATH.segment.chat}/${PATH.segment.sessions}/${activeSessionId}`
      : `/${PATH.segment.chat}`;
    const isChatActive = location.pathname.startsWith(`/${PATH.segment.chat}`);
    const toolsPage = {
      name: _(msg`Tools`),
      href: '/catalog',
      isActive: location.pathname.startsWith('/catalog'),
      Icon: (
        <GridIcon className="h-7 w-7 shrink-0 transition-all md:h-5 md:w-5" />
      ),
    };

    if (isAuthenticated) {
      return [
        toolsPage,
        {
          name: _(msg`Portal AI`),
          href: chatHref,
          from: location,
          isActive: isChatActive,
          Icon: (
            <div className="relative flex h-7 w-7 shrink-0 justify-center md:h-5 md:w-5">
              <PortalChatIcon
                isActive={isChatActive}
                className="absolute bottom-0 md:hidden"
              />
              <img
                src={PortalChat}
                alt="Chat"
                className="absolute bottom-0 hidden max-h-12 min-h-12 min-w-12 max-w-12 shrink-0 transition-all md:flex md:max-h-5 md:min-h-5 md:min-w-5 md:max-w-5"
              />
            </div>
          ),
        },
        {
          name: _(msg`Live`),
          href: '/live',
          isActive: location.pathname.startsWith('/live'),
          Icon: (
            <NotificationTextIcon className="h-7 w-7 shrink-0 transition-all md:h-5 md:w-5" />
          ),
        },
      ];
    }

    return [
      toolsPage,
      {
        name: _(msg`Portal AI`),
        href: chatHref,
        from: location,
        isActive: isChatActive,
        Icon: (
          <PortalChatIcon
            isActive={isChatActive}
            isSimple
            className="h-7 w-7 shrink-0 md:h-5 md:w-5"
          />
        ),
      },
    ];
  }, [_, location, activeSessionId, isAuthenticated]);
  const userName = getUserName(user);
  const userAvatar = user?.avatar ?? undefined;

  return (
    <>
      <nav className="fixed bottom-0 left-0 top-0 hidden w-[var(--side-nav-width)] shrink-0 flex-col justify-between border-r-[0.5px] border-foreground/25 bg-background px-4 py-6 md:flex">
        <div className="flex flex-col gap-12">
          <Link
            aria-label="Home"
            as={RouterLink}
            to="/"
            className="flex items-center gap-2">
            <PortalLogo className="h-[1.5rem] w-[6.75rem]" />
          </Link>
          <div className="flex flex-col gap-2">
            {pages.map(({name, href, isActive, Icon, from}) => (
              <Button
                aria-label="Menu item"
                key={name}
                as={RouterLink}
                to={href}
                {...(from && {state: {from}})}
                disableAnimation
                className={twMerge(
                  'min-w-auto flex h-auto items-center justify-start gap-2 overflow-visible rounded-lg px-3 py-2 text-medium font-medium text-primary !opacity-100 !transition-all',
                  isActive
                    ? 'bg-default-200 text-primary [&>svg]:text-primary'
                    : 'bg-transparent text-default-500 data-[hover=true]:text-primary [&>svg]:text-default-500 [&>svg]:data-[hover=true]:text-primary',
                )}
                startContent={Icon}>
                {name}
              </Button>
            ))}
          </div>
        </div>
        {isAuthenticated ? (
          <Button
            aria-label="Profile"
            as={RouterLink}
            to={'/profile/account'}
            disableAnimation
            disableRipple
            className="flex h-auto max-h-none min-h-0 min-w-0 flex-col gap-4 whitespace-normal rounded-lg border border-default-200 bg-transparent p-4 text-start">
            <div className="flex items-center gap-2">
              <Avatar
                src={userAvatar}
                alt={userName}
                className="flex h-8 w-8 shrink-0"
              />
              <span className="text-medium font-semibold text-foreground">
                {userName}
              </span>
            </div>
          </Button>
        ) : null}
      </nav>
      <nav className="fixed bottom-0 left-0 right-0 z-[1000] flex h-[var(--bottom-nav-height)] shrink-0 touch-pinch-zoom justify-around border-t-[0.5px] border-foreground/25 bg-background/75 px-5 py-2 backdrop-blur-xl md:hidden">
        {pages.map(({name, href, isActive, Icon}) => (
          <Button
            aria-label="Menu item"
            key={name}
            as={RouterLink}
            to={href}
            disableAnimation
            className={twMerge(
              'min-w-auto flex h-auto max-h-none flex-col items-center justify-start gap-2 overflow-visible rounded-lg bg-transparent p-0 text-[0.625rem]/[100%] font-semibold tracking-[0.115px] text-primary !opacity-100 !transition-all',
              isActive
                ? 'text-primary [&>svg]:text-primary'
                : 'text-default-500 [&>svg]:text-default-500',
            )}
            startContent={Icon}>
            {name}
          </Button>
        ))}
      </nav>
    </>
  );
});

NavigationBar.displayName = 'NavigationBar';

export default NavigationBar;
