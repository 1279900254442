import {Input, InputProps} from '@nextui-org/react';
import {FC} from 'react';
import {twMerge} from 'tailwind-merge';

const NextUIInput: FC<InputProps> = ({classNames, errorMessage, ...props}) => (
  <Input
    variant="bordered"
    classNames={{
      ...classNames,
      inputWrapper: twMerge(
        `border-[length:thin] !border-foreground/20 group-data-[focus=true]:!border-foreground/40 group-data-[disabled=true]:bg-foreground/[0.03]`,
        classNames?.inputWrapper,
      ),
      input: twMerge(
        `!text-foreground ${props?.startContent ? '!pl-3' : '!pl-0'} ${props?.endContent ? '!pr-3' : '!pr-0'}`,
        classNames?.input,
      ),
      label: twMerge('text-sm font-medium !text-foreground', classNames?.label),
      helperWrapper: twMerge('mt-1', classNames?.helperWrapper),
    }}
    radius="sm"
    labelPlacement="outside"
    placeholder=" "
    fullWidth
    errorMessage={props.isInvalid ? errorMessage : undefined}
    {...props}
  />
);

export default NextUIInput;
