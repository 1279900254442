import {CSSProperties} from 'react';

export type SectionFormat = 'json';

export type WidgetSectionFilter = {[key: string]: any};

export type SectionTemplate =
  | 'score'
  | 'markdown'
  | 'charts'
  | 'table'
  | 'suggested_email'
  | 'connected_trackers'
  | 'notice'
  | 'wordcloud'
  | 'tree'
  | 'minibrowser_test'
  | 'actions'
  | 'decided_actions'
  | 'tabs'
  | 'banner'
  | 'business_margin_form';

export type SectionContext = 'Preview' | 'DrillDown';

export type StyleConfigItem = {
  style?: {
    container?: CSSProperties;
    title?: CSSProperties;
    content?: CSSProperties;
  };
  explain_button_colors?: 'normal' | 'inverted';
};

export type StyleConfig = {
  Preview?: StyleConfigItem;
  DrillDown?: StyleConfigItem;
};

export type ChartIcon = 'checkmark';

export type MarkdownSection = {
  markdown: string;
  template: 'markdown';
};

export type ScoreSection = {
  score: {
    score: number;
    max_score?: number;
    icon_name?: ChartIcon;
    icon_color?: string;
  };
  template: 'score';
};

export type WordCloudSection = {
  template: 'wordcloud';
  title?: string;
  wordcloud: [
    {
      words: {
        label: string;
        value: number;
      }[];
    },
  ];
};

export type TreeNode = {
  label: string;
  children?: TreeNode[];
};

export type TreeSection = {
  template: 'tree';
  title: string;
  tree: TreeNode[];
};

export type MinibrowserTestStateName = 'initial' | 'failed' | 'completed';

export type MinibrowserTextIcon =
  | 'help'
  | 'warning'
  | 'meta'
  | 'calendar-check'
  | 'facebook'
  | 'instagram';

export type MinibrowserTestStateChangeAction = {
  label: string;
  type: 'primary' | 'secondary';
  action: 'state_change';
  to: MinibrowserTestStateName;
};

export type MinibrowserTestState = {
  icon: MinibrowserTextIcon;
  title: string;
  description: string;
  actions?: MinibrowserTestStateChangeAction[];
};

export type MinibrowserTestSection = {
  template: 'minibrowser_test';
  guide: MinibrowserTestState;
  customer_data_field: string;
  states: Record<MinibrowserTestStateName, MinibrowserTestState>;
};

export type WidgetSection = {
  format: SectionFormat;
  contexts?: SectionContext[];
  style_config?: StyleConfig;
  json:
    | MarkdownSection
    | ScoreSection
    | ChartsSection
    | TableSection
    | SuggestedEmailSection
    | WordCloudSection
    | TreeSection
    | ConnectedTrackersSection
    | MinibrowserTestSection
    | NoticeSection
    | TabsSection
    | ActionsSection
    | DecisidedActionsSection
    | BannerSection
    | BusinessMarginFormSection;
};

export type ChartType = 'donut' | 'bar' | 'horizontal-bars';

export type DonutChartData = {
  label: string;
  value: number;
  max_value: number;
  text_color: string;
};

export type DonutChart = {
  data: DonutChartData;
  chart_type: ChartType;
};

export type BarChartData = {
  label: string;
  value: number;
  max_value?: number;
  text_color: string;
};

export type BarChart = {
  data: BarChartData;
  chart_type: ChartType;
};

export type HorizontalBarsChartItem = {
  label: string;
  [key: string]: any;
};

export type HorizontalBarsChart = {
  data: HorizontalBarsChartItem[];
  chart_type: ChartType;
  title: string;
  subtitle?: string;
  format: ChartFromat;
  'show-legend'?: boolean;
  'data-keys'?: ChartDataKey[];
};

export type ChartFromat = {
  style?: 'currency' | 'percent' | 'decimal';
  currency?: string;
  notation?: 'standard' | 'compact';
};

export type ChartDataKey = {
  key: string;
  label: string;
  'chart-color': string;
  'font-weight': string;
  'max-value': number;
};

export type ChartsSection = {
  template: 'charts';
  charts: (DonutChart | BarChart | HorizontalBarsChart)[];
  title?: string;
  format?: ChartFromat;
  updated_at?: string;
  description?: string;
};

export type TableData = {
  rows: {[key: string]: any}[];
  header: string[];
};

export type TableSection = {
  table: TableData;
  template: 'table';
};

export type SuggestedEmailSection = {
  suggested_email: string;
  template: 'suggested_email';
};

export type ConnectedTrackersSection = {
  connected_trackers: {
    rows: {
      name: string;
      icon_url: string;
      is_connected: boolean;
    }[];
    updated_at: string;
  };
  template: 'connected_trackers';
};

export type NoticeSection = {
  notice: {
    icon_url: string;
    description: string;
  };
  template: 'notice';
};

export type ActionsSection = {
  actions: {action_id: string}[];
  template: 'actions';
};

export type DecisidedActionsSection = {
  actions: {action_id: string}[];
  template: 'decided_actions';
};

export type BannerSection = {
  template: 'banner';
  background_url: string;
  banner: {
    title: string;
    description: string;
    background?: string;
    explain?: WidgetExplain;
    link?: {
      href: '#recalculate_margin';
      text: string;
    };
  };
};

export type BusinessMarginFormSection = {
  template: 'business_margin_form';
  business_margin_form: {
    title: string;
    explain?: WidgetExplain;
    label: string;
    value?: number;
    button_title: string;
    action: 'rerun_tool';
  };
};

export type SectionRole = 'filter';

export type TabsSection = {
  role: SectionRole;
  tabs: {
    label: string;
    value: string;
  }[];
  template: 'tabs';
  filter_key: string;
  default_filter_value: string;
};

export type WidgetExplain = {
  content: string;
  prompt: string;
};

export type Widget = {
  sections: WidgetSection[];
  title?: string;
  notes?: string;
  position?: 'after' | 'before';
  anchor?: string;
  explain?: WidgetExplain;
  page?: string;
  style_config?: StyleConfig;
  download_file?: {
    button_text: string;
    contexts: SectionContext[];
    id: string;
  };
};

export enum ImpactLevel {
  None = 0,
  Medium = 1,
  High = 2,
  Ultra = 3,
}
