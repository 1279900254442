import {ComponentProps, memo} from 'react';
import {Button} from '@nextui-org/react';
import {msg} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import HandIcon from '@/components/icons/HandIcon';
import {useMatchesTWBreakpoint} from '@/hooks';
import {useNavigate} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {Language} from '@/__generated__/graphql';

interface Props {
  developer: string;
  category: string;
  copyright: string;
  languages: Language[];
  className?: ComponentProps<'div'>['className'];
}

const AppInfo = memo(
  ({developer, category, languages, copyright, className}: Props) => {
    const {_} = useLingui();
    const isMobile = !useMatchesTWBreakpoint('md');
    const navigate = useNavigate();

    const renderInfoItem = (label: string, value: string | string[]) => {
      const content = Array.isArray(value) ? value.join(', ') : value;

      return (
        <div className="flex justify-between gap-8 border-b-1 border-foreground/10 py-2 md:h-[4rem] md:flex-col md:justify-normal md:gap-1 md:border-none md:py-0">
          <span className="text-sm text-default-400">{label}</span>
          <span className="text-right text-xs text-foreground md:text-left">
            {content}
          </span>
        </div>
      );
    };

    const renderInfoButton = (
      icon: React.ReactNode,
      text: string,
      onPress: () => void,
    ) => {
      return (
        <Button
          aria-label="Info"
          disableAnimation
          disableRipple
          className="flex h-auto min-h-0 w-full rounded-none bg-transparent px-0 py-2 text-primary md:border-t-1 md:border-foreground/10 [&>svg]:stroke-primary"
          {...(isMobile ? {endContent: icon} : {startContent: icon})}
          onPress={onPress}>
          <div className="flex grow">{text}</div>
        </Button>
      );
    };

    return (
      <div
        className={twMerge(`grid grid-cols-1 gap-2 md:grid-cols-4`, className)}>
        {renderInfoItem(_(msg`Developer`), developer)}
        {renderInfoItem(_(msg`Category`), category)}
        {renderInfoItem(
          _(msg`Languages`),
          languages.map(lang => lang.name),
        )}
        {renderInfoItem(_(msg`Copyright`), copyright)}
        {renderInfoButton(<HandIcon />, _(msg`Privacy Policy`), () => {
          navigate('/legal/privacy');
        })}
      </div>
    );
  },
);

AppInfo.displayName = 'AppInfo';

export default AppInfo;
