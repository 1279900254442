import PortalSpinner from '@/components/PortalSpinner';
import {useFileDownload} from '@/hooks';
import {Trans} from '@lingui/macro';
import {Button, ButtonProps} from '@nextui-org/react';
import {memo} from 'react';
import {twMerge} from 'tailwind-merge';

export const DownloadFileButton = memo(
  ({
    className,
    children,
    filename,
    displayName,
    ...props
  }: ButtonProps & {filename: string; displayName?: string}) => {
    const {downloadFile} = useFileDownload({
      name: filename,
      displayName,
    });

    return (
      <Button
        aria-label="Download file"
        color="primary"
        className={twMerge(
          'h-9 rounded-lg text-sm font-medium text-background',
          className,
        )}
        spinner={<PortalSpinner size="sm" />}
        onPress={downloadFile}
        {...props}>
        {children ? children : <Trans>Download file</Trans>}
      </Button>
    );
  },
);

DownloadFileButton.displayName = 'DownloadFileButton';

export default DownloadFileButton;
