import axios from 'axios';
import {getCountryData, TCountryCode} from 'countries-list';
import {
  instrumentTracker,
  InstrumentEventName,
} from '@/common/instrumentTracker';
import {IntegrationType} from './types';

window._hsq = window._hsq || [];

interface UserInfo {
  id: string;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
}

interface IPInfoResponse {
  country: string;
  region: string;
  city: string;
  timezone: string;
}

interface GeoInfo {
  countryCode?: string;
  country?: string;
  region?: string;
  city?: string;
  timezone: string;
}

export const getIPGeoInfo = async (): Promise<GeoInfo | undefined> => {
  try {
    const {data} = await axios.get<IPInfoResponse>('https://ipinfo.io/json/');
    const countryData = data.country
      ? getCountryData(data.country.toUpperCase() as TCountryCode)
      : undefined;

    return {
      countryCode: data.country,
      country: countryData?.name,
      region: data.region,
      city: data.city,
      timezone: data.timezone,
    };
  } catch (e) {
    console.warn('Failed to get geo info:', e);

    return;
  }
};

export const identifyHSUser = ({id, email, firstName, lastName}: UserInfo) => {
  window._hsq.push([
    'identify',
    {
      email,
      firstname: firstName ?? undefined,
      lastname: lastName ?? undefined,
      ...(id ? {id} : {}),
    },
  ]);

  window._hsq.push(['trackPageView']);
};

export const reportEmailAuthStart = () => {
  window.dataLayer.push({
    event: 'auth-started-email',
  });
  instrumentTracker.track(InstrumentEventName.AUTH_STARTED_EMAIL);
};

export const reportEmailSignUp = () => {
  window.dataLayer.push({
    event: 'sign_up',
  });
  instrumentTracker.track(InstrumentEventName.SIGNUP_SUCCESS, {
    method: 'email',
  });
};

export const reportEmailSignIn = () => {
  window.dataLayer.push({
    event: 'successful-sign-in-email',
  });
  instrumentTracker.track(InstrumentEventName.SIGNIN_SUCCESS, {
    method: 'email',
  });
};

export const reportGoogleSignUp = () => {
  window.dataLayer.push({
    event: 'successful-authorization-google',
  });
  instrumentTracker.track(InstrumentEventName.SIGNUP_SUCCESS, {
    method: 'google',
  });
};

export const reportGoogleSignIn = () => {
  window.dataLayer.push({
    event: 'successful-sign-in-google',
  });
  instrumentTracker.track(InstrumentEventName.SIGNIN_SUCCESS, {
    method: 'google',
  });
};

export const reportGoogleAuthStart = async () => {
  window.dataLayer.push({
    event: 'auth-started-google',
  });

  await instrumentTracker.track(InstrumentEventName.AUTH_STARTED_GOOGLE);

  await instrumentTracker.flushQueue();
};

export const reportAppleSignUp = () => {
  window.dataLayer.push({
    event: 'successful-authorization-appleid',
  });
  instrumentTracker.track(InstrumentEventName.SIGNUP_SUCCESS, {
    method: 'apple',
  });
};

export const reportAppleSignIn = () => {
  window.dataLayer.push({
    event: 'successful-sign-in-appleid',
  });
  instrumentTracker.track(InstrumentEventName.SIGNIN_SUCCESS, {
    method: 'apple',
  });
};

export const reportAppleAuthStart = async () => {
  window.dataLayer.push({
    event: 'auth-started-apple',
  });

  await instrumentTracker.track(InstrumentEventName.AUTH_STARTED_APPLE);

  await instrumentTracker.flushQueue();
};

export const reportSubscribe = (subscriptionId: string) => {
  window.dataLayer.push({
    event: 'free-trial-started',
    transaction_id: subscriptionId,
  });
  instrumentTracker.track(InstrumentEventName.SUBSCRIBE_STARTED, {
    transaction_id: subscriptionId,
  });
};

export const reportActionApplyStart = (insightСategory?: string | null) => {
  window.dataLayer.push({
    event: 'action-apply-started',
    insight_category: insightСategory,
  });
  instrumentTracker.track(InstrumentEventName.ACTION_APPLY_STARTED, {
    insight_category: insightСategory,
  });
};

export const reportActionApply = (insightСategory?: string | null) => {
  window.dataLayer.push({
    event: 'action-applied',
    insight_category: insightСategory,
  });
  instrumentTracker.track(InstrumentEventName.ACTION_APPLIED, {
    insight_category: insightСategory,
  });
};

export const reportIntegrationStart = async (
  integration_type: IntegrationType,
) => {
  window.dataLayer.push({
    event: 'grant-access-started',
    integration_type,
  });

  switch (integration_type) {
    case IntegrationType.metaAds:
      instrumentTracker.track(InstrumentEventName.CONNECT_INTEGRATION_SUCCESS, {
        '3rd_party_service': integration_type,
      });
      break;
    default:
      break;
  }

  await instrumentTracker.track(InstrumentEventName.INTEGRATION_START, {
    '3rd_party_service': integration_type,
  });

  await instrumentTracker.flushQueue();
};

export const reportIntegrationConnect = (integration_type: string) => {
  window.dataLayer.push({
    event: 'granted-access-provided',
    integration_type,
  });
  instrumentTracker.track(InstrumentEventName.INTEGRATION_PROVIDED, {
    '3rd_party_service': integration_type,
  });
};

export const reportLogout = async () => {
  window.dataLayer.push({
    event: 'logout',
  });

  return await instrumentTracker.track(InstrumentEventName.LOGOUT);
};

// export const reportSubscribe = (subscriptionId: string) => {
//   window.dataLayer.push({
//     event: 'purchase',
//     transaction_id: subscriptionId,
//     value: 300,
//     currency: 'USD',
//     items: [
//       {
//         id: 'portal-ai-subscription',
//         name: 'Portal AI Subscription',
//         price: 300,
//         quantity: 1,
//       },
//     ],
//   });
// };
