import {SVGProps} from 'react';

export default function CoinSwapIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0 20.627C0 9.58126 8.95431 0.626953 20 0.626953C31.0457 0.626953 40 9.58126 40 20.627C40 31.6726 31.0457 40.627 20 40.627C8.95431 40.627 0 31.6726 0 20.627Z"
        fill="url(#paint0_linear_2306_32998)"
      />
      <path
        d="M13.2929 9.80071C13.6834 9.41018 14.3166 9.41018 14.7071 9.80071L16.7071 11.8007C17.0976 12.1912 17.0976 12.8244 16.7071 13.2149L14.7071 15.2149C14.3166 15.6054 13.6834 15.6054 13.2929 15.2149C12.9024 14.8244 12.9024 14.1912 13.2929 13.8007L13.5527 13.5409C12.108 13.7569 11 15.003 11 16.5078C11 17.0601 10.5523 17.5078 10 17.5078C9.44772 17.5078 9 17.0601 9 16.5078C9 13.8805 11.0264 11.7266 13.6014 11.5235L13.2929 11.2149C12.9024 10.8244 12.9024 10.1912 13.2929 9.80071Z"
        fill="url(#paint1_linear_2306_32998)"
      />
      <path
        d="M26.7071 25.8007C27.0976 26.1912 27.0976 26.8244 26.7071 27.2149L26.4473 27.4747C27.892 27.2587 29 26.0126 29 24.5078C29 23.9555 29.4477 23.5078 30 23.5078C30.5523 23.5078 31 23.9555 31 24.5078C31 27.1351 28.9736 29.2891 26.3986 29.4922L26.7071 29.8007C27.0976 30.1912 27.0976 30.8244 26.7071 31.2149C26.3166 31.6054 25.6834 31.6054 25.2929 31.2149L23.2929 29.2149C23.1054 29.0274 23 28.773 23 28.5078C23 28.2426 23.1054 27.9882 23.2929 27.8007L25.2929 25.8007C25.6834 25.4102 26.3166 25.4102 26.7071 25.8007Z"
        fill="url(#paint2_linear_2306_32998)"
      />
      <path
        d="M17.0623 15.5698C17.5206 12.1474 20.4521 9.50781 24 9.50781C27.866 9.50781 31 12.6418 31 16.5078C31 20.0558 28.3605 22.9872 24.938 23.4455C24.4533 19.324 21.1838 16.0545 17.0623 15.5698Z"
        fill="url(#paint3_linear_2306_32998)"
      />
      <path
        d="M16 17.5078C12.134 17.5078 9 20.6418 9 24.5078C9 28.3738 12.134 31.5078 16 31.5078C19.866 31.5078 23 28.3738 23 24.5078C23 20.6418 19.866 17.5078 16 17.5078Z"
        fill="url(#paint4_linear_2306_32998)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2306_32998"
          x1="40"
          y1="0.626953"
          x2="0"
          y2="40.627"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.147195" stopColor="#10C700" />
          <stop offset="0.839847" stopColor="#00D1C5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2306_32998"
          x1="20"
          y1="9.50781"
          x2="20"
          y2="31.5078"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.312679" stopColor="white" />
          <stop offset="1" stopColor="#BAFFBC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2306_32998"
          x1="20"
          y1="9.50781"
          x2="20"
          y2="31.5078"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.312679" stopColor="white" />
          <stop offset="1" stopColor="#BAFFBC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2306_32998"
          x1="20"
          y1="9.50781"
          x2="20"
          y2="31.5078"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.312679" stopColor="white" />
          <stop offset="1" stopColor="#BAFFBC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2306_32998"
          x1="20"
          y1="9.50781"
          x2="20"
          y2="31.5078"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.312679" stopColor="white" />
          <stop offset="1" stopColor="#BAFFBC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
