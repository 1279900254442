import {Dispatch, memo, SetStateAction, useEffect} from 'react';
import {TabsSection as TabsSectionType, WidgetSectionFilter} from './types';
import {Tab, Tabs} from '@nextui-org/react';
import {twMerge} from 'tailwind-merge';

const FiltersTabsSection = memo(
  ({
    tabs,
    filter_key,
    default_filter_value,
    className,
    widgetFilter,
    setWidgetFilter,
  }: {
    className?: string;
    widgetFilter?: WidgetSectionFilter;
    setWidgetFilter?: Dispatch<SetStateAction<WidgetSectionFilter>>;
  } & Omit<TabsSectionType, 'role'>) => {
    useEffect(() => {
      const value = default_filter_value || tabs[0]?.value;

      if (value) {
        setWidgetFilter?.(prev => ({
          ...prev,
          [filter_key]: default_filter_value || tabs[0].value,
        }));
      }
    }, [default_filter_value, tabs, setWidgetFilter, filter_key]);

    return (
      <Tabs
        className={twMerge('-ml-2', className)}
        classNames={{
          tab: 'text-sm font-medium px-2 pb-3',
          cursor: 'bg-primary',
        }}
        variant="underlined"
        selectedKey={widgetFilter?.[filter_key] ?? tabs[0]?.value}
        onSelectionChange={value => {
          setWidgetFilter?.(prev => ({
            ...prev,
            [filter_key]: value,
          }));
        }}
        aria-label="Tabs variants">
        {tabs.map(tab => (
          <Tab key={tab.value} title={tab.label} />
        ))}
      </Tabs>
    );
  },
);

FiltersTabsSection.displayName = 'FiltersTabsSection';

const TabsSection = memo(
  ({
    role,
    ...props
  }: {
    className?: string;
    widgetFilter?: WidgetSectionFilter;
    setWidgetFilter?: Dispatch<SetStateAction<WidgetSectionFilter>>;
  } & TabsSectionType) => {
    switch (role) {
      case 'filter':
        return <FiltersTabsSection {...props} />;
      default:
        throw new Error(`Unsupported tabs role: ${role}`);
    }
  },
);

TabsSection.displayName = 'TabsSection';

export default TabsSection;
