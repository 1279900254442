import {memo} from 'react';
import {Outlet} from 'react-router-dom';

const Profile = memo(() => {
  return <Outlet />;
});

Profile.displayName = 'Profile';

export default Profile;
