import {SVGProps} from 'react';

export default function HelpIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 1.5C9.3873 1.5 2 8.8873 2 18C2 27.1127 9.3873 34.5 18.5 34.5C27.6127 34.5 35 27.1127 35 18C35 8.8873 27.6127 1.5 18.5 1.5ZM16.8599 12.4068C17.4707 12.0479 18.1889 11.9166 18.8872 12.0364C19.5854 12.1562 20.2188 12.5192 20.6751 13.0612C21.1313 13.6032 21.381 14.2892 21.38 14.9977L21.38 14.9999C21.38 15.7036 20.8323 16.4373 19.7979 17.1269C19.3264 17.4412 18.8442 17.6834 18.4739 17.848C18.2909 17.9293 18.1405 17.9894 18.0394 18.0279C17.9945 18.045 17.9494 18.0615 17.9041 18.0774C17.1192 18.34 16.6952 19.1889 16.957 19.9743C17.2189 20.7602 18.0684 21.1849 18.8543 20.923L19.1073 20.8314C19.2523 20.7761 19.4534 20.6956 19.6923 20.5894C20.1658 20.379 20.8086 20.0586 21.462 19.623C22.6774 18.8128 24.3793 17.2968 24.38 15.0013C24.3819 13.5847 23.8825 12.213 22.9701 11.1292C22.0576 10.0452 20.7909 9.31915 19.3943 9.0796C17.9978 8.84005 16.5615 9.10249 15.3399 9.82044C14.1183 10.5384 13.1902 11.6655 12.72 13.0022C12.4451 13.7837 12.8557 14.64 13.6372 14.9149C14.4187 15.1899 15.2751 14.7792 15.55 13.9977C15.7851 13.3294 16.2491 12.7658 16.8599 12.4068ZM18.5 23.9999C17.6716 23.9999 17 24.6715 17 25.4999C17 26.3284 17.6716 26.9999 18.5 26.9999H18.515C19.3434 26.9999 20.015 26.3284 20.015 25.4999C20.015 24.6715 19.3434 23.9999 18.515 23.9999H18.5Z"
        fill="#007AFF"
      />
    </svg>
  );
}
