import {memo} from 'react';
import {Decision as DecisionType} from './types';
import {twMerge} from 'tailwind-merge';
import {DecisionButton} from './DecisionButton';
import {AnimatePresence, motion} from 'framer-motion';
import {DecisionStatusEnum} from '@/__generated__/graphql';
import {useGetRelativeTime} from '@/hooks';

export const Decision = memo(
  ({
    className,
    decision,
    isDisabled,
  }: {
    className?: string;
    decision: DecisionType;
    isDisabled?: boolean;
  }) => {
    const getRelativeTime = useGetRelativeTime();

    return (
      <motion.div
        initial={{marginBottom: 0, height: 0, opacity: 0}}
        animate={{marginBottom: '1.5rem', height: 'auto', opacity: 1}}
        exit={{marginBottom: 0, height: 0, opacity: 0}}
        transition={{duration: 0.5}}
        className={twMerge('flex items-center overflow-hidden', className)}>
        <div className="flex flex-1 items-center gap-2">
          <img
            src={decision.iconUrl ?? undefined}
            alt={decision.title}
            className="h-10 w-10 shrink-0 rounded-full border-[length:thin] border-foreground/10"
          />
          <div className="flex flex-col gap-1 text-xs">
            <div className="font-semibold">{decision.title}</div>
            <div>{decision.description}</div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-1">
          <DecisionButton isDisabled={isDisabled} decision={decision} />
          <AnimatePresence initial={false}>
            {decision.status === DecisionStatusEnum.Approved && (
              <motion.div
                initial={{height: 0, opacity: 0}}
                animate={{height: 'auto', opacity: 1}}
                exit={{height: 0, opacity: 0}}
                className="text-[0.625rem] font-normal leading-[0.5rem] text-foreground/40 sm:text-xs sm:leading-4">
                {getRelativeTime(decision.updatedAt)}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    );
  },
);

Decision.displayName = 'Decision';
