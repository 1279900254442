import {ErrorURLParam} from '@/common/types';
import {createContext} from 'react';

export interface ErrorParamHandlerContextValue {
  error: ErrorURLParam | null;
  resetError: (type?: ErrorURLParam['type']) => void;
}

export const ErrorParamHandlerContext = createContext<
  ErrorParamHandlerContextValue | undefined
>(undefined);
