import {InsightLayoutType} from '@/__generated__/graphql';
import {memo} from 'react';
import DetailedInsight, {DetailedInsightProps} from './DetailedInsight';
import CompactInsight, {CompactInsightProps} from './CompactInsight';

type Props = DetailedInsightProps | CompactInsightProps;

const InsightPreview = memo((props: Props) => {
  switch (props.insight.layoutType) {
    case InsightLayoutType.Detailed:
      return <DetailedInsight {...props} />;
    default:
      return <CompactInsight {...props} />;
  }
});

InsightPreview.displayName = 'InsightPreview';

export default InsightPreview;
