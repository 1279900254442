import {Button} from '@nextui-org/react';
import EyeIcon from './icons/EyeIcon';
import EyeOffIcon from './icons/EyeOffIcon';
import {FC} from 'react';

const eyeIconClassName = 'h-6 w-6';

const ShowPasswordButton: FC<{
  showPassword: boolean;
  onPress: (showPassword: boolean) => void;
}> = ({showPassword, onPress}) => (
  <Button
    aria-label="Show password"
    variant="light"
    isIconOnly
    disableRipple
    className="!bg-transparent stroke-default-600 data-[pressed=true]:scale-95 data-[hover=true]:stroke-default-400"
    onPress={() => onPress(!showPassword)}>
    {showPassword ? (
      <EyeOffIcon className={eyeIconClassName} />
    ) : (
      <EyeIcon className={eyeIconClassName} />
    )}
  </Button>
);

export default ShowPasswordButton;
