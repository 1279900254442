import {SVGProps} from 'react';

export default function HandIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}>
      <path
        d="M5.74984 9.537V11.8518M5.74984 9.537V3.98144C5.74984 3.21438 6.3841 2.59255 7.1665 2.59255C7.94891 2.59255 8.58317 3.21438 8.58317 3.98144M5.74984 9.537C5.74984 8.76993 5.11557 8.14811 4.33317 8.14811C3.55077 8.14811 2.9165 8.76993 2.9165 9.537V11.3888C2.9165 15.2242 6.08782 18.3333 9.99984 18.3333C13.9119 18.3333 17.0832 15.2242 17.0832 11.3888V6.75922C17.0832 5.99216 16.4489 5.37033 15.6665 5.37033C14.8841 5.37033 14.2498 5.99216 14.2498 6.75922M8.58317 3.98144V9.07403M8.58317 3.98144V3.05551C8.58317 2.28845 9.21743 1.66663 9.99984 1.66663C10.7822 1.66663 11.4165 2.28845 11.4165 3.05551V3.98144M11.4165 3.98144V9.07403M11.4165 3.98144C11.4165 3.21438 12.0508 2.59255 12.8332 2.59255C13.6156 2.59255 14.2498 3.21438 14.2498 3.98144V6.75922M14.2498 6.75922V9.07403"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
