import {SVGProps} from 'react';

export default function PuzzlePieceIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2929 1.29289C11.6834 0.902369 12.3166 0.902369 12.7071 1.29289L15.3267 3.91245C15.959 2.92821 16.7685 2.30702 17.6897 2.07298C18.9563 1.7512 20.1716 2.2324 20.9696 3.03039C21.7676 3.82838 22.2488 5.04374 21.927 6.31029C21.693 7.23155 21.0718 8.04101 20.0876 8.67334L22.7071 11.2929C23.0976 11.6834 23.0976 12.3166 22.7071 12.7071L19.1071 16.3071C18.8673 16.5469 18.5224 16.6488 18.1908 16.5779C17.8593 16.507 17.5862 16.2729 17.4655 15.956C16.9383 14.572 16.2678 14.1257 15.8178 14.0114C15.35 13.8925 14.8341 14.0551 14.4446 14.4446C14.0551 14.8341 13.8925 15.35 14.0114 15.8178C14.1257 16.2678 14.572 16.9383 15.956 17.4655C16.2729 17.5862 16.507 17.8593 16.5779 18.1908C16.6488 18.5224 16.5469 18.8673 16.3071 19.1071L12.7071 22.7071C12.3166 23.0976 11.6834 23.0976 11.2929 22.7071L8.67334 20.0876C8.04101 21.0718 7.23155 21.693 6.31029 21.927C5.04374 22.2488 3.82838 21.7676 3.03039 20.9696C2.2324 20.1716 1.7512 18.9563 2.07298 17.6897C2.30702 16.7685 2.92821 15.959 3.91245 15.3267L1.29289 12.7071C0.902369 12.3166 0.902369 11.6834 1.29289 11.2929L4.89289 7.69289C5.13265 7.45313 5.47758 7.3512 5.80915 7.42212C6.14073 7.49304 6.41378 7.72714 6.53449 8.044C7.06174 9.42805 7.73217 9.87428 8.18217 9.9886C8.64999 10.1075 9.16588 9.9449 9.55539 9.55539C9.9449 9.16588 10.1075 8.64999 9.9886 8.18217C9.87428 7.73217 9.42805 7.06174 8.044 6.53449C7.72714 6.41378 7.49304 6.14073 7.42212 5.80915C7.3512 5.47758 7.45313 5.13265 7.69289 4.89289L11.2929 1.29289Z"
        fill="currentColor"
      />
    </svg>
  );
}
