import PortalSpinner from '@/components/PortalSpinner';
import {Button, ButtonProps} from '@nextui-org/react';
import {FC} from 'react';
import {twMerge} from 'tailwind-merge';

const BackButton: FC<ButtonProps> = ({className, children, ...props}) => (
  <Button
    aria-label="Back"
    className={twMerge(
      `w-auto min-w-0 px-0 text-sm font-medium text-foreground hover:opacity-100 data-[hover=true]:bg-transparent/[0.03]`,
      className,
    )}
    variant="light"
    disableRipple
    spinner={<PortalSpinner size="sm" />}
    {...props}>
    {children}
  </Button>
);

export default BackButton;
