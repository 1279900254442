import {memo, useMemo} from 'react';
import {
  BarChart as BarChartType,
  BarChartData,
  ChartsSection as ChartsSectionType,
  DonutChartData,
  HorizontalBarsChart as HorizontalBarsChartType,
} from './types';
import {twJoin, twMerge} from 'tailwind-merge';
import DonutChart from './charts/DonutChart';
import UpdatedAt from './UpdatedAt';
import BarChart from './charts/BarChart';
import HorizontalBarsChart from './charts/HorizontalBarsChart';

type Props = {
  className?: string;
  isDrillDown?: boolean;
} & ChartsSectionType;

const ChartSection = memo(
  ({
    className,
    charts,
    title,
    description,
    isDrillDown,
    updated_at,
    format,
  }: Props) => {
    const chartType = useMemo(() => charts[0]?.chart_type, [charts]);
    const gridClass = (() => {
      if (charts.length === 1) {
        return 'grid-cols-1';
      } else if (charts.length % 3 === 0) {
        return 'grid-cols-3';
      }

      return 'grid-cols-2';
    })();
    const classByChart = (() => {
      switch (chartType) {
        case 'donut':
          return `grid ${gridClass}`;
        case 'bar':
          return `grid aspect-[3/2] ${gridClass}`;
        case 'horizontal-bars':
          return 'flex flex-col';
        default:
          return '';
      }
    })();

    return (
      <div
        className={twMerge(
          'flex flex-col items-center justify-center gap-6',
          className,
        )}>
        {title ? (
          <div className={`flex w-full flex-col gap-4`}>
            <div
              className={`w-full ${isDrillDown ? 'text-center font-semibold text-foreground' : 'text-start text-default-500'}`}>
              {title}
            </div>
          </div>
        ) : null}
        <div
          className={twJoin(
            `w-full justify-center gap-x-2 gap-y-6`,
            classByChart,
          )}>
          {charts.map(({chart_type, data}, idx) => {
            switch (chart_type) {
              case 'donut':
                return (
                  <DonutChart
                    key={idx}
                    size={charts.length % 3 ? 106 : 82}
                    data={data as DonutChartData}
                  />
                );
              case 'bar':
                return (
                  <BarChart
                    key={idx}
                    data={data as BarChartData}
                    charts={charts as BarChartType[]}
                    format={format}
                  />
                );
              case 'horizontal-bars':
                return (
                  <HorizontalBarsChart
                    key={idx}
                    chart={charts[idx] as HorizontalBarsChartType}
                  />
                );
              default:
                throw new Error(`Unknown chart type: ${chart_type}`);
            }
          })}
        </div>

        {Boolean(description) && isDrillDown ? (
          <div className="text-center text-xs font-normal text-default-500 sm:text-sm">
            {description}
          </div>
        ) : null}
        {!isDrillDown && <UpdatedAt updatedAt={updated_at} />}
      </div>
    );
  },
);

ChartSection.displayName = 'ChartSection';

export default ChartSection;
