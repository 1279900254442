import {memo, useEffect, useState, PropsWithChildren} from 'react';
import {Theme, ThemeProviderContext} from '@/contexts';

export const ThemeProvider = memo(
  ({
    children,
    defaultTheme = 'light',
    storageKey = 'ui-theme',
    ...props
  }: PropsWithChildren & {
    defaultTheme?: Theme;
    storageKey?: string;
  }) => {
    const [theme, setTheme] = useState<Theme>(
      () => (localStorage.getItem(storageKey) as Theme) || defaultTheme,
    );
    const [mode] = useState<'light' | 'dark'>('light');

    // NOTE: not needed for now
    // useEffect(() => {
    //   const root = window.document.documentElement;

    //   root.classList.remove('light', 'dark');

    //   if (theme === 'system') {
    //     const systemTheme = window.matchMedia('(prefers-color-scheme: dark)')
    //       .matches
    //       ? 'dark'
    //       : 'light';

    //     root.classList.add(systemTheme);
    //     setMode(systemTheme);
    //   } else {
    //     root.classList.add(theme);
    //     setMode(theme);
    //   }
    // }, [theme]);

    useEffect(() => {
      const root = window.document.documentElement;

      root.classList.add(theme);
    }, []);

    const value = {
      mode,
      theme,
      setTheme: (theme: Theme) => {
        localStorage.setItem(storageKey, theme);
        setTheme(theme);
      },
    };

    return (
      <ThemeProviderContext.Provider {...props} value={value}>
        {children}
      </ThemeProviderContext.Provider>
    );
  },
);

ThemeProvider.displayName = 'ThemeProvider';
