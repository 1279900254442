export const API_URL =
  (import.meta.env.VITE_API_URL as string) || 'http://localhost:8000';
// use this from time to time for cross-browser testing local frontend remotely
// export const API_URL = 'https://dev-v2-api.portal.ai';
export const API_LIMIT = 20;
export const FACEBOOK_APP_ID =
  (import.meta.env.VITE_FACEBOOK_APP_ID as string) || '1107222943603503';
export const FACEBOOK_APP_CONFIG_ID =
  (import.meta.env.VITE_FACEBOOK_APP_CONFIG_ID as string) || '8073415376021853';
export const MODE = import.meta.env.MODE || 'development';
export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT || 'local';
export const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY || '';
export const AMPLITUDE_OPT_OUT = ENVIRONMENT === 'local';
export const SENTRY_OPT_OUT = ENVIRONMENT === 'local';
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || '';

export const LS_AUTH_TOKEN_KEY = 'authToken';
export const LS_REFRESH_TOKEN_KEY = 'refreshToken';
export const LS_INITIAL_QS_AFTER_LOGIN = 'initialQsAfterLogin';
export const LS_PENDING_AGENTS_SETUPS = 'pendingAgentsSetups';
export const LS_UNAUTHENTICATED_CHAT_SESSION_IDS =
  'unauthenticatedChatSessionIds';
export const COMPANY_INFO = `Portal AI Inc, Address: 541 Jefferson Ave Ste 100 Redwood City, California San Mateo County, 94063, US`;
export const PERSIST_FORMS_IN_SS = true;

export const INTEGRATION_CONNECTION_PARAM = 'integration_connection';

/**
 * Routes
 */

export const PATH = {
  segment: {
    chat: 'chat',
    sessions: 'sessions',
  },
  param: {
    sessionId: ':sessionId',
  },
};

export const FORCE_RUN_TOOLS = new Set(['marketing_readiness']);
