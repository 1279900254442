import {memo, useEffect, useMemo} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import AppHeader from './components/AppHeader';
import PortalSpinner from '@/components/PortalSpinner';
import AppImages from './components/AppImages';
import AppDescription from './components/AppDescription';
import {formatApolloError} from '../helpers';
import AppBanner from './components/AppBanner';
import AppStats from './components/AppStats';
import AppReviews from './components/AppReviews';
import {Trans} from '@lingui/macro';
import AppInfo from './components/AppInfo';
import AppExtraSections from './components/AppExtraSections';
// import {getRiskIndexGroup} from '@/utils';
import {ToolSetupConfig} from '@/common/types';
import {useAppContext, useAuthContext, useToolContext} from '@/hooks';
import ToolInfoHeader from './components/ToolInfoHeader';

const CatalogueApp = memo(({infoMode}: {infoMode?: boolean}) => {
  const [searchParams] = useSearchParams();
  const {isAuthenticated} = useAppContext();
  const {business} = useAuthContext();
  const {isLoading, isUnlocked, agent, error, unlockTool} = useToolContext();
  const navigate = useNavigate();
  const {stepId} = useParams<{stepId: string}>();
  const unlockStep = useMemo(() => {
    if (stepId) {
      return stepId;
    }

    const setupConfig = agent?.setupConfig as ToolSetupConfig | undefined;

    return setupConfig?.steps?.[0]?.name;
  }, [agent, stepId]);
  const showUnlock = searchParams.get('showUnlock') === 'true';
  let unlockLink: string | undefined = undefined;

  if (!isAuthenticated) {
    unlockLink = `auth/login?showUnlock=true`;
  } else if (unlockStep) {
    unlockLink = `unlock/${unlockStep}`;
  }

  useEffect(() => {
    if (
      (isAuthenticated ? business : true) &&
      !isUnlocked &&
      !isLoading &&
      showUnlock
    ) {
      unlockLink ? navigate(unlockLink, {replace: true}) : unlockTool();
    }
  }, [
    business,
    isUnlocked,
    navigate,
    unlockLink,
    unlockTool,
    isLoading,
    isAuthenticated,
    showUnlock,
  ]);

  if (!agent && isLoading) {
    return (
      <PortalSpinner
        classNames={{base: 'backdrop-blur-sm fixed inset-0 z-[1000]'}}
        size="lg"
      />
    );
  }

  const formattedError = error ? formatApolloError(error) : undefined;

  if (formattedError || !agent) {
    return (
      <div className="flex w-full flex-col gap-8">
        <div className="flex w-full flex-col gap-[0.875rem] md:gap-8">
          <hr className="mx-5 bg-default-200 md:mx-12" />
          <div className="px-5 text-2xl font-medium text-danger md:px-12">
            {formattedError ?? 'App data not found'}
          </div>
        </div>
      </div>
    );
  }
  // const riskIndexGroup = getRiskIndexGroup(agent.riskIndex);

  return (
    <div className="flex w-full max-w-[var(--max-page-width)] flex-col pb-[8rem] md:pb-[7rem]">
      <div className="flex w-full flex-col gap-[0.875rem] md:gap-8">
        {infoMode ? (
          <ToolInfoHeader />
        ) : (
          <>
            <AppBanner
              banner={agent.bannerImage}
              bannerText={agent.bannerText}
            />
            <AppHeader
              unlockLink={unlockLink}
              explain={agent.explain}
              templateName={agent.templateName}
            />
            <hr className="mx-5 bg-default-200 md:mx-12" />
          </>
        )}
        <AppStats
          // riskIndexGroup={riskIndexGroup}
          unlocks={agent.unlocks ?? 0}
          activationsText={agent.activationsText}
          rating={agent.rating ?? 0}
          categoryName={agent.categoryName}
          categoryImage={agent.categoryImage?.url}
          dataSources={agent.dataSources}
          languages={agent.languages}
          className="px-5 md:px-12"
        />
        <div className="md:px-12">
          <AppImages images={agent.images} className="px-5 md:px-0" />
        </div>
        <AppDescription
          description={agent.description}
          className="px-5 md:px-12"
        />
        <div className="flex w-full flex-col">
          <hr className="mx-5 bg-default-200 md:mx-12" />
          <div className="px-5 pt-[0.875rem] text-medium font-semibold text-foreground md:px-12 md:pb-4 md:pt-4 md:text-xl">
            <Trans>Rating & Reviews</Trans>
          </div>
          <AppReviews
            templateName={agent.templateName}
            rating={agent.rating ?? 0}
            ratingClassName="px-5 md:px-12"
            reviewsClassName="px-5 md:px-0 md:mx-12 md:w-[calc(100%-6rem)]"
            setRating={() => {}} // TODO implement
          />
        </div>
        {agent.dataSources.length ? (
          <div className="flex w-full flex-col">
            <hr className="mx-5 bg-default-200 md:mx-12" />
            <div className="px-5 py-[0.875rem] text-medium font-semibold text-foreground md:px-12 md:py-[1rem] md:text-xl">
              <Trans>Data Needed</Trans>
            </div>
            <AppExtraSections
              // riskIndexGroup={riskIndexGroup}
              dataSources={agent.dataSources}
              className="px-5 md:px-12"
            />
          </div>
        ) : null}
        <div className="flex w-full flex-col">
          <hr className="mx-5 bg-default-200 md:mx-12" />
          <div className="px-5 py-[0.875rem] text-medium font-semibold text-foreground md:px-12 md:py-[1rem] md:text-xl">
            <Trans>Information</Trans>
          </div>
          <AppInfo
            developer={agent.developer ?? ''}
            category={agent.categoryName ?? ''}
            copyright={agent.copyright ?? ''}
            languages={agent.languages}
            className="px-5 md:px-12"
          />
        </div>
      </div>
    </div>
  );
});

CatalogueApp.displayName = 'CatalogueApp';

export default CatalogueApp;
