import ChatSessions from '@/components/Chat/components/ChatSessions';
import {memo, useMemo} from 'react';
import BackButton from '@/components/buttons/BackButton';
import {Trans} from '@lingui/macro';

const PortalChatSessions = memo(() => {
  const sessionsClassNames = useMemo(
    () => ({
      scroll: 'py-6 pb-0',
    }),
    [],
  );
  return (
    <div className={'flex h-full w-full max-w-[48rem] grow flex-col'}>
      <div className="space-between flex max-w-full items-center gap-2 py-3">
        <div className="flex flex-1 justify-center">
          <BackButton className="mr-auto" />
        </div>
        <div className="flex-5 flex justify-center text-center text-medium font-semibold">
          <Trans>Sessions</Trans>
        </div>
        <div className="flex flex-1" />
      </div>
      <ChatSessions classNames={sessionsClassNames} />
    </div>
  );
});

PortalChatSessions.displayName = 'PortalChatSessions';

export default PortalChatSessions;
