import {twMerge} from 'tailwind-merge';

interface Props {
  message?: string;
  className?: string;
}

const ErrorMessage = ({message, className = '', ...restProps}: Props) => {
  if (!message) {
    return null;
  }

  return (
    <div
      className={twMerge(`whitespace-pre text-tiny text-danger`, className)}
      {...restProps}>
      {message}
    </div>
  );
};

export default ErrorMessage;
