import {memo} from 'react';
import {TableSection as TableSectionType} from './types';
import {
  Button,
  getKeyValue,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import {twJoin} from 'tailwind-merge';
import {Trans} from '@lingui/macro';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon';
import {useNavigate} from 'react-router-dom';
import {useTableData} from '@/routes/CatalogueApp/helpers';

const TableSection = memo(
  ({
    title,
    table,
    className,
  }: {title?: string; className?: string} & TableSectionType) => {
    const navigate = useNavigate();
    const {exceedsLimit, columns, items} = useTableData(table, true);

    return (
      <div
        className={twJoin(
          'relative overflow-hidden rounded-3xl p-6',
          className,
        )}>
        <Table
          aria-label="Table widget section"
          shadow="none"
          radius="none"
          removeWrapper
          classNames={{
            th: 'break-words max-w-[calc(100dvw/2)] first:pl-0 last:pr-0 px-1.5 bg-transparent text-sm font-medium text-default-400 border-b-[length:thin] border-foreground/10 h-auto pb-4',
            td: 'break-words max-w-[calc(100dvw/2)] first:pl-0 last:pr-0 text-sm font-medium pt-6 pb-0 align-top px-1.5',
          }}>
          <TableHeader columns={columns}>
            {column => (
              <TableColumn className={column.align} key={column.key}>
                {column.label}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody items={items}>
            {item => (
              <TableRow key={item._key} className="break-words">
                {columnKey => (
                  <TableCell
                    className={columns.find(i => i.key === columnKey)?.align}>
                    {getKeyValue(item, columnKey)}
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        {exceedsLimit ? (
          <>
            <div className="absolute bottom-0 left-0 right-0 flex h-[min(16rem,50%)] flex-col">
              <div className="flex w-full grow bg-gradient-to-t from-background to-transparent" />
              <Button
                disableAnimation
                disableRipple
                className="h-auto max-h-none w-full max-w-none gap-1 bg-background p-6 font-medium text-primary !opacity-100"
                endContent={<ChevronLeftIcon className="h-5 w-5 rotate-180" />}
                onPress={() =>
                  navigate('table', {
                    state: {
                      table,
                      title,
                    },
                  })
                }>
                <Trans>Show all</Trans>
              </Button>
            </div>
            <div className="h-[2.75rem]" />
          </>
        ) : null}
      </div>
    );
  },
);

TableSection.displayName = 'TableSection';

export default TableSection;
