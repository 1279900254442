import {memo} from 'react';
import {usePaginatedInsights} from '../hooks';
import BackButton from '@/components/buttons/BackButton';
import {Trans} from '@lingui/macro';
import InsightPreview from '@/routes/CatalogueApp/unlocked/components/InsightPreview';

const LiveInsights = memo(() => {
  const {insights} = usePaginatedInsights();

  return (
    <div className="relative flex w-full max-w-2xl flex-col">
      <div className="space-between sticky top-0 z-20 flex max-w-full items-center gap-2 bg-background/75 py-3 backdrop-blur-xl">
        <div className="flex flex-1 justify-center">
          <BackButton className="mr-auto" to=".." />
        </div>
        <div className="flex-5 flex justify-center text-center text-medium font-semibold">
          <Trans>Recent Insights</Trans>
        </div>
        <div className="flex flex-1" />
      </div>
      <div className="flex flex-col gap-6 p-6">
        {insights.map(insight => (
          <InsightPreview
            key={insight.id}
            insight={insight}
            appName={insight.agent?.templateName}
            appIcon={insight.agent?.logo?.url}
            isDrillDown={false}
            onlyActions
            className={'w-full min-w-full max-w-full'}
            href={insight.id}
          />
        ))}
      </div>
    </div>
  );
});

LiveInsights.displayName = 'LiveInsights';

export default LiveInsights;
