import {ComponentProps, memo} from 'react';
import {Button} from '@nextui-org/react';
import {Trans} from '@lingui/macro';
import {twMerge} from 'tailwind-merge';
import ModalOutlet from '@/components/ModalOutlet';
import {Link as RouterLink} from 'react-router-dom';
import {useAppContext, useToolContext} from '@/hooks';
import ExplainButton from '@/components/ExplainButton';
import {Explain} from '@/common/types';

interface Props {
  className?: ComponentProps<'div'>['className'];
  explain?: Explain | null;
  templateName: string;
  unlockLink?: string;
}

const subPath = ['auth', 'unlock'];

const AppHeader = memo(
  ({unlockLink, explain, templateName, className}: Props) => {
    const {isAuthenticated} = useAppContext();
    const {isUnlocked, appName, agent} = useToolContext();
    const {logo, name, subtitle} = agent!;

    return (
      <>
        <div className={twMerge(`flex w-full flex-col md:px-12`, className)}>
          <div className="flex gap-4 px-5 md:px-0">
            {logo?.url ? (
              <img
                className="flex aspect-square w-[7.375rem] shrink-0 self-center rounded-[1.875rem] md:w-[5.75rem] md:rounded-[1.5rem]"
                src={logo?.url}
              />
            ) : null}
            <div className="flex flex-col gap-7">
              <div className="flex flex-col gap-1">
                <div className="text-xl font-semibold text-foreground md:text-2xl md:font-bold">
                  {name}
                </div>
                <div className="text-xs text-default-500 md:text-medium md:font-medium">
                  {subtitle}
                </div>
              </div>

              <div className="flex gap-1">
                {isUnlocked ? null : (
                  <Button
                    aria-label="Apply"
                    as={RouterLink}
                    color="primary"
                    to={
                      isAuthenticated
                        ? unlockLink
                        : 'auth/login?showUnlock=true'
                    }
                    className="h-[2rem] min-h-0 w-auto min-w-0 rounded-full px-4 text-medium font-semibold text-background">
                    <Trans>Apply</Trans>
                  </Button>
                )}
                {explain ? (
                  <ExplainButton
                    {...explain}
                    templateName={templateName}
                    className="h-[2rem]"
                    classNames={{
                      content: 'text-sm',
                      icon: 'h-5 w-5',
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <ModalOutlet basePath={`/catalog/${appName}/info`} subPath={subPath} />
      </>
    );
  },
);

AppHeader.displayName = 'AppHeader';

export default AppHeader;
