import {gql} from '@/__generated__';
import {API_LIMIT} from '@/config';
import {useAuthContext, useToolContext} from '@/hooks';
import {useQuery} from '@apollo/client';
import {memo, useEffect} from 'react';
import {motion} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import PortalSpinner from '@/components/PortalSpinner';
import TopicPreview from './TopicPreview';

const TOOL_TOPICS_CONNECTION_DOCUMENT = gql(`
  query GetTopicConnection(
    $filter: JSON
    $order: JSON
    $first: Int
    $after: String
  ) {
    connection: getTopicConnection(
      filter: $filter
      order: $order
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          title
          widgets
          order
          hasDrilldown
          explain
          styleConfig
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const ToolTopicList = memo(({className}: {className?: string}) => {
  const {agent} = useToolContext();
  const {business} = useAuthContext();
  const {ref: lastItemRef, inView: isLastItemInView} = useInView();
  const {data, fetchMore} = useQuery(TOOL_TOPICS_CONNECTION_DOCUMENT, {
    variables: {
      first: API_LIMIT,
      filter: {
        businessId: business?.id,
        agentId: agent?.id,
      },
      order: {order: 'ASC'},
    },
    skip: !business || !agent,
  });
  const pageInfo = data?.connection.pageInfo;

  useEffect(() => {
    if (isLastItemInView && pageInfo?.hasNextPage) {
      fetchMore({variables: {after: pageInfo.endCursor}});
    }
  }, [isLastItemInView, pageInfo?.hasNextPage, pageInfo?.endCursor, fetchMore]);

  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      className={className}>
      {data?.connection.edges.map(e => (
        <TopicPreview
          templateName={agent?.templateName ?? ''}
          key={e.node.id}
          topic={e.node}
        />
      ))}
      {pageInfo?.hasNextPage && (
        <PortalSpinner
          ref={lastItemRef}
          size="md"
          className="hidden w-full"
          classNames={{wrapper: 'm-2'}}
        />
      )}
    </motion.div>
  );
});

ToolTopicList.displayName = 'ToolTopicList';

export default ToolTopicList;
