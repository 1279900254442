import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  TreeSection as TreeSectionType,
  TreeNode as TreeNodeType,
} from './types';
import {twMerge} from 'tailwind-merge';
import {Button} from '@nextui-org/react';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon';
import debounce from 'lodash/debounce';
import {useResizeObserver} from '@/hooks';

type TreeSectionProps = {
  className?: string;
} & TreeSectionType;

type TreeNodeProps = {
  node: TreeNodeType;
  level: number;
  isLast: boolean;
  className?: string;
  updateKey: string;
  triggerUpdate: () => void;
  setTop?: (top: number) => void;
};

const TreeNode = memo(
  ({
    node,
    level,
    isLast,
    className,
    updateKey,
    triggerUpdate,
    setTop,
  }: TreeNodeProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const isExpandable = (node.children?.length ?? 0) > 0;
    const [lastItemTop, setLastItemTop] = useState(0);

    const onPress = useCallback(() => {
      setIsExpanded(prev => !prev);
    }, []);

    useEffect(() => {
      if (isExpanded && !isExpandable) {
        setIsExpanded(false);
      }
    }, [isExpandable, isExpanded]);

    useLayoutEffect(() => {
      triggerUpdate();
    }, [isExpanded]);

    useEffect(() => {
      if (!containerRef.current || !isLast || !setTop) {
        return;
      }

      const top = containerRef.current.offsetTop ?? 0;

      setTop(top);
    }, [updateKey, isLast, setTop]);

    return (
      <div
        ref={containerRef}
        className={twMerge('relative flex flex-col', className)}>
        <Button
          aria-label="Toggle tree node"
          disableAnimation
          disableRipple
          isDisabled={!isExpandable}
          className={`z-10 mb-2 h-auto max-h-none min-h-0 w-auto min-w-0 justify-between overflow-visible rounded-lg border border-[#D0D5DD] bg-background px-[0.875rem] py-[0.625rem] text-start text-[#344054] !opacity-100`}
          endContent={
            isExpandable ? (
              <ChevronLeftIcon
                className={`flex h-5 w-5 shrink-0 ${isExpanded ? 'rotate-90' : '-rotate-90'}`}
              />
            ) : null
          }
          onPress={onPress}>
          {/* down from the center */}
          {level > 0 && !isLast ? (
            <div
              className={`absolute bottom-[-0.6rem] left-[-1rem] top-[50%] w-[1px] bg-[#BDBDBF]`}
            />
          ) : null}
          {/* up from the center */}
          {level > 0 ? (
            <div
              className={`absolute -top-2 bottom-[50%] left-[-1rem] z-0 w-[1px] bg-[#BDBDBF]`}
            />
          ) : null}
          {/* horizontal to the element itself */}
          {level > 0 ? (
            <div
              className={`absolute left-[-1rem] top-[50%] h-[1px] w-[1rem] bg-[#BDBDBF]`}
            />
          ) : null}
          <div
            className={
              isLast
                ? 'line-clamp-1 overflow-hidden text-ellipsis whitespace-normal'
                : 'overflow-hidden text-ellipsis whitespace-normal break-words'
            }>
            {node.label}
          </div>
        </Button>
        {/* additional line to parent when there is nested subtree in between */}
        {isExpandable && isExpanded && lastItemTop ? (
          <div
            style={{height: `${lastItemTop}px`}}
            className={`absolute left-[calc(1rem+1px)] top-0 z-0 w-[1px] bg-[#BDBDBF]`}
          />
        ) : null}
        {node.children
          ? node.children.map((child, index) => {
              return (
                <TreeNode
                  key={index}
                  node={child}
                  level={level + 1}
                  isLast={index === node.children!.length - 1}
                  className={isExpanded ? 'ml-[2rem]' : 'ml-[2rem] hidden'}
                  updateKey={updateKey}
                  triggerUpdate={triggerUpdate}
                  setTop={setLastItemTop}
                />
              );
            })
          : null}
      </div>
    );
  },
);

TreeNode.displayName = 'TreeNode';

const TreeSection = memo(({tree, className}: TreeSectionProps) => {
  const observerRef = useRef<HTMLDivElement>(null);
  const [updateKey, setUpdateKey] = useState('');

  const triggerUpdate = useCallback(() => {
    setUpdateKey(String(Math.random() * Date.now()));
  }, []);

  const onResizeRef = useRef(
    debounce(
      () => {
        triggerUpdate();
      },
      100,
      {
        leading: true,
        trailing: true,
      },
    ),
  );

  useResizeObserver(observerRef, onResizeRef.current);

  return (
    <div className={twMerge('flex flex-col', className)}>
      <div ref={observerRef} className="h-0 w-full bg-transparent" />
      {tree.map((node, index) => {
        return (
          <TreeNode
            key={index}
            node={node}
            level={0}
            isLast={index === tree.length - 1}
            updateKey={updateKey}
            triggerUpdate={triggerUpdate}
          />
        );
      })}
    </div>
  );
});

TreeSection.displayName = 'TreeSection';

export default TreeSection;
