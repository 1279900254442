import {Trans} from '@lingui/macro';
import {memo} from 'react';
import {motion} from 'framer-motion';

export const UnlockProgress = memo(
  ({stepIdx, stepsLength}: {stepIdx: number; stepsLength: number}) => {
    const calcWidth = (idx: number) => `${((idx + 1) * 100) / stepsLength}%`;

    return (
      <div className="flex w-full flex-col gap-1">
        <Trans>
          Step {stepIdx + 1} of {stepsLength}
        </Trans>
        <div className="h-2 w-full rounded-lg bg-default-100">
          <motion.div
            className="h-2 rounded-lg bg-primary"
            initial={{width: calcWidth(stepIdx - 1)}}
            animate={{width: calcWidth(stepIdx)}}></motion.div>
        </div>
      </div>
    );
  },
);

UnlockProgress.displayName = 'UnlockProgress';
