import {memo} from 'react';
import {useAuthContext} from '@/hooks';
import {gql} from '@/__generated__';
import {useMutation} from '@apollo/client';
import {IntegrationType} from '@/common/types';
import {reportIntegrationStart} from '@/common/analytics';
import AvailableIntegration, {
  AvailableIntegrationProps,
} from './AvailableIntegration';

const CONNECT_GOOGLE_ADS = gql(`
  mutation ConnectGoogleAdsIntegration(
    $input: GoogleAdsIntegrationConnectInput!
  ) {
    href: connectGoogleAdsIntegration(input: $input)
  }
`);

type Props = {
  isActive?: boolean;
  isDisabled?: boolean;
  callbackPath?: string;
} & Omit<
  AvailableIntegrationProps,
  'integrationType' | 'isActive' | 'isDisabled' | 'ref'
>;

const ConnectGoogleAds = memo(
  ({
    isActive = false,
    isDisabled = false,
    callbackPath = 'integrations',
    ...triggerProps
  }: Props) => {
    const {business} = useAuthContext();
    const [connect, {loading}] = useMutation(CONNECT_GOOGLE_ADS, {
      onCompleted: data => {
        window.location.href = data.href;
      },
    });

    return (
      <AvailableIntegration
        integrationType={IntegrationType.googleAds}
        isDisabled={loading || isActive || isDisabled}
        isActive={isActive}
        onClick={() => {
          if (!isActive) {
            reportIntegrationStart(IntegrationType.googleAds).finally(() => {
              connect({
                variables: {
                  input: {
                    callbackPath,
                    businessId: business?.id ?? '',
                  },
                },
              });
            });
          }
        }}
        {...triggerProps}
      />
    );
  },
);

ConnectGoogleAds.displayName = 'ConnectGoogleAds';

export default ConnectGoogleAds;
