import {Asset, ResponsiveImage} from '@/__generated__/graphql';
import {useMatchesTWBreakpoint} from './useMatchesTWBreakpoint';

export const useResponsiveImage = (
  image: ResponsiveImage | undefined | null,
): Asset | undefined => {
  const is2XLarge = useMatchesTWBreakpoint('2xl');
  const isXLarge = useMatchesTWBreakpoint('xl');
  const isLarge = useMatchesTWBreakpoint('lg');
  const isMedium = useMatchesTWBreakpoint('md');
  const isSmall = useMatchesTWBreakpoint('sm');

  if (!image) {
    return;
  }

  if (is2XLarge && image.xxLarge?.url) {
    return image.xxLarge;
  } else if (isXLarge && image.xLarge?.url) {
    return image.xLarge;
  } else if (isLarge && image.large?.url) {
    return image.large;
  } else if (isMedium && image.medium?.url) {
    return image.medium;
  } else if (isSmall && image.small?.url) {
    return image.small;
  }

  return image.default ?? undefined;
};

export default useResponsiveImage;
