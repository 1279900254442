import {FC, useEffect} from 'react';
import {RouterProvider} from 'react-router-dom';
import {I18nProvider} from '@lingui/react';
import {i18n} from '@lingui/core';
import router from '@/router';
import {dynamicActivate} from '@/i18n';
import {useTheme} from '@/hooks';
import {AuthProvider} from '@/components/providers';
import {useApollo} from './hooks/useApollo';
import {ApolloProvider} from '@apollo/client';

const App: FC = () => {
  const {mode} = useTheme();
  const apolloClient = useApollo();

  useEffect(() => {
    dynamicActivate();
  }, []);

  return (
    <div className={`${mode === 'dark' ? 'dark' : ''} text-sm text-foreground`}>
      <I18nProvider i18n={i18n}>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </ApolloProvider>
      </I18nProvider>
    </div>
  );
};

export default App;
