import {memo} from 'react';
import ToolSettingsHeader from './ToolSettingsHeader';
import {useNavigate, useParams} from 'react-router-dom';
import startCase from 'lodash/startCase';
import {Trans} from '@lingui/macro';
import {formByStepId} from '../../config/formByStepId';
import {ToolUnlockStepId} from '@/common/types';

const StepEditPage = memo(() => {
  const navigate = useNavigate();
  const {stepId} = useParams<{stepId: string}>();
  const StepEditForm = formByStepId[stepId as ToolUnlockStepId];

  return (
    <div
      style={{WebkitOverflowScrolling: 'touch'}}
      className="relative flex h-full w-full max-w-2xl grow flex-col items-center overflow-auto">
      <ToolSettingsHeader
        className="sticky top-0 z-20 bg-background/75 backdrop-blur-xl"
        title={<Trans>Edit {startCase(stepId).replace('Url', 'URL')}</Trans>}
      />
      <div className="flex w-full flex-1 flex-col gap-12 p-6">
        <StepEditForm
          className="gap-12"
          showDivider
          classNames={{
            itemsWrapper: 'gap-0 [&>:last-child]:mt-6',
            itemWrapper: 'gap-0',
          }}
          submitTitle={<Trans>Update</Trans>}
          onSubmit={async () => navigate(-1)}
        />
      </div>
    </div>
  );
});

StepEditPage.displayName = 'StepEditPage';

export default StepEditPage;
