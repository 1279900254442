import {useRef, useMemo, useLayoutEffect, useCallback} from 'react';
import WordCloud, {ListEntry} from 'wordcloud';
import {WordCloudSection as WordCloudSectionType} from '../types';
import {interpolate} from 'framer-motion';
import {useMatchesTWBreakpoint} from '@/hooks';

function shapeRectangle(theta: number, aspectRatio: number) {
  const x = Math.abs(Math.cos(theta));
  const y = Math.abs(Math.sin(theta));

  if (aspectRatio > 1) {
    // Wide rectangle
    return Math.min(1 / x, 1 / (y * aspectRatio));
  } else {
    // Tall rectangle
    return Math.min(1 / (x / aspectRatio), 1 / y);
  }
}

interface Props {
  wordcloud: WordCloudSectionType['wordcloud'];
  width: number;
  height: number;
  className?: string;
}

const colors = [
  '#007AFF33',
  '#35B6FF80',
  '#007AFFCC',
  '#5481B2',
  '#2E649F',
  '#356294',
  '#0E3A6A',
];

function getColorForValue(value: number, min: number, max: number): string {
  const clampedValue = Math.max(min, Math.min(max, value));
  const range = Math.max(max - min, 1);
  const segmentSize = range / colors.length;
  const index = Math.floor((clampedValue - min) / segmentSize);

  return colors[Math.min(index, colors.length - 1)];
}

const WordCloudSection = ({wordcloud, width, height, className}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const words: ListEntry[] = useMemo(() => {
    return (
      wordcloud
        .find(i => i.words)
        ?.words.map(w => [w.label, w.value] as ListEntry)
        .filter(w => w[0] && Number.isFinite(w[1])) ?? []
    );
  }, [wordcloud]);
  const isMediumScreen = useMatchesTWBreakpoint('md');
  const minValue = useMemo(() => Math.min(...words.map(w => w[1])), [words]);
  const maxValue = useMemo(() => Math.max(...words.map(w => w[1])), [words]);
  const getSize = useMemo(() => {
    const sizes = (() => {
      const base = [10, 20];
      let increment = 0;

      if (words.length <= 5) {
        increment += 2;
      }
      if (words.length <= 10) {
        increment += 2;
      }
      if (isMediumScreen) {
        increment += 6;
      }

      return base.map(i => i + increment);
    })();
    return interpolate([minValue, maxValue], sizes);
  }, [minValue, maxValue, words.length, isMediumScreen]);
  const getColor = useCallback(
    (__: string, weight: string | number) =>
      typeof weight === 'string'
        ? 'black'
        : getColorForValue(weight, minValue, maxValue),
    [minValue, maxValue],
  );

  useLayoutEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;

      WordCloud(canvas, {
        list: [...words],
        gridSize: Math.round(
          ((isMediumScreen ? 16 : 12) * width) / (64 * words.length),
        ),
        weightFactor: getSize,
        fontFamily: 'Inter',
        color: getColor,
        backgroundColor: 'transparent',
        rotateRatio: 0,
        clearCanvas: true,
        abortThreshold: 1000,
        drawMask: false,
        shrinkToFit: true,
        drawOutOfBound: words.length > 10,
        shape: theta => {
          return shapeRectangle(theta, width / height);
        },
      });
    }

    return () => {
      WordCloud.stop();
    };
  }, [words, width, height, isMediumScreen, getSize, getColor]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className={className}
    />
  );
};

export default WordCloudSection;
