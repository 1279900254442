import {memo, ReactNode, useState} from 'react';
import {Formik, Form, Field} from 'formik';
import {object, string} from 'yup';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';
import {useAuthContext, useToastQueue} from '@/hooks';
import ErrorMessage from '@/components/ErrorMessage';
import {ApolloError, useApolloClient, useMutation} from '@apollo/client';
import NextUIInput from '@/components/inputs/NextUIInput';
import {formatApolloError} from '@/routes/helpers';
import {UPSERT_CUSTOMER_DATA} from '@/common/mutations';
import {useGetBusinessUrl} from '../hooks';
import {ContinueButton} from './components/ContinueButton';
import {URL_INPUT_PROPS} from '@/components/inputs';

const BusinessUrlForm = memo(
  ({
    onSubmit,
    submitTitle,
  }: {
    onSubmit?: () => Promise<void> | void;
    submitTitle?: ReactNode;
  }) => {
    const {user} = useAuthContext();
    const apolloClient = useApolloClient();
    const [error, setError] = useState<string | undefined>();
    const {_} = useLingui();
    const {addErrorToast} = useToastQueue();
    const [upsertCustomerData] = useMutation(UPSERT_CUSTOMER_DATA, {
      onCompleted: onSubmit,
    });
    const {businessUrl} = useGetBusinessUrl();

    return (
      <Formik
        enableReinitialize
        initialValues={{businessUrl: businessUrl ?? ''}}
        validationSchema={object({
          businessUrl: string()
            .url(_(msg`Invalid URL`))
            .required(_(msg`Required`)),
        })}
        onSubmit={async values => {
          setError(undefined);

          try {
            await upsertCustomerData({
              variables: {
                input: {
                  key: 'website_url',
                  value: values.businessUrl,
                  userId: user?.id,
                },
              },
            });

            apolloClient.refetchQueries({
              include: ['GetCustomerDataConnection'],
            });
          } catch (e: any) {
            if (e instanceof ApolloError) {
              setError(formatApolloError(e) || undefined);
            } else {
              const {data} = e.response || {};
              const message = data.detail || e.message;

              setError(message);
              addErrorToast({message});
            }
          }
        }}>
        {({isSubmitting, touched, errors}) => {
          return (
            <Form className="flex w-full flex-col gap-6">
              <Field
                as={NextUIInput}
                name="businessUrl"
                {...URL_INPUT_PROPS}
                classNames={{input: '!pl-0'}}
                label={_(msg`Your Business URL`)}
                startContent="https://"
                placeholder=" "
                isInvalid={Boolean(touched.businessUrl && errors.businessUrl)}
                errorMessage={
                  touched.businessUrl ? errors.businessUrl : undefined
                }
                isDisabled={isSubmitting}
              />
              <ErrorMessage message={error} />
              <ContinueButton
                type="submit"
                isLoading={isSubmitting}
                title={submitTitle}
              />
            </Form>
          );
        }}
      </Formik>
    );
  },
);

BusinessUrlForm.displayName = 'BusinessUrlForm';

export default BusinessUrlForm;
