import {GetAgentConnectionQuery} from '@/__generated__/graphql';
import {Card} from '@nextui-org/react';
import {memo} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';

type Props = GetAgentConnectionQuery['connection']['edges'][0]['node'] & {
  className?: string;
};

const ActiveItem = memo(({templateName, logo, name, className}: Props) => {
  const location = useLocation();

  return (
    <Card
      as={RouterLink}
      disableAnimation
      disableRipple
      to={`/catalog/${templateName}/unlocked`}
      state={{from: location}}
      className={twMerge(
        'flex flex-col items-center gap-3 rounded-none border-none bg-transparent px-3 shadow-none',
        className,
      )}>
      {logo?.url ? (
        <img
          alt={name}
          className="aspect-square w-[4rem] self-center rounded-2xl"
          src={logo?.url}
        />
      ) : null}
      <div className="line-clamp-1 max-w-[5rem] overflow-hidden text-ellipsis break-all text-center text-xs text-foreground">
        {name}
      </div>
    </Card>
  );
});

ActiveItem.displayName = 'ActiveItem';

export default ActiveItem;
