import {gql} from '@/__generated__';
import {reportSubscribe} from '@/common/analytics';
import {useMutation} from '@apollo/client';
import PortalSpinner from '@/components/PortalSpinner';
import {FC, useEffect, useState} from 'react';
import {Navigate, useSearchParams} from 'react-router-dom';

const SET_USER_STRIPE_CUSTOMER_ID = gql(`
  mutation SetUserStripeCustomerId($checkoutSessionId: String!) {
    user: setUserStripeCustomerId(checkoutSessionId: $checkoutSessionId) {
      id
      createdAt
      updatedAt
      firstName
      lastName
      email
      phone
      newsletter
      description
      avatar
      verified
      stripeCustomerId
      stripeSubscription {
        id
        status
      }
    }
  }
`);

const StripeSuccess: FC = () => {
  const [searchParams] = useSearchParams();
  const checkoutSessionId = searchParams.get('checkoutSessionId') ?? '';
  const [isCompleted, setIsCompleted] = useState(false);
  const [setUserStripeCustomerId] = useMutation(SET_USER_STRIPE_CUSTOMER_ID, {
    variables: {checkoutSessionId},
    onCompleted: ({user}) => {
      if (user?.stripeSubscription?.id) {
        reportSubscribe(user.stripeSubscription.id);
      }

      setIsCompleted(true);
    },
    onError: () => {
      setIsCompleted(true);
    },
  });

  useEffect(() => {
    if (checkoutSessionId) {
      setUserStripeCustomerId();
    }
  }, [checkoutSessionId]);

  if (!checkoutSessionId || isCompleted) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="relative flex h-[100dvh] max-h-[100dvh] min-h-[100dvh] w-[100dvw] min-w-[100dvw] max-w-[100dvw] flex-col bg-default-100">
      <PortalSpinner
        classNames={{
          base: 'backdrop-blur-sm fixed inset-0 z-40',
        }}
        size="lg"
      />
    </div>
  );
};

export default StripeSuccess;
