import {getDayjsDate} from '@/utils';
import {msg} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import dayjs from 'dayjs';
import getIsInApp from 'inapp-spy';
import {useCallback, useEffect, useMemo, useRef} from 'react';

export const useIsInAppBrowser = (
  options:
    | {
        ua?: string;
      }
    | undefined = {},
) => {
  return getIsInApp(options);
};

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = () => {
      savedCallback.current?.();
    };

    if (delay !== null) {
      const intervalId = setInterval(handler, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
};

export const useGetRelativeTime = () => {
  const {_, i18n} = useLingui();
  const today = useMemo(() => dayjs(), []);
  const yesterday = useMemo(() => today.subtract(1, 'day'), [today]);

  const getRelativeTime = useCallback(
    (
      date: string | Date | null,
      {preposition = false}: {preposition?: boolean} = {},
    ) => {
      const dayjsDate = getDayjsDate(date);

      if (!dayjsDate) {
        return '';
      }

      if (dayjsDate.isSame(today, 'day')) {
        const timeStr = i18n.date(dayjsDate.toDate(), {
          hour: 'numeric',
          minute: '2-digit',
        });

        if (preposition) {
          return `${_(msg`at`)} ${timeStr}`;
        }

        return timeStr;
      }

      if (dayjsDate.isSame(yesterday, 'day')) {
        return _(msg`Yesterday`);
      }

      const dateStr = i18n.date(dayjsDate.toDate(), {
        month: 'short',
        day: 'numeric',
      });

      if (preposition) {
        return `${_(msg`on`)} ${dateStr}`;
      }

      return dateStr;
    },
    [today, yesterday, i18n, _],
  );

  return getRelativeTime;
};

export function useDidUpdateEffect(
  effect: () => void | (() => void),
  deps: unknown[],
) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      const cleanup = effect();

      if (cleanup) {
        return () => cleanup();
      }
    }

    didMountRef.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
