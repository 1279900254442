import {
  HTMLAttributes,
  lazy,
  memo,
  PropsWithChildren,
  Suspense,
  SVGProps,
} from 'react';
import PortalSpinner from './PortalSpinner';
import {twMerge} from 'tailwind-merge';
import {ImpactLevel} from '@/routes/CatalogueApp/unlocked/components/sections/types';

const Lightbulb03SolidGradientIcon = lazy(
  () => import('@/components/icons/Lightbulb03SolidGradientIcon'),
);
const iconByName = {
  Lightbulb03: Lightbulb03SolidGradientIcon,
};

const renderIcon = (
  name: keyof typeof iconByName,
  props?: Omit<SVGProps<SVGSVGElement>, 'ref'>,
) => {
  const Icon = iconByName[name];

  return (
    <Suspense fallback={<PortalSpinner />}>
      <Icon {...props} />
    </Suspense>
  );
};

const bgByImpactLevel: Record<ImpactLevel, [string, string, string]> = {
  [ImpactLevel.None]: ['#999999', '#333333', '#E5E5E5'],
  [ImpactLevel.Medium]: ['#99FFA9', '#33FF53', '#E5FFEA'],
  [ImpactLevel.High]: ['#99F3FF', '#33E6FF', '#E5FCFF'],
  [ImpactLevel.Ultra]: ['#FF99FA', '#FF34F6', '#FFE5F2'],
};
interface Props {
  icon?: keyof typeof iconByName;
  impact: ImpactLevel;
  style?: HTMLAttributes<HTMLDivElement>['style'];
  className?: string;
  classNames?: {
    icon?: string;
  };
}

export const GradientIcon = memo(
  ({
    icon = 'Lightbulb03',
    impact,
    style,
    className,
    classNames,
    children,
  }: PropsWithChildren<Props>) => {
    const validImpact =
      Math.max(0, Math.min(impact, Object.keys(bgByImpactLevel).length - 1)) ??
      0;
    const gradientColors = bgByImpactLevel[validImpact as ImpactLevel];

    return (
      <div
        style={{
          ...style,
          background: `linear-gradient(180deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%), linear-gradient(180deg, ${gradientColors[2]} 0%, ${gradientColors[2]} 100%), linear-gradient(90deg, #943FFF 0%, #6C3EF0 50%, #474EFF 100%), #F0F0F2`,
        }}
        className={twMerge(
          'my-auto flex h-[3.25rem] w-[3.25rem] shrink-0 items-center justify-center rounded-full',
          className,
        )}>
        {renderIcon(icon, {
          className: twMerge(
            'h-8 w-8 text-[#808080] [&>path]:text-background',
            classNames?.icon,
          ),
        })}
        {children}
      </div>
    );
  },
);

GradientIcon.displayName = 'GradientIcon';

export default GradientIcon;
