import {memo, useState} from 'react';
import {Formik, Form, Field} from 'formik';
import {object} from 'yup';
import {Button} from '@nextui-org/react';
import {useLingui} from '@lingui/react';
import {Trans, msg} from '@lingui/macro';
import {usePasswordRequirements, useToastQueue} from '@/hooks';
import ErrorMessage from '@/components/ErrorMessage';
import {ApolloError} from '@apollo/client';
import {formatApolloError} from '../helpers';
import NextUIInput from '@/components/inputs/NextUIInput';
import PortalSpinner from '@/components/PortalSpinner';
import BackButton from './BackButton';
import ShowPasswordButton from '@/components/ShowPasswordButton';

type Values = {
  newPassword: string;
};

const ResetPasswordNewPasswordForm = memo(
  ({
    isLoading = false,
    onSubmit,
    onBack,
  }: {
    isLoading?: boolean;
    onSubmit: (values: Values) => Promise<void>;
    onBack: (values: Values) => Promise<void>;
  }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const {_} = useLingui();
    const {addErrorToast} = useToastQueue();
    const {getPasswordRequirementsValidator, renderPasswordRequirements} =
      usePasswordRequirements();

    return (
      <Formik
        initialValues={{
          newPassword: '',
        }}
        validationSchema={object({
          newPassword: getPasswordRequirementsValidator(),
        })}
        onSubmit={async values => {
          setError(undefined);

          try {
            await onSubmit(values);
          } catch (e: any) {
            if (e instanceof ApolloError) {
              setError(formatApolloError(e) || undefined);
            } else {
              const {data} = e.response || {};
              const message = data.detail || e.message;

              setError(message);
              addErrorToast({message});
            }
          }
        }}>
        {({isSubmitting, touched, errors, values}) => (
          <Form className="flex min-h-[5.25rem] w-full flex-col gap-12">
            <div className="flex w-full flex-col gap-6">
              <Field
                as={NextUIInput}
                name="newPassword"
                type={showPassword ? 'text' : 'password'}
                label={_(msg`New Password`)}
                placeholder={_(msg`Enter new password`)}
                endContent={
                  <ShowPasswordButton
                    showPassword={showPassword}
                    onPress={setShowPassword}
                  />
                }
                classNames={{
                  inputWrapper: 'pr-0.5',
                }}
                isInvalid={Boolean(touched.newPassword && errors.newPassword)}
                errorMessage={
                  touched.newPassword ? errors.newPassword : undefined
                }
                isDisabled={isSubmitting || isLoading}
              />
              {renderPasswordRequirements({password: values.newPassword})}
              <ErrorMessage message={error} />
            </div>

            <div className="flex w-full flex-col gap-2">
              <Button
                aria-label="Save new password"
                fullWidth
                type="submit"
                variant="solid"
                color="primary"
                radius="sm"
                className="font-medium text-background"
                spinner={<PortalSpinner size="sm" />}
                isLoading={isSubmitting || isLoading}
                isDisabled={isSubmitting || isLoading}>
                <Trans>Continue</Trans>
              </Button>
              <BackButton onPress={() => onBack(values)}>
                <Trans>Back</Trans>
              </BackButton>
            </div>
          </Form>
        )}
      </Formik>
    );
  },
);

ResetPasswordNewPasswordForm.displayName = 'ResetPasswordNewPasswordForm';

export default ResetPasswordNewPasswordForm;
