import {Toast as ToastType} from '@/common/types';
import {Trans} from '@lingui/macro';
import {Button, Card, CardBody, Snippet} from '@nextui-org/react';
import {FC, ReactNode} from 'react';
import {CloseIcon} from '@nextui-org/shared-icons';
import CheckCircleIcon from './icons/CheckCircleIcon';
import XCircleIcon from './icons/XCircleIcon';
import AlertCircleIcon from './icons/AlertCircleIcon';

const Toast: FC<{
  toast: ToastType;
  onClose: () => void;
}> = ({toast: {message, type, title}, onClose}) => {
  let toastIcon: ReactNode = null;

  switch (type) {
    case 'success':
      toastIcon = <CheckCircleIcon className="h-9 w-9 text-success" />;
      if (!title) title = <Trans>Success</Trans>;
      break;
    case 'error':
      toastIcon = <XCircleIcon className="h-9 w-9 text-danger" />;
      if (!title) title = <Trans>Oops!</Trans>;
      break;
    case 'warning':
      toastIcon = <AlertCircleIcon className="h-9 w-9 text-warning" />;
      if (!title) title = <Trans>Warning!</Trans>;
      break;
    default:
      break;
  }

  return (
    <Card role="alert" fullWidth className="!border-[thin] border-divider">
      <CardBody className="flex flex-row items-center gap-3">
        <div className="shrink-0">{toastIcon}</div>
        <div className="flex flex-1 flex-col gap-3">
          <div className="flex items-center justify-between gap-3">
            <h3 className="font-semibold capitalize">{title}</h3>
            <Button
              aria-label="Close toast"
              className="shrink-0"
              isIconOnly
              color="default"
              onClick={onClose}
              variant="light"
              size="sm"
              radius="full">
              <CloseIcon className="h-4 w-4" />
            </Button>
          </div>
          {!message ? null : type === 'error' ? (
            <Snippet
              classNames={{
                pre: 'flex-1 overflow-auto w-0 max-h-60 whitespace-break-spaces',
              }}
              hideSymbol>
              {message}
            </Snippet>
          ) : (
            <p>{message}</p>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default Toast;
