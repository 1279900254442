import {Button, ButtonProps} from '@nextui-org/react';
import {memo} from 'react';
import {DecisionStatusEnum} from '@/__generated__/graphql';
import {Decision} from './types';
import {twMerge} from 'tailwind-merge';
import {useMutation} from '@apollo/client';
import {APPROVE_DECISION} from '@/common/mutations';
import PortalSpinner from '@/components/PortalSpinner';
import {AnimatePresence, motion} from 'framer-motion';

const motionProps = {
  initial: {opacity: 0},
  animate: {opacity: 1},
  exit: {opacity: 0},
};

type Props = Omit<ButtonProps, 'children'> & {
  decision: Decision;
};

export const DecisionButton = memo(({className, decision, ...props}: Props) => {
  const [approveDecision, approveDecisionResult] = useMutation(
    APPROVE_DECISION,
    {variables: {id: decision.id}},
  );
  const text =
    decision.status === DecisionStatusEnum.Approved
      ? decision.approvedButtonText
      : decision.approveButtonText;

  return (
    <AnimatePresence initial={false} mode="wait">
      {decision.status === DecisionStatusEnum.Active ? (
        <Button
          aria-label="Approve decision"
          as={motion.button}
          {...motionProps}
          key={DecisionStatusEnum.Active}
          radius="full"
          color={props.isDisabled ? 'default' : 'primary'}
          variant="solid"
          className={twMerge(
            'h-8 min-w-0 px-4 font-semibold transition-[color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform] duration-200',
            props.isDisabled
              ? 'bg-[#F2F2F5] text-foreground/25'
              : 'text-background',
            className,
          )}
          isLoading={approveDecisionResult.loading}
          spinner={<PortalSpinner size="sm" />}
          onPress={() => approveDecision()}
          {...props}>
          {text}
        </Button>
      ) : (
        <Button
          aria-label="Decision approved"
          as={motion.button}
          {...motionProps}
          key={DecisionStatusEnum.Approved}
          radius="full"
          color="default"
          variant="solid"
          className={twMerge(
            'pointer-events-none h-8 min-w-0 bg-[#F2F2F5] px-4 font-semibold text-foreground/50',
            className,
          )}
          {...props}>
          {text}
        </Button>
      )}
    </AnimatePresence>
  );
});

DecisionButton.displayName = 'DecisionButton';
