import {memo, useCallback} from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import EditProfileForm from '../forms/EditProfileForm';
import ChangeEmailForm from '../forms/ChangeEmailForm';
import DeleteAccountForm from '../forms/DeleteAccountForm';

const ProfileSettingsOption = memo(() => {
  const {option} = useParams();
  const navigate = useNavigate();

  const onClose = useCallback(() => {
    navigate('..');
  }, [navigate]);

  if (!option) {
    return null;
  }

  switch (option) {
    case 'edit-profile':
      return <EditProfileForm onClose={onClose} />;
    case 'change-email':
      return <ChangeEmailForm onClose={onClose} />;
    case 'delete-account':
      return <DeleteAccountForm onClose={onClose} />;
    default:
      return <Navigate to=".." replace />;
  }
});

ProfileSettingsOption.displayName = 'ProfileSettingsOption';

export default ProfileSettingsOption;
