import {ComponentProps, memo} from 'react';
import PortalLogo from '@/components/icons/PortalLogo';
import {twMerge} from 'tailwind-merge';
import {ResponsiveImage} from '@/__generated__/graphql';
import {useResponsiveImage} from '@/hooks';
import BackButton from '@/components/buttons/BackButton';
import {useLocation} from 'react-router-dom';

interface Props {
  banner: ResponsiveImage;
  bannerText: string;
  className?: ComponentProps<'div'>['className'];
}

const AppBanner = memo(({banner, bannerText, className}: Props) => {
  const bannerData = useResponsiveImage(banner);
  const location = useLocation();
  const backTo = location.state?.from || '../..';

  return (
    <div className={twMerge(`flex w-full flex-col`, className)}>
      <div className="relative w-full">
        <div className="absolute left-0 right-0 top-0 flex h-[3.25rem] items-center">
          <BackButton to={backTo} />
        </div>
      </div>
      <div
        style={{
          backgroundImage: bannerData?.url
            ? `url(${bannerData.url})`
            : undefined,
        }}
        className={`flex flex-col items-center gap-5 bg-cover bg-center bg-no-repeat pb-10 pt-4 text-foreground lg:pt-10`}>
        <PortalLogo className="h-[1.375rem] w-[6.1719rem]" />
        <div className="whitespace-pre text-center font-haffer text-[1.625rem]/[120%] font-medium">
          {bannerText}
        </div>
      </div>
    </div>
  );
});

AppBanner.displayName = 'AppBanner';

export default AppBanner;
