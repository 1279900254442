import {memo} from 'react';
import {Modal, ModalBody, ModalContent, ModalHeader} from '@/components/Modal';
import PortalSpinner from '@/components/PortalSpinner';
import {Plural, Trans} from '@lingui/macro';
import AccountSelectorItem from './AccountSelectorItem';
import {Button, ModalFooter} from '@nextui-org/react';

interface Props {
  items: AccountSelectorEntry[];
  isOpen: boolean;
  isLoading: boolean;
  onOpenChange: (isOpen: boolean) => void;
  toggleItem: (id: string) => void;
  submit: () => void;
}

export interface AccountSelectorEntry {
  id: string;
  title: string;
  subtitle: string;
  errorStatus?: string;
  errorDetail?: string;
  isSelected: boolean;
  isDisabled: boolean;
}

const AccountSelectorView = memo(
  ({items, isOpen, isLoading, onOpenChange, toggleItem, submit}: Props) => {
    const allItemsAreDisabled =
      items.length > 0 && items.every(item => item.isDisabled);

    return (
      <Modal
        isOpen={isOpen}
        presentation="fullScreen"
        onOpenChange={onOpenChange}
        classNames={{wrapper: 'z-[1200]'}}>
        <ModalContent className="md:w-[min(36rem,calc(100dvw-4rem))]">
          <ModalHeader className="p-4">
            <Trans>Select a Google Ads account</Trans>
          </ModalHeader>
          <ModalBody className="gap-6 overflow-auto p-4 pb-6 text-sm">
            <div className="text-sm font-medium text-foreground/50">
              {allItemsAreDisabled ? (
                <Plural
                  value={items.length}
                  one="Selected account cannot be connected to Portal AI. Please review the attached error message."
                  other="Selected accounts cannot be connected to Portal AI. Please review the attached error messages."
                />
              ) : (
                <Trans>
                  Select one or a few Google Ads accounts you want to connect to
                  Portal AI to improve the campaigns.
                </Trans>
              )}
            </div>
            <div className="flex w-full flex-col gap-4">
              {items.map(item => (
                <AccountSelectorItem
                  key={item.id}
                  {...item}
                  onPress={() => toggleItem(item.id)}
                />
              ))}
            </div>
            <div className="text-sm font-medium text-foreground/50">
              <Trans>
                If you need to connect another Google Ads account that is not
                present in the list above, please connect another Google account
                first.
              </Trans>
            </div>
          </ModalBody>
          <ModalFooter
            style={{boxShadow: '0px -4px 12px 0px rgba(0, 0, 0, 0.10)'}}
            className="justify-end gap-2 border-t border-foreground/10">
            <Button
              aria-label="Connect"
              disableRipple
              disableAnimation
              spinner={<PortalSpinner size="sm" />}
              className={`h-auto w-full rounded-lg bg-foreground px-4 py-2 text-background md:w-auto`}
              isLoading={isLoading}
              isDisabled={isLoading || allItemsAreDisabled}
              onPress={submit}>
              <Trans>Connect</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);

AccountSelectorView.displayName = 'AccountSelectorView';

export default AccountSelectorView;
