import {ComponentProps, memo, useMemo} from 'react';
import dayjs from 'dayjs';
import {AnimatePresence, motion} from 'framer-motion';
import rehypeRaw from 'rehype-raw';
import {getDayjsDate} from '@/utils';
import {ChatMessage} from '@/common/types/chat';
import Markdown from '@/components/Markdown';
import MarkdownComponents from './MarkdownComponents';
import {getMessageElementId} from '../helpers';

const rehypePlugins = [rehypeRaw];

interface MessageProps extends ChatMessage {
  content: string;
}

interface WrapperProps extends ChatMessage {
  now: dayjs.Dayjs;
  className?: ComponentProps<'div'>['className'];
}

const Message = memo(({id, content}: MessageProps) => {
  const text = content?.trim() ?? '';

  return (
    <div
      id={getMessageElementId(id)}
      className="flex min-w-11 max-w-full flex-col items-start justify-center break-words rounded-2xl bg-[#F5F5F7] p-3 text-sm text-foreground">
      {text ? (
        <Markdown
          className={'prose prose-sm max-w-full text-foreground'}
          rehypePlugins={rehypePlugins}
          components={MarkdownComponents}>
          {text}
        </Markdown>
      ) : (
        <div className="animated-ellipsis-simple" />
      )}
    </div>
  );
});

Message.displayName = 'Message';

const MessageWrapper = memo(
  ({now, created_at, className = '', ...rest}: WrapperProps) => {
    const isNewMessage = useMemo(
      () => getDayjsDate(created_at)?.isAfter(now) ?? false,
      [created_at, now],
    );
    const classByAuthor = 'justify-start ml-8';

    if (isNewMessage) {
      return (
        <AnimatePresence>
          <motion.div
            className={`flex origin-bottom-right ${classByAuthor} ${className}`}
            initial={{opacity: 0.5, scale: 0.95, translateY: 20}}
            animate={{
              opacity: 1,
              scale: 1,
              translateY: 0,
              transition: {duration: 0.425, ease: 'circOut'},
            }}>
            <Message {...rest} {...{created_at}} />
          </motion.div>
        </AnimatePresence>
      );
    }

    return (
      <motion.div
        className={`flex ${classByAuthor} ${className}`}
        initial={{opacity: 0}}
        animate={{opacity: 1}}>
        <Message {...rest} {...{created_at}} />
      </motion.div>
    );
  },
);

MessageWrapper.displayName = 'MessageWrapper';

export default MessageWrapper;
