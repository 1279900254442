import {gql} from '@/__generated__';
import CloseButton from '@/components/Modal';
import ErrorMessage from '@/components/ErrorMessage';
import {useAuthContext} from '@/hooks';
import {useMutation} from '@apollo/client';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button} from '@nextui-org/react';
import {ModalHeader, ModalBody, ModalFooter} from '@/components/Modal';
import {Field, Form, Formik} from 'formik';
import {FC, useState} from 'react';
import * as Yup from 'yup';
import NextUIInput from '@/components/inputs/NextUIInput';
import PortalSpinner from '@/components/PortalSpinner';
import ShowPasswordButton from '@/components/ShowPasswordButton';

const CHANGE_USER_EMAIL = gql(`
  mutation ChangeUserEmail($id: ID!, $input: ChangeUserEmailInput!) {
    user: changeUserEmail(id: $id, input: $input) {
      ...UpdateUserFragment
    }
  }
`);

const ChangeEmailForm: FC<{onClose: () => void}> = ({onClose}) => {
  const {_} = useLingui();
  const {user} = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);
  const [changeUserEmail, changeUserEmailResult] = useMutation(
    CHANGE_USER_EMAIL,
    {
      onCompleted: () => {
        onClose();
      },
    },
  );

  return (
    <Formik
      initialValues={{
        password: '',
        email: '',
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(8, _(msg`Password is too short`))
          .required(_(msg`Required`)),
        email: Yup.string()
          .email(_(msg`Invalid email`))
          .required(_(msg`Required`)),
      })}
      onSubmit={async values => {
        await changeUserEmail({
          variables: {
            id: user?.id,
            input: {
              password: values.password,
              email: values.email,
            },
          },
        }).catch(() => null);
      }}>
      {({isSubmitting, touched, errors, dirty}) => {
        const isPasswordInvalid = Boolean(touched.password && errors.password);
        const isEmailInvalid = Boolean(touched.email && errors.email);

        return (
          <Form className="flex flex-1 flex-col">
            <ModalHeader>
              <div className="flex flex-col gap-1">
                <h1 className="text-lg font-semibold">
                  <Trans>Change your email</Trans>
                </h1>
                <p className="text-sm font-normal text-foreground/50">
                  <Trans>Change the email you use to log in to Portal AI</Trans>
                </p>
              </div>
              <CloseButton
                className="absolute right-5 top-5 md:right-10"
                onPress={onClose}
              />
            </ModalHeader>
            <ModalBody className="gap-5">
              <Field
                as={NextUIInput}
                name="password"
                type={showPassword ? 'text' : 'password'}
                variant="bordered"
                label={_(msg`Password`)}
                endContent={
                  <ShowPasswordButton
                    showPassword={showPassword}
                    onPress={setShowPassword}
                  />
                }
                classNames={{
                  label: 'text-sm font-medium',
                  inputWrapper: 'pr-0.5',
                }}
                radius="sm"
                labelPlacement="outside"
                placeholder={_(msg`Enter password`)}
                isInvalid={isPasswordInvalid}
                errorMessage={isPasswordInvalid ? errors.password : undefined}
                isRequired
                isDisabled={isSubmitting}
              />
              <Field
                as={NextUIInput}
                name="email"
                type="email"
                variant="bordered"
                label={_(msg`New email address`)}
                classNames={{label: 'text-sm font-medium'}}
                radius="sm"
                labelPlacement="outside"
                placeholder={_(msg`Enter new email address`)}
                isInvalid={isEmailInvalid}
                errorMessage={isEmailInvalid ? errors.email : undefined}
                isRequired
                isDisabled={isSubmitting}
              />
              <ErrorMessage message={changeUserEmailResult.error?.message} />
            </ModalBody>
            <ModalFooter>
              <Button
                aria-label="Change email"
                fullWidth
                type="submit"
                variant="solid"
                radius="sm"
                color="primary"
                className="text-medium font-semibold text-background"
                isLoading={isSubmitting}
                spinner={<PortalSpinner size="sm" />}
                isDisabled={!dirty || isSubmitting}>
                <Trans>Change email</Trans>
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangeEmailForm;
