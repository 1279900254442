import {SVGProps} from 'react';

export default function RefreshCcw01Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}>
      <path
        d="M1.33203 6.66667C1.33203 6.66667 2.66869 4.84548 3.75459 3.75883C4.84049 2.67218 6.3411 2 7.9987 2C11.3124 2 13.9987 4.68629 13.9987 8C13.9987 11.3137 11.3124 14 7.9987 14C5.2633 14 2.95544 12.1695 2.23321 9.66667M1.33203 6.66667V2.66667M1.33203 6.66667H5.33203"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
