import dayjs from 'dayjs';
import {ChatSessionWithMessages} from '@/common/types/chat';
import {getDayjsDate} from '@/utils';
import {msg} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button} from '@nextui-org/react';
import {memo, useMemo} from 'react';
import {twMerge} from 'tailwind-merge';
import AnimatedText from '@/components/AnimatedText';

interface Props {
  session: ChatSessionWithMessages;
  isActive: boolean;
  now: dayjs.Dayjs;
  unreadCount?: number;
  className?: string;
  onPress: (session: ChatSessionWithMessages) => void;
}

const ChatSessionItem = memo(
  ({session, className, now, unreadCount, isActive, onPress}: Props) => {
    const {_} = useLingui();
    const lastMessageAt = useMemo(() => {
      const message = session.messages?.[0];

      if (!message) {
        return;
      }

      let date = getDayjsDate(message.created_at);

      if (!date) {
        return;
      }

      if (now.isSame(date, 'day')) {
        return date.format('LT');
      }

      date = date.startOf('day');

      const diffDays = now.diff(date, 'day');

      if (diffDays === 1) {
        return _(msg`Yesterday`);
      }

      return _(msg`${diffDays} days ago`);
    }, [session.messages]);

    return (
      <div
        className={twMerge(
          `flex w-full transition-all ${isActive ? 'bg-foreground-200' : 'bg-transparent'}`,
          className,
        )}>
        <Button
          aria-label="Chat session"
          fullWidth
          disableRipple
          disableAnimation
          className={`flex h-auto min-h-0 items-start justify-start whitespace-normal rounded-none bg-transparent px-6 py-4 text-start`}
          startContent={
            <img
              src={session.agent?.logo?.url}
              alt={session.agent?.name}
              className="h-[4rem] w-[4rem] shrink-0 rounded-2xl border-[0.5px] border-foreground/10"
            />
          }
          onPress={() => onPress(session)}>
          <div className="flex grow flex-col gap-1">
            <div className="flex justify-between gap-2">
              <div className="line-clamp-2 text-sm font-medium text-foreground">
                {session.agent?.name}
              </div>
              <div className="shrink-0 text-sm text-default-400">
                {lastMessageAt}
              </div>
            </div>
            <div className="flex justify-between gap-2 overflow-hidden text-ellipsis">
              <AnimatedText
                text={session.summary || _(msg`Pending session`)}
                className="line-clamp-2 text-sm text-default-500"
              />
              {unreadCount ? (
                <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-primary text-medium font-medium text-background">
                  {unreadCount}
                </div>
              ) : null}
            </div>
          </div>
        </Button>
      </div>
    );
  },
);

ChatSessionItem.displayName = 'ChatSessionItem';

export default ChatSessionItem;
