import {GET_AGENT_CONNECTION} from '@/common/queries';
import ModalOutlet from '@/components/ModalOutlet';
import orderBy from 'lodash/orderBy';
import {useAppContext, useAuthContext} from '@/hooks';
import {isToolUnlocked} from '@/utils';
import {useQuery} from '@apollo/client';
import {memo, useCallback, useEffect, useMemo} from 'react';
import CatalogueItem from './components/CatalogueItem';
import {msg} from '@lingui/macro';
import HeaderWithProfile from '../components/HeaderWithProfile';
import {useLingui} from '@lingui/react';

const Catalogue = memo(() => {
  const {isAuthenticated} = useAppContext();
  const {business} = useAuthContext();
  const {_} = useLingui();
  const agentsResult = useQuery(GET_AGENT_CONNECTION, {
    variables: {
      filter: {
        is_new: true,
        is_listed: true,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const pageInfo = agentsResult.data?.connection?.pageInfo;
  const getIsUnlocked = useCallback(
    ({templateName}: {templateName: string}) => {
      if (!isAuthenticated) {
        return false;
      }

      return isToolUnlocked(business, templateName) ?? false;
    },
    [isAuthenticated, business],
  );
  const items = useMemo(() => {
    const unsorted =
      agentsResult.data?.connection?.edges?.map(e => ({
        ...e.node,
        isUnlocked: getIsUnlocked(e.node),
      })) ?? [];

    return orderBy(
      unsorted,
      ['isUnlocked', 'featured', 'key'],
      ['asc', 'desc', 'asc'],
    );
  }, [agentsResult.data, getIsUnlocked]);

  // const unlockeditems = useMemo(
  //   () => items.filter(({node}) => getIsUnlocked(node)),
  //   [items, getIsUnlocked],
  // );

  useEffect(() => {
    if (pageInfo?.hasNextPage) {
      agentsResult.fetchMore({variables: {after: pageInfo.endCursor}});
    }
  }, [pageInfo?.hasNextPage, pageInfo?.endCursor]);

  return (
    <>
      <ModalOutlet basePath="/catalog" subPath="auth" />
      <div className="flex w-full max-w-2xl flex-col">
        <HeaderWithProfile title={_(msg`AI Tools`)} />
        <div className="flex w-full flex-col pb-6">
          <div className="flex flex-col gap-11 py-6">
            {items.map(item => (
              <CatalogueItem key={item.id} {...item} />
            ))}
          </div>
          {/* {unlockeditems.length ? (
            <div className="flex flex-col gap-3 border-t border-default-200">
              <div className="py-4 text-xl font-bold text-foreground">
                <Trans>Active AI Tools</Trans>
              </div>
              <div className="grid grid-cols-3 gap-2 rounded-3xl bg-[#F7F7FA] py-6">
                {unlockeditems.map(({node}) => (
                  <ActiveItem key={node.id} {...node} />
                ))}
              </div>
            </div>
          ) : null} */}
        </div>
      </div>
    </>
  );
});

Catalogue.displayName = 'Catalogue';

export default Catalogue;
