import {createContext, MutableRefObject} from 'react';
import {URLSearchParamsInit} from 'react-router-dom';

export interface SearchParamsContextValue {
  searchParams: URLSearchParams;
  searchParamsRef: MutableRefObject<URLSearchParams>;
  setSearchParams: (params: URLSearchParamsInit) => void;
}

export const SearchParamsContext = createContext<
  SearchParamsContextValue | undefined
>(undefined);
