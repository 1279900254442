import {FC, memo} from 'react';
import {useAppContext, useAuthContext} from '@/hooks';
import {Navigate, useLocation, useParams} from 'react-router-dom';
import AnimatedOutlet from '@/components/AnimatedOutlet';
import {AnimatePresence, motion} from 'framer-motion';
import {Image} from '@nextui-org/react';
import {useQuery} from '@apollo/client';
import {GET_AGENT} from '@/common/queries';
import PortalSpinner from '@/components/PortalSpinner';
import {Trans} from '@lingui/macro';
import {ModalBody, ModalHeader} from '@/components/Modal';
import {LegalFooter} from '../components/LegalFooter';

const Login: FC = memo(() => {
  const location = useLocation();
  const {user} = useAuthContext();
  const {isAuthenticated} = useAppContext();
  const showLegal = location.pathname.endsWith('/login');
  const {appName} = useParams();
  const {data, loading} = useQuery(GET_AGENT, {
    variables: {
      template: appName!,
    },
    skip: !appName,
    onError: console.error,
  });
  const agent = data?.agent;

  if (isAuthenticated && user && user.verified) {
    return <Navigate to="../.." replace />;
  }

  if (!data && loading) {
    return (
      <PortalSpinner
        classNames={{
          base: 'backdrop-blur-sm fixed inset-0 z-[1000]',
        }}
        size="lg"
      />
    );
  }

  return (
    <>
      <ModalHeader className="!px-0 pb-0">
        <div
          style={{
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.05) 100%)',
          }}
          className="flex w-full gap-3 px-5 pb-5 md:px-10">
          {agent ? (
            <>
              <Image
                removeWrapper
                radius="sm"
                src={agent.logo?.url ?? undefined}
                alt={agent.name}
                width={56}
                height={56}
              />
              <div className="flex flex-col justify-between">
                <h3 className="text-sm font-semibold uppercase text-foreground/60">
                  {agent.name}
                </h3>
                <h1 className="text-lg font-semibold text-primary-900">
                  <Trans>Sign Up to Apply</Trans>
                </h1>
              </div>
            </>
          ) : (
            <h1 className="text-lg font-semibold text-primary-900">
              {location.pathname.endsWith('/login/profile') ? (
                <Trans>Create an account</Trans>
              ) : (
                <Trans>Sign Up</Trans>
              )}
            </h1>
          )}
        </div>
      </ModalHeader>
      <ModalBody className="max-w-lg gap-6">
        <AnimatePresence mode="wait">
          <motion.div
            className="flex shrink flex-col items-center justify-center self-stretch"
            key={location.pathname}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.2}}>
            <AnimatedOutlet />
          </motion.div>
        </AnimatePresence>
        {showLegal && <LegalFooter />}
      </ModalBody>
    </>
  );
});

Login.displayName = 'LoginV2';

export default Login;
