import {memo, useEffect, useRef, useState} from 'react';
import {motion, HTMLMotionProps} from 'framer-motion';

const fintLongestMatchIndex = (prevText: string, text: string) => {
  const prevTextLength = prevText.length;
  const textLength = text.length;
  let i = 0;

  while (i < prevTextLength && i < textLength && prevText[i] === text[i]) {
    i++;
  }

  return i;
};

interface Props extends HTMLMotionProps<'span'> {
  text: string;
  duration?: number;
  initialAnimation?: boolean;
}

const AnimatedTextCompletion = memo(
  ({text, duration = 0.25, initialAnimation, ...textProps}: Props) => {
    const prevTextRef = useRef(initialAnimation ? '' : text);
    const idxRef = useRef(1);
    const [parts, setParts] = useState<[string, string]>(
      initialAnimation ? ['', text] : [text, ''],
    );

    useEffect(() => {
      if (prevTextRef.current === text) {
        return;
      }

      const split = fintLongestMatchIndex(prevTextRef.current, text);

      idxRef.current++;
      prevTextRef.current = text;

      setParts([text.slice(0, split), text.slice(split)]);

      return;
    }, [text]);

    return (
      <>
        <span className={textProps?.className ?? ''}>{parts[0]}</span>
        <motion.span
          key={idxRef.current}
          initial={{opacity: 0}}
          animate={{opacity: 1, transition: {duration}}}
          {...textProps}>
          {parts[1]}
        </motion.span>
      </>
    );
  },
);

AnimatedTextCompletion.displayName = 'AnimatedTextCompletion';

export default AnimatedTextCompletion;
