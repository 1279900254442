import AnimatedText from '@/components/AnimatedText';
import Edit05Icon from '@/components/icons/Edit05Icon';
import EqualIcon from '@/components/icons/EqualIcon';
import {PATH} from '@/config';
import {useChatContext} from '@/hooks';
import {Button} from '@nextui-org/react';
import {memo} from 'react';
import {Link as RouterLink} from 'react-router-dom';

const PortalChatHeader = memo(() => {
  const {activeSession, setActiveSession} = useChatContext();

  return (
    <div
      className={`flex flex-[0_0_auto] items-center justify-between gap-4 p-6 px-3 py-3`}>
      <Button
        aria-label="Open chat sessions"
        as={RouterLink}
        to={`/${PATH.segment.chat}/${PATH.segment.sessions}`}
        disableRipple
        isIconOnly
        className={`aspect-square h-auto w-auto min-w-0 shrink-0 justify-between rounded-full bg-transparent px-2 py-0 text-sm font-medium text-foreground data-[hover=true]:bg-foreground/5 data-[selectable=true]:focus:bg-foreground/5`}>
        <EqualIcon className="h-6 w-6 shrink-0 text-primary" />
      </Button>
      <div className="line-clamp-1 overflow-hidden text-ellipsis break-all">
        <AnimatedText
          text={activeSession?.summary ?? ''}
          className="text-medium font-semibold"
        />
      </div>
      <Button
        aria-label="New chat session"
        disableRipple
        isIconOnly
        isDisabled={!activeSession}
        className={`aspect-square h-auto w-auto min-w-0 shrink-0 justify-between rounded-full bg-transparent px-2 py-0 text-sm font-medium text-foreground data-[hover=true]:bg-foreground/5 data-[selectable=true]:focus:bg-foreground/5`}
        onPress={() => setActiveSession()}>
        <Edit05Icon className="h-6 w-6 shrink-0 text-primary" />
      </Button>
    </div>
  );
});

PortalChatHeader.displayName = 'PortalChatHeader';

export default PortalChatHeader;
