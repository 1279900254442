import React, {ComponentProps, memo, useCallback, useMemo} from 'react';
import {ScrollShadow} from '@nextui-org/react';
import {twJoin, twMerge} from 'tailwind-merge';
import {useChatContext} from '@/hooks';
import ChatSessionItem from './ChatSessionItem';
import dayjs from 'dayjs';
import {ChatSessionWithMessages} from '@/common/types/chat';
import {useChatSessions} from '../../hooks';

interface Props {
  className?: ComponentProps<'div'>['className'];
  classNames?: {
    container?: string;
    scroll?: string;
  };
}

const ChatSessions = memo(({className = '', classNames = {}}: Props) => {
  const {unreadCountBySession} = useChatContext();
  const {activeSessionId, sessions, onSessionPress} = useChatSessions({
    groupSessions: false,
  });
  const now = useMemo(() => dayjs(), [activeSessionId]);
  const onItemPress = useCallback(
    (item: ChatSessionWithMessages) => {
      onSessionPress(item.id);
    },
    [onSessionPress],
  );

  return (
    <div
      className={twMerge(
        `flex flex-col pb-[var(--bottom-nav-height)] transition-opacity`,
        className,
        classNames.container,
      )}>
      <ScrollShadow
        aria-label="Chat Sessions"
        className={twMerge(
          `flex flex-1 flex-col overflow-y-auto`,
          classNames.scroll,
        )}
        size={40}>
        {sessions.map((item, idx) => {
          if ('header' in item) {
            return (
              <div
                key={item.header}
                className={twJoin(
                  `mb-1 flex items-center px-6 text-xs text-foreground/60`,
                  idx ? 'mt-8' : '',
                )}>
                {item.header}
              </div>
            );
          }

          const isActive = item.id === activeSessionId;
          const prevItem = idx > 0 ? sessions[idx - 1] : null;
          const showSeparator =
            idx > 0 &&
            prevItem &&
            !('header' in prevItem) &&
            prevItem.id !== activeSessionId &&
            !isActive;

          return (
            <React.Fragment key={item.id}>
              {showSeparator && <div className="mx-4 h-px bg-foreground/10" />}
              <ChatSessionItem
                session={item}
                unreadCount={unreadCountBySession[item.id]}
                now={now}
                isActive={isActive}
                className="lg:rounded-xl"
                onPress={onItemPress}
              />
            </React.Fragment>
          );
        })}
      </ScrollShadow>
    </div>
  );
});

ChatSessions.displayName = 'ChatSessions';

export default ChatSessions;
