import {memo, PropsWithChildren, useCallback, useMemo, useRef} from 'react';
import {SearchParamsContext} from '@/contexts/SearchParamsContext';
import {
  createSearchParams,
  URLSearchParamsInit,
  useSearchParams,
} from 'react-router-dom';

const searchPramsToObject = (searchParams: URLSearchParamsInit) => {
  const params = createSearchParams(searchParams);
  const entries = params.entries();
  const result: Record<string, string> = {};

  for (const [key, value] of entries) {
    result[key] = value;
  }

  return result;
};

export const SearchParamsProvider = memo(({children}: PropsWithChildren) => {
  const [searchParams, setSearchParamsState] = useSearchParams();
  const searchParamsRef = useRef(searchParams);

  searchParamsRef.current = searchParams;

  const setSearchParams = useCallback(
    (params: URLSearchParamsInit) => {
      const newParams = new URLSearchParams(
        searchPramsToObject(searchParamsRef.current),
      );

      Object.entries(params).forEach(([key, value]) => {
        if (!value) {
          newParams.delete(key);
        } else {
          newParams.set(key, value);
        }
      });

      searchParamsRef.current = newParams;

      setSearchParamsState(newParams, {replace: true});
    },
    [setSearchParamsState],
  );

  const value = useMemo(
    () => ({
      searchParams,
      searchParamsRef,
      setSearchParams,
    }),
    [searchParams, searchParamsRef, setSearchParams],
  );

  return (
    <SearchParamsContext.Provider value={value}>
      {children}
    </SearchParamsContext.Provider>
  );
});

SearchParamsProvider.displayName = 'SearchParamsProvider';
