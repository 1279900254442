import {ChangeEvent} from 'react';
import {Field, FormikHelpers} from 'formik';
import {InputProps} from '@nextui-org/react';
import NextUIInput from './NextUIInput';

const NumberInput = ({
  name,
  value,
  setFieldValue,
  ...props
}: InputProps & {setFieldValue: FormikHelpers<any>['setFieldValue']}) => {
  return (
    <Field
      as={NextUIInput}
      name={name}
      type="text"
      inputMode="decimal"
      value={String(value)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value;

        // Replace all commas except the last one and transform the last comma to dot
        val = val.replace(/,(?=.*,)/g, '').replace(',', '.');
        val = val.replace(/([^0-9.]+)/, '');
        val = val.replace(/^0+(?=\d)/, '');

        const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);

        if (!match) {
          setFieldValue(name!, '');
          return;
        }

        const value = (match[1] || '') + (match[2] || '');

        if (value.startsWith('.')) {
          setFieldValue(name!, '0.');
          return;
        }

        setFieldValue(name!, value);
      }}
      {...props}
    />
  );
};

export default NumberInput;
