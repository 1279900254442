import {PropsWithChildren, memo, useEffect, useMemo, useState} from 'react';
import {AuthContext} from '@/contexts';
import {gql} from '@/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {useAppContext} from '@/hooks';
import {getIPGeoInfo, identifyHSUser} from '@/common/analytics';
import {instrumentTracker} from '@/common/instrumentTracker';

// TODO: use pagination for businesses
const GET_USER = gql(`
  query GetUser($userId: ID) {
    user: getUser(id: $userId) {
      id
      createdAt
      updatedAt
      firstName
      lastName
      email
      phone
      newsletter
      description
      avatar
      verified
      stripeCustomerId
      authProvider
      authProviderId
      stripeSubscription {
        id
        status
        nextPaymentAt
        name
        image
        currency
        amount
        interval
        intervalCount
      }
      businesses {
        edges {
          node {
            id
            name
            agents {
              edges {
                node {
                  id
                  templateName
                }
              }
            }
          }
        }
      }
      ipGeolocation
    }
  }
`);

const STORE_GEOLOCATION = gql(`
  mutation StoreUserGeolocation($input: StoreUserGeolocationInput!) {
    storeUserGeolocation(input: $input) {
      id
    }
  }
`);

export const AuthProvider = memo(({children}: PropsWithChildren) => {
  const {isAuthenticated} = useAppContext();
  const [signupEmail, setSignupEmail] = useState('');
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const {data, loading} = useQuery(GET_USER, {
    skip: !isAuthenticated,
  });
  const [storeGeolocation] = useMutation(STORE_GEOLOCATION);
  const value = useMemo(
    () => ({
      business: data?.user.businesses.edges[0]?.node ?? null,
      isLoading: loading,
      user: data?.user,
      isDemo: false,
      resetPasswordEmail,
      setResetPasswordEmail,
      signupEmail,
      setSignupEmail,
    }),
    [data?.user, loading, resetPasswordEmail, signupEmail],
  );

  useEffect(() => {
    if (!data?.user) {
      return;
    }

    identifyHSUser(data.user);

    instrumentTracker.setUserId(data.user.id);

    (async () => {
      const geoInfo = await getIPGeoInfo();

      if (geoInfo) {
        await storeGeolocation({
          variables: {
            input: {
              ...geoInfo,
            },
          },
        });
      }
    })();
  }, [data?.user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
});

AuthProvider.displayName = 'AuthProvider';
