import {
  GetAgentConnectionQuery,
  MessageAuthorType,
  SurveySessionStatus,
} from '@/__generated__/graphql';

export type Agent = GetAgentConnectionQuery['connection']['edges'][0]['node'];

export type ChatMessageAuthorType = 'user' | 'agent';

export type ChatMessage = {
  id: string;
  session_id: string;
  author_type: ChatMessageAuthorType;
  content: string;
  summary?: string | null;
  hidden?: boolean | null;
  created_at: string;
  updated_at: string;
};

export type ChatMessageHeaderItem = {
  itemType: 'header';
  type: ChatMessageAuthorType;
  name: string;
  avatarUrl?: string;
};

export type ChatUnreadSeparatorItem = {
  itemType: 'unreadSeparator';
};

export type ChatItem =
  | ChatMessage
  | ChatMessageHeaderItem
  | ChatUnreadSeparatorItem;

export enum SessionSSEEventType {
  messageAdded = 'messageAdded',
  messageUpdated = 'messageUpdated',
  messageSummaryAdded = 'messageSummaryAdded',
  messageError = 'messageError',
  summary = 'summary',
  sessionLocked = 'sessionLocked',
  terminated = 'terminated',
}

export type ChatSession = {
  id: string;
  summary?: string | null;
  agent?: Pick<
    Agent,
    'id' | 'name' | 'logo' | 'chatAvatar' | 'templateName'
  > | null;
  locked?: boolean;
  created_at: string;
  updated_at: string;
  last_read_at?: string;
};

export type ChatSessionWithMessages = ChatSession & {
  messages: ChatMessage[];
  messagesPageInfo?: {
    hasNextPage: boolean;
    endCursor?: string | null;
  };
};

// API types

export type SurveyChatSession = {
  id: string;
  surveyId: string;
  status?: SurveySessionStatus | null | undefined;
  created_at: string;
  updated_at: string;
  survey: {
    name: string;
  };
};

export type SurveyChatMessage = {
  id: string;
  content: string;
  session_id: string;
  author_type: MessageAuthorType;
  created_at: string;
  updated_at: string;
};
