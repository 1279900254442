import {useAuthContext} from '@/hooks';
import {useEffect, useMemo} from 'react';
import {useQuery} from '@apollo/client';
import {
  GET_AGENT_CONNECTION,
  GET_INSIGHT_WITH_AGENT_CONNECTION,
} from '@/common/queries';
import {useInView} from 'react-intersection-observer';

export const usePaginatedInsights = () => {
  const {ref: insightsEndRef, inView: insightsEndReached} = useInView();
  const {business} = useAuthContext();
  const insightsData = useQuery(GET_INSIGHT_WITH_AGENT_CONNECTION, {
    variables: {
      first: 20,
      filter: {
        topics: {
          $any: {
            businessId: business?.id,
          },
        },
      },
      order: {createdAt: 'DESC'},
    },
    skip: !business?.id,
  });
  const pageInfo = insightsData.data?.connection.pageInfo;
  const endCursor = pageInfo?.endCursor;
  const hasMore = pageInfo?.hasNextPage ?? false;
  const insights = useMemo(
    () => insightsData.data?.connection.edges?.map(ins => ins.node) ?? [],
    [insightsData.data],
  );

  useEffect(() => {
    if (insightsEndReached && hasMore && !insightsData.loading) {
      insightsData.fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  }, [insightsEndReached, hasMore, insightsData.loading, endCursor]);

  return {
    insightsEndRef,
    insightsEndReached,
    insights,
    insightsLoading: insightsData.loading,
  };
};

export const useActiveTools = () => {
  const {business} = useAuthContext();
  const agentsResult = useQuery(GET_AGENT_CONNECTION, {
    variables: {
      filter: {
        is_new: true,
        is_listed: true,
        businesses: {
          $any: {
            id: business?.id,
          },
        },
      },
    },
    skip: !business?.id,
  });

  const activeTools = useMemo(
    () => agentsResult.data?.connection?.edges?.map(agent => agent.node) ?? [],
    [agentsResult.data],
  );

  return {
    activeTools,
    activeToolsLoading: agentsResult.loading,
  };
};
