import {SVGProps} from 'react';

export default function SendIcon({
  enabled,
  ...props
}: SVGProps<SVGSVGElement> & {enabled: boolean}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      {enabled ? (
        <path d="M21.5946 1.4575C21.1988 1.32529 20.8303 1.4209 20.6472 1.47411C20.4475 1.5321 20.2096 1.625 19.9709 1.7182L3.0138 8.3356C2.74697 8.43969 2.48852 8.54051 2.28905 8.63914C2.11669 8.72436 1.75035 8.91405 1.54586 9.30766C1.32036 9.74172 1.32067 10.2585 1.54668 10.6923C1.75163 11.0856 2.11819 11.2749 2.29065 11.3599C2.49023 11.4583 2.74875 11.5588 3.0157 11.6626L7.73572 13.4981C8.08784 13.6351 8.2639 13.7035 8.43905 13.7077C8.59387 13.7115 8.74744 13.6792 8.88765 13.6134C9.04628 13.539 9.17985 13.4054 9.447 13.1383L14.2924 8.29289C14.6829 7.90237 15.3161 7.90237 15.7066 8.29289C16.0971 8.68342 16.0971 9.31658 15.7066 9.70711L10.8612 14.5525C10.5941 14.8197 10.4605 14.9532 10.3861 15.1119C10.3203 15.2521 10.2881 15.4056 10.2918 15.5605C10.296 15.7356 10.3644 15.9117 10.5014 16.2638L12.3369 20.9837C12.4407 21.2507 12.5412 21.5093 12.6396 21.7089C12.7246 21.8813 12.9139 22.2479 13.3072 22.4528C13.741 22.6788 14.2578 22.6791 14.6919 22.4536C15.0855 22.2492 15.2751 21.8828 15.3604 21.7105C15.459 21.511 15.5598 21.2526 15.6639 20.9858L22.2813 4.02861C22.3745 3.7899 22.4674 3.55198 22.5254 3.35235C22.5786 3.16916 22.6742 2.8007 22.542 2.40493C22.3926 1.95779 22.0417 1.60688 21.5946 1.4575Z" />
      ) : (
        <path
          d="M10.4995 13.5001L20.9995 3.00005M10.6271 13.8281L13.2552 20.5861C13.4867 21.1815 13.6025 21.4791 13.7693 21.566C13.9139 21.6414 14.0862 21.6415 14.2308 21.5663C14.3977 21.4796 14.5139 21.1821 14.7461 20.587L21.3364 3.69925C21.5461 3.16207 21.6509 2.89348 21.5935 2.72185C21.5437 2.5728 21.4268 2.45583 21.2777 2.40604C21.1061 2.34871 20.8375 2.45352 20.3003 2.66315L3.41258 9.25349C2.8175 9.48572 2.51997 9.60183 2.43326 9.76873C2.35809 9.91342 2.35819 10.0857 2.43353 10.2303C2.52043 10.3971 2.81811 10.5128 3.41345 10.7444L10.1715 13.3725C10.2923 13.4195 10.3527 13.443 10.4036 13.4793C10.4487 13.5114 10.4881 13.5509 10.5203 13.596C10.5566 13.6468 10.5801 13.7073 10.6271 13.8281Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
